import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';

import { FormsValidators } from '../validators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[greaterThan]',
  // eslint-disable-next-line no-use-before-define
  providers: [{ provide: NG_VALIDATORS, useExisting: GreaterThanValidatorDirective, multi: true }],
  standalone: true,
})
export class GreaterThanValidatorDirective implements Validator {
  @Input() public greaterThan: string | number;

  public validate(c: UntypedFormControl) {
    return FormsValidators.greaterThan(+this.greaterThan)(c);
  }
}
