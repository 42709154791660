import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { contactUsPhone } from '../../constants';
import { AnalyticsService } from '../../services';
import { NgIf } from '@angular/common';
@Component({
  selector: 'lib-contact-page',
  templateUrl: 'contact-page.component.html',
  styleUrls: ['contact-page.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ContactPageComponent implements AfterViewInit {
  public contactUsPhone = contactUsPhone;
  public constructor(private analyticsService: AnalyticsService, private activatedRoute: ActivatedRoute) {}

  public ngAfterViewInit() {
    this.analyticsService.log('Contact Us');
  }

  public get isLenderHub(): boolean {
    return !!this.activatedRoute.snapshot.data.isLenderHub;
  }
}
