<div [formGroup]="formGroup">
  <div class="mb-4 row">
    <label class="col-12" [for]="id + 'Text'">Please provide {{ placeholder }}:</label>
    <div class="col-12">
      <app-esis-html-editor
        [id]="id + 'Text'"
        [control]="formGroup.get('text')"
        [placeholder]="placeholder" />
    </div>
  </div>
</div>
