import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '@msslib/services/data.service';
import { ToastService } from '@msslib/services/toast.service';
import { ConfigService } from '@msslib/services/config.service';
// import { EMAIL_VERIFICATION_SERVICE, type IEmailVerificationService } from '@msslib/models/email-verification';
import { EmailVerificationService } from './email-verification.service';

@Injectable({
  providedIn: 'root',
})
export class ClubHubDataService extends DataService {
  public constructor(
    http: HttpClient,
    toastService: ToastService,
    configService: ConfigService,
    emailVerificationService: EmailVerificationService,
    // @Inject(EMAIL_VERIFICATION_SERVICE) emailVerificationService: IEmailVerificationService,
  ) {
    super(
      http,
      toastService,
      configService,
      configService.config.clubHubApiUrl,
      emailVerificationService,
    );
  }
}
