import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lib-form-checkbox-list',
  styleUrls: ['form-checkbox-list.component.scss'],
  templateUrl: 'form-checkbox-list.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgIf,
  ],
})
export class FormCheckboxListComponent extends FieldBaseComponent {
  public get controls() {
    return (this.formGroup.controls[this.config.name] as any).controls;
  }

  public get validation() {
    return this.controls.find((valid: Record<string, boolean>) => valid.value) ? true : false;
  }
}
