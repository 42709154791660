import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { NgIf } from '@angular/common';
import { EsisHtmlEditorComponent } from '../esis-html-editor/esis-html-editor.component';

@Component({
  selector: 'app-non-compliance',
  templateUrl: 'non-compliance.component.html',
  styleUrls: ['non-compliance.component.scss'],
  standalone: true,
  imports: [
    EsisHtmlEditorComponent,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
  ],
})

export class NonComplianceComponent {
  @Input() public nonCompliance: UntypedFormGroup;
  @Input() public nonComplianceCI: UntypedFormGroup;
  @Input() public nonComplianceIO: UntypedFormGroup;
  @Input() public lenderUser: string | null;

  public get isHalifax() {
    return this.lenderUser === 'Halifax';
  }

  public get isScottishWidows() {
    return this.lenderUser === 'Scottish Widows';
  }

  public get isExtendedNonCompliance(): boolean {
    return this.isHalifax || this.isScottishWidows;
  }

  public text(field: string) {
    return this[field].get('text');
  }

  public valid(field: string, name: string): boolean {
    return this[field].controls[name].valid && this[field].controls[name].touched;
  }

  public invalid(field: string, name:string): boolean {
    return this[field].controls[name].invalid && this[field].controls[name].touched;
  }
}
