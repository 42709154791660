import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormControl,
  UntypedFormGroup, Validators } from '@angular/forms';
import { IncentivesAdditionalFeatures } from 'apps/shared/src/models';
import { FlexibleFeaturesIncentivesDisplayRulesModalComponent }
  from '../flexible-features-incentives-display-rules-modal/flexible-features-incentives-display-rules-modal.component';
import { Condition, RulesEngineSchema, cloneCondition } from '@msslib/models/rules-engine';
import { ModalService } from '@msslib/services';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { EsisHtmlEditorComponent } from '../esis-html-editor/esis-html-editor.component';
import { FormsValidators } from '@msslib/components';

@Component({
  selector: 'app-incentives-additional-features',
  templateUrl: 'incentives-additional-features.component.html',
  styleUrls: ['incentives-additional-features.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    NgClass,
    FlexibleFeaturesIncentivesDisplayRulesModalComponent,
    EsisHtmlEditorComponent,
  ],
})

export class IncentivesAdditionalFeaturesComponent {
  @ViewChild('displayRulesModalTemplateRef') public displayRulesModalTemplateRef: TemplateRef<void>;
  @ViewChild('displayRulesModalComponent') public displayRulesModalComponent:
    FlexibleFeaturesIncentivesDisplayRulesModalComponent;
  @Input() public incentivesAdditionalFeatures: UntypedFormArray;
  @Input() public incentives: IncentivesAdditionalFeatures[];
  @Input() public displayRulesSchema: RulesEngineSchema;
  @Input() public disabled = false;
  public removingItem: number | null = null;

  public editingDisplayRuleIndex: number;
  public editingDisplayRuleCondition: Condition | null;

  public constructor(private fb: UntypedFormBuilder, private modalService: ModalService) {
  }

  public createItem(type = '', text = '', condition: Condition | null = null): UntypedFormGroup {
    const formGroup = this.fb.group({
      type: new UntypedFormControl(type, Validators.pattern('^[a-zA-Z0-9 _-]+$')),
      text: new UntypedFormControl(text, {
        validators: [FormsValidators.requiredHtml],
        updateOn: 'blur',
      }),
      condition,
    });
    if (this.disabled) {
      formGroup.disable({ onlySelf: false });
    }
    return formGroup;
  }

  public addItem(type = '', text = '', condition: Condition | null = null): void {
    this.incentivesAdditionalFeatures.push(this.createItem(type, text, condition));
  }

  public removeFee(featureIndex: number) {
    this.incentivesAdditionalFeatures.removeAt(featureIndex);
    this.removingItem = null;
  }

  public valid(name:string, index:number): boolean {
    const control = (this.incentivesAdditionalFeatures.controls[index] as UntypedFormGroup).controls[name];
    return control.valid && control.touched;
  }

  public invalid(name:string, index:number): boolean {
    const control = (this.incentivesAdditionalFeatures.controls[index] as UntypedFormGroup).controls[name];
    return control.invalid && control.touched;
  }

  public getControl(name:string, index:number) {
    return (this.incentivesAdditionalFeatures.controls[index] as UntypedFormGroup).get(name);
  }

  public hasDisplayCondition(index: number): boolean {
    return !!this.incentivesAdditionalFeatures.get([index, 'condition'])?.value;
  }

  public editDisplayCondition(index: number) {
    this.editingDisplayRuleIndex = index;
    // create a clone so that editing it and closing the modal without clicking 'apply' doesn't change the original
    const existingCondition = this.incentivesAdditionalFeatures.get([index, 'condition'])?.value as Condition | null;
    this.editingDisplayRuleCondition = existingCondition ? cloneCondition(existingCondition) : null;

    this.modalService.open({
      title: 'Visible Only When',
      component: this.displayRulesModalTemplateRef,
      sticky: true,
    }).catch(() => { /* ignore unhandled promise error */ });
  }

  public saveDisplayRuleCondition(newCondition: Condition | null) {
    const field = this.incentivesAdditionalFeatures.get([this.editingDisplayRuleIndex, 'condition']);
    field?.setValue(newCondition);
    field?.markAsTouched();
    field?.markAsDirty();
  }
}
