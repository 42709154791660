<!--Implement full modal here (instead of just content) so that we can add a save alert here -->
<div class="modal-header">
  <h3 class="modal-title">Visible Only When</h3>
  <div class="d-flex align-items-center">
    <span [@fadeInOut]="showSaveSuccessMessage ? 'in' : 'out'" class="bg-success me-2 px-2 py-1 text-white" role="alert">
      Rules saved successfully
    </span>
    <button type="button" aria-label="Close" class="btn-close" (click)="dismiss()"></button>
  </div>
</div>

<div class="modal-body bg-light">
  <lib-rules-engine-condition-editor
    [schema]="schema"
    [condition]="condition"
    (conditionChange)="onConditionChange($event)"
    [canDeleteCondition]="true"
    [showErrors]="showErrors"
    [disabled]="disabled" />

  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-2022--secondary px-4" [disabled]="disabled" (click)="apply()">Apply</button>
  </div>
</div>
