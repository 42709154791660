import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lib-form-select',
  styleUrls: ['form-select.component.scss'],
  templateUrl: 'form-select.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgbTooltip,
    NgClass,
    NgFor,
    FormFieldErrorComponent,
  ],
})
export class FormSelectComponent extends FieldBaseComponent {
  public onChange($event: Event) {
    if (this.config.onSelectChange) {
      this.config.onSelectChange(($event.target as HTMLInputElement).value);
    }
  }
}
