import { Component } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { NgFor } from '@angular/common';

@Component({
  templateUrl: 'repeating.component.html',
  standalone: true,
  imports: [NgFor, FormlyModule],
})
export class RepeatingFieldTypeComponent extends FieldArrayType {}
