import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/naming-convention
import { NgIf } from '@angular/common';
import { EsisHtmlEditorComponent } from '../esis-html-editor/esis-html-editor.component';

@Component({
  selector: 'app-additional-information',
  templateUrl: 'additional-information.component.html',
  styleUrls: ['additional-information.component.scss'],
  standalone: true,
  imports: [
    EsisHtmlEditorComponent,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
  ],
})

export class AdditionalInformationComponent {
  @Input() public additionalInformation: UntypedFormGroup;

  public get text() {
    return this.additionalInformation.get('text');
  }

  public valid(name:string): boolean {
    return this.additionalInformation.controls[name].valid && this.additionalInformation.controls[name].touched;
  }

  public invalid(name:string): boolean {
    return this.additionalInformation.controls[name].invalid && this.additionalInformation.controls[name].touched;
  }
}
