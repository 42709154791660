import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ModalStateService } from '../../services';
import { ModalOptions } from '../../models';
import { ɵEmptyOutletComponent } from '@angular/router';
import { HorizontalFieldWrapperComponent }
  from '../formly/wrappers/horizontal-field-wrapper/horizontal-field-wrapper.component';
import { FormCardWrapperComponent } from '../formly/wrappers/form-card-wrapper/form-card-wrapper.component';
import { CurrencyMultiAddFieldTypeComponent } from '../formly/fields/currency-multi-add/currency-multi-add.component';
import { AdditionalIncomeHeaderFieldTypeComponent }
  from '../formly/fields/additional-income-header/additional-income-header.component';
import { TextAreaFieldTypeComponent } from '../formly/fields/textarea/textarea.component';
import { MultiSelectFieldTypeComponent } from '../formly/fields/multiselect/multiselect.component';
import { SelectFieldTypeComponent } from '../formly/fields/select/select.component';
import { RepeatingFieldTypeComponent } from '../formly/fields/repeating/repeating.component';
import { RadioListComponent } from '../formly/fields/radio-list/radio-list.component';
import { RadioButtonFieldTypeComponent } from '../formly/fields/radio-button/radio-button.component';
import { PostcodeInputComponent } from '../formly/fields/postcode-input/postcode-input.component';
import { NumberFieldTypeComponent } from '../formly/fields/number/number.component';
import { InputFieldTypeComponent } from '../formly/fields/input/input.component';
import { DisabledFieldTypeComponent } from '../formly/fields/disabled/disabled.component';
import { DateFieldTypeComponent } from '../formly/fields/date/date.component';
import { CurrencyFieldTypeComponent } from '../formly/fields/currency/currency.component';
import { ButtonsFieldTypeComponent } from '../formly/fields/buttons/buttons.component';
import { NgIf, NgTemplateOutlet } from '@angular/common';

/**
 * The component displayed in the confirmation modal opened by the ModalService.
 */
@Component({
  selector: 'lib-modal-component',
  styleUrls: ['modal.component.scss'],
  templateUrl: 'modal.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    ButtonsFieldTypeComponent,
    CurrencyFieldTypeComponent,
    DateFieldTypeComponent,
    DisabledFieldTypeComponent,
    InputFieldTypeComponent,
    NumberFieldTypeComponent,
    PostcodeInputComponent,
    RadioButtonFieldTypeComponent,
    RadioListComponent,
    RepeatingFieldTypeComponent,
    SelectFieldTypeComponent,
    MultiSelectFieldTypeComponent,
    TextAreaFieldTypeComponent,
    AdditionalIncomeHeaderFieldTypeComponent,
    CurrencyMultiAddFieldTypeComponent,
    FormCardWrapperComponent,
    HorizontalFieldWrapperComponent,
    ɵEmptyOutletComponent,
  ],
})
export class ModalComponent implements AfterViewInit {
  public options: ModalOptions;
  public isDisabled = false;

  public constructor(private state: ModalStateService, private readonly changeDetectorRef: ChangeDetectorRef) {
    this.options = state.options;
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      if (this.options.disabledTillScroll) {
        this.isDisabled = true;
      }
    }, 200);
  }

  public onScroll(event: any) {
    const distanceFromBottom = 120;
    if (
      this.options.disabledTillScroll &&
      this.isDisabled &&
      event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - distanceFromBottom
    ) {
      this.isDisabled = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  public yes() {
    this.state.topmostModal?.close('confirmed');
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
  }

  public no() {
    this.state.topmostModal?.dismiss('not confirmed');
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
  }

  public cross() {
    this.state.topmostModal?.dismiss('Cross click');
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
  }

  public get titleAlignment() {
    switch (this.options.titleAlign) {
      case 'left':
        return 'text-left';
      case 'center':
        return 'text-center ml-4';
      case 'right':
        return 'text-right';
      default:
        return 'text-left';
    }
  }

  public get titleFloatingContentIsHtmlString(): boolean {
    return typeof this.options.titleFloatingContent === 'string';
  }
}
