import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Condition,
  ConditionType,
  LogicalGroupCondition,
  LogicalOperator,
  PropertyCondition,
  RulesEngineSchema,
  alwaysConditionPropertyName,
} from '@msslib/models/rules-engine';
import { RulesEngineLogicalGroupConditionEditorComponent }
  from '../logical-group-condition-editor/logical-group-condition-editor.component';
import { RulesEnginePropertyConditionEditorComponent }
  from '../property-condition-editor/property-condition-editor.component';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'lib-rules-engine-condition-editor',
  templateUrl: 'condition-editor.component.html',
  styleUrls: ['condition-editor.component.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    RulesEnginePropertyConditionEditorComponent,
    NgIf,
    RulesEngineLogicalGroupConditionEditorComponent,
  ],
})
export class RulesEngineConditionEditorComponent {
  @Input({ required: true }) public schema: RulesEngineSchema;

  @Input({ required: true }) public condition: Condition | null;
  @Output() public conditionChange = new EventEmitter<Condition | null>();

  /** Whether or not this condition is editable. */
  @Input() public disabled = false;

  /** Whether or not this condition editor is the root of the condition tree. */
  @Input() public isRoot = true;

  /** Whether or not to show errors to the user. */
  @Input() public showErrors = false;

  /** Whether or not the 'Always' condition is present. */
  @Input() public allowAlwaysCondition = false;
  @Input() public alwaysConditionLabel = '-Always-';

  /** Whether or not to show the bin icon next to property conditions. */
  @Input() public canDeleteCondition = false;

  /** If not the root, what the parent group's logical operator is. The inverse is used as default for new groups. */
  @Input() public parentLogicalOperator: LogicalOperator | undefined;

  @Input() public testIdSuffix: string = '';

  public readonly conditionType = ConditionType;

  public addAdjacentCondition() {
    this.condition = this.condition
      ? {
        type: ConditionType.LogicalGroup,
        operator: this.parentLogicalOperator === LogicalOperator.And ? LogicalOperator.Or : LogicalOperator.And,
        operands: [
          // If this was an 'Always' condition, that reset it to a default one as we can only have 'always' at the root
          this.condition.type === ConditionType.Property && this.condition.propertyName === alwaysConditionPropertyName
            ? { type: ConditionType.Property, propertyName: '' } as PropertyCondition
            : this.condition,
          { type: ConditionType.Property, propertyName: '' } as PropertyCondition,
        ],
      } satisfies LogicalGroupCondition
      : { type: ConditionType.Property, propertyName: '' } as PropertyCondition;
    this.conditionChange.emit(this.condition);
  }

  // Special handler for the group condition: when we delete the penultimate child condition (so there is only one left)
  // we remove the group and replace this condition with the one remaining child condition.
  public groupConditionChange(newCondition: LogicalGroupCondition) {
    if (newCondition.operands.length === 1) {
      this.condition = newCondition.operands[0];
    }

    this.conditionChange.emit(this.condition);
  }

  public onDeleteCondition() {
    this.condition = null;
    this.conditionChange.emit(null);
  }

  public collapseGroup() {
    if (this.condition?.type === ConditionType.LogicalGroup && this.condition.operands.length === 1) {
      this.condition = this.condition.operands[0];
    }
  }
}
