import { Component } from '@angular/core';
import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgFor } from '@angular/common';

@Component({
  templateUrl: 'radio-button.component.html',
  styleUrls: ['radio-button.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
})
export class RadioButtonFieldTypeComponent extends MssFieldBaseFieldTypeComponent {
}
