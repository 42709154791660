import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormControl,
  UntypedFormGroup } from '@angular/forms';
import { FieldTypes, IFieldConfig } from '@msslib/models';
import { FormFieldDirective } from '../../directives/form-field.directive';
import { NgFor } from '@angular/common';

@Component({
  exportAs: 'appForm',
  selector: 'lib-form',
  styleUrls: ['form.component.scss'],
  templateUrl: 'form.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    FormFieldDirective,
  ],
})
export class AppFormComponent implements OnInit, OnChanges {
  @ViewChild('formRef', { static: true }) public ngForm: NgForm;
  @Input() public model: unknown;
  @Input() public fullWidth: boolean;
  public form: UntypedFormGroup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _config: IFieldConfig[] = [];

  @Input()
  public set config(value: IFieldConfig[]) {
    this._config = value;
  }

  public get config(): IFieldConfig[] {
    return this._config.filter(x => !x.hidden);
  }

  public constructor(private fb: UntypedFormBuilder) {}

  public ngOnInit() {
    this.form = this.createGroup();
    if (this.model) {
      this.form.patchValue(this.model);
    }
  }

  public ngOnChanges() {
    this.configChanged();
  }

  public configChanged() {
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      const visibleConfig = this.visibleConfig.map(item => item.name);

      controls
        .filter(control => !visibleConfig.includes(control))
        .forEach(control => this.form.removeControl(control));

      visibleConfig
        .filter(control => !controls.includes(control))
        .forEach(name => {
          const config = this.config.find(control => control.name === name);
          if (config) {
            this.form.addControl(name, this.createControl(config));
          }
        });
    }
  }

  public get controls() {
    return this.form.controls;
  }

  public get rawValue() {
    return this.form.getRawValue();
  }

  public get changes() {
    return this.form.valueChanges;
  }

  public get valid() {
    return this.form.valid;
  }

  public get value() {
    return this.form.value;
  }

  public get submitted() {
    return this.ngForm.submitted;
  }

  private get visibleConfig() {
    return this.config
      .filter(({ type, hidden }) => type !== FieldTypes.Button && type !== FieldTypes.ButtonGroup && !hidden);
  }

  public createGroup() {
    const group = this.fb.group({});
    this.visibleConfig.forEach(control => group.addControl(control.name, this.createControl(control)));
    return group;
  }

  public createControl(config: IFieldConfig): UntypedFormControl | UntypedFormArray {
    const { disabled, validation, asyncValidation, value, options } = config;
    if (config.type === FieldTypes.CheckboxList && options) {
      return this.fb.array(
        options.map((item) => {
          return this.fb.control(item.selected);
        }),
        config.validation ? config.validation[0] : null,
      );
    }
    return this.fb.control({ disabled, value }, validation, asyncValidation);
  }

  public setDisabled(name: string, disable: boolean) {
    if (this.form.controls[name]) {
      const method = disable ? 'disable' : 'enable';
      this.form.controls[name][method]();
      return;
    }

    this.config = this.config.map(item => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  public setValue(name: string, value: unknown) {
    this.form.controls[name].setValue(value, { emitEvent: true });
  }

  public patchForm(model: Record<string, unknown>) {
    this.form.patchValue(model);
  }

  public reset() {
    this.ngForm.resetForm();
  }

  public validate() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }

  public showControl(name: string, show: boolean) {
    const config = this._config.find(control => control.name === name);
    if (config) {
      config.hidden = !show;
    }

    if (show && config) {
      this.form.addControl(name, this.createControl(config));
    } else {
      this.form.removeControl(name);
    }
  }
}
