import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder,
  UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { roles } from '@msslib/constants';
import { FeeTypesObj, ProductFeeType, getFormattedFeeTypes } from '@msslib/constants/product-fees';
import { AuthorizeService } from '@msslib/services';
import { CostsToBePaid, WhenPayable, WhenRefundable } from 'apps/shared/src/models';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CurrencyMaskDirective } from '@msslib/components';
import { EnumValuesPipe, StartCasePipe } from '@msslib/pipes';

@Component({
  selector: 'app-costs-to-be-paid',
  templateUrl: 'costs-to-be-paid.component.html',
  styleUrls: ['costs-to-be-paid.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    NgClass,
    CurrencyMaskDirective,
    EnumValuesPipe,
    StartCasePipe,
  ],
})

export class CostsToBePaidComponent implements OnInit {
  @Input() public costsToBePaid: UntypedFormArray;
  @Input() public costs: CostsToBePaid[];
  @Input() public feesMissingInEsis: boolean;
  @Output() public costsToBePaidFormTouched = new EventEmitter<boolean>();
  @Input() public disabled = false;
  public removingItem: number | null = null;
  public whenPayable = WhenPayable;
  public whenRefundable = WhenRefundable;
  public skippedFees = [ProductFeeType.Custom];
  public allFeeTypes: FeeTypesObj[] = getFormattedFeeTypes(this.skippedFees);

  public constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthorizeService,
  ) { }

  public ngOnInit() {

    if (!this.authService.hasRole(roles.packager)) {
      this.skippedFees.push(ProductFeeType.Packager);
      this.allFeeTypes = getFormattedFeeTypes(this.skippedFees);
    }
  }

  public createItem(
    type: ProductFeeType | null = null,
    whenRefundable: WhenRefundable | null = null,
    amountRefundable: number | null = null,
    addToLoan: boolean | null = null,
    whenPayable: WhenPayable | null = null,
  ): UntypedFormGroup {
    const formGroup = this.fb.group({
      type: new UntypedFormControl(type, Validators.pattern('^[a-zA-Z0-9 _-]+$')),
      whenRefundable: new UntypedFormControl(whenRefundable, Validators.min(1)),
      amountRefundable: new UntypedFormControl(amountRefundable, this.amountRefundableValidator),
      addToLoan,
      whenPayable: new UntypedFormControl(whenPayable, Validators.min(1)),
    });
    if (this.disabled) {
      formGroup.disable({ onlySelf: false });
    }
    return formGroup;
  }

  public addItem(
    type: ProductFeeType | null = null,
    whenRefundable: WhenRefundable | null = null,
    amountRefundable: number | null = null,
    addToLoan: boolean | null = null,
    whenPayable: WhenPayable | null = null,
  ): void {
    this.costsToBePaid.push(this.createItem(type, whenRefundable, amountRefundable, addToLoan, whenPayable));
  }

  public removeFee(costIndex: number) {
    this.costsToBePaid.removeAt(costIndex);
    this.costsToBePaid.markAsDirty();
    this.removingItem = null;
  }

  public valid(name: string, index: number): boolean {
    const control = (this.costsToBePaid.controls[index] as UntypedFormGroup).controls[name];
    return control.valid && control.touched;
  }

  public invalid(name: string, index: number): boolean {
    const control = (this.costsToBePaid.controls[index] as UntypedFormGroup).controls[name];
    return control.invalid && control.touched;
  }

  public value(name: string, index: number) {
    return (this.costsToBePaid.controls[index] as UntypedFormGroup).controls[name].value;
  }

  public errors(name: string, index: number) {
    return (this.costsToBePaid.controls[index] as UntypedFormGroup).controls[name].errors;
  }

  public trackByKey(_index: number, item: { key: unknown }) {
    return item.key;
  }

  public whenRefundableChange(newValue: WhenRefundable, index: number) {
    // When the refundable dropdown changes, update the refundable amount.
    // If set to 'Not Refundable', clear the value otherwise set it to the current value, which will force the
    // validation to re-run. If we do not re-run validation, when moving from not refundable to another option, the user
    // can immediately press save and save a £0 value against a required refundable amount.
    const amountControl = this.costsToBePaid.get([index, 'amountRefundable']);
    amountControl?.setValue(+newValue as WhenRefundable === WhenRefundable.NotRefundable ? 0 : +amountControl.value);
  }

  public amountRefundableValidator(control: AbstractControl): ValidationErrors | null {
    // If the fee that this refundable amount is for is refundable, run a required and a min validator on it.
    if (
      +(control.parent?.getRawValue() as CostsToBePaid)?.whenRefundable as WhenRefundable !==
      WhenRefundable.NotRefundable
    ) {
      return (Validators.compose([Validators.required, Validators.min(0.01)])?.(control) ?? null);
    }
    return null;
  }

  public get showMissingFeesMessage() {
    return this.costsToBePaid.untouched && this.feesMissingInEsis;
  }
}
