import { Component } from '@angular/core';
import { FieldBaseComponent } from '../field-base';
import { SlugifyPipe } from '../../../../pipes/slugify.pipe';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lib-form-input',
  styleUrls: ['form-input.component.scss'],
  templateUrl: 'form-input.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgbTooltip,
    NgClass,
    FormFieldErrorComponent,
    SlugifyPipe,
  ],
})
export class FormInputComponent extends FieldBaseComponent {}
