<div class="modal-header">
  <h3 class="modal-title">{{title}}</h3>
  <div class="d-flex align-items-center">
    <button
      *ngIf="activePage === page.Form"
      type="submit"
      (click)="onSubmit()"
      [class.btn-2022--disabled]="!form.valid"
      [class.btn-2022--secondary]="form.valid"
      class="btn me-2 modal-header-btn"
    >
      Generate Document
    </button>

    <ng-container *ngIf="activePage === page.Preview">
      <button type="button" (click)="activePage = page.Form" class="btn me-2 modal-header-btn">
        <i class="fa fa-edit"></i>
      </button>

      <button type="button" class="btn me-2 modal-header-btn" (click)="printPdf()">
        <i class="fa fa-print"></i>
      </button>
    </ng-container>

    <button type="button" aria-label="Close" class="btn-close" (click)="dismiss()"></button>
  </div>
</div>

<div class="modal-body bg-light">
  <ng-container [ngSwitch]="activePage">
    <!-- Loading page -->
    <div *ngSwitchCase="page.Loading" class="d-flex flex-column align-items-center justify-content-center">
      <div class="spinner-border text-2022--primary" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <!-- Form page -->
    <div *ngSwitchCase="page.Form">

      <div class="mb-3">
        <label class="input-label">Select Product <sup>*</sup></label>
        <lib-simple-grid
          [datasource]="availableProducts"
          [columns]="selectProductColumns"
          [trackRowByFn]="trackProductRowByFn"
          [rowSelectionMode]="1"
          [pageSizeInit]="10"
          [minGridHeight]="0"
          [selectedRowKeys]="selectedProductIds"
          (selectedRowsChange)="onProductSelected($event[0])"
        >
          <ng-template sgCellTemplate columnName="type" let-row="row">
            <lib-type-product-column [useProductTransferAvailability]="true" [product]="row"></lib-type-product-column>
          </ng-template>
        </lib-simple-grid>
      </div>
      <div class="invalid-feedback d-block" *ngIf="invalid('productId')">
        <span *ngIf="errors('productId').required">Please select a product.</span>
      </div>

      <form [formGroup]="form" class="form-container">
        <div class="mb-3">
          <label class="input-label">Mortgage Type <sup>*</sup></label>
          <div class="rounded-2 py-1 border border-2022--grey bg-white">
            <div class="btn-group d-flex">
              <div class="col px-1">
                <input formControlName="mortgageType" id="previewModel_mortgageType_purchase" type="radio" class="btn-check" [value]="mortgageType.Purchase">
                <label
                  for="previewModel_mortgageType_purchase"
                  class="btn btn-sm w-100 btn-2022--secondary py-1"
                  [class.btn-2022--white]="form.value.mortgageType !== mortgageType.Purchase">
                  Purchase
                </label>
              </div>
              <div class="col px-1 border-start">
                <input formControlName="mortgageType" id="previewModel_mortgageType_remortgage" type="radio" class="btn-check" [value]="mortgageType.Remortgage">
                <label
                  for="previewModel_mortgageType_remortgage"
                  class="btn btn-sm w-100 btn-2022--secondary py-1"
                  [class.btn-2022--white]="form.value.mortgageType !== mortgageType.Remortgage">
                  Remortgage
                </label>
              </div>
            </div>
          </div>
          <div class="invalid-feedback d-block" *ngIf="invalid('mortgageType')">
            <span *ngIf="errors('mortgageType').required">Please select a mortgage type.</span>
          </div>
        </div>

        <div class="mb-3">
          <label class="input-label">Property Value <sup>*</sup></label>
          <div class="input-group input-group-sm">
            <span class="input-group-text">£</span>
            <input formControlName="propertyValue" type="text" class="form-control" min="0" step="1000" maxlength="11" currencyMask (keydown)="preventUnwanted($event)">
          </div>
          <div class="invalid-feedback d-block" *ngIf="invalid('propertyValue')">
            <span *ngIf="errors('propertyValue').required">Please enter a property value.</span>
            <span *ngIf="errors('propertyValue').min">Property value must be at least £1.</span>
          </div>
        </div>

        <div class="mb-3">
          <label class="input-label">Loan Amount <sup>*</sup></label>
          <div class="input-group input-group-sm">
            <span class="input-group-text">£</span>
            <input formControlName="loanAmount" type="text" class="form-control" min="0" step="1000" maxlength="11" currencyMask (keydown)="preventUnwanted($event)">
          </div>
          <div class="invalid-feedback d-block" *ngIf="invalid('loanAmount')">
            <span *ngIf="errors('loanAmount').required">Please enter a loan amount.</span>
            <span *ngIf="errors('loanAmount').min">Loan amount must be at least £1.</span>
            <span *ngIf="errors('loanAmount').max">Loan amount should not exceed 500% of property value.</span>
          </div>
        </div>

        <div class="mb-3">
          <label class="input-label">Loan to Value</label>
          <div class="input-group input-group-sm">
            <input type="text" class="form-control" [value]="ltv" disabled>
            <span class="input-group-text">%</span>
          </div>
        </div>

        <div class="mb-3">
          <label class="input-label">Repayment Method <sup>*</sup></label>
          <select formControlName="repaymentMethod" class="form-select form-select-sm">
              <option [ngValue]="undefined" hidden>Please select...</option>
              <option [ngValue]="repaymentMethod.CapitalAndInterest">Capital and Interest</option>
              <option [ngValue]="repaymentMethod.InterestOnly">Interest Only</option>
              <option [ngValue]="repaymentMethod.InterestOnlyPartAndPart">Interest Only Part & Part</option>
          </select>
          <div class="invalid-feedback d-block" *ngIf="invalid('repaymentMethod')">
            <span *ngIf="errors('repaymentMethod').required">Please select a repayment method.</span>
          </div>
        </div>

        <div class="mb-3" [hidden]="hideIoLoanAmount">
          <label class="input-label">Amount on Interest Only <sup>*</sup></label>
          <div class="input-group input-group-sm">
            <span class="input-group-text">£</span>
            <input formControlName="ioLoanAmount" type="text" class="form-control" min="0" step="1000" currencyMask (keydown)="preventUnwanted($event)">
          </div>
          <div class="invalid-feedback d-block" *ngIf="invalid('ioLoanAmount')">
            <span *ngIf="errors('ioLoanAmount').required">Please enter an amount on interest only, or select a different repayment method.</span>
            <span *ngIf="errors('ioLoanAmount').min">Interest only loan amount must be at least £1.</span>
            <span *ngIf="errors('ioLoanAmount').max">Amount on interest only must be less than loan amount.</span>
          </div>
        </div>

        <div class="mb-3">
          <label class="input-label">Mortgage Term <sup>*</sup></label>
          <div class="row">
            <div class="col">
              <div class="input-group input-group-sm">
                <input formControlName="mortgageTermYears" type="number" class="form-control" min="0" max="40" (keydown)="preventUnwanted($event)">
                <span class="input-group-text">years</span>
              </div>
            </div>
            <div class="col">
              <div class="input-group input-group-sm">
                <input formControlName="mortgageTermMonths" type="number" class="form-control" min="0" max="11" (keydown)="preventUnwanted($event)">
                <span class="input-group-text">months</span>
              </div>
            </div>
          </div>
          <div class="invalid-feedback d-block" *ngIf="invalid('mortgageTermYears') || invalid('mortgageTermMonths')">
            <div *ngIf="errors('mortgageTermYears').required || errors('mortgageTermMonths').required">Please enter a mortgage term.</div>
            <div *ngIf="errors('mortgageTermYears').min">years should be a minimum of 0</div>
            <div *ngIf="errors('mortgageTermYears').max">years should be a maximum of 40</div>
            <div *ngIf="errors('mortgageTermMonths').min">months should be a minimum of 0</div>
            <div *ngIf="errors('mortgageTermMonths').max">months should be a maximum of 11</div>
          </div>
          <div class="invalid-feedback d-block" *ngIf="(touched('mortgageTermYears') || touched('mortgageTermMonths'))">
            <div *ngIf="errors('').termGreaterThan">Mortgage term must be greater than 0</div>
            <div *ngIf="errors('').termHasMax &&
             !errors('mortgageTermYears').max &&
             !errors('mortgageTermMonths').max">Mortgage term has a maximum of 40 years</div>
          </div>
        </div>
      </form>
    </div>

    <!-- PDF preview page -->
    <div *ngSwitchCase="page.Preview">
      <pdf-viewer
        [src]="pdfArrayBuffer"
        [render-text]="true"
        class="bg-2022--grey"
      />
    </div>
  </ng-container>
</div>

<ng-template #typeTooltipContent>
  <lib-type-product-column-tooltip />
</ng-template>

<ng-template #productAttributesTemplate>
  <app-esis-preview-attributes-modal [product]="selectedProduct" />
</ng-template>
