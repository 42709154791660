import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lib-form-button',
  styleUrls: ['form-button.component.scss'],
  templateUrl: 'form-button.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class FormButtonComponent extends FieldBaseComponent {}
