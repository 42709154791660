import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  templateUrl: 'textarea.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
})
export class TextAreaFieldTypeComponent extends FieldType {
}
