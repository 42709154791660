import { Component } from '@angular/core';

@Component({
  selector: 'lib-disclaimer-content-clubhub',
  templateUrl: 'disclaimer-content-clubhub.component.html',
  styleUrls: ['disclaimer-content-clubhub.component.scss'],
  standalone: true,
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class DisclaimerContentClubHubComponent {

}
