import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AnalyticsService } from '@msslib/services/analytics.service';
import { IEmbedConfig, IMiReport } from '../mi-surveying-services/mi-surveying-services.model';
import { MiSurveyingServicesService } from './mi-surveying-services.service';
import { PowerBiComponent } from '../../../../../../../libs/msslib/src/lib/components/powerbi/powerbi.component';
import { NgFor, NgIf } from '@angular/common';
import { MiPageNavComponent } from '../mi-page-nav/mi-page-nav.component';
import { MSSLIB_CONFIG } from '@msslib/models';
import { environment } from 'apps/lenderhub/src/environments/environment';
import { ConfigService } from '@msslib/services';

@Component({
  selector: 'app-mi-surveying-services',
  templateUrl: './mi-surveying-services.component.html',
  styleUrls: ['./mi-surveying-services.component.css'],
  standalone: true,
  imports: [
    MiPageNavComponent,
    NgIf,
    NgFor,
    PowerBiComponent,
  ],
  providers: [
    {
      provide: MSSLIB_CONFIG,
      useValue: environment.mssLibConfig,
    },
    ConfigService,
  ],
})
export class MiSurveyingServicesComponent implements OnInit, AfterViewInit {
  public miReports: IMiReport[];
  public embedConfig: IEmbedConfig;
  public isReportView = false;

  public constructor(private analyticsService: AnalyticsService, private miService: MiSurveyingServicesService) {}

  public ngOnInit() {
    this.miService.getReports().subscribe(reports => {
      this.miReports = reports;
    });
  }

  public ngAfterViewInit() {
    this.analyticsService.log('Surveying Services MI');
  }

  public reportChange(reportId: string) {
    this.miService.getReportConfiguration(reportId).subscribe(config => {
      this.embedConfig = config;
    });
    this.isReportView = true;
  }

  public goBackToList() {
    this.isReportView = false;
  }
}
