import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LendingTypeService, ModalService } from '@msslib/services';
import { LendingType, ScheduledValuationFeeScaleDateUpdateRequest, ValuationFeeScale } from '@msslib/models';
import { ProductService } from '@msslib/services/product.service';
import { EventScheduleComponent, ScheduleFormType } from '@msslib/components';
import { DatePipe, NgFor } from '@angular/common';
import parseISO from 'date-fns/parseISO';
import { FeeScaleConditionType } from '@msslib/models/enums/fee-scale-condition-type';
import { ValuationFeeScalesEditorComponent }
  from '../valuation-fee-scales-editor/valuation-fee-scales-editor.component';
import { ValuationFeeScalesCardComponent } from '../valuation-fee-scales-card/valuation-fee-scales-card.component';
import { LendingTypeButtonsComponent } from '../lending-type-buttons/lending-type-buttons.component';

@Component({
  selector: 'lib-valuation-fee-scales',
  templateUrl: './valuation-fee-scales.component.html',
  styleUrls: ['./valuation-fee-scales.component.scss'],
  standalone: true,
  imports: [
    LendingTypeButtonsComponent,
    NgFor,
    ValuationFeeScalesCardComponent,
    ValuationFeeScalesEditorComponent,
    EventScheduleComponent,
    DatePipe,
  ],
})
export class ValuationFeeScalesComponent implements OnInit {
  @Input() public lenderNameId: number;
  @ViewChild('valuationFeeScalesEditorModalTemplate')
  public valuationFeeScalesEditorModalTemplate: TemplateRef<void>;
  @ViewChild('rescheduleModalTemplate') public rescheduleModalTemplate: TemplateRef<void>;
  @ViewChild('scheduleDeleteModalTemplate') public scheduleDeleteModalTemplate: TemplateRef<void>;
  @ViewChild('saveResultDialogTemplate') public saveResultDialogTemplate: TemplateRef<void>;
  public valuationFeeScales: ValuationFeeScale[] = [];
  public valuationFeeScaleCurrent: ValuationFeeScale | null;
  public lendingTypeCode: string;
  public hasUnsavedChanges = false;
  public scheduleForm: ScheduleFormType;
  public saveResultMessage: string;

  public constructor(public lendingTypeService: LendingTypeService,
                     private productService: ProductService,
                     private modalService: ModalService,
                     private datePipe: DatePipe) {
    this.scheduleForm = EventScheduleComponent.buildScheduleForm();
  }

  public ngOnInit(): void {
    this.lendingTypeService.lendingType = this.lendingTypeService.lendingTypes[0];
    this.lendingTypeCode = this.lendingTypeService.lendingType.code;
    this.fetchValuationFeeScales();
  }

  public switchLendingType(lendingType: LendingType) {
    this.lendingTypeCode = lendingType.code;
    this.fetchValuationFeeScales();
  }

  public fetchValuationFeeScales() {
    const fetchValuationFeeScalesObservable = this.lenderNameId ?
      this.productService.getValuationFeeScalesByLenderNameId(this.lendingTypeCode, this.lenderNameId) :
      this.productService.getValuationFeeScales(this.lendingTypeCode);
    fetchValuationFeeScalesObservable.subscribe(data => {
      this.valuationFeeScales = [...data];
    });
  }

  public handleDelete(valuationFeeScaleSelected: ValuationFeeScale) {
    this.valuationFeeScaleCurrent = valuationFeeScaleSelected;
    this.openScheduleDeleteModal();
  }

  public handleReschedule(valuationFeeScaleSelected: ValuationFeeScale) {
    this.valuationFeeScaleCurrent = valuationFeeScaleSelected;
    EventScheduleComponent.resetScheduleForm(this.scheduleForm,
      parseISO(this.valuationFeeScaleCurrent.startDate as string));
    this.openValuationFeeScalesRescheduleModal();
  }

  public handleView(valuationFeeScaleSelected: ValuationFeeScale) {
    this.valuationFeeScaleCurrent = valuationFeeScaleSelected;
    this.openValuationFeeScalesEditor();
  }

  public createValuationFeeScales() {
    this.valuationFeeScaleCurrent = null;
    this.openValuationFeeScalesEditor();
  }

  private openValuationFeeScalesEditor() {
    this.modalService.open({
      title: 'Valuation Fee Scales',
      size: 'lg',
      sticky: true,
      template: this.valuationFeeScalesEditorModalTemplate,
      beforeDismiss: async () => await this.checkHasUnsavedChanges(),
    }).then(() => null, () => null);
  }

  private openValuationFeeScalesRescheduleModal() {
    this.modalService.open({
      title: 'Reschedule',
      size: 'md',
      sticky: true,
      template: this.rescheduleModalTemplate,
    }).then(() => null, () => null);
  }

  private openScheduleDeleteModal() {
    this.modalService.open({
      title: 'Delete schedule',
      size: 'md',
      sticky: true,
      template: this.scheduleDeleteModalTemplate,
    }).then(() => null, () => null);
  }

  public checkHasUnsavedChanges() {
    if (!this.hasUnsavedChanges) {
      return Promise.resolve(true);
    }

    return this.modalService.open({
      title: 'Discard changes?',
      message: 'You have made changes to this tab which have not yet been saved. ' +
        'Would you like to discard these changes?',
      showButtons: true,
      hideTopClose: true,
      sticky: true,
      size: 'md',
      okLabel: 'No, continue editing',
      cancelLabel: 'Yes, discard changes',
    }).then(() => false, () => true);
  }

  public closeValuationFeeScalesEditor() {
    this.modalService.close();
  }

  public rescheduleModalClick() {
    if (!this.scheduleForm.valid) {
      return;
    }
    const newScheduleDate = EventScheduleComponent.getScheduleFormDate(this.scheduleForm) as Date;
    const dateStr = this.datePipe.transform(newScheduleDate, 'dd/MM/yyyy HH:mm');
    const dateUpdateRequest: ScheduledValuationFeeScaleDateUpdateRequest = {
      newDate: newScheduleDate,
      condition: this.valuationFeeScaleCurrent?.condition as FeeScaleConditionType,
    };
    const updateScheduledValuationFeeScaleDateObservable = this.lenderNameId ?
      this.productService.updateScheduledValuationFeeScaleDateByLenderNameId(
        this.lendingTypeCode, this.lenderNameId, dateUpdateRequest) :
      this.productService.updateScheduledValuationFeeScaleDate(this.lendingTypeCode, dateUpdateRequest);
    updateScheduledValuationFeeScaleDateObservable
      .subscribe(() => {
        this.saveResultMessage =
          `Valuation scales and fees have been successfully submitted and wil be live on ${dateStr}.`;
        this.modalService.close();
        this.openValuationFeeScalesSaveResultDialog();
        this.fetchValuationFeeScales();
      });
  }

  public openValuationFeeScalesSaveResultDialog() {
    this.modalService.open({
      title: 'Valuation Fee Scales',
      size: 'md',
      sticky: true,
      template: this.saveResultDialogTemplate,
    }).then(() => null, () => null);
  }

  public scheduleDeleteNoClick() {
    this.modalService.close();
  }

  public scheduleDeleteYesClick() {
    const deleteScheduledValuationFeeScaleObservable = this.lenderNameId ?
      this.productService.deleteScheduledValuationFeeScaleByLenderNameId(
        this.lendingTypeCode, this.lenderNameId, this.valuationFeeScaleCurrent?.condition as FeeScaleConditionType) :
      this.productService.deleteScheduledValuationFeeScale(
        this.lendingTypeCode, this.valuationFeeScaleCurrent?.condition as FeeScaleConditionType);
    deleteScheduledValuationFeeScaleObservable
      .subscribe(() => {
        this.modalService.close();
        this.fetchValuationFeeScales();
      });
  }
}
