export enum FeeScaleConditionType
{
  Standard = 0,
  ExpatNotInUk = 1,
  HelpToBuy = 2,
  Hmo = 3,
  Purchase = 4,
  Remortgage = 5,
  SelfBuild = 6,
}
