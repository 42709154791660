import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProductService } from '@msslib/services/product.service';
import { ModalService, ToastService } from '@msslib/services';
import {
  EventScheduleComponent,
  ScheduleFormType,
  VariableRateCodesEditorComponent,
  VariableRateCodesEditorComponentFormType,
} from '@msslib/components';
import {
  VariableRateCodeLookupUpdateRequest,
  VariableRateCodeLookupViewModel,
  VariableRateCodeUpdateRequest,
} from '@msslib/models/variable-rate-codes';
import { format, formatISO, parseISO } from 'date-fns';
import { hasChanges } from '@msslib/helpers/model-helper';
import { Subject, takeUntil } from 'rxjs';
import { join2 } from '@msslib/helpers';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-variable-rate-codes',
  templateUrl: './variable-rate-codes.component.html',
  styleUrls: ['./variable-rate-codes.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    VariableRateCodesEditorComponent,
    EventScheduleComponent,
  ],
})
export class VariableRateCodesComponent implements OnInit, OnDestroy {

  @Input() public lenderNameId: number;
  public variableRateCodes: VariableRateCodeLookupViewModel;
  public variableRateCodeForm: VariableRateCodesEditorComponentFormType;
  public variableRateCodeScheduleForm: ScheduleFormType;
  public hasUnsavedChanges: boolean;
  private pristineFormModel: any;
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  @Output() public unsavedChangesChange = new EventEmitter<boolean>();

  public constructor(
    private productService: ProductService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
  }

  public ngOnInit(): void {
    this.fetchVariableRateCodes();
  }

  public fetchVariableRateCodes() {
    const getVariableRateCodesObservable =
      this.lenderNameId ? this.productService.getVariableRateCodesByLenderNameId(this.lenderNameId) :
        this.productService.getVariableRateCodes();
    getVariableRateCodesObservable
      .subscribe(result => {
        this.variableRateCodes = result;
        this.variableRateCodeForm = VariableRateCodesEditorComponent.buildForm(result.codes, undefined, []);
        this.variableRateCodeScheduleForm = EventScheduleComponent.buildScheduleForm();
        this.checkVariableRateCodeFormUnsavedChanges();
      });
  }

  public async updateVariableRateCodes() {
    // Allow the button to be clicked if the form is not valid so that the user can see which fields are invalid.
    this.variableRateCodeForm.markAllAsTouched();
    this.variableRateCodeScheduleForm.markAllAsTouched();
    if (!this.isVariableRateCodeFormValid() || !this.variableRateCodeScheduleForm.valid) {
      return;
    }

    // If there are changes already scheduled to go live in future, then confirm with the user that these changes will
    // overwrite the scheduled changes
    if (this.variableRateCodes.scheduledDate) {
      try {
        await this.modalService.open({
          title: 'Update Revert Rate',
          message: 'Are you sure you want to update the revert rate? You currently have  revert rate update scheduled '
            + `for ${format(parseISO(this.variableRateCodes.scheduledDate), 'dd/MM/yyyy HH:mm')}. `
            + 'If you click no, your current revert rate schedule will remain.',
          size: 'md',
          showButtons: true,
          cancelLabel: 'No, go back',
          okLabel: 'Yes, update schedule',
        });
      } catch {
        // If user clicks 'No', don't update
        return;
      }
    }

    // If the form is valid, submit it and show a toast
    const scheduleDate = EventScheduleComponent.getScheduleFormDate(this.variableRateCodeScheduleForm);
    const requestModel :VariableRateCodeLookupUpdateRequest = {
      codes: this.getFormValue(),
      goLiveAt: scheduleDate ? formatISO(scheduleDate) : null,
    };
    const updateVariableRateCodesObservable =
      this.lenderNameId ? this.productService.updateVariableRateCodesByLenderNameId(this.lenderNameId, requestModel) :
        this.productService.updateVariableRateCodes(requestModel);

    updateVariableRateCodesObservable
      .subscribe(() => {
        this.toastService.success('Variable rate codes have been updated');
        this.fetchVariableRateCodes();
        this.hasUnsavedChanges = false;
        this.unsavedChangesChange.emit(this.hasUnsavedChanges);
      });
  }

  public get isUpdateButtonDisabled(): boolean {
    return !this.variableRateCodeForm.touched && !this.hasUnsavedChanges;
  }

  public deleteVariableRateCode(index: number) {
    const variableRateCode = this.variableRateCodes.codes[index].code;
    const variableCodeUsageObservable = this.lenderNameId ?
      this.productService.isVariableCodeInUseByLenderNameId(variableRateCode, this.lenderNameId) :
      this.productService.isVariableCodeInUse(variableRateCode);

    variableCodeUsageObservable.subscribe(({ inUse, isLenderUsing, packagersUsing }) => {
      if (inUse) {
        const inUseBy = [
          isLenderUsing ? 'by you' : '',
          packagersUsing.length
            ? `by packager${packagersUsing.length > 1 ? 's' : ''} ${join2(packagersUsing, ', ', ' and ')}`
            : '',
        ].filter(Boolean).join(' and ');
        this.toastService.danger(
          `Cannot delete this variable rate as it is being used ${inUseBy} for one or more products.`);
      } else {
        this.variableRateCodes.codes[index].markedAsDeleted = true;
        this.variableRateCodeForm.get([index, 'markedAsDeleted'])?.setValue(true);
        this.variableRateCodeForm.get([index])?.disable();
        this.variableRateCodeForm.get([index])?.markAsTouched();
      }
    });
  }

  public checkVariableRateCodeFormUnsavedChanges() {
    this.pristineFormModel = this.variableRateCodeForm.value;
    this.variableRateCodeForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((currentModel) => {
      this.hasUnsavedChanges = hasChanges(this.pristineFormModel, currentModel);
      this.unsavedChangesChange.emit(this.hasUnsavedChanges);
    });
  }

  private isVariableRateCodeFormValid() {
    if (this.variableRateCodeForm.disabled) {
      return true;
    }
    return this.variableRateCodeForm.valid;
  }

  private getFormValue() {
    const formValue = this.variableRateCodeForm.getRawValue();
    formValue.forEach(x => x.variableRate = x.variableRate ?? x.currentRate);
    return formValue.filter(x => !x.markedAsDeleted) as VariableRateCodeUpdateRequest[];
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
