import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FeeCalculationScenario, getBridgingFeeDisplayName } from '@msslib/constants/product-fees';
import {
  CustomBridgingProductCalculations,
} from '@msslib/models/custom-product-calculations';
import { ModalStateService } from '@msslib/services/modal-state.service';
import { ModalService } from '@msslib/services/modal.service';
import { BridgingProductFeeTypeModal } from 'apps/shared/src/models';
import { NgFor } from '@angular/common';

@Component({
  selector: 'lib-bridging-product-fees-modal',
  templateUrl: './bridging-product-fees-modal.component.html',
  styleUrls: ['./bridging-product-fees-modal.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgFor,
  ],
})
export class BridgingProductFeesModalComponent implements OnInit {
  public allFees: {feeKey: string; feeName:string}[];
  private nonDigitNumericChars = ['e','E', '+', '-', '.'];
  public form: FormGroup;
  public eFeeCalculationScenarios = FeeCalculationScenario;
  private disabledFees: string[] = ['Arrangement fee', 'Chaps fee'];
  @Output() public customBridgingProductCalculations = new EventEmitter<CustomBridgingProductCalculations>();

  public constructor(
    private modalService: ModalService,
    private modalStateService: ModalStateService,
    private formBuilder: FormBuilder) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      calculateOverInitialPeriod: [false],
      customTrueCostPeriod: [24, [Validators.required, Validators.min(6), Validators.max(1000)]],
      deductCashback: [true],
      fees: this.formBuilder.array([]),
    });

    this.allFees = Object.keys(BridgingProductFeeTypeModal).map((feeType: string) => {
      const fee = BridgingProductFeeTypeModal[feeType];
      return { feeKey: feeType, feeName: getBridgingFeeDisplayName(fee) };
    });

    this.patchFormValues();
  }

  private patchFormValues() {
    Object.keys(BridgingProductFeeTypeModal).forEach((feeType: string) => {
      this.feeArrayControl.push(this.formBuilder.group({
        feeCalculationScenario: FeeCalculationScenario.OneOffCost,
        feeName:[BridgingProductFeeTypeModal[feeType]],
      }));
    });
    if (this.modalStateService.options.data?.model) {
      this.form.patchValue(this.modalStateService.options.data.model);
    }
  }

  public update(): void {
    this.customBridgingProductCalculations.emit(this.form.value);
    this.modalService.dismiss();
  }


  public preventUnwanted(event) {
    if (this.nonDigitNumericChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  public get feeArrayControl(): FormArray {
    return this.form.get('fees') as FormArray;
  }

  public selectAllFees(scenario: FeeCalculationScenario) {
    this.feeArrayControl.controls.forEach(control => {
      if (this.disabledFees.includes(getBridgingFeeDisplayName(control.value.feeName)) &&
        scenario === FeeCalculationScenario.Exclude) {
        return;
      }
      control.setValue({feeCalculationScenario: scenario, feeName: control.value.feeName });
    });
  }

  public isFeeDisabled(feeName: string): boolean {
    return feeName === 'Arrangement fee' || feeName === 'Chaps fee';
  }
}
