/* eslint-disable no-use-before-define */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationResultStatus, AuthorizeService, ConfigService } from '../../services';
import { applicationPaths, loginActions, queryParameterNames, returnUrlType } from '../../constants';
import { AsyncPipe } from '@angular/common';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
@Component({
  selector: 'lib-login',
  styleUrls: ['login.component.css'],
  templateUrl: 'login.component.html',
  standalone: true,
  imports: [AsyncPipe],
})
export class LoginComponent implements OnInit {
  public message = new BehaviorSubject<string | null>(null);

  public constructor(
    private authorizeService: AuthorizeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
  ) {}

  public async ngOnInit() {
    const action = this.activatedRoute.snapshot.url[1];
    switch (action.path) {
      case loginActions.login:
        await this.login(this.getReturnUrl());
        break;
      case loginActions.loginCallback:
        await this.processLoginCallback();
        break;
      case loginActions.loginFailed:
        const message = this.activatedRoute.snapshot.queryParamMap.get(queryParameterNames.message);
        this.message.next(message);
        break;
      case loginActions.profile:
        this.redirectToProfile();
        break;
      case loginActions.register:
        this.redirectToRegister();
        break;
      case loginActions.marketingPreferences:
        this.redirectToMarketingPreferences();
        break;
      case loginActions.crmSystemInformation:
        this.redirectToCrmSystemInformation();
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }

  private async login(returnUrl: string): Promise<void> {
    const state: INavigationState = { returnUrl };
    const result = await this.authorizeService.signIn(state);
    this.message.next(null);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        await this.router.navigate(applicationPaths.loginFailedPathComponents, {
          queryParams: { [queryParameterNames.message]: result.message },
        });
        break;
      default:
        throw new Error(`Invalid status result ${(result as any).status}.`);
    }
  }

  private async processLoginCallback(): Promise<void> {
    const url = window.location.href;
    const result = await this.authorizeService.completeSignIn(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as completeSignIn never redirects.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(this.getReturnUrl(result.state));
        localStorage.setItem('canRetryAuth', '1');
        break;
      case AuthenticationResultStatus.Fail:
        // retry login 1 more time - this remedies issue with user book marking login page
        if (localStorage.getItem('canRetryAuth') === '0') {
          localStorage.setItem('canRetryAuth', '1');
          this.message.next(result.message);
        } else {
          localStorage.setItem('canRetryAuth', '0');
          await this.navigateToReturnUrl(this.getReturnUrl());
        }
        break;
    }
  }

  private redirectToRegister(): void {
    this.redirectToApiAuthorizationPath(
      `${applicationPaths.identityRegisterPath}?${returnUrlType}=?client_id=${this.configService.applicationName}`,
    );
  }

  private redirectToProfile(): void {
    this.redirectToApiAuthorizationPath(
      `${applicationPaths.identityManagePath}?${returnUrlType}=?client_id=${this.configService.applicationName}`,
    );
  }

  private redirectToMarketingPreferences(): void {
    this.redirectToApiAuthorizationPath(
      `${applicationPaths.identityMarketingPreferences}?${returnUrlType}=` +
      `?client_id=${this.configService.applicationName}`,
    );
  }

  private redirectToCrmSystemInformation(): void {
    this.redirectToApiAuthorizationPath(
      `${applicationPaths.identityCrmSystemInformation}?${returnUrlType}=` +
      `?client_id=${this.configService.applicationName}`,
    );
  }

  private async navigateToReturnUrl(returnUrl: string) {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (this.activatedRoute.snapshot.queryParams as INavigationState).returnUrl;
    // If the url is coming from the query string, check that is either
    // a relative url or an absolute url
    if (fromQuery && !fromQuery.startsWith('/') && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return ((state?.returnUrl) ?? fromQuery) || applicationPaths.defaultLoginRedirectPath;
  }

  private redirectToApiAuthorizationPath(apiAuthorizationPath: string) {
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    const redirectUrl = `${this.configService.config.identityServerUrl}${apiAuthorizationPath}`;
    window.location.replace(redirectUrl);
  }
}

interface INavigationState {
  [returnUrlType]: string;
}
