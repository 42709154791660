import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RulesEngineDataType, TypeMetadata } from '@msslib/models/rules-engine';
import { coerceType } from '../utils';
import { AttributesFromObjectDirective } from '../../../directives/attributes-from-object.directive';
import { FormsModule } from '@angular/forms';
import { NgFor, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'lib-rules-engine-value-editor',
  templateUrl: 'value-editor.component.html',
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    FormsModule,
    AttributesFromObjectDirective,
    NgFor,
  ],
})
export class RulesEngineValueEditorComponent implements OnChanges {
  @Input({ required: true }) public type: TypeMetadata;
  @Input() public label: string;
  @Input() public nullable: boolean;
  @Input() public disabled = false;
  @Input() public testIdSuffix: string;

  @Input() public value: any;
  @Output() public valueChange = new EventEmitter<any>();

  public elementType: 'input' | 'select';
  public inputAttributes: Record<string, string>;
  public selectOptions: { value: any; displayName: string }[];

  public ngOnChanges(changes: SimpleChanges): void {
    if ('type' in changes || 'nullable' in changes) {
      // Only change this if the type is changed
      this.elementType = this.getElementType();
      this.inputAttributes = this.getInputAttributes();
      this.selectOptions = this.getSelectOptions();
    }
  }

  public onValueChange(newValue: any) {
    this.value = coerceType(newValue, this.type, this.nullable);
    this.valueChange.emit(this.value);
  }

  private getElementType(): 'input' | 'select' {
    switch (this.type.dataType) {
      case RulesEngineDataType.String:
      case RulesEngineDataType.Integer:
      case RulesEngineDataType.Decimal:
        return 'input';
      case RulesEngineDataType.Boolean:
      case RulesEngineDataType.Enum:
        return 'select';
    }
  }

  /** For types that use an <input> element, gets the HTML attributes for the element. */
  private getInputAttributes(): Record<string, string> {
    switch (this.type.dataType) {
      case RulesEngineDataType.Integer:
        return { type: 'number', step: '1' };
      case RulesEngineDataType.Decimal:
        return { type: 'number', step: 'any' };
      default:
        return { type: 'text' };
    }
  }

  /** For types that a <select> element, gets the options for the dropdown. */
  private getSelectOptions() {
    switch (this.type.dataType) {
      case RulesEngineDataType.Boolean:
        return [
          ...(this.nullable
            ? [{ value: null, displayName: '-Nothing-' }]
            : []
          ),
          { value: true, displayName: 'Yes' },
          { value: false, displayName: 'No' },
        ];
      case RulesEngineDataType.Enum:
        return [
          ...(this.nullable
            ? [{ value: null, displayName: '-Nothing-' }]
            : []
          ),
          ...(this.type.choices ?? []),
        ];
      default:
        return [];
    }
  }

  /** Prevents decimals from being typed for integer number fields. */
  public onInputKeyDown(event: KeyboardEvent) {
    switch (this.type.dataType) {
      case RulesEngineDataType.Integer: return !'Ee+.'.includes(event.key);
      case RulesEngineDataType.Decimal: return !'Ee+'.includes(event.key);
      default: return true;
    }
  }
}
