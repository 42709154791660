import { EventEmitter, Injectable } from '@angular/core';
import { CriteriaIssue, QuestionType } from '@msslib/models/criteria-issue';
import { ClubHubDataService } from '@msslib/services';
import { criteriaSteps } from 'apps/clubhub/src/app/ignite/models/criteria/criteria-steps';
import {
  CriteriaAnswers,
  CriteriaFormAnswer,
  CriteriaFormResult,
  CriteriaFormResultRequest,
  GetOutcomesRequest,
  GetProductsOutcomesRequest,
  IntuitiveCriteriaPropertyStatus,
  OutcomeDetailsCardV2,
} from 'apps/clubhub/src/app/ignite/pages/criteria-v2/models';
import {
  CriteriaType,
  OutcomeDetails,
  OutcomeDetailsCard,
  OutcomeResponse,
} from 'apps/shared/src/models';
import { Observable, tap } from 'rxjs';
import { downloadBlob, join2 } from '@msslib/helpers';
import { CriteriaV2Service } from '../../../services/criteria-v2.service';
import { AffordabilityRequest, BdgAffordabilityRequest, BtlAffordabilityRequest }
  from 'apps/clubhub/src/app/ignite/models/affordability';
import { BrokerService } from 'apps/clubhub/src/app/services';
import { OutcomeResults } from '@msslib/models/enums/outcome-results';

@Injectable({
  providedIn: 'root',
})
export class CriteriaV2OutcomeService {

  public basketItems: CriteriaFormResult[] = [];
  public outcome: OutcomeDetails = { details: [] } as {details: OutcomeResponse[]} as OutcomeDetails;
  public step: string = criteriaSteps.criteriaSelect;
  public loading = false;
  public lenderCount = 0;
  public criteriaIssues: CriteriaIssue[];
  public criteriaSearchId: string;
  public outcomeUpdated = new EventEmitter<OutcomeDetails>();

  public constructor(
    private clubHubDataService: ClubHubDataService,
    public criteriaV2Service: CriteriaV2Service,
    private brokerService: BrokerService,
  ) {}

  public addBasketItem(item: CriteriaFormResult): void {
    this.basketItems.push(item);
    this.basketItems.sort((a, b) => a.criteriaContextName.localeCompare(b.criteriaContextName));
  }

  public getEpcReportPdf(postcode: string, address: string): void {
    this.clubHubDataService
      .postFileBlob('propertyCriteria/EpcReport', { postcode, address })
      .subscribe({
        next: blob => downloadBlob(blob, `EPC-${address.replace(/[\s,]+/, '_')}-${postcode}.pdf`),
        error: () => window.open('https://find-energy-certificate.service.gov.uk'),
      });
  }

  public get manualCriteria() {
    return this.basketItems.filter(item => item.criteriaType === CriteriaType.Manual);
  }

  public get intuitiveCriteria() {
    return this.basketItems.filter(item => item.criteriaType === CriteriaType.Intuitive);
  }

  public get propertyCriteria() {
    return this.basketItems.filter(item => item.criteriaType === CriteriaType.Property);
  }

  public get yesLenders() {
    return this.outcome.details
      .filter(x => x.overallResult.outcomeResult === 'Y');
  }

  public get referLenders() {
    return this.outcome.details
      .filter(x => x.overallResult.outcomeResult === 'R');
  }

  public get noLenders() {
    return this.outcome.details
      .filter(x => x.overallResult.outcomeResult === 'N');
  }

  public getLenderOverallResultByLender(lenderName: string): OutcomeResults | undefined {
    const lenderOutcome = this.outcome.details?.find(
      item => item.overallResult.lender.toUpperCase() === lenderName.toUpperCase(),
    );

    if (!lenderOutcome) {
      return;
    }

    const outcomeResult = lenderOutcome.overallResult.outcomeResult;
    switch (outcomeResult) {
      case 'Y': return OutcomeResults.Yes;
      case 'N': return OutcomeResults.No;
      case 'R': return OutcomeResults.Refer;
      default: return;
    }
  }

  public getLenderCriteriaByLender(
    lenderName: string, criteriaType: CriteriaType, property: IntuitiveCriteriaPropertyStatus) {
    const lenderOutcomes =  this.outcome.details
      ?.filter(item => item.overallResult.lender.toUpperCase() === lenderName.toUpperCase());

    if (!lenderOutcomes?.length) {
      return;
    }

    const contextResults = lenderOutcomes?.flatMap(lenderOutcome => lenderOutcome.contextResults)
      .filter((context) => {
        if (property === IntuitiveCriteriaPropertyStatus.Both) {
          return context.criteriaType === criteriaType;
        }
        return context.criteriaType === criteriaType &&
          context.isProperty === (property === IntuitiveCriteriaPropertyStatus.Property) ;
      }) ?? [];

    return {
      contextResults: contextResults,
      overallResult: lenderOutcomes[0].overallResult,
      outcomeContextCards: lenderOutcomes[0].outcomeContextCards,
      skipList: lenderOutcomes[0].skipList,
    } as OutcomeResponse;
  }

  public removeBasketItem(item: CriteriaFormResult): void {
    const index = this.basketItems.indexOf(item);
    if (index > -1) {
      this.basketItems.splice(index, 1);
    }
  }

  public removeBasketItemById(contextId: number): void {
    this.basketItems = this.basketItems.filter(item => item.criteriaContextId !== contextId);
  }

  public removeNotIntuitiveBaskets() {
    this.basketItems = this.intuitiveCriteria;
  }

  public clearBasket(): void {
    this.basketItems = [];
  }

  public clearBasketIntuitive(): void {
    this.basketItems = this.basketItems.filter(item => item.criteriaType !== CriteriaType.Intuitive);
  }

  public clearBasketProperty(): void {
    this.basketItems = this.basketItems.filter(item => item.criteriaType !== CriteriaType.Property);
  }

  public reset(): void {
    this.clearBasket();
    this.step = criteriaSteps.criteriaSelect;
    this.lenderCount = 0;
    this.outcome = {} as OutcomeDetails;
  }

  public get outcomeCards(): OutcomeDetailsCardV2[] {
    return this.basketItems.map(item => {
      return {
        issue: item.issue ?? (this.getIssueName(item.criteriaContextLendingTypeId) ?? ''),
        context: item.criteriaContextName,
        criteriaType: item.criteriaType,
        questionsAndAnswers: item.answers.map(curAnswer => {
          return {
            question: curAnswer.questionName,
            answerUnits: curAnswer.answerType === QuestionType.FullRange ? curAnswer.answerUnits : '',
            answer: curAnswer.answerType === QuestionType.MultiSelect
              ? join2(curAnswer.answer.map(x => x.name), ', ', ' and ')
              : curAnswer.answerType === QuestionType.SingleSelect
                ? curAnswer.answerName
                : curAnswer.answer.toString(),
          };
        }),
      };
    });
  }

  public getOutcomeType(text: string) : CriteriaType {
    if (text.includes('Intuitive Criteria')) {
      return CriteriaType.Intuitive;
    }
    if (text.includes('Added Automatically')) {
      return CriteriaType.Property;
    }
    return CriteriaType.Manual;
  }

  public getIssueName(criteriaContextLendingTypeId: number): string | undefined {
    const criteria = this.criteriaV2Service.criteria
      .find(x => x.contexts.some(y => y.criteriaContextLendingTypeId === criteriaContextLendingTypeId));
    return criteria?.name;
  }

  private createBasketItems(detailsCards: OutcomeDetailsCard[]) {
    return detailsCards.map(c => {
      return {
        criteriaContextLendingTypeId: c.criteriaContextLendingTypeId,
        criteriaContextId: c.contextId,
        criteriaContextName: c.context,
        criteriaContextVersionId: c.criteriaContextVersionId,
        issue: c.issue,
        issueId: c.issueId,
        criteriaType: c.outcomeType,
        answers:  c.questionsAndAnswers.map(q => {
          if (q.answerType !== QuestionType.MultiSelect) {
            return {
              answerType: q.answerType,
              questionId: q.questionId,
              questionName: q.question,
              answer: q.answerType === QuestionType.SingleSelect ? q.answerId || q.answer : q.answer,
              answerName: q.answerName,
              answerUnits: q.answerUnits,
            } as CriteriaFormAnswer;
          }
          return {
            answerType: q.answerType,
            questionId: q.questionId,
            questionName: q.question,
            answer: q.answer ? q.answer?.map(answer=> {
              return {
                id: answer.id,
                name: answer.name,
                answerUnits: answer.answerUnits,
              } as CriteriaAnswers;
            }) : q.answers?.map(answer=> {
              return {
                id:answer.id,
                name: answer.text,
                answerUnits: '',
              } as CriteriaAnswers;
            }),
          } as CriteriaFormAnswer;
        }),
      } as CriteriaFormResult;
    });
  }

  public updateBasketItems(newDetailsCards: OutcomeDetailsCard[], isFirstTimePropertyCheck: boolean = true) {
    const newBasketItems = this.createBasketItems(newDetailsCards);
    if (!isFirstTimePropertyCheck) {
      this.basketItems = this.basketItems?.filter(x=>
        !newBasketItems.map(n =>n.criteriaContextName).includes(x.criteriaContextName));
    }
    this.basketItems = this.basketItems.concat(newBasketItems);
  }

  public setBasketItems(detailsCards: OutcomeDetailsCard[]) {
    this.basketItems = this.createBasketItems(detailsCards);
  }

  public getOutcome(lenderTypeId: number, affordabilitySearchId: string | null,
    affordabilityModelRequest: AffordabilityRequest | BtlAffordabilityRequest | BdgAffordabilityRequest | null = null)
    : Observable<OutcomeDetails> {
    this.loading = true;
    const criteriaFormResults = this.getCriteriaFormResultRequests();
    const getOutcomesRequest = {
      criteriaRequests: criteriaFormResults,
      affordabilitySearchId: affordabilitySearchId,
      affordabilityRequest: affordabilityModelRequest,
      brokerFirmDetails: this.brokerService.broker ? {
        agencyNumber: this.brokerService.broker?.agencyNumber,
        fcaNumber:this.brokerService.broker?.fcaNumber,
      } : null,
    } as GetOutcomesRequest;

    return this.clubHubDataService.post<OutcomeDetails>(
      `CriteriaV2/Outcome/${lenderTypeId}`, getOutcomesRequest)
      .pipe(tap((res: OutcomeDetails) => {
        this.step = criteriaSteps.criteriaResult;
        this.outcome = res;
        this.lenderCount = res.details.length;
        this.outcomeUpdated.emit(res);
        setTimeout(() => {
          this.loading = false;
          window.scrollTo(0, 0);
        }, 15000); // if you change the length of this timeout you will need to change it in the css animation too.
      }));
  }

  public getProductsIntuitiveOutcome(lenderTypeId: number, productsModelRequest: any)
    : Observable<OutcomeDetails> {
    const criteriaFormResults = this.getCriteriaFormResultRequests();
    const getProductsOutcomesRequest = {
      criteriaRequests: criteriaFormResults,
      productsSearchRequest: productsModelRequest,
      brokerFirmDetails: this.brokerService.broker ? {
        agencyNumber: this.brokerService.broker?.agencyNumber,
        fcaNumber:this.brokerService.broker?.fcaNumber,
      } : null,
    } as GetProductsOutcomesRequest;

    return this.clubHubDataService.post<OutcomeDetails>(
      `Product/GetIntuitiveOutcomesV2/${lenderTypeId}`, getProductsOutcomesRequest)
      .pipe(tap((res: OutcomeDetails) => {
        this.step = criteriaSteps.criteriaResult;
        this.clearBasketIntuitive();

        if (!this.outcome?.details?.length) {
          this.outcome = res;
        } else {
          this.mergeOutcomeDetails(res);
        }

        this.updateBasketItems(res.contexts);
        this.lenderCount = this.outcome.details.length;
        this.outcomeUpdated.emit(res);
      }));
  }

  private getCriteriaFormResultRequests(): CriteriaFormResultRequest[] {
    const criteriaFormResults: CriteriaFormResultRequest[] = this.basketItems.map(item => {
      const context = this.criteriaIssues?.flatMap(issue => issue.contexts)
        .find(context => context.criteriaContextLendingTypeId === item.criteriaContextLendingTypeId);
      const issue = this.criteriaIssues
        ?.find(x => x.contexts.find(y => y.criteriaContextLendingTypeId === item.criteriaContextLendingTypeId));
      return {
        answers: item.answers,
        issue: item.issue ?? issue?.name,
        issueId: item.issueId ?? issue?.id,
        context: item.criteriaContextName,
        criteriaContextId: item.criteriaContextId,
        criteriaContextLendingTypeId: (item.criteriaContextLendingTypeId ?? context?.criteriaContextLendingTypeId) ?? 0,
        criteriaContextVersionId: (item.criteriaContextVersionId ?? context?.criteriaContextVersionId) ?? 0,
        criteriaType: item.criteriaType,
      } as CriteriaFormResultRequest;
    });

    return criteriaFormResults;
  }

  public mergeOutcomeDetails(outcomes: OutcomeDetails) {
    const outcomeDetails = this.outcome;

    outcomes.details.forEach(x => {
      const lenderOutcome = outcomeDetails.details.filter(y => y.overallResult.lender === x.overallResult.lender);
      if (lenderOutcome.length) {
        const lenderDetails = lenderOutcome[0];
        // Remove intuitive contexts
        lenderDetails.contextResults = lenderDetails.contextResults
          .filter(r => r.criteriaType !== CriteriaType.Intuitive);

        // merge with updated intuitive contexts
        lenderDetails.contextResults = lenderDetails.contextResults.concat(x.contextResults);

        // Get overall outcome Result
        const lenderOutcomeResult = lenderDetails.overallResult.outcomeResult;
        const propertySearchOutcomeResult = x.overallResult.outcomeResult;
        const overallOutcomeResult = this.getOverallOutcome(lenderOutcomeResult, propertySearchOutcomeResult);
        if (overallOutcomeResult) {
          lenderDetails.overallResult.outcomeResult = overallOutcomeResult.toString();
        }
      } else {
        outcomeDetails.details.push(x);
      }
    });
  }

  public getOverallOutcome(...results: (OutcomeResults | string | undefined)[]) {
    if (results.every(r => r === undefined)) {
      return undefined;
    }

    if (results.some(r => r === OutcomeResults.No)) {
      return OutcomeResults.No;
    }

    if (results.some(r => r === OutcomeResults.Refer || r === OutcomeResults.Unset)) {
      return OutcomeResults.Refer;
    }

    if (results.some(r => r === OutcomeResults.Yes)) {
      return OutcomeResults.Yes;
    }

    return undefined;
  }
}
