import { Component, Input, OnInit } from '@angular/core';
import { ClientApps } from '@msslib/models/enums';
import { loginActions } from '@msslib/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthorizeService } from '../../services';
import { RouterLink } from '@angular/router';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

interface AccountMenuItem {
  label: string;
  routerLink: string;
}

@Component({
  selector: 'lib-login-menu',
  styleUrls: ['login-menu.component.scss'],
  templateUrl: 'login-menu.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgFor,
    NgbDropdownItem,
    RouterLink,
    AsyncPipe,
  ],
})
export class LoginMenuComponent implements OnInit {
  @Input() private client: ClientApps;
  public isAuthenticated$: Observable<boolean>;
  public accountMenu$: Observable<AccountMenuItem[]>;

  public constructor(private authorizeService: AuthorizeService) {}

  public ngOnInit() {
    this.isAuthenticated$ = this.authorizeService.isAuthenticated();

    this.accountMenu$ = this.authorizeService.isSsoUser$
      .pipe(map((isSsoUser) => [
        {
          label: 'Change password',
          routerLink: '/authentication/profile',
          when: !isSsoUser,
        },
        {
          label: 'Change areas of interest',
          routerLink: `/authentication/${loginActions.marketingPreferences}`,
          when: this.isClubHub && !isSsoUser,
        },
        {
          label: 'Change CRM system information',
          routerLink: `/authentication/${loginActions.crmSystemInformation}`,
          when: this.isClubHub && !isSsoUser,
        },
        {
          label: 'Firm Details',
          routerLink: '/firmdetails',
          when: this.isClubHub,
        },
        {
          label: 'Document Preferences',
          routerLink: '/documentpreferences',
          when: this.isClubHub,
        },
        {
          label: 'Fees / True Cost',
          routerLink: '/productpreferences',
          when: this.isClubHub,
        },
      ].filter(x => x.when)));
  }

  public get isClubHub(): boolean {
    return this.client === ClientApps.ClubHub;
  }
}
