import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EsisHtmlEditorComponent } from '../esis-html-editor/esis-html-editor.component';

@Component({
  selector: 'app-single-text-editor',
  templateUrl: 'single-text-editor.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    EsisHtmlEditorComponent,
  ],
})
export class SingleTextEditorComponent {
  @Input() public id: string;
  @Input() public formGroup: FormGroup<{ text: FormControl<string> }>;
  @Input() public placeholder: string;

  public get text() {
    return this.formGroup.get('text');
  }

  public valid(name:string): boolean {
    return this.formGroup.controls[name].valid && this.formGroup.controls[name].touched;
  }

  public invalid(name:string): boolean {
    return this.formGroup.controls[name].invalid && this.formGroup.controls[name].touched;
  }
}
