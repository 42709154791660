import { Component, Input } from '@angular/core';
import { IAccordionItem } from '../../models';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-accordion',
  styleUrls: ['accordion.component.scss'],
  templateUrl: 'accordion.component.html',
  standalone: true,
  imports: [NgbModule],
})
export class AccordionComponent {
  @Input() public items: IAccordionItem[];
}
