import { Component, Inject, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  EMAIL_VERIFICATION_SERVICE,
  IEmailVerificationService,
  VerificationFlowResult,
} from '@msslib/models/email-verification';
import { Subscription, timer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

export enum Page {
  CodeEntry,
  Confirmed,
}

@Component({
  selector: 'lib-email-verification-modal',
  templateUrl: 'email-verification-modal.component.html',
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    FormsModule,
    NgIf,
  ],
})
export class EmailVerificationModalComponent implements OnDestroy {

  public page = Page.CodeEntry;
  public verificationCode: string;
  public codeErrorMessage = '';
  public requiresEmailResend = false;
  public isSharedDevice: boolean | undefined;

  public pageEnum = Page;

  private countdownTimerSub: Subscription | null;

  public constructor(
    public activeModal: NgbActiveModal,
    @Inject(EMAIL_VERIFICATION_SERVICE) public emailVerificationService: IEmailVerificationService,
  ) { }

  public ngOnDestroy(): void {
    this.countdownTimerSub?.unsubscribe();
  }

  public verify(): void {
    this.codeErrorMessage = '';
    this.emailVerificationService.submitVerificationCode(this.verificationCode)
      .subscribe({
        next: () => this.page = Page.Confirmed,
        error: (err: HttpErrorResponse) => {
          this.verificationCode = '';
          const { hasMoreAttempts } = err.error.result;
          if (hasMoreAttempts) {
            this.codeErrorMessage = 'We have been unable to verify the code you have entered, please try again';
          } else {
            this.codeErrorMessage = 'Too many incorrect verification code attempts, please request a new code';
            this.requiresEmailResend = true;

            // If the user was too quick and cannot yet request a new email, setup a timer to update the button
            // We subscribe so the timer starts ticking and updating the UI each second, and also listen out for when
            // the user is able to then request a new code and when that is the case also unsubscribe from the timer.
            if (!this.emailVerificationService.canRequestVerificationEmail) {
              this.countdownTimerSub = timer(0, 1000)
                .subscribe(() => {
                  if (this.emailVerificationService.canRequestVerificationEmail) {
                    this.countdownTimerSub?.unsubscribe();
                    this.countdownTimerSub = null;
                  }
                });
            }
          }
        },
      });
  }

  public resendEmail(): void {
    this.emailVerificationService.sendVerificationEmail().subscribe(() => {
      this.requiresEmailResend = false;
      this.codeErrorMessage = '';
    });
  }

  public completeVerification(): void {
    this.activeModal.close({
      shouldSave: !this.isSharedDevice,
    } as VerificationFlowResult);
  }
}
