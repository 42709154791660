import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '@msslib/services/modal.service';

@Component({
  selector: 'lib-confirm-switch-modal',
  styleUrls: ['confirm-switch-modal.component.scss'],
  templateUrl: 'confirm-switch-modal.component.html',
  standalone: true,
})
export class ConfirmSwitchModalComponent {
  @Input() public lendingType: string;
  @Output() public confirmSwitch: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor(private modalService: ModalService) {}

  public confirm(value: boolean) {
    this.confirmSwitch.emit(value);
    this.modalService.dismiss();
  }

}
