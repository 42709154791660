<div ngbAccordion>
  @for (item of items; track item; let i = $index) {
    <div ngbAccordionItem>
      <div ngbAccordionHeader>
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton (click)="item.onClick(item.title)">{{ item.title }}</button>
        </h2>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            {{ item.description }}
          </ng-template>
        </div>
      </div>
    </div>
  }
</div>
