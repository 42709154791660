<div class="row mx-0">
  <div class="col">
    <h1>
      Get in touch with the team
    </h1>
    <p>
      You can contact us Monday to Friday between the hours of 9am-5:30pm on
      <a href="tel:{{ contactUsPhone.value }}">{{ contactUsPhone.text }}</a
      >, please select option 4.
    </p>
    <p>
      Calls may be recorded and monitored (call charges will apply).
    </p>
  </div>
</div>

<div class="row mx-0" *ngIf="isLenderHub">
  <div class="col">
    <p>
      If you have any questions or require any assistance with Lender Hub please email
      <a href="mailto:mortgageclublenderteam@landg.com">mortgageclublenderteam&#64;landg.com</a>
    </p>
  </div>
</div>
