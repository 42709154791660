import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IInputOption } from '@msslib/components/formly/models/input';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { createPopper } from '@popperjs/core';
import { KeyCodeNames } from '@msslib/constants/key-codes';
import { NgFor } from '@angular/common';

@Component({
  templateUrl: 'multiselect.component.html',
  styleUrls: ['multiselect.component.scss'],
  standalone: true,
  imports: [NgFor],
})
export class MultiSelectFieldTypeComponent extends FieldType implements OnInit, AfterViewInit {
  @ViewChild('dropdown') public dropdownElementRef: ElementRef<HTMLInputElement>;
  @ViewChild('popper') public popperElementRef: ElementRef<HTMLInputElement>;

  public dropdownId: string;
  public dropdown: any;
  public isShown: boolean;

  public static defaultOptions: FormlyFieldConfig = {
    defaultValue: [],
    props: {
      placeholder: 'Please select...',
    },
  };

  public ngOnInit(): void {
    this.dropdownId = `multiselect-${Math.random()}`;
  }

  public ngAfterViewInit(): void {
    const reference = this.dropdownElementRef.nativeElement;
    const popper = this.popperElementRef.nativeElement;
    const instance = createPopper(reference, popper) as any;
    instance.strategy = 'absolute';
    instance.placement = 'bottom-start';
    instance.modifiers = [
      { name: 'flip', enabled: false },
    ];

    this.dropdown = new (window as any).bootstrap.Dropdown(
      this.dropdownElementRef.nativeElement,
      {
        popperConfig: instance,
      },
    );
  }

  private get selectedOptionsValues(): unknown[] {
    const selectedOptions = [...this.formControl.value ?? []];
    return Array.isArray(selectedOptions) ? selectedOptions : [];
  }

  public get selectedLabels(): string {
    const options = (this.props.options as any[]) ?? [];
    const labels = options.filter(o => this.isChecked(o)).map(o => o.label);
    return labels.length ? labels.join(', ') : 'Please select...';
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    if ((event.code ?? '') as KeyCodeNames === KeyCodeNames.Enter) {
      this.dropdown.toggle();
      event.stopPropagation();
    }
  }

  public onDropdownShow() {
    this.isShown = true;
  }

  public onDropdownHide() {
    this.isShown = false;
  }

  public trackByFn(index: number) {
    return index;
  }

  public isChecked(option: IInputOption): boolean {
    return this.selectedOptionsValues.some(o => o === option.value);
  }

  public changeValue(option: IInputOption, isChecked: boolean): void {
    let selectedOptionsValues = this.selectedOptionsValues;
    if (isChecked) {
      selectedOptionsValues.push(option.value);
    } else {
      selectedOptionsValues = selectedOptionsValues.filter(r => r !== option.value);
    }

    this.formControl.setValue(selectedOptionsValues);
  }
}
