import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { IInputOption } from '@msslib/components/formly/models/input';
import { FormsValidators } from '@msslib/components/forms/validators/forms.validators';
import { AuthorizeService, FormStateService, FormatService, ModalService } from '@msslib/services';
import {
  IApplicants,
  IBaseIncomeContext,
  IPropertyAndLoan,
  IPropertyBtl,
  IUiAffordabilityModel,
  IUiApplicant,
  IUiBaseIncome,
  IUiMainIncome,
  IUiOtherMortgage,
  IUiWorkRelatedIncomeBtlOption,
  UiBaseIncomeEnumType,
  errorMessages,
  expenditureForSubjectPropertyCriteria,
  initialProductLengthTooltip,
} from 'apps/clubhub/src/app/ignite/models/affordability';
import { IgniteHelperService } from '@msslib/services/ignite.helper';
import { IgniteService } from 'apps/clubhub/src/app/ignite/services/ignite.service';
import {
  ApplicantCountry,
  BenefitIncomeType,
  BtlType,
  ContractType,
  EmploymentStatus,
  ExpenditureForSubjectPropertyType,
  Frequency,
  HolidayLetFrequency,
  MortgageLenders,
  MortgageType,
  ProductLength,
  ProductType,
  PropertyType,
  PropertyUse,
  RemortgageReason,
  RemortgageType,
  RepaymentMethod,
  RepaymentVehicle,
  SkipListKeys,
  TaxStatusBtl,
  UkLocation,
  WorkRelatedIncomeType,
  WorkRelatedIncomeTypeBtl,
} from 'apps/shared/src/models';
import {
  AddOptionModalComponent,
} from '@msslib/components/add-option-modal';
import { FormFieldType, WrapperType, yesNoOptions } from '@msslib/components/formly/formly.config';
import { LenderService } from 'apps/shared/src/services/lender.service';
import { map } from 'rxjs';

const defaultWorkRelatedIncomeTypes = [
  WorkRelatedIncomeTypeBtl.Bonus,
  WorkRelatedIncomeTypeBtl.Commission,
  WorkRelatedIncomeTypeBtl.Overtime,
];

@Injectable({
  providedIn: 'root',
})
export class AffordabilityFormFieldsBtlService {
  public constructor(
    private helperService: IgniteHelperService,
    private modalService: ModalService,
    private igniteService: IgniteService,
    private authService: AuthorizeService,
    private formatService: FormatService,
    private formStateService: FormStateService,
    private lenderService: LenderService,
  ) { }

  public get model(): IUiAffordabilityModel | undefined {
    return this.igniteService.model;
  }

  public get allowCriteriaPlus() {
    return this.authService.allowCriteriaPlus;
  }

  public fields: FormlyFieldConfig[] = [
    // Step 1
    {
      key: 'propertyAndLoan',
      wrappers: [WrapperType.FormCard],
      props: {
        title: 'Property & Loan',
        summary: `<p>
          <i class="fas fa-info-circle"></i>
          All fields within this section are mandatory
        </p>`,
      },
      validators: {
        max500PercentageLoanValue: {
          expression: (_control: AbstractControl, field: FormlyFieldConfig) =>
            this.helperService.isLtvValid(field.form?.getRawValue().propertyAndLoan as IPropertyAndLoan),
          message: errorMessages.ltv500Percent,
          errorPath: 'loanAmount',
        },
        lessThanDeposit: {
          expression: (control: AbstractControl, field: FormlyFieldConfig) => {
            const { loanAmount, propertyValue } = field.form?.getRawValue().propertyAndLoan as IPropertyAndLoan;
            const equityLoanValue = control.value.equityLoanValue;
            if (!equityLoanValue) {
              return true;
            }

            return equityLoanValue < propertyValue - loanAmount;
          },
          message: 'Equity loan value must be less than deposit',
          errorPath: 'equityLoanValue',
        },
        numberOfBlocks: {
          expression: (_: AbstractControl, field: FormlyFieldConfig) => {
            const { numberOfBlocks, btlType } = field.form?.getRawValue().propertyAndLoan as IPropertyAndLoan;
            if (btlType !== BtlType.MUFB) {
              return true;
            }

            return numberOfBlocks && numberOfBlocks > 1;
          },
          message: 'Number of units should be greater than 1',
          errorPath: 'numberOfBlocks',
        },
        interestOnlyAmount: {
          expression: (control: AbstractControl, field: FormlyFieldConfig) => {
            const { loanAmount, interestOnlyAmount } = field.form?.getRawValue().propertyAndLoan as IPropertyAndLoan;
            if (!interestOnlyAmount) {
              return true;
            }

            return +interestOnlyAmount < +loanAmount;
          },
          message: errorMessages.interestOnlyLessThanLoanAmount,
          errorPath: 'interestOnlyAmount',
        },
        productTransferInitialDate: this.helperService.getProductTransferInitialPeriodValidation(),
      },
      fieldGroup: [
        {
          key: 'location',
          type: FormFieldType.Select,
          props: {
            label: 'Property location',
            change: (field: FormlyFieldConfig) => {
              const { location } = field.model;
              if (location === UkLocation.NorthernIreland) {
                field.form?.patchValue([{ helpToBuy: false }]);
              }
              this.igniteService.skipListChange.next(
                [SkipListKeys.PropertyLocation, SkipListKeys.HelpToBuyScheme],
              );
            },
            required: true,
            options: [
              this.helperService.defaultSelectOption,
              { value: UkLocation.EastMidlands, label: 'East Midlands' },
              { value: UkLocation.EastOfEngland, label: 'East of England' },
              { value: UkLocation.GreaterLondon, label: 'Greater London' },
              { value: UkLocation.NorthEast, label: 'North East' },
              { value: UkLocation.NorthWest, label: 'North West' },
              { value: UkLocation.SouthEast, label: 'South East' },
              { value: UkLocation.SouthWest, label: 'South West' },
              { value: UkLocation.WestMidlands, label: 'West Midlands' },
              { value: UkLocation.YorkshireAndHumberside, label: 'Yorkshire & Humberside' },
              { value: UkLocation.NorthernIreland, label: 'Northern Ireland' },
              { value: UkLocation.Scotland, label: 'Scotland' },
              { value: UkLocation.Wales, label: 'Wales' },
            ],
          },
        },
        {
          key: 'propertyType',
          type: FormFieldType.RadioButtons,
          defaultValue: PropertyType.House,
          props: {
            label: 'Property type',
            options: [
              { label: 'House', value: PropertyType.House },
              { label: 'Flat', value: PropertyType.Flat },
            ],
            required: true,
          },
        },
        {
          key: 'btlType',
          type: FormFieldType.Select,
          defaultValue: BtlType.Standard,
          props: {
            label: 'BTL type',
            options: this.helperService.productBtlTypeOptions,
            required: true,
          },
        },
        {
          key: 'newBuild',
          type: FormFieldType.RadioButtons,
          defaultValue: false,
          props: {
            label: 'New build',
            options: yesNoOptions,
            required: true,
          },
        },
        {
          key: 'mortgageType',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'Mortgage type',
            options: [
              { label: 'Purchase', value: MortgageType.Purchase },
              { label: 'Remortgage', value: MortgageType.Remortgage },
            ],
            required: true,
          },
        },
        {
          key: 'firstTimeBuyer',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'First time buyer',
            options: yesNoOptions,
            required: true,
          },
          expressions: {
            hide: field => field.model?.mortgageType !== MortgageType.Purchase,
          },
        },
        {
          key: 'firstTimeLandlord',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'First time landlord',
            options: yesNoOptions,
            required: true,
          },
          expressions: {
            hide: field => field.model?.mortgageType !== MortgageType.Purchase,
          },
        },
        {
          key: 'ownerOccupier',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'Owner occupier',
            options: yesNoOptions,
            required: true,
          },
          expressions: {
            hide: field =>
              field.model?.firstTimeBuyer
              || field.model?.firstTimeBuyer === null
              || field.model?.mortgageType !== MortgageType.Purchase,
          },
        },
        {
          key: 'remortgageType',
          type: FormFieldType.Select,
          props: {
            label: 'Remortgage type',
            options: [
              { label: 'Let to buy', value: RemortgageType.LetToBuy },
              { label: 'Like for like', value: RemortgageType.LikeForLike },
              { label: 'Capital raising', value: RemortgageType.CapitalRaising },
            ],
            required: true,
          },
          expressions: {
            hide: field => (field.model as IPropertyAndLoan)?.mortgageType !== MortgageType.Remortgage,
          },
        },
        ...this.mortgageLenderFields(),
        {
          key: 'amountTransferredFromOtherLender',
          type: FormFieldType.Currency,
          props: {
            label: 'Current mortgage balance outstanding',
            tooltip: 'What is the outstanding mortgage balance from the previous lender?',
            required: true,
          },
          expressions: {
            hide: field =>
              field.model?.mortgageType !== MortgageType.Remortgage
              || field.model?.remortgageType !== RemortgageType.CapitalRaising,
          },
        },
        {
          key: 'propertyValue',
          type: FormFieldType.Currency,
          props: {
            label: 'Property value',
            required: true,
            change: (field: FormlyFieldConfig, event) => {
              const value = +event.target.value.replace(',', '');
              this.fields
                .find(f => f.key === 'propertyAndLoan')?.fieldGroup
                ?.find(f => f.key === 'equityValue')?.formControl
                ?.setValue(value - field.parent?.model.loanAmount);
            },
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
          expressions: {
            'props.readonly': () => this.igniteService.hasPresetPropertyValue,
          },
        },
        {
          key: 'loanAmount',
          type: FormFieldType.Currency,
          props: {
            label: 'Loan amount',
            required: true,
            change: (field: FormlyFieldConfig, event) => {
              const value = +event.target.value.replace(',', '');
              this.fields
                .find(f => f.key === 'propertyAndLoan')?.fieldGroup
                ?.find(f => f.key === 'equityValue')?.formControl
                ?.setValue(field.parent?.model.propertyValue - value);
            },
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
          expressions: {
            'props.readonly': () => this.igniteService.hasPresetPropertyValue,
          },
        },
        {
          key: 'ltv',
          type: FormFieldType.Disabled,
          props: {
            label: 'LTV',
            addonEnd: '%',
          },
          expressions: {
            'props.value': (field) => this.helperService.calculateLtv(field.model as IPropertyAndLoan),
          },
        },
        {
          key: 'equityLoanValue',
          type: FormFieldType.Currency,
          props: {
            label: 'Equity loan value',
            tooltip:`<p>
              Enter the amount of the equity loan from the government scheme.
              DO NOT include any of the customer's own deposit.
            </p>`,
          },
          expressions: {
            hide: field => field.model?.location === UkLocation.NorthernIreland
              || !field.model?.helpToBuy,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
        {
          key: 'equityLoanValue',
          type: FormFieldType.Currency,
          props: {
            label: 'Equity loan value',
            tooltip: 'Enter the amount of the equity loan from the government scheme. DO NOT include any of the ' +
              'customer\'s own deposit.',
            required: true,
          },
          expressions: {
            hide: field => field.model?.location === UkLocation.NorthernIreland || !field.model?.helpToBuy,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
        {
          key: 'remortgageReason',
          type: FormFieldType.Select,
          props: {
            label: 'Reason for remortgage',
            tooltip: 'What is the intention for the Help to Buy Equity loan Remortgage?',
            options: [
              { label: 'Purchase additional shares', value: RemortgageReason.PurchaseAdditionalShares },
              { label: '£ for £ remortgage', value: RemortgageReason.PoundForPound },
              { label: 'Other', value: RemortgageReason.Other },
            ],
            required: true,
          },
          expressions: {
            hide: (field) =>
              (field.model as IPropertyAndLoan).mortgageType !== MortgageType.Remortgage ||
              !(field.model as IPropertyAndLoan).helpToBuy,
            'props.disabled': (field) => (field.model as IPropertyAndLoan)
              .productTypeExtended === ProductType.RetirementInterestOnly,
          },
        },
        ...this.repaymentMethodFields(true),
        {
          key: 'repaymentVehicle',
          type: FormFieldType.Select,
          defaultValue: RepaymentVehicle.SaleOfProperty,
          props: {
            label: 'Repayment vehicle',
            tooltip: 'How will the interest only element be repaid at the end of the term?',
            options: [
              { label: 'Sale of subject property', value: RepaymentVehicle.SaleOfProperty },
              { label: 'Sale of other property', value: RepaymentVehicle.SaleOfSecondProperty },
              { label: 'Endowments / Investments / Pension', value: RepaymentVehicle.EndowmentsInvestmentsPension },
              { label: 'Savings / ISA\'s', value: RepaymentVehicle.SavingsISA },
            ],
            required: true,
            change: (field: FormlyFieldConfig, event) => {
              this.igniteService.skipListChange.next([SkipListKeys.RepaymentVehicle]);
              const isSaleOfProperty =
                this.helperService
                  .getEnumValue(event.target.value) as RepaymentVehicle === RepaymentVehicle.SaleOfProperty;
              if (isSaleOfProperty) {
                (field.parent?.formControl as UntypedFormGroup).controls.equityValue
                  .setValue(field.parent?.model.propertyValue - field.parent?.model.loanAmount);
              }
            },
          },
          expressions: {
            hide: (field) =>
              (field.model as IPropertyAndLoan).repaymentMethod.value !== RepaymentMethod.InterestOnly &&
              (field.model as IPropertyAndLoan).repaymentMethod.value !== RepaymentMethod.InterestOnlyPartAndPart,
          },
        },
        {
          key: 'numberOfBlocks',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Number of units',
            required: true,
          },
          expressions: {
            hide: field => field.parent?.model?.btlType !== BtlType.MUFB,
          },
        },
        {
          key: 'holidayLetIncome',
          expressions: {
            hide: field => field.parent?.model?.btlType !== BtlType.HolidayLet,
          },
          props: {
            suppressErrors: true,
          },
          fieldGroup: [
            {
              type: FormFieldType.Separator,
              props: {
                header: 'Holiday Let Income',
                headerTooltip: 'Enter expected Low, Mid, and High seasonal rentals',
              },
            },
            {
              key: 'holidayLetIncomeLow',
              type: FormFieldType.Currency,
              props: {
                label: 'Low season rental amount received / expected',
              },
            },
            {
              key: 'holidayLetIncomeMedium',
              type: FormFieldType.Currency,
              props: {
                label: 'Mid season rental amount received / expected',
              },
            },
            {
              key: 'holidayLetIncomeHigh',
              type: FormFieldType.Currency,
              props: {
                label: 'High season rental amount received / expected',
              },
            },
            {
              key: 'holidayLetIncomeFrequency',
              type: FormFieldType.Select,
              defaultValue: null,
              props: {
                options: this.helperService.buildSelectOptionsFromEnum(HolidayLetFrequency),
                label: 'Payment frequency',
              },
            },
            {
              template: `
                <hr class="mt-4" />
              `,
            },
          ],
        },
        {
          key: 'rentalAmountReceivedExpected',
          type: FormFieldType.Currency,
          props: {
            label: 'Monthly rental amount received / expected',
            tooltip: 'What is the monthly rental amount received / expected if let on Assured Shorthold Tenancy basis',
            required: true,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
        {
          key: 'equityValue',
          type: FormFieldType.Currency,
          props: {
            label: 'How much equity is to remain in the property?',
            required: true,
          },
          expressions: {
            hide: field =>
              field.model?.repaymentVehicle !== RepaymentVehicle.SaleOfProperty ||
              field.model?.repaymentMethod.value === RepaymentMethod.CapitalAndInterest,
          },
          validators: {
            validation: [FormsValidators.integer, FormsValidators.greaterThan(0)],
          },
        },
        {
          key: 'interestOnlyAmount',
          type: FormFieldType.Currency,
          props: {
            label: 'Amount on interest only',
            required: true,
          },
          expressions: {
            hide: field => field.model?.repaymentMethod.value !== RepaymentMethod.InterestOnlyPartAndPart,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
        {
          type: FormFieldType.Disabled,
          props: {
            label: 'Repayment amount',
            addonStart: '£',
            testId: 'repaymentAmount',
          },
          expressions: {
            hide: (field) =>
              (field.model as IPropertyAndLoan).repaymentMethod.value !== RepaymentMethod.InterestOnlyPartAndPart,
            'props.value': (field) => {
              const { loanAmount, interestOnlyAmount } = field.model as IPropertyAndLoan;
              const repaymentAmount = this.helperService.calculateRepaymentAmount(loanAmount, interestOnlyAmount);
              return repaymentAmount ? this.formatService.formatCurrency(repaymentAmount) : 0;
            },
          },
        },
        {
          key: 'lettingAgentUsed',
          type: FormFieldType.RadioButtons,
          defaultValue: false,
          props: {
            label: 'Will a letting agent be used?',
            options: yesNoOptions,
            required: true,
          },
        },
        {
          key: 'monthlyLettingAgentFee',
          type: FormFieldType.Currency,
          props: {
            label: 'Monthly Letting Agent fee',
            required: true,
          },
          expressions: {
            hide: field => !field.model?.lettingAgentUsed,
          },
        },
        ...this.getOtherExpenditureFields<IUiWorkRelatedIncomeBtlOption>(
          'Other expenditure for subject property',
          'Add other expenditure for subject property',
          'otherExpenditure',
          ExpenditureForSubjectPropertyType,
          null,
          expenditureForSubjectPropertyCriteria,
          null,
          'Please input all that apply',
        ),
        {
          key: 'mortgageTerm',
          props: {
            label: 'Mortgage term',
            required: true,
            groupedField: true,
          },
          validators: {
            ...this.helperService.yearMonthValidator,
            max40Years: {
              expression: (control: AbstractControl) => {
                const { years, months } = control.value;
                return this.helperService.isMortgageTermValid(years, months);
              },
              message: this.max40YearsValidationMessage,
            },
          },
          fieldGroupClassName: 'row',
          fieldGroup: this.helperService.yearsAndMonthsFields(),
        },
        {
          key: 'productLength',
          type: FormFieldType.Select,
          defaultValue: ProductLength.TwoYears,
          props: {
            label: 'Initial product period',
            tooltip: initialProductLengthTooltip,
            options: this.helperService.productLengthBtlOptions,
            required: true,
          },
        },
        {
          key: 'numberOfBedrooms',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Number of bedrooms',
            min: 2,
            max: 20,
            required: true,
          },
          expressions: {
            hide: field => field.model?.btlType !== BtlType.HMO,
          },
          validators: {
            validation: [FormsValidators.greaterThan(2), FormsValidators.lessThan(21)],
          },
        },
        {
          key: 'propertySelfContainedFlats',
          type: FormFieldType.RadioButtons,
          defaultValue: false,
          props: {
            label: 'Does the property have self contained flats?',
            options: yesNoOptions,
            required: true,
          },
          expressions: {
            hide: field => field.model?.btlType !== BtlType.MUFB,
          },
        },
        {
          key: 'limitedCompanyPurchase',
          type: FormFieldType.RadioButtons,
          defaultValue: false,
          props: {
            label: 'Limited company purchase',
            options: yesNoOptions,
            required: true,
          },
        },
        {
          key: 'limitedCompanySpecialPurposeVehicle',
          type: FormFieldType.RadioButtons,
          defaultValue: false,
          props: {
            label: 'Is the Limited company in a special Purpose Vehicle?',
            options: yesNoOptions,
            required: true,
          },
          expressions: {
            hide: field => !field.model?.limitedCompanyPurchase,
          },
        },
        {
          key: 'portfolioLandlord',
          type: FormFieldType.RadioButtons,
          defaultValue: false,
          props: {
            label: 'Portfolio Landlord',
            tooltip: '4 or more Buy to Let or Consent to Let mortgaged properties (including this application)',
            options: yesNoOptions,
            required: true,
          },
        },
        {
          key: 'numberOfProperties',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'How many properties in the portfolio?',
            min: 3,
            required: true,
          },
          expressions: {
            hide: field => !field.model?.portfolioLandlord,
          },
          validators: {
            validation: [FormsValidators.greaterThan(3)],
          },
        },
        {
          key: 'totalPortfolioMortgageBalance',
          type: FormFieldType.Currency,
          props: {
            label: 'Total Portfolio Mortgage Balance',
            tooltip: 'Including subject property',
            required: true,
          },
          expressions: {
            hide: field => !field.model?.portfolioLandlord,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
        {
          key: 'totalPortfolioValuation',
          type: FormFieldType.Currency,
          props: {
            label: 'Total portfolio valuation',
            required: true,
          },
          expressions: {
            hide: field => !field.model?.portfolioLandlord,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
        {
          key: 'totalGrossMonthlyRent',
          type: FormFieldType.Currency,
          props: {
            label: 'Total gross monthly rent',
            required: true,
          },
          expressions: {
            hide: field => !field.model?.portfolioLandlord,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
      ],
    },
    // Step 2
    {
      key: 'applicants',
      wrappers: [WrapperType.FormCard],
      props: {
        title: 'Applicants',
        summary: `<p>
          <i class="fas fa-info-circle"></i>
          All fields within this section are mandatory
        </p>`,
      },
      validators: {
        totalDependants: {
          expression: (control: AbstractControl) => {
            const {
              financialDependants,
              dependantsAged0To5,
              dependantsAged6To11,
              dependantsAged12To17,
              dependantsAged18AndOver,
            } = control.value as IApplicants;
            if (!financialDependants) {
              return true;
            }
            const total = +dependantsAged0To5 + +dependantsAged6To11 + +dependantsAged12To17 + +dependantsAged18AndOver;
            return total > 0 && total <= 20;
          },
          message: 'Total dependants must be between 1 and 20',
          errorPath: 'dependantsAged18AndOver',
        },
      },
      fieldGroup: [
        {
          key: 'numberOfApplicants',
          type: FormFieldType.RadioButtons,
          defaultValue: 1,
          props: {
            label: 'Number of applicants',
            options: [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
            ],
            required: true,
            change: (field: FormlyFieldConfig) => {
              const numberOfApplicants = field.model.numberOfApplicants;
              this.onNumberOfApplicantsChange(numberOfApplicants, numberOfApplicants);

              this.fields = [...this.fields];
            },
          },
        },
        {
          key: 'applicantAges',
          type: FormFieldType.Repeating,
          props:{
            suppressErrors: true,
          },
          wrappers: [WrapperType.NoWrappers],
          fieldArray: {
            wrappers: [WrapperType.NoWrappers],
            props:{
              suppressErrors: true,
            },
            fieldGroup: [
              {
                expressions: {
                  template: field => {
                    const summary = +field.model.applicantNumber === 1 ? `
                      <p class="mb-3">
                        Enter the applicants in the order of how you want their income to be considered
                      </p>
                    ` : '';
                    return `
                      <hr class="my-0" />
                      <h2 class="fw-light mt-4 mb-3">Applicant ${field.model.applicantNumber}</h2>
                      ${summary}
                    `;
                  },
                },
              },
              {
                key: 'dateOfBirth',
                type: FormFieldType.Date,
                props: {
                  label: 'Date of birth',
                  required: true,
                },
                validators: {
                  validation: [FormsValidators.date, FormsValidators.atLeast18Years],
                },
              },
              {
                key: 'countryResident',
                type: FormFieldType.Select,
                props: {
                  label: 'What country is the applicant currently resident in',
                  options: this.helperService.countryResidentTypeOptions,
                  required: true,
                },
              },
              {
                key: 'grossAnnualIncome',
                type: FormFieldType.Currency,
                props: {
                  label: 'Gross Annual Income',
                  required: true,
                  tooltip: 'Please include total of all income before tax.',
                },
                validators: {
                  validation: [FormsValidators.greaterThan(0)],
                },
              },
              {
                key: 'grossAnnualIncomeUkLandProperty',
                type: FormFieldType.Currency,
                props: {
                  label: 'Gross Annual Income from UK Land and Property',
                  tooltip: 'Excluding the subject property',
                  required: true,
                },
                validators: {
                  validation: [FormsValidators.money],
                },
              },
              {
                key: 'taxStatusBtl',
                type: FormFieldType.Select,
                props: {
                  label: 'Tax status',
                  required: true,
                },
                validators: {
                  validation: [FormsValidators.isEnumMember(TaxStatusBtl)],
                },
                expressions: {
                  'props.options': (field) => {
                    const countryResident  = field.model?.countryResident;
                    if (countryResident === ApplicantCountry.Scotland) {
                      return this.helperService.buildSelectOptionsFromEnum(TaxStatusBtl)
                        .filter((option) => option.value !== TaxStatusBtl.Additional);
                    }
                    return this.helperService
                      .buildSelectOptionsFromEnum(TaxStatusBtl)
                      .filter(
                        (option) =>
                          option.value !== TaxStatusBtl.Top &&
                          option.value !== TaxStatusBtl.Starter &&
                          option.value !== TaxStatusBtl.Intermediate,
                      );
                  },
                  hide: field => field.model?.countryResident === null,
                },
              },
            ],
          },
        },
        {
          template: '<hr class="mt-4" />',
        },
        {
          key: 'topSlicing',
          type: FormFieldType.RadioButtons,
          defaultValue: false,
          props: {
            label: 'Top slicing',
            options: yesNoOptions,
            required: true,
            change: () => this.igniteService.topSlicingChange = true,
          },
        },
        {
          key: 'financialDependants',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'Any financial dependants',
            options: yesNoOptions,
            required: true,
          },
          expressions: {
            hide: field => field.model?.applicantNumber > 1 || !field.model?.topSlicing,
          },
        },
        {
          key: 'dependantsAged0To5',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Dependants aged 0-5',
            min: 0,
            max: 20,
          },
          expressions: {
            hide: field => !field.model?.financialDependants || !field.model?.topSlicing,
          },
        },
        {
          key: 'dependantsAged6To11',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Dependants aged 6-11',
            min: 0,
            max: 20,
          },
          expressions: {
            hide: field => !field.model?.financialDependants || !field.model?.topSlicing,
          },
        },
        {
          key: 'dependantsAged12To17',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Dependants aged 12-17',
            min: 0,
            max: 20,
          },
          expressions: {
            hide: field => !field.model?.financialDependants || !field.model?.topSlicing,
          },
        },
        {
          key: 'dependantsAged18AndOver',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: 'Dependants aged 18 and over',
            min: 0,
            max: 20,
          },
          expressions: {
            hide: field => !field.model?.financialDependants || !field.model?.topSlicing,
          },
        },

        // Submit button to always be the in last card. Hide *this* button if top slicing is 'Yes' (i.e. bigger form)
        {
          key: 'proceedBtn',
          type: 'buttons',
          props: {
            buttons: [
              { label: 'Proceed', type: 'submit', className: 'btn-2022--secondary' },
            ],
          },
          expressions: {
            hide: (field: FormlyFieldConfig) =>
              field.parent?.parent?.model.applicants.topSlicing || this.igniteService.expenditureBtlShow,
          },
        },
      ],
    },
    // Step 3
    {
      key: 'incomeAndExpenditure',
      wrappers: [WrapperType.FormCard],
      expressions: {
        hide: (field: FormlyFieldConfig) =>
          !field.parent?.model.applicants.topSlicing && !this.igniteService.expenditureBtlShow,
      },
      props: {
        id: 'header-income-expenditure',
        title: 'Income & Expenditure',
        summary: `<p>
          <i class="fas fa-info-circle"></i>
          Please note - Entering the correct expenditure at this point will allow us to give you a more
          accurate affordability amount! (Some lenders apply default expenditure values but some take the
          expenditure values entered below.)
        </p>`,
      },
      fieldGroup: [
        {
          key: 'applicants',
          type: FormFieldType.Repeating,
          wrappers: [WrapperType.NoWrappers],
          fieldArray: {
            wrappers: [WrapperType.NoWrappers],
            expressions: {
              hide: (field: FormlyFieldConfig) => {
                const { applicantNumber } = field.model as IUiApplicant;
                const { numberOfIncomesNeeded } = field.parent?.parent?.parent?.model.applicants;
                return applicantNumber > numberOfIncomesNeeded;
              },
            },
            validators: {
              atLeastOneAdditionalIncome: {
                expression: (_control: AbstractControl, field: FormlyFieldConfig) => {
                  const {
                    applicantNumber,
                    primaryIncome,
                    workAdditionalIncome,
                    pensionIncome,
                    benefitAdditionalIncome,
                    otherAdditionalIncome,
                  } = (field.model ?? {}) as IUiApplicant;
                  const { employmentStatus } = primaryIncome ?? {};
                  if (applicantNumber !== 1 || !this.helperService.isHomemakerStudentOrUnemployed(employmentStatus)) {
                    return true;
                  }

                  const totalIncome = [
                    ...(workAdditionalIncome ? workAdditionalIncome : []),
                    ...(benefitAdditionalIncome ? benefitAdditionalIncome : []),
                    ...(otherAdditionalIncome ? otherAdditionalIncome : []),
                  ].map((o) => o.option.amount)
                    .reduce((a, b) => +(a ?? 0) + +b, pensionIncome.amount);

                  return +(totalIncome ?? 0) > 0;
                },
                message: 'Total income must be greater than zero. Please add at least 1 income from the options below.',
                errorPath: 'primaryIncome.employmentStatus',
              },
              retiredRequiresPensionIncome: {
                expression: (_control: AbstractControl, field: FormlyFieldConfig) => {
                  const { primaryIncome, pensionIncome } = (field.model ?? {}) as IUiApplicant;
                  const { employmentStatus } = primaryIncome ?? {};
                  return employmentStatus !== EmploymentStatus.Retired || +(pensionIncome?.amount ?? 0) > 0;
                },
                message: 'Pension income must be greater than 0',
                errorPath: 'pensionIncome.amount',
              },
              creditCardBalanceToRepayLessThanTotalOutstandingCreditCardBalance: {
                expression: (_control: AbstractControl, field: FormlyFieldConfig) => {
                  const { creditCardBalance, creditCardBalanceToRepay } = (field.model ?? {}) as IUiApplicant;
                  if (+(creditCardBalance ?? 0) === 0) {
                    return true;
                  }
                  return +(creditCardBalanceToRepay ?? 0) <= +(creditCardBalance ?? 0);
                },
                message: errorMessages.creditCardBalanceToRepayLessThanTotalOutstandingCreditCardBalance,
                errorPath: 'creditCardBalanceToRepay',
              },
            },
            fieldGroup: [
              {
                expressions: {
                  template: field => `
                    <hr class="my-0" />
                    <h2 class="fw-light mt-4 mb-3">Applicant ${field.model.applicantNumber}</h2>
                  `,
                },
              },
              this.primaryIncomeFields('primaryIncome', true),
              {
                key: 'additionalIncome',
                type: FormFieldType.RadioButtons,
                props: {
                  label: 'Additional income',
                  tooltip: 'Any income that has not been included above',
                  options: this.helperService.yesNoOptions,
                  required: true,
                  change: (field: FormlyFieldConfig, event) => {
                    this.setAllAdditionalIncome(
                      field.model.primaryIncome.employmentStatus,
                      event.target.value,
                      field.parent?.model as IUiApplicant,
                    );
                  },
                },
                expressions: {
                  'props.disabled': (field) => {
                    const { employmentStatus } = (field.model as IUiApplicant).primaryIncome;
                    return this.helperService.isUnemployed(employmentStatus);
                  },
                },
              },
              ...this.getAdditionalIncomeFields<IUiWorkRelatedIncomeBtlOption>(
                'Additional income',
                'All other income',
                'workAdditionalBtlIncome',
                WorkRelatedIncomeTypeBtl,
                defaultWorkRelatedIncomeTypes,
                'Other earned income received that is not included in the main income above',
                null,
                true,
                'Please input all that apply',
              ),
              {
                template: `
                  <hr class="mt-4" />
                  <h2 class="font-roboto fw-light mt-4 mb-3">Pension income (annual)</h2>
                `,
                expressions: {
                  hide: (field: FormlyFieldConfig) =>
                    !(field.model as IUiApplicant).additionalIncome,
                },
              },
              {
                key: 'pensionIncome',
                wrappers: [WrapperType.NoWrappers],
                expressions: {
                  hide: (field) =>
                    !field.parent?.model?.additionalIncome,
                },
                fieldGroup: [
                  {
                    key: 'amount',
                    type: FormFieldType.Currency,
                    props: {
                      label: 'Pension',
                    },
                  },
                ],
              },
              {
                template: `
                  <hr class="mt-4" />
                  <h2 class="fw-light mt-4 mb-3">Expenditure (monthly)</h2>
                `,
              },
              {
                key: 'monthlyRentForResidentialProperty',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly Rent for residential property?',
                  required: false,
                },
                expressions: {
                  hide: field =>
                    field.parent?.parent?.parent?.parent?.model.propertyAndLoan.ownerOccupier ||
                    field.parent?.parent?.parent?.parent?.model.propertyAndLoan.ownerOccupier === null,
                },
              },
              {
                key: 'creditCardBalance',
                type: FormFieldType.Currency,
                props: {
                  label: 'Total outstanding credit card balance',
                  tooltip: 'Combined total of all outstanding credit cards',
                  required: true,
                },
              },
              {
                key: 'willRepayCreditCard',
                type: FormFieldType.RadioButtons,
                defaultValue: false,
                props: {
                  label: 'Is any of the balance to be repaid before completion?',
                  options: yesNoOptions,
                  required: true,
                },
                expressions: {
                  hide: field => field.model.creditCardBalance.toString() === '0',
                },
              },
              {
                key: 'creditCardBalanceToRepay',
                type: FormFieldType.Currency,
                props: {
                  label: 'What amount is to be repaid?',
                  required: true,
                },
                expressions: {
                  hide: field =>
                    (field.model.creditCardBalance ?? 0).toString() === '0' || !field.model.willRepayCreditCard,
                },
                validators: {
                  validation: [FormsValidators.greaterThan(0)],
                },
              },
              {
                key: 'totalMonthlyCreditPayments',
                type: FormFieldType.Currency,
                props: {
                  label: 'Total monthly payments for all other credit commitments',
                  tooltip: 'Combined total of all outstanding credit commitments EXCLUDING credit cards',
                  required: true,
                },
              },

              // Other
              {
                template: `
                  <hr class="mt-4" />
                  <h2 class="fw-light mt-4 mb-3">Other committed expenditure (monthly)</h2>
                `,
              },
              {
                key: 'groundRentServiceCharge',
                type: FormFieldType.Currency,
                props: {
                  label: 'Ground rent/ Service charge (including residential property)',
                  required: true,
                },
              },
              {
                key: 'childcareAndSchoolCollegeUniversityFees',
                type: FormFieldType.Currency,
                props: {
                  label: 'Child care/School/College/University fees',
                  required: true,
                },
              },
              {
                key: 'childMaintenance',
                type: FormFieldType.Currency,
                props: {
                  label: 'Child Maintenance',
                  required: true,
                },
              },
              {
                key: 'councilTax',
                type: FormFieldType.Currency,
                props: {
                  label: 'Council Tax',
                  required: true,
                },
              },
              {
                key: 'otherCommittedExpenditure',
                type: FormFieldType.Currency,
                props: {
                  label: 'Other committed expenditure',
                  required: true,
                },
              },
              {
                key: 'studentLoanContributions',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly student loan contributions',
                  required: true,
                },
                expressions: {
                  hide: field => !this.helperService.isEmployed(field.model.primaryIncome.employmentStatus),
                },
              },
              {
                key: 'repaymentVehicle',
                type: FormFieldType.Currency,
                props: {
                  label: 'Repayment Vehicle',
                  required: true,
                },
              },

              {
                key: 'householdExpenditureSameForAllApplicants',
                type: FormFieldType.RadioButtons,
                defaultValue: true,
                props: {
                  label: 'Are these household expenses for all applicants in the application? (these will not duplicate'
                    + ' across applicants on selection of Yes)',
                  options: yesNoOptions,
                  required: true,
                },
                expressions: {
                  hide: field =>
                    field.parent?.parent?.parent?.parent?.model.applicants.numberOfApplicants === 1 ||
                    field.model.applicantNumber > 1,
                },
              },
            ],
          },
        },
      ],
    },
    {
      wrappers: [WrapperType.FormCard],
      key: 'otherProperties',
      expressions: {
        hide: (field: FormlyFieldConfig) =>
          !field.parent?.model.applicants.topSlicing && !this.igniteService.expenditureBtlShow,
      },
      fieldGroup: [
        // Step 4
        {
          key: 'numberOfOtherBTLMortgages',
          type: FormFieldType.Select,
          props: {
            label: 'Number of other BTL Mortgages',
            tooltip: 'Excluding the subject property',
            options: [
              this.helperService.defaultSelectOption,
              ...Array(11).fill(undefined).map((val, i) => {
                return { value: i, label: `${i}` };
              }),
            ],
            required: true,
            change: (field: FormlyFieldConfig) => {
              this.onNumberOfOtherBTLMortgages(field.model.numberOfOtherBTLMortgages);
            },
          },
        },
        {
          key: 'propertiesBtl',
          type: FormFieldType.Repeating,
          wrappers: [WrapperType.NoWrappers],
          fieldArray: {
            wrappers: [WrapperType.NoWrappers],
            props: {
              label: 'Property',
            },
            validators: {
              mortgageBalance: {
                expression: (group: UntypedFormGroup) =>
                  this.helperService.canPaymentsCoverMortgageOutstanding(group),
                message: errorMessages.paymentsNotCoveringMortgageBalance,
                errorPath: 'monthlyBTLMortgageRepayment',
              },
            },
            fieldGroup: [
              {
                expressions: {
                  template: field => `
                    <hr class="my-0" />
                    <h2 class="font-roboto fw-light mt-4 mb-3">Property ${field.model.propertyNumber}</h2>
                  `,
                },
              },
              {
                key: 'totalBTLMortgageBalanceOutstanding',
                type: FormFieldType.Currency,
                props: {
                  label: 'Total BTL Mortgage Balance outstanding',
                  required: true,
                },
              },
              {
                key: 'monthlyBTLMortgageRepayment',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly BTL Mortgage repayment',
                  required: true,
                },
              },
              // ...this.repaymentMethodFields(true),
              {
                key: 'repaymentMethod',
                type: FormFieldType.Select,
                defaultValue: RepaymentMethod.InterestOnly,
                props: {
                  label: 'Method of repayment',
                  options: this.helperService.buildSelectOptionsFromEnum(RepaymentMethod),
                  required: true,
                },
              },
              {
                key: 'repaymentVehicle',
                type: FormFieldType.Select,
                defaultValue: null,
                props: {
                  label: 'Repayment vehicle',
                  tooltip: 'How will the interest only element be repaid at the end of the term?',
                  options: [
                    {
                      label: 'Sale of Subject Property',
                      value: RepaymentVehicle.SaleOfProperty,
                    },
                    {
                      label: 'Sale of other Property',
                      value: RepaymentVehicle.SaleOfSecondProperty,
                    },
                    {
                      label: 'Endowments / Investments / Pension',
                      value: RepaymentVehicle.EndowmentsInvestmentsPension,
                    },
                    {
                      label: 'Savings / ISA\'s',
                      value: RepaymentVehicle.SavingsISA,
                    },
                  ],
                  required: true,
                },
                expressions: {
                  hide: (field) =>
                    (field.model as IPropertyBtl).repaymentMethod !== RepaymentMethod.InterestOnly &&
                    (field.model as IPropertyBtl).repaymentMethod !== RepaymentMethod.InterestOnlyPartAndPart,
                },
              },
              {
                key: 'equityRemainsInProperty',
                type: FormFieldType.Currency,
                props: {
                  label: 'How much Equity is to remain in the property?',
                  required: true,
                },
                expressions: {
                  hide: field =>
                    field.model?.repaymentVehicle !== RepaymentVehicle.SaleOfProperty ||
                    field.model?.repaymentMethod === RepaymentMethod.CapitalAndInterest,
                },
              },
              {
                key: 'amountInterestOnly',
                type: FormFieldType.Currency,
                props: {
                  label: 'Amount on Interest Only',
                  required: true,
                },
                validators: {
                  validation: [FormsValidators.greaterThan(0)],
                },
                expressions: {
                  hide: field => field.model?.repaymentMethod !== RepaymentMethod.InterestOnlyPartAndPart,
                },
              },
              {
                key: 'repaymentAmount',
                type: FormFieldType.Currency,
                props: {
                  label: 'Repayment Amount',
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.repaymentMethod !== RepaymentMethod.InterestOnlyPartAndPart,
                },
              },
              {
                key: 'interestRate',
                type: FormFieldType.Number,
                props: {
                  label: 'Interest Rate',
                  required: true,
                  addonEnd: '%',
                  allowDecimal: true,
                },
                validators: {
                  validation: [FormsValidators.percentage],
                },
              },
              {
                key: 'mortgageTerm',
                props: {
                  required: true,
                  label: 'Mortgage term',
                  groupedField: true,
                },
                validators: {
                  ...this.helperService.yearMonthValidator,
                  max40Years: {
                    expression: (control) => {
                      const { years, months } = control.value;
                      return this.helperService.isMortgageTermValid(years, months);
                    },
                    message: this.max40YearsValidationMessage,
                  },
                },
                fieldGroupClassName: 'row',
                fieldGroup: this.helperService.yearsAndMonthsFields(),
              },
              {
                key: 'rentalAmountReceived',
                type: FormFieldType.Currency,
                props: {
                  label: 'Rental amount received',
                  tooltip: 'Input gross monthly figure',
                  required: true,
                },
              },
            ],
          },

        },
        // Other mortgages
        {
          key: 'numberOfOtherMortgages',
          type: FormFieldType.Select,
          props: {
            label: 'Number of Residential mortgages',
            options: [
              this.helperService.defaultSelectOption,
              ...Array(11).fill(undefined)
                .map((_val, i) => ({ value: i, label: `${i}` })),
            ],
            required: true,
            change: (field: FormlyFieldConfig) => {
              this.onNumberOfOtherResiMortgages(field.model.numberOfOtherMortgages);
            },
          },
        },
        {
          key: 'properties',
          type: FormFieldType.Repeating,
          wrappers: [WrapperType.NoWrappers],
          fieldArray: {
            wrappers: [WrapperType.NoWrappers],
            props: {
              label: 'Other mortgage',
            },
            validators: {
              mortgageBalance: {
                expression: (group: UntypedFormGroup) => this.helperService.canPaymentsCoverMortgageBalance(group),
                message: errorMessages.paymentsNotCoveringMortgageBalance,
                errorPath: 'monthlyMortgagePayments',
              },
            },
            fieldGroup: [
              {
                expressions: {
                  template: field => `
                    <hr class="my-0" />
                    <h2 class="font-roboto fw-light mt-4 mb-3">Other mortgage ${field.model.propertyNumber}</h2>
                  `,
                },
              },
              {
                key: 'propertyValue',
                type: FormFieldType.Currency,
                props: {
                  label: 'Property value',
                  required: true,
                },
                validators: {
                  validation: [
                    FormsValidators.greaterThan(0),
                    FormsValidators.integer,
                  ],
                },
              },
              {
                key: 'totalMortgageBalance',
                type: FormFieldType.Currency,
                props: {
                  label: 'Total mortgage balance',
                  required: true,
                },
                validators: {
                  validation: [
                    FormsValidators.greaterThan(0),
                    FormsValidators.integer,
                  ],
                },
              },
              {
                key: 'repaymentMethod',
                type: FormFieldType.Select,
                defaultValue: RepaymentMethod.InterestOnly,
                props: {
                  label: 'Method of repayment',
                  options: this.helperService.buildSelectOptionsFromEnum(RepaymentMethod),
                  required: true,
                },
              },
              {
                key: 'repaymentVehicle',
                type: FormFieldType.Select,
                defaultValue: null,
                props: {
                  label: 'Repayment vehicle',
                  tooltip: 'How will the interest only element be repaid at the end of the term?',
                  options: [
                    {
                      value: RepaymentVehicle.SaleOfProperty,
                      label: 'Sale of Subject Property',
                    },
                    {
                      value: RepaymentVehicle.SaleOfSecondProperty,
                      label: 'Sale of other Property',
                    },
                    {
                      value: RepaymentVehicle.EndowmentsInvestmentsPension,
                      label: 'Endowments / Investments / Pension',
                    },
                    {
                      value: RepaymentVehicle.SavingsISA,
                      label: 'Savings / ISA\'s',
                    },
                  ],
                  change: (field: FormlyFieldConfig, event) => {
                    const equityValue =
                      this.helperService
                        .getEnumValue(event.target.value) as RepaymentVehicle === RepaymentVehicle.SaleOfProperty
                        ? (this.model?.propertyAndLoan.propertyValue ?? 0) -
                            (this.model?.propertyAndLoan.loanAmount ?? 0)
                        : 0;
                    if (this.model && field.parent?.key) {
                      const index = +field.parent.key;
                      this.model.mortgages[index].equityRemains = equityValue;
                    }
                    this.igniteService.updateModel(this.model);
                  },
                  required: true,
                },
                expressions: {
                  hide: (field) =>
                    (field.model as IUiOtherMortgage)?.repaymentMethod !== RepaymentMethod.InterestOnly &&
                    (field.model as IUiOtherMortgage)?.repaymentMethod !== RepaymentMethod.InterestOnlyPartAndPart,
                  'props.disabled': () => false,
                },
              },
              {
                key: 'equityRemains',
                type: FormFieldType.Currency,
                props: {
                  label: 'How much Equity is to remain in the property?',
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.repaymentVehicle !== RepaymentVehicle.SaleOfProperty ||
                    field.model?.repaymentMethod === RepaymentMethod.CapitalAndInterest,
                },
              },
              {
                key: 'repaymentBalance',
                type: FormFieldType.Currency,
                props: {
                  label: 'Repayment balance',
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.repaymentMethod !== RepaymentMethod.InterestOnlyPartAndPart,
                },
                validators: {
                  validation: [FormsValidators.integer],
                },
              },
              {
                key: 'interestOnlyBalance',
                type: FormFieldType.Currency,
                props: {
                  label: 'Interest only balance',
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.repaymentMethod !== RepaymentMethod.InterestOnlyPartAndPart,
                },
                validators: {
                  validation: [FormsValidators.integer],
                },
              },
              {
                key: 'remainingTermOfLoan',
                props: {
                  label: 'Remaining term of the loan',
                },
                validators: {
                  ...this.helperService.yearMonthValidator,
                  max40Years: {
                    expression: (control: AbstractControl) => {
                      const { years, months } = control.value;
                      return this.helperService.isMortgageTermValid(years, months);
                    },
                    message: this.max40YearsValidationMessage,
                  },
                },
                fieldGroupClassName: 'row',
                fieldGroup: this.helperService.yearsAndMonthsFields(),
              },
              {
                key: 'monthlyMortgagePayments',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly mortgage payments',
                  required: true,
                },
                validators: {
                  validation: [FormsValidators.integer],
                },
              },
              {
                key: 'monthlyUtilities',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly utilities',
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.propertyUse !== PropertyUse.OwnUse,
                },
                validators: {
                  validation: [FormsValidators.integer],
                },
              },
              {
                key: 'monthlyCouncilTax',
                type: FormFieldType.Currency,
                props: {
                  label: 'Monthly council tax',
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.propertyUse !== PropertyUse.OwnUse,
                },
                validators: {
                  validation: [FormsValidators.integer],
                },
              },
              {
                key: 'interestRate',
                type: FormFieldType.Number,
                defaultValue: 0,
                props: {
                  label: 'Interest rate',
                  required: true,
                  min: 0,
                  max: 100,
                  addonEnd: '%',
                  allowDecimal: true,
                },
                validators: {
                  validation: [FormsValidators.greaterThan(0), FormsValidators.percentage],
                },
              },
              {
                key: 'tenancyInPlace',
                type: FormFieldType.RadioButtons,
                defaultValue: true,
                props: {
                  label: 'Is there a tenancy in place',
                  options: yesNoOptions,
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.propertyUse !== PropertyUse.Rental,
                },
              },
              {
                key: 'grossMonthlyRental',
                type: FormFieldType.Currency,
                props: {
                  label: 'Gross monthly rental',
                  required: true,
                },
                expressions: {
                  hide: field => field.model?.propertyUse !== PropertyUse.Rental,
                },
                validators: {
                  validation: [FormsValidators.integer],
                },
              },
            ],
          },
        },

        // Submit button always to be the last item within the last card
        {
          key: 'proceedBtn',
          type: 'buttons',
          props: {
            buttons: [
              { label: 'Proceed', type: 'submit', className: 'btn-2022--secondary' },
            ],
          },
        },
      ],

    },
  ];

  public get benefitIncomeCustomOptions(): IInputOption[] {
    return [
      this.helperService.defaultSelectOption,
      { value: BenefitIncomeType.ChildBenefit, label: 'Child benefit' },
      { value: BenefitIncomeType.ChildTaxCredits, label: 'Child tax credits' },
      { value: BenefitIncomeType.WorkingTaxCredits, label: 'Working tax credits' },
      { value: BenefitIncomeType.CarersAllowance, label: 'Carers allowance' },
      { value: BenefitIncomeType.DlaOrPip, label: 'DLA or PIP' },
      { value: BenefitIncomeType.UniversalCredit, label: 'Universal credit' },
    ];
  }

  private max40YearsValidationMessage(error, field: FormlyFieldConfig): string {
    return `${field.parent?.props?.text} has a maximum of 40 years`;
  }

  public templateHeader(title: string): FormlyFieldConfig {
    return {
      template: `
        <hr class="separator">
        <h2>${title}</h2>
      `,
    };
  }

  private mortgageLenderFields(): FormlyFieldConfig[] {
    const mortgageLenderHidden = (field) => {
      return field?.model?.mortgageType !== MortgageType.Remortgage
        && field?.model?.mortgageType !== MortgageType.Purchase;
    };

    const initialPeriodHidden = (field) => field?.model?.mortgageType !== MortgageType.Remortgage;

    return [
      {
        key: 'mortgageLender',
        wrappers: [WrapperType.NoWrappers],
        props: {
          suppressErrors: true,
        },
        fieldGroup: [
          {
            key: 'value',
            type: FormFieldType.Select,
            props: {
              label: 'Who is the current mortgage Lender?',
              allowNull: true,
            },
            expressions: {
              hide: field => mortgageLenderHidden(field.parent?.parent),
              'props.options': this.lenderService.getUnarchivedLendersNames(this.helperService.getBtlLendingType())
                .pipe(map(lenderNames => this.helperService.getMortgageLendersList(lenderNames))),
            },
          },
        ],
      },
      {
        key: 'currentMortgageLender',
        type: FormFieldType.Input,
        expressions: {
          hide: field => {
            if (mortgageLenderHidden(field)) {
              return true;
            }

            const mortgageLenderValue = field.model?.mortgageLender?.value;
            return !mortgageLenderValue || mortgageLenderValue !== MortgageLenders.Other;
          },
        },
      },
      {
        key: 'productTransferInitialDate',
        type: FormFieldType.Date,
        props: {
          label: 'What is the end date for the Initial period?',
        },
        validators: {
          validation: [FormsValidators.dateOrEmpty],
        },
        expressions: {
          hide: field => {
            if (initialPeriodHidden(field)) {
              return true;
            }

            return field.model?.mortgageLender?.value === MortgageLenders.Other;
          },
        },
      },
    ];
  }

  private repaymentMethodFields(showAllFields: boolean): FormlyFieldConfig[] {
    return [
      {
        key: 'repaymentMethod',
        wrappers: [WrapperType.NoWrappers],
        expressions: {
          'props.text': (field) => {
            if (showAllFields) {
              return 'Method of repayment';
            }
            switch (field.model as RepaymentMethod) {
              case RepaymentMethod.InterestOnly:
                return 'Method of repayment - Interest Only';
              case RepaymentMethod.InterestOnlyPartAndPart:
                return 'Method of repayment - Interest Only Part & Part';
            }
          },
        },
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'value',
            type: FormFieldType.Select,
            defaultValue: RepaymentMethod.InterestOnly,
            hide: !showAllFields,
            props: {
              label: 'Method of repayment',
              options: this.helperService.buildSelectOptionsFromEnum(RepaymentMethod),
              required: true,
            },
            expressions: {
              'props.readonly': field => field.parent?.parent?.model.helpToBuy ||
                (field.parent?.parent?.formControl as UntypedFormGroup).controls?.productTypeExtended
                  ?.value === ProductType.RetirementInterestOnly,
            },
          },
        ],
      },
    ];
  }

  private onNumberOfApplicantsChange(numberOfApplicants: number, numberOfIncomesNeeded: number) {
    if (!this.model) {
      this.igniteService.updateModel();
    }
    if (this.model?.applicants.applicantAges) {
      if ((this.model.applicants.applicantAges?.length ?? 0) > numberOfApplicants) {
        this.model.applicants.applicantAges = this.model.applicants.applicantAges.slice(0, numberOfApplicants);
      }
      if (
        (this.model.incomeAndExpenditure?.applicants?.length ?? 0) > numberOfIncomesNeeded &&
        this.model.incomeAndExpenditure.applicants
      ) {
        this.model.incomeAndExpenditure.applicants =
          this.model.incomeAndExpenditure.applicants.slice(0, numberOfIncomesNeeded);
      }

      if (this.model.applicants.applicantAges) {
        while (this.model.applicants.applicantAges.length < numberOfApplicants) {
          this.igniteService.addApplicant();
        }
      }
      while ((this.model.incomeAndExpenditure?.applicants?.length ?? 0) < numberOfIncomesNeeded) {
        this.igniteService.addApplicantIncome();
      }
    }

    this.igniteService.updateModel();
  }

  private onNumberOfOtherBTLMortgages(numberOfBTLMortgages: number) {
    if (!this.model) {
      this.igniteService.updateModel();
    }
    if (this.model) {
      if (this.model?.otherProperties.propertiesBtl === undefined) {
        this.model.otherProperties.propertiesBtl = [];
      }
      if (this.model.otherProperties.propertiesBtl?.length > numberOfBTLMortgages) {
        this.model.otherProperties.propertiesBtl =
          this.model.otherProperties.propertiesBtl.slice(0, numberOfBTLMortgages);
      }

      while (this.model.otherProperties.propertiesBtl?.length < numberOfBTLMortgages) {
        this.igniteService.addBtlProperty();
      }
    }

    this.igniteService.updateModel();
  }

  private onNumberOfOtherResiMortgages(numberOfMortgages: number) {
    if (!this.model) {
      this.igniteService.updateModel();
    }

    if (this.model) {
      if (!this.model?.otherProperties.properties) {
        this.model.otherProperties.properties = [];
      }
      if (this.model.otherProperties.properties.length > numberOfMortgages) {
        this.model.otherProperties.properties =
          this.model.otherProperties.properties.slice(0, numberOfMortgages);
      }
      while (this.model.otherProperties.properties.length < numberOfMortgages) {
        this.igniteService.addResiProperty();
      }
    }

    this.igniteService.updateModel();
  }

  private hideSalaryDividendsAndNetProfitFields(model: IUiMainIncome) {
    const { employmentStatus } = model;

    return employmentStatus !== EmploymentStatus.DirectorOrShareholder;
  }

  private optionAddHandler(model, field, title, optionLabel, options) {
    this.modalService
      .open({
        title,
        component: AddOptionModalComponent,
        data: {
          model,
          field,
          optionLabel,
          options,
        },
      })
      .then(
        () => {
          this.igniteService.updateModel();
        },
        () => null,
      );
  }

  private removeOptionItem(field: FormlyFieldConfig) {
    const parentModel = field.parent?.parent?.parent?.model;
    this.removeCategory(field.model.category, parentModel);
    this.igniteService.updateModel();
  }

  private removeCategory(category, parentModel) {
    const categories = parentModel.map((c) => c.option.category);
    const index = categories.indexOf(category);
    if (index > -1) {
      parentModel.splice(index, 1);
    }
  }

  private setAllAdditionalIncome(employmentStatus: EmploymentStatus, additionalIncome: boolean, model: IUiApplicant) {
    if (this.helperService.isEmployed(employmentStatus) && additionalIncome) {
      defaultWorkRelatedIncomeTypes
        .slice()
        .reverse();
    } else {
      model.workAdditionalBtlIncome = model.workAdditionalBtlIncome?.filter(
        (x) => !defaultWorkRelatedIncomeTypes.includes(x.option.category),
      );
    }

    this.igniteService.updateModel();
  }

  private getOtherExpenditureFields<ModelType extends IUiBaseIncome>(
    title: string,
    addText: string,
    key: string,
    enumValue: unknown,
    defaultOptions: UiBaseIncomeEnumType[] | null = null,
    criteriaContexts: IBaseIncomeContext[] | null = null,
    customOptions: IInputOption[] | null = null,
    headerTooltip: string | null = null,
  ): FormlyFieldConfig[] {
    defaultOptions = defaultOptions ?? [];
    criteriaContexts = criteriaContexts ?? [];
    const fields: FormlyFieldConfig[] = [
      {
        template: '<hr class="mt-4" />',
      },
      {
        template: `
          <h2 class="font-roboto fw-light mt-4 mb-3">${title}</h2>`,
        props: {
          addText: title,
          tooltip: headerTooltip,
        },
      },
    ];
    fields.push(
      ...[
        {
          key,
          type: FormFieldType.Repeating,
          fieldArray: {
            wrappers: [WrapperType.NoWrappers],
            fieldGroup: this.additionalIncomeFields(true, enumValue, customOptions),
          },
        } as FormlyFieldConfig,
        {
          key: `${key  }Btn`,
          type: FormFieldType.Buttons,
          props: {
            numColumns: 1,
            buttons: [
              {
                label: addText,
                className: 'btn-2022--secondary',
                onClick: (field: FormlyFieldConfig) => this.optionAddHandler(
                  field.model[key],
                  field,
                  addText,
                  'Expenditure type',
                  this.getExpenditureForSubjectPropertyCriteriaLabels(criteriaContexts)
                    .filter((option: IInputOption) => !defaultOptions?.includes(option.value))
                    .filter((option: IInputOption) => field.model[key]
                      ? field.model[key]?.every((m: { option: ModelType }) => m.option.category !== option.value)
                      : true),
                ),
              },
            ],
          },
        } as FormlyFieldConfig,
      ],
    );

    fields.push({
      template: '<hr class="mt-4" />',
    });

    return fields;
  }

  private getAdditionalIncomeFields<ModelType extends IUiBaseIncome>(
    title: string,
    addText: string,
    key: string,
    enumValue: any,
    defaultOptions: UiBaseIncomeEnumType[] | null = null,
    tooltip: string | null = null,
    customOptions: IInputOption[] | null = null,
    isWorkRelatedIncome = false,
    headerTooltip: string | null = null,
  ): FormlyFieldConfig[] {
    defaultOptions = defaultOptions ?? [];
    const fields: FormlyFieldConfig[] = [
      {
        wrappers: [WrapperType.NoWrappers],
        props: {
          tooltip: headerTooltip,
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => !field.parent?.model?.additionalIncome,
          template: () => `
            <hr class="mt-4" />
            <h2 class="font-roboto fw-light mt-4 mb-3">${title}</h2>
          `,
        },
      },
    ];

    if (isWorkRelatedIncome) {
      const bonusCommissionOvertimeFields:
        FormlyFieldConfig<FormlyFieldProps & Record<string, unknown>>[] = [];
      defaultWorkRelatedIncomeTypes.forEach((category) => {
        const amountsFieldKey = `${category}Amounts`;
        const categoryField: FormlyFieldConfig = {
          key: category,
          defaultValue: null,
          type: FormFieldType.Select,
          expressions: {
            hide: (field: FormlyFieldConfig) => !field.parent?.model?.additionalIncome,
          },
          props: {
            label: this.helperService.getEnumLabel(category, WorkRelatedIncomeType),
            options: this.helperService.buildSelectOptionsFromEnum(Frequency),
            change: (field: FormlyFieldConfig, event) => {
              const frequency: Frequency = this.helperService.getEnumValue(event.target.value);
              const fieldLength = this.workIncomeMainFieldLabel[frequency].length;
              if (!field.model[amountsFieldKey]) {
                field.model[amountsFieldKey] = [];
              }
              field.model[amountsFieldKey].length = fieldLength;
              this.igniteService.updateModel(this.model);
            },
          },
        };

        const categoryAmountFields: FormlyFieldConfig = {
          key: amountsFieldKey,
          type: FormFieldType.Repeating,
          expressions: {
            hide: (field) => !field.parent?.model?.additionalIncome,
          },
          fieldArray: {
            key: 'amount',
            type: FormFieldType.Currency,
            props: {
              required: true,
            },
            expressions: {
              'props.label': (field: FormlyFieldConfig) => {
                return this.workIncomeMainFieldLabel[field.parent?.parent?.model[category]][field.key];
              },
            },
          },
        };

        bonusCommissionOvertimeFields.push(categoryField);
        bonusCommissionOvertimeFields.push(categoryAmountFields);
      });

      fields.push(...bonusCommissionOvertimeFields);
    }

    fields.push(
      ...[
        {
          key,
          type: FormFieldType.Repeating,
          expressions: {
            hide: (field: FormlyFieldConfig) =>
              !field.parent?.model?.additionalIncome,
          },
          props: {
            tooltip,
          },
          fieldArray: {
            wrappers: [WrapperType.NoWrappers],
            fieldGroup: this.additionalIncomeFields(true, enumValue, customOptions),
          },
        } as FormlyFieldConfig,
        {
          key: `${key  }Btn`,
          type: FormFieldType.Buttons,
          props: {
            numColumns: 1,
            buttons: [
              {
                label: addText,
                className: 'btn-2022--secondary',
                onClick: (field: FormlyFieldConfig) => {
                  this.optionAddHandler(
                    field.model[key],
                    field,
                    addText,
                    'Income type',
                    (
                      this.helperService.buildSelectOptionsFromEnum(enumValue)
                    )
                      .filter((option: IInputOption) => !defaultOptions?.includes(option.value))
                      .filter((option: IInputOption) => field.model[key]
                        ? field.model[key]?.every((m: { option: ModelType }) => m.option.category !== option.value)
                        : true),
                  );
                },
              },
            ],
          },
          expressions: {
            hide: (field: FormlyFieldConfig) =>
              !field.parent?.model?.additionalIncome,
          },
        } as FormlyFieldConfig,
      ],
    );

    return fields;
  }

  private additionalIncomeFields<ModelType extends IUiBaseIncome>(
    showAllFields: boolean,
    enumValue: unknown,
    customOptions: IInputOption[] | null = null,
  ): FormlyFieldConfig[] {
    return [
      {
        key: 'option',
        fieldGroupClassName: 'row align-items-center',
        fieldGroup: [
          {
            type: FormFieldType.AdditionalIncomeHeader,
            props: {
              button: {
                className: 'link',
                iconClass: 'fas fa-trash-alt text-danger',
                onClick: this.removeOptionItem.bind(this),
              },
            },
            expressions: {
              hide: () => !showAllFields,
              'props.headerLabel': (field) =>
                this.getAdditionalIncomeLabel(customOptions, field.model as ModelType, enumValue),
            },
          },
          {
            key: 'category',
            type: FormFieldType.Input,
            className: 'd-none',
            props: {
              inputType: 'hidden',
            },
            hide: !showAllFields,
          },
          {
            key: 'amount',
            type: FormFieldType.Currency,
            className: 'col-md-6 mb-3',
            props: {
              required: true,
              label: 'Amount',
            },
            hide: !showAllFields,
          },
          {
            key: 'frequency',
            className: 'col-md-6 mb-3',
            type: FormFieldType.Select,
            props: {
              options: this.helperService.buildSelectOptionsFromEnum(Frequency),
              required: true,
              label: 'Payment frequency',
            },
            expressions: {
              hide: () => !showAllFields,
              'props.disabled': () => {
                return !showAllFields;
              },
            },
          },
          {
            key: 'frequency',
            type: FormFieldType.Input,
            className: 'd-none',
            props: {
              inputType: 'hidden',
            },
            expressions: {
              hide: () => !showAllFields,
            },
          },
        ],
      },
    ];
  }

  private getAdditionalIncomeLabel(customOptions: IInputOption[] | null, model: IUiBaseIncome, enumValue: unknown) {
    return customOptions
      ? customOptions.find((x) => x.value === model.category)?.label
      : this.helperService.getEnumLabel(model.category, enumValue);
  }

  private getExpenditureForSubjectPropertyCriteriaLabels(expenditureCriteria: any) {
    return [
      this.helperService.defaultSelectOption,
      ...expenditureCriteria.map((item) => {
        return {
          value: item.category,
          label: item.context,
        };
      }),
    ];
  }

  private primaryIncomeFields(key: string, isPrimaryIncome: boolean): FormlyFieldConfig {
    const employmentStatusOptions = [
      this.helperService.defaultSelectOption,
      { value: EmploymentStatus.Employed, label: 'Employed' },
      { value: EmploymentStatus.SelfEmployed, label: 'Self employed (sole trader/partner)' },
      { value: EmploymentStatus.DirectorOrShareholder, label: 'Director/Shareholder' },
    ];
    return {
      key,
      wrappers: [WrapperType.NoWrappers],
      expressions: {
        hide: field => !isPrimaryIncome && !field.form?.getRawValue().secondJob,
      },
      validators: {
        grossSalaryForLatestPeriod: {
          ...this.helperService.salaryAndDividendsGreaterThanZero(),
          errorPath: 'grossSalaryForLatestPeriod',
        },
        grossDividendsForLatestPeriod: {
          ...this.helperService.salaryAndDividendsGreaterThanZero(),
          errorPath: 'grossDividendsForLatestPeriod',
        },
        netProfitBeforeDividendsForLatestPeriod: {
          ...this.helperService.salaryAndDividendsGreaterThanZero(),
          errorPath: 'netProfitBeforeDividendsForLatestPeriod',
        },
        greaterThanZeroShareholder: {
          ...this.helperService.salaryAndDividendsGreaterThanZero(),
          errorPath: 'grossAnnualIncome',
        },
      },
      fieldGroup: [
        {
          key: 'employmentStatus',
          type: FormFieldType.Select,
          defaultValue: null,
          props: {
            label: 'Employment status',
            options: employmentStatusOptions,
            change: (field: FormlyFieldConfig, event) => {
              const employmentStatus = this.helperService.getEnumValue(event.target.value) as EmploymentStatus;
              if (employmentStatus === EmploymentStatus.Employed) {
                (field.parent?.formControl as UntypedFormGroup).controls.contractType?.setValue(ContractType.Permanent);
              }

              if (isPrimaryIncome) {
                (field.form?.parent as UntypedFormGroup).patchValue({
                  additionalIncome: this.helperService.isUnemployed(employmentStatus),
                });

                this.setAllAdditionalIncome(
                  employmentStatus,
                  field.parent?.model.additionalIncome,
                  field.parent?.parent?.model as IUiApplicant,
                );
              }
            },
            required: true,
          },
        },
        {
          key: 'shareholding',
          type: FormFieldType.Number,
          defaultValue: 0,
          props: {
            label: '% Shareholding',
            required: true,
            addonEnd: '%',
            min: 0,
            max: 100,
          },
          expressions: {
            hide: field => field.model?.employmentStatus !== EmploymentStatus.DirectorOrShareholder,
          },
          validators: {
            validation: [FormsValidators.greaterThan(0), FormsValidators.percentage],
          },
        },
        {
          key: 'treatedAsEmployee',
          type: FormFieldType.RadioButtons,
          props: {
            label: 'Treated as an employee for tax purposes',
            tooltip: 'Is the applicant paid via PAYE?',
            required: true,
          },
          expressions: {
            hide: field =>
              field.model.employmentStatus !== EmploymentStatus.Employed ||
              (field.model.contractType !== ContractType.SubContractorFtc &&
                field.model.contractType !== ContractType.SubContractorOpen),
          },
        },
        {
          key: 'timeRemainingOnContractMonths',
          props: {
            label: 'Length of time remaining on contract',
          },
          validators: {
            ...this.helperService.yearMonthValidator,
          },
          fieldGroupClassName: 'row',
          fieldGroup: this.helperService.yearsAndMonthsFields(),
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              const { employmentStatus, contractType } = field.parent?.model;
              return (
                employmentStatus !== EmploymentStatus.Employed ||
                (contractType !== ContractType.FixedTerm && contractType !== ContractType.SubContractorFtc)
              );
            },
          },
        },
        {
          key: 'grossAnnualIncome',
          type: FormFieldType.Currency,
          props: {
            label: 'Gross Annual Employed Income',
            tooltip: 'Gross income before any tax deductions if classed as an employee',
            required: true,
          },
          expressions: {
            hide: field => {
              const { employmentStatus } = field.parent?.model;
              return employmentStatus !== EmploymentStatus.Employed;
            },
          },
          validators: {
            greaterThanZeroNotShareholder: {
              expression: (_control: AbstractControl, field: FormlyFieldConfig) => {
                const { grossAnnualIncome, employmentStatus } = field.form?.getRawValue() as IUiMainIncome;
                return +grossAnnualIncome > 0 || employmentStatus === EmploymentStatus.DirectorOrShareholder;
              },
              message: 'Gross Annual Income should be greater than 0',
            },
          },
        },
        {
          key: 'grossAnnualIncome',
          type: FormFieldType.Currency,
          props: {
            label: 'Self-employed Income Latest Period',
            required: true,
          },
          expressions: {
            hide: field => {
              const { employmentStatus } = field.parent?.model;
              return employmentStatus !== EmploymentStatus.SelfEmployed;
            },
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
        {
          key: 'previousAnnualIncome',
          type: FormFieldType.Currency,
          props: {
            label: 'Self-employed Income Previous Period',
            required: true,
          },
          expressions: {
            hide: field => {
              const { employmentStatus } = field.parent?.model;
              return employmentStatus !== EmploymentStatus.SelfEmployed;
            },
          },
          validators: {
            validation: [FormsValidators.greaterThan(0)],
          },
        },
        {
          className: 'row',
          key: 'grossSalaryForLatestPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Director\'s salary for latest period',
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model),
          },
        },
        {
          key: 'grossSalaryForPreviousPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Director\'s salary period 2',
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model) ||
              this.formStateService.hideGrossSalaryPreviousFields,
          },
        },
        {
          type: FormFieldType.Buttons,
          props: {
            numColumns: 1,
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model),
            'props.buttons': () => [
              {
                label: `${this.formStateService.hideGrossSalaryPreviousFields ? 'Add' : 'Hide'} previous years`,
                className: 'btn-2022--secondary',
                onClick: () => this.formStateService.toggleExpandedField('hideGrossSalaryPreviousFields'),
              },
            ],
          },
        },
        {
          className: 'row',
          key: 'grossDividendsForLatestPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Gross dividends for latest period',
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model),
          },
        },
        {
          key: 'grossDividendsForPreviousPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Gross dividends period 2',
          },
          expressions: {
            hide: field =>
              this.hideSalaryDividendsAndNetProfitFields(field.model) ||
              this.formStateService.hideGrossDividendsPreviousFields,
          },
        },
        {
          type: FormFieldType.Buttons,
          props: {
            numColumns: 1,
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model),
            'props.buttons': () => [
              {
                label: `${this.formStateService.hideGrossDividendsPreviousFields ? 'Add' : 'Hide'} previous years`,
                className: 'btn-2022--secondary',
                onClick: () => this.formStateService.toggleExpandedField('hideGrossDividendsPreviousFields'),
              },
            ],
          },
        },
        {
          className: 'row',
          key: 'netProfitBeforeDividendsForLatestPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Net profit before dividends for latest period',
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model),
          },
        },
        {
          key: 'netProfitBeforeDividendsForPreviousPeriod',
          type: FormFieldType.Currency,
          props: {
            label: 'Net profit before dividends period 2',
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model) ||
              this.formStateService.hideNetProfitBeforeDividendsPreviousFields,
          },
        },
        {
          type: FormFieldType.Buttons,
          props: {
            numColumns: 1,
          },
          expressions: {
            hide: field => this.hideSalaryDividendsAndNetProfitFields(field.model),
            'props.buttons': () => [
              {
                label: `${this.formStateService.hideNetProfitBeforeDividendsPreviousFields
                  ? 'Add' : 'Hide'} previous years`,
                className: 'btn-2022--secondary',
                onClick: () =>
                  this.formStateService.toggleExpandedField('hideNetProfitBeforeDividendsPreviousFields'),
              },
            ],
          },
        },
      ],
    };
  }

  private get workIncomeMainFieldLabel() {
    return {
      [Frequency.Annually]: ['Latest year amount', 'Year 2 amount'],
      [Frequency.HalfYearly]: ['Latest period amount', 'Period 2 amount'],
      [Frequency.Quarterly]: ['Latest quarter amount', 'Quarter 2 amount', 'Quarter 3 amount', 'Quarter 4 amount'],
      [Frequency.Monthly]: ['Latest month amount', 'Month 2 amount', 'Month 3 amount'],
      [Frequency.FourWeekly]: ['Latest period amount', 'Period 2 amount', 'Period 3 amount'],
      [Frequency.Fortnightly]: [
        'Latest fortnight amount',
        'Fortnight 2 amount',
        'Fortnight 3 amount',
        'Fortnight 4 amount',
        'Fortnight 5 amount',
        'Fortnight 6 amount',
      ],
      [Frequency.Weekly]: [
        'Latest week amount',
        'Week 2 amount',
        'Week 3 amount',
        'Week 4 amount',
        'Week 5 amount',
        'Week 6 amount',
        'Week 7 amount',
        'Week 8 amount',
        'Week 9 amount',
        'Week 10 amount',
        'Week 11 amount',
        'Week 12 amount',
      ],
    };
  }
}
