<div class="property-condition-editor-grid">
  <span class="when"><strong>When</strong></span>

  <!-- Property operand -->
  <lib-rules-engine-property-selector
    class="property"
    [properties]="properties"
    [propertyName]="condition?.propertyName ?? ''"
    (propertyNameChange)="setProperty($event)"
    [disabled]="disabled"
    [testIdSuffix]="testIdSuffix" />

  <!-- Operator -->
  <select
    *ngIf="availableOperators.length"
    class="operator form-select form-select-sm"
    [ngModel]="condition.operatorName"
    (ngModelChange)="setOperator($event)"
    [disabled]="disabled"
    [attr.data-testid]="'default_rules_operator_' + testIdSuffix"
  >
    <option
      *ngFor="let operator of availableOperators; trackBy: trackByName"
      [ngValue]="operator.name">
      {{operator.name}}
    </option>
  </select>

  <!-- Right-hand operands -->
  <div class="operands row m-0">
    <div class="col p-0" *ngFor="let operand of rightHandOperands; let index = index; trackBy: trackByIndex">
      <lib-rules-engine-value-editor
        [type]="schema.types.get(operand.typeName)"
        [label]="operand.displayName"
        [nullable]="getOperandIsNullable(operand)"
        [value]="getOperand(index)"
        (valueChange)="setOperand(index, $event)"
        [disabled]="disabled"
        [testIdSuffix]="index + '_' + testIdSuffix"
      />
    </div>
  </div>
</div>

<div *ngIf="showErrors && errorMessages?.length > 0" class="error-message-container mt-2" role="alert">
  <div class="error-message-icon">
    <i class="fas fa-exclamation-circle"></i>
  </div>
  <div class="flex-grow-1 p-2">
    <p class="m-0" *ngFor="let errorMessage of errorMessages">{{errorMessage}}</p>
  </div>
</div>
