<section *ngIf="showDisclaimer" class="disclaimer container-fluid d-print-none">
  <div class="row"><span class="yellow"></span><span class="green"></span><span class="red"></span><span class="blue"></span></div>
  <div class="row mt-1">
    <div class="col">
      <p class="box-simple text-center">
        This website is designed to give professional financial advisers information and tools that they can use to help
        control and develop their business and should not be relied upon by private investors or any other persons.
        <br>
        It remains the responsibility of the regulated firm or financial adviser using Ignite to ensure that the
        information they provide is accurate (including but not limited to the application of fees, any cost
        <br>
        calculations and the documentation provided) and that the advice they provide is in line with MCOBs rules and
        guidance.
        <br>
        If you are unsure you can contact our product support team at
        <a href="mailto: lgmstechsupport@landg.com" >lgmstechsupport&#64;landg.com</a>
      </p>
      <p class="box-simple text-center mt-2">
        Product disclaimer: Lenders may launch a new Product or withdraw a Product at any time without prior notice.
        Please check directly with the lender regarding product availability and how criteria may affect this.
      </p>
      <p class="box-simple pt-0 text-center mt-2">
        The Sub-Processor engaged by L&G is as follows: IDOL/Investments Discounts On Line Limited
        (company number 04231834) with registered office at <u>One Coleman Street, London, EC2R 5AA</u>.
      </p>
    </div>
  </div>
</section>
<footer class="footer-wrapper container-fluid mt-2">
  <div class="d-flex mx-4 footer-height">
    <div class="flex-fill d-flex align-items-end mb-1 ps-2">
      <ul aria-label="Footer navigation" class="list-inline mb-3">
        <li class="list-inline-item">
          <a href="//www.legalandgeneral.com/adviser/accessibility/" data-analytics="Accessibility">
            Accessibility
          </a>
        </li>
        <li class="list-inline-item">
          <a href="//www.legalandgeneral.com/adviser/mortgage-club/legal-information/" data-analytics="Legal and regulatory information">
            Legal and regulatory information
          </a>
        </li>
        <li class="list-inline-item">
          <a href="//www.legalandgeneral.com/adviser/privacy-policy/" data-analytics="Professional Business Privacy policy">
            Professional Business Privacy policy
          </a>
        </li>
        <li class="list-inline-item">
          <a href="//www.legalandgeneral.com/adviser/cookie-policy/" data-analytics="Cookie policy">
            Cookie policy
          </a>
        </li>
        <li class="list-inline-item">
          <a href="//www.legalandgeneral.com/adviser/security-information/" data-analytics="Security">
            Security information
          </a>
        </li>
        <li class="list-inline-item">
          <a href="//www.legalandgeneral.com/adviser/all-our-websites/" data-analytics="All our websites">
            All our websites
          </a>
        </li>
        <li class="list-inline-item">
          <a
            href="http://careers.legalandgeneralgroup.com/"
            target="_blank"
            rel="noopener noreferrer"
            data-analytics="Legal &amp; General Group Careers"
          >
            Careers
          </a>
        </li>
      </ul>
    </div>
    <div class="d-flex align-items-end pe-2">
      <div class="text-end mb-3">
        <img
          class="mb-2"
          src="//www.legalandgeneral.com/web_resources/prototype-design/estate/css/landg/images/landg-logo.45b0217c.svg"
          alt="Legal and General"
        />
        <p class="mb-1">&copy; Legal & General Group plc {{ year }}</p>
      </div>
    </div>
  </div>
</footer>
