import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandingData, ClientApps } from '../../models';
import { BrandingService } from '../../services/branding.service';
import { LoginMenuComponent } from '../login-menu/login-menu.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'lib-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    LoginMenuComponent,
    AsyncPipe,
  ],
})
export class HeaderComponent {

  @Input() public showNav = true;
  @Input() public client: ClientApps;
  public navbarOpen = false;
  public clientApp: ClientApps;

  public constructor(private brandingService: BrandingService) { }

  public get branding$(): Observable<BrandingData | undefined> {
    return this.brandingService.branding$;
  }

  public toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
  }
}
