import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastService } from '@msslib/services/toast.service';
import { LenderPackagers } from '../../models/lender-packagers';
import { UpdatePackagerRelationshipsRequest } from '../../models/update-packager-relationships-request';
import { PackagerRelationshipsService } from '@msslib/services/packager-relationships.service';
import { Subject, takeUntil } from 'rxjs';
import { hasChanges } from '@msslib/helpers/model-helper';
import { NgFor } from '@angular/common';

@Component({
  selector: 'lib-packager-relationships',
  templateUrl: './packager-relationships.component.html',
  styleUrls: ['./packager-relationships.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgFor,
  ],
})
export class PackagerRelationshipsComponent implements OnInit, OnDestroy {

  @Input() public lenderNameId: number;
  @Output() public unsavedChangesChange = new EventEmitter<boolean>();
  public lenderPackagers: LenderPackagers;
  public form: FormGroup = this.fb.group({
    packagers: this.fb.array([]),
  });
  public hasUnsavedChanges: boolean;
  private pristineFormModel: any;
  public ngUnsubscribe: Subject<void> = new Subject<void>();

  public constructor(
    private packagerRelationshipsService: PackagerRelationshipsService,
    private fb: FormBuilder,
    private toastService: ToastService,
  ) {
  }

  public ngOnInit(): void {
    this.getAvailablePackersForLender();
  }

  private getAvailablePackersForLender() {
    this.packagerRelationshipsService.getAvailablePackersForLender(this.lenderNameId).subscribe(
      (lenderPackagers: LenderPackagers) => {
        this.lenderPackagers = lenderPackagers;
        this.createFormControls();
        this.checkFormUnsavedChanges();
      });
  }

  private get packagersArray() {
    return this.form.controls.packagers as FormArray;
  }

  private createFormControls(): void {
    for (let i = 0; i < this.lenderPackagers.packagersLendingTypes.length; i++) {
      const lender = this.lenderPackagers.packagersLendingTypes[i];

      const packagerForm = this.fb.group({
        id: [lender.lenderNameId],
        lendingTypes: this.fb.array([]),
      });

      this.packagersArray.push(packagerForm);

      const lendingTypesArray = this.packagersArray.at(i).get('lendingTypes') as FormArray;
      for (const lendingType of this.lenderPackagers.lenderNameLendingTypes.lendingTypes) {
        const currentLendingType = lender.lendingTypes.find(l => l.lendingTypeId === lendingType.lendingTypeId);

        const lendingTypeForm = this.fb.group({
          lenderId: [currentLendingType?.lenderId],
          enabled: [{
            value: currentLendingType?.enabled,
            disabled: !lender.lendingTypes.map(l => l.lendingTypeId).includes(lendingType.lendingTypeId)
              || currentLendingType?.archived,
          }],
        });

        lendingTypesArray.push(lendingTypeForm);
      }
    }
  }

  public onSubmit(): void {
    const request = this.form.value.packagers.flatMap(packager =>
      packager.lendingTypes.filter(lendingType => lendingType.lenderId).map(lendingType => ({
        lenderId: lendingType.lenderId,
        enabled: lendingType.enabled,
      } as UpdatePackagerRelationshipsRequest)),
    );
    this.packagerRelationshipsService.updatePackagerRelationships(this.lenderNameId, request).subscribe(
      () => {
        this.toastService.success('Packager selection saved');
        this.setPristineValues();
      },
    );
  }

  public checkFormUnsavedChanges() {
    this.setPristineValues();
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((currentModel) => {
      this.hasUnsavedChanges = hasChanges(this.pristineFormModel, currentModel);
      this.unsavedChangesChange.emit(this.hasUnsavedChanges);
    });
  }

  private setPristineValues() {
    this.pristineFormModel = this.form.value;
    this.hasUnsavedChanges = false;
    this.unsavedChangesChange.emit(this.hasUnsavedChanges);
  }

  public selectAll() {
    this.packagersArray.controls
      .map((control: FormArray) => (control.get('lendingTypes') as FormArray).controls
        .map(value => {
          const enabledControl = value.get('enabled');
          if (enabledControl && !enabledControl.disabled) {
            enabledControl.setValue(true);
          }
        }));

    this.form.markAsDirty();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
