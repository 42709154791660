import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lib-form-button-group',
  templateUrl: 'form-button-group.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class FormButtonGroupComponent extends FieldBaseComponent {
  public submit($event: Event) {
    $event.preventDefault();
    if (this.config?.onSubmit) {
      this.config.onSubmit(this.formGroup);
    }
    this.fc.validate();
  }
}
