<div [formGroup]="additionalObligations" class="mb-4 row">
  <label class="col-12" for="additionalObligationsText">Please provide additional obligations wording:</label>
  <div class="col-12">
    <app-esis-html-editor
      id="additionalObligationsText"
      [control]="additionalObligations.get('text')"
      placeholder="Additional Obligations wording"
      [isRequired]="true" />
    <div *ngIf="invalid('additionalObligations', 'text') && controlText('additionalObligations')?.errors?.['required']" class="invalid-feedback">
      Additional Obligation wording is required.
    </div>
  </div>
</div>

<ng-container *ngIf="isBarclays">
  <div [formGroup]="linkedSavings">
    <div class="mb-4 row">
      <label class="col-12" for="linkedSavingsText">Please provide Linked Savings wording:</label>
      <div class="col-12">
        <app-esis-html-editor
          id="linkedSavingsText"
          [control]="linkedSavings.get('text')"
          placeholder="Linked Savings wording"
          [isRequired]="true" />
        <div *ngIf="invalid('linkedSavings', 'text') && controlText('linkedSavings')?.errors?.['required']" class="invalid-feedback">
          Linked Savings wording is required.
        </div>
      </div>
    </div>
  </div>
  <div [formGroup]="springBoard">
    <div class="mb-4 row">
      <label class="col-12" for="springBoardText">Please provide Spring Board wording:</label>
      <div class="col-12">
        <app-esis-html-editor
          id="springBoardText"
          [control]="springBoard.get('text')"
          placeholder="Spring Board wording"
          [isRequired]="true" />
        <div *ngIf="invalid('springBoard', 'text') && controlText('springBoard')?.errors?.['required']" class="invalid-feedback">
          Spring Board wording is required.
        </div>
      </div>
    </div>
  </div>
</ng-container>
