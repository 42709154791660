import {
  Component,
  Input,
} from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-yes-no-column',
  templateUrl: 'yes-no-column.component.html',
  styleUrls: ['yes-no-column.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class YesNoColumnComponent {
  @Input() public columnValue: boolean | null | undefined;
}
