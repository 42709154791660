import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormsValidators } from '@msslib/components/forms/validators';
import { AddressSearchType, AddressesSearchResults, DocumentPreferences }
  from '../../models/document-preferences.model';
import { PostcodeService } from '../../services';
import { ToastService } from '@msslib/services';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { UserDetailsService } from '@msslib/services/user-details.service';
import { firstValueFrom, tap } from 'rxjs';
import { FormFieldType, WrapperType } from '@msslib/components/formly/formly.config';
import { DocumentPreferenceInfoType } from '@msslib/models/enums/document-preferences.enum';
import { Address } from '@msslib/models';
import { NgIf } from '@angular/common';


@Component({
  selector: 'lib-document-preferences',
  templateUrl: './document-preferences.component.html',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
})
export class DocumentPreferencesComponent implements OnInit {

  @Input() public config: FormlyFieldConfig[];
  @Input() public form: UntypedFormGroup;
  @Input() public model: DocumentPreferences;
  @Input() public showSave = true;
  @Output() public requestForbidden: EventEmitter<void> = new EventEmitter<void>();
  @Output() public savePreferences: EventEmitter<DocumentPreferences> = new EventEmitter<DocumentPreferences>();

  private defaultProps: Record<string, unknown>;
  public isManualAddress: AddressSearchType = { complaints: false, broker: false};
  private addressSearchSuccessful: AddressSearchType = { complaints: false, broker: false};
  public addresses: AddressesSearchResults = { complaints: [], broker: []};

  public constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private userDetailsService: UserDetailsService,
    private postCodeService: PostcodeService,
    private toastService: ToastService,
  ) {}

  public ngOnInit() {
    this.requestEsisDocPreferences();
  }

  public get isValid(): boolean {
    return this.form.valid;
  }

  private requestEsisDocPreferences(): void {
    this.userDetailsService.getEsisDocPreferences().subscribe({
      next: (preferences: DocumentPreferences) => this.initializeForm(preferences),
      error: (error: HttpErrorResponse) => {
        if (error.status as HttpStatusCode === HttpStatusCode.Forbidden) {
          this.requestForbidden.emit();
        } else {
          this.initializeForm();
        }
      },
    });
  }

  private initializeForm(preferences?: DocumentPreferences): void {
    this.defaultProps = {
    };

    this.model = {
      complaints: null,
      evidenceOfResearch: null,
    } as DocumentPreferences;

    if (preferences) {
      this.model = preferences;
      // should show manual address fields if pre-population
      this.isManualAddress.complaints = !!preferences.complaints;
      this.isManualAddress.broker = !!preferences.broker;
    }

    this.config = this.config ?? this.getConfig();

    if (!this.form) {
      this.form = new UntypedFormGroup({});
      this.form.patchValue(this.model);
    }
  }

  private getConfig(): FormlyFieldConfig[] {
    return [
      {
        type: FormFieldType.Template,
        className: 'h2',
        props: {
          label: 'Broker Details',
        },
      },
      {
        key: 'broker',
        wrappers: [WrapperType.NoWrappers],
        fieldGroup: [
          {
            key: 'brokerName',
            type: FormFieldType.Input,
            props: {
              label: 'Broker Name',
              ...this.defaultProps,
              required: true,
            },
          },
          {
            key: 'postcode',
            type: FormFieldType.PostCode,
            props: {
              label: 'Postcode',
              submitText: 'Find Address',
              linkText: 'Enter address manually',
              ...this.defaultProps,
              required: true,
              setAddressType: this.setAddressSearchTypeBroker.bind(this),
              clickHandler: this.searchAddress.bind(this, DocumentPreferenceInfoType.BrokerDetails),
            },
            expressions: {
              hide: () => this.isManualAddress.broker,
            },
            validators: {
              validation: [
                FormsValidators.postcode,
              ],
            },
          },
          {
            key: 'addressLineLookUp',
            type: FormFieldType.Select,
            props: {
              label: 'Address',
              placeholder: 'Please Select...',
              options: [],
              ...this.defaultProps,
              required: true,
              change: field =>
                this.prePopAddress(field.model[field.key as string], DocumentPreferenceInfoType.BrokerDetails),
            },
            expressions: {
              hide: () => !this.addressSearchSuccessful.broker || this.isManualAddress.broker,
            },
          },
          {
            key: 'addressLine1',
            type: FormFieldType.Input,
            props: {
              label: 'Address Line 1',
              ...this.defaultProps,
              required: true,
            },
            expressions: {
              hide: () => !this.isManualAddress.broker,
            },
          },
          {
            key: 'addressLine2',
            type: FormFieldType.Input,
            props: {
              label: 'Address Line 2',
              ...this.defaultProps,
            },
            expressions: {
              hide: () => !this.isManualAddress.broker,
            },
          },
          {
            key: 'town',
            type: FormFieldType.Input,
            props: {
              label: 'Town',
              ...this.defaultProps,
              required: true,
            },
            expressions: {
              hide: () => !this.isManualAddress.broker,
            },
          },
          {
            key: 'county',
            type: FormFieldType.Input,
            props: {
              label: 'County',
              ...this.defaultProps,
              required: false,
            },
            expressions: {
              hide: () => !this.isManualAddress.broker,
            },
          },
          {
            key: 'postcode',
            type: FormFieldType.PostCode,
            props: {
              label: 'Postcode',
              linkText: 'Search for address',
              setAddressType: this.setAddressSearchTypeBroker.bind(this),
              ...this.defaultProps,
              required: true,
            },
            expressions: {
              hide: () => !this.isManualAddress.broker,
            },
          },
          {
            key: 'telephone',
            type: FormFieldType.Input,
            props: {
              label: 'Telephone Number',
              ...this.defaultProps,
              maxLength: 15,
              required: true,
            },
            validators: {
              telephone: {
                expression: (fc: UntypedFormControl) => !FormsValidators.telephone(fc),
                message: 'Enter valid telephone number',
              },
            },
          },
          {
            key: 'emailAddress',
            type: FormFieldType.Input,
            props: {
              label: 'Email Address',
              inputType: 'email',
              ...this.defaultProps,
              required: false,
            },
            validators: {
              validation: [FormsValidators.email],
            },
          },
        ],
      },
      {
        type: FormFieldType.Template,
        className: 'h2',
        props: {
          label: 'Complaints Information',
          tooltip: 'Please provide full details of where a customer should direct a complaint',
        },
      },
      {
        key: 'complaints',
        wrappers: [WrapperType.NoWrappers],
        fieldGroup: [
          {
            key: 'contactPerson',
            type: FormFieldType.Input,
            props: {
              label: 'Contact Person / Department Name',
              ...this.defaultProps,
              required: true,
            },
          },
          {
            key: 'postcode',
            type: FormFieldType.PostCode,
            props: {
              label: 'Complaints Postcode',
              submitText: 'Find Address',
              linkText: 'Enter address manually',
              ...this.defaultProps,
              required: true,
              setAddressType: this.setAddressSearchTypeComplaints.bind(this),
              clickHandler: this.searchAddress.bind(this, DocumentPreferenceInfoType.ComplaintsInformation),
            },
            expressions: {
              hide: () => this.isManualAddress.complaints,
            },
            validators: {
              validation: [
                FormsValidators.postcode,
              ],
            },
          },
          {
            key: 'addressLineLookUp',
            type: FormFieldType.Select,
            props: {
              label: 'Complaints Address',
              placeholder: 'Please Select...',
              options: [],
              ...this.defaultProps,
              required: true,
              change: field =>
                this.prePopAddress(field.model[field.key as string], DocumentPreferenceInfoType.ComplaintsInformation),
            },
            expressions: {
              hide: () => !this.addressSearchSuccessful.complaints || this.isManualAddress.complaints,
            },
          },
          {
            key: 'addressLine1',
            type: FormFieldType.Input,
            props: {
              label: 'Address Line 1',
              ...this.defaultProps,
              required: true,
            },
            expressions: {
              hide: () => !this.isManualAddress.complaints,
            },
          },
          {
            key: 'addressLine2',
            type: FormFieldType.Input,
            props: {
              label: 'Address Line 2',
              ...this.defaultProps,
            },
            expressions: {
              hide: () => !this.isManualAddress.complaints,
            },
          },
          {
            key: 'town',
            type: FormFieldType.Input,
            props: {
              label: 'Town',
              ...this.defaultProps,
              required: true,
            },
            expressions: {
              hide: () => !this.isManualAddress.complaints,
            },
          },
          {
            key: 'county',
            type: FormFieldType.Input,
            props: {
              label: 'County',
              ...this.defaultProps,
              required: false,
            },
            expressions: {
              hide: () => !this.isManualAddress.complaints,
            },
          },
          {
            key: 'postcode',
            type: FormFieldType.PostCode,
            props: {
              label: 'Postcode',
              linkText: 'Search for address',
              setAddressType: this.setAddressSearchTypeComplaints.bind(this),
              ...this.defaultProps,
              required: true,
            },
            expressions: {
              hide: () => !this.isManualAddress.complaints,
            },
          },
          {
            key: 'telephone',
            type: FormFieldType.Input,
            props: {
              label: 'Telephone Number',
              ...this.defaultProps,
              maxLength: 15,
              required: true,
            },
            validators: {
              telephone: {
                expression: (fc: UntypedFormControl) => !FormsValidators.telephone(fc),
                message: 'Enter valid telephone number',
              },
            },
          },
          {
            key: 'emailAddress',
            type: FormFieldType.Input,
            props: {
              label: 'Email Address',
              inputType: 'email',
              ...this.defaultProps,
              required: false,
            },
            validators: {
              validation: [FormsValidators.email],
            },
          },
          {
            key: 'maxHandlingTime',
            type: FormFieldType.Number,
            props: {
              label: 'Max time for handling complaints (weeks)',
              min: 0,
              ...this.defaultProps,
              required: true,
            },
            validators: {
              validation: [
                FormsValidators.integer,
              ],
            },
          },
        ],
      },
      {
        template: '',
      },
      {
        type: FormFieldType.Template,
        className: 'h2',
        props: {
          label: 'Evidence Of Research',
        },
      },
      {
        key: 'evidenceOfResearch',
        wrappers: [WrapperType.NoWrappers],
        fieldGroup: [
          {
            key: 'numberOfResults',
            type: 'input',
            props: {
              label: 'Number of Results to Display',
              type: 'number',
              ...this.defaultProps,
              required: true,
            },
            validators: {
              validation: [
                FormsValidators.integer,
              ],
            },
          },
          {
            key: 'resultsGridTitle',
            type: 'input',
            props: {
              label: 'Results Grid Title',
              ...this.defaultProps,
              required: false,
            },
          },
          {
            key: 'resultsGridDescription',
            type: 'input',
            props: {
              label: 'Results Grid Description',
              ...this.defaultProps,
              required: false,
            },
          },
          {
            key: 'reasonForRecommendation',
            type: 'textarea',
            props: {
              label: 'Reason for Recommendation',
              ...this.defaultProps,
              required: false,
            },
          },
          {
            key: 'additionalInformation',
            type: 'textarea',
            props: {
              label: 'Additional Information',
              ...this.defaultProps,
              required: false,
            },
          },
        ],
      },
    ];
  }

  private setAddressSearchTypeComplaints(): void {
    this.isManualAddress.complaints = !this.isManualAddress.complaints;
  }

  private setAddressSearchTypeBroker(): void {
    this.isManualAddress.broker = !this.isManualAddress.broker;
  }

  private prePopAddress(fullAddress: string, formType: DocumentPreferenceInfoType) : void {
    const currentForm = this.selectFormByType(formType);
    const newAddress = this.addresses[currentForm].find(address => address.fullAddress === fullAddress);
    const [firstLine, ...secondLine] = newAddress.addressLine1.split(',');
    // update model
    this.model[currentForm].addressLine1 = firstLine.trim();
    this.model[currentForm].addressLine2 = secondLine.join(',').trim();
    this.model[currentForm].town = newAddress.town;
    // patch value in form
    this.form.patchValue(this.model);
  }

  public searchAddress(formType: DocumentPreferenceInfoType): void {
    const currentForm = this.selectFormByType(formType);
    if (!FormsValidators.postcodeRegex.test(this.form.value[currentForm].postcode)) {
      return;
    }
    this.postCodeService.findAddress(this.form.value[currentForm].postcode).subscribe(
      (addresses: Address[]) => {

        this.addressSearchSuccessful[currentForm] = addresses.length > 0;
        const addressFieldConfig: FormlyFieldConfig | undefined =
          this.config.find(x => x.key === currentForm)?.fieldGroup?.find(
            x => x.key === 'addressLineLookUp' && x.type === FormFieldType.Select,
          );

        if (addressFieldConfig?.props && this.addressSearchSuccessful[currentForm]) {
          addressFieldConfig.props.options = addresses.map(a => (
            { value: a.fullAddress, label: a.fullAddress }
          ));
          this.addresses[currentForm] = addresses;
        }
        this.form.get([currentForm, 'postcode'])?.updateValueAndValidity();
      },
    );
  }

  private selectFormByType(formType: DocumentPreferenceInfoType) : string {
    switch (formType) {
      case DocumentPreferenceInfoType.BrokerDetails:
        return 'broker';
      case DocumentPreferenceInfoType.ComplaintsInformation:
        return 'complaints';
      case DocumentPreferenceInfoType.EvidenceOfResearch:
        return 'evidenceOfResearch';
      default:
        return '';
    }
  }

  public markAllAsTouched() {
    this.form.markAllAsTouched();
  }

  public saveDocPreferences(showToast = true): Promise<void> {
    if (!this.isManualAddress.complaints && !this.addressSearchSuccessful.complaints) {
      this.triggerAddressValidation(DocumentPreferenceInfoType.ComplaintsInformation);
    }
    if (!this.isManualAddress.broker && !this.addressSearchSuccessful.broker) {
      this.triggerAddressValidation(DocumentPreferenceInfoType.BrokerDetails);
    }
    return this.form.valid
      ? firstValueFrom(this.userDetailsService.saveEsisDocPreferences(this.model)
        .pipe(tap(() => {
          if (showToast) {
            this.toastService.success('Successfully saved document preferences');
          }
          this.savePreferences.emit(this.form.value);
        })))
      : Promise.reject();
  }

  private triggerAddressValidation(formType: DocumentPreferenceInfoType) : void {
    const currentForm = this.selectFormByType(formType);
    this.addressSearchSuccessful[currentForm] = true;
    this.searchAddress(formType);
    this.changeDetectorRef.detectChanges();
    this.form.markAllAsTouched();
  }
}
