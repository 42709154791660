import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lib-form-radio-list',
  styleUrls: ['form-radio-list.component.scss'],
  templateUrl: 'form-radio-list.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    FormFieldErrorComponent,
  ],
})
export class FormRadioListComponent extends FieldBaseComponent {
  public onChange(option: string) {
    if (this.config.onRadioChange) {
      this.config.onRadioChange(option);
    }
  }
}
