import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IInputList } from '../../models';
import { FormsModule } from '@angular/forms';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'lib-radio-buttons',
  styleUrls: ['./radio-buttons.component.scss'],
  templateUrl: './radio-buttons.component.html',
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    FormsModule,
    NgIf,
    NgTemplateOutlet,
  ],
})
export class RadioButtonsComponent implements OnInit {
  @Input() public list: IInputList[];
  @Input() public value: unknown;
  @Input() public large: boolean;
  @Input() public underLine = false;
  @Output() public valueChange = new EventEmitter();
  public id: number;

  public ngOnInit(): void {
    this.id = Math.floor(Math.random() * 10000) + 1;
  }

  public trackByFn(index: number) {
    return index;
  }

  public onChange(value: unknown) {
    this.value = value;
    this.valueChange.emit(value);
  }
}
