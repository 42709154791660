import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quoteNumber',
  standalone: true,
})
export class AsantoQuoteNumberPipe implements PipeTransform {
  public transform(value: string) {
    return value?.slice(0, 8).toUpperCase();
  }
}
