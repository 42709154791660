<p>Please confirm the type of customer.</p>

<form [formGroup]="form" (submit)="submit()">
  <div *ngFor="let prop of applicableProductProperties" class="row">
    <span class="col-6"><strong>{{getLabel(prop)}}</strong></span>

    <div class="form-check col-3">
      <input
        type="radio"
        class="form-check-input"
        [formControlName]="prop"
        [value]="true"
        [id]="'preview_attr_' + prop + '_true'"
        [attr.data-testid]="'preview_attr_' + prop + '_true'"
      />
      <label class="form-check-label" [for]="'preview_attr_' + prop + '_true'">Yes</label>
    </div>

    <div class="form-check col-3">
      <input
        type="radio"
        class="form-check-input"
        [formControlName]="prop"
        [value]="false"
        [id]="'preview_attr_' + prop + '_false'"
        [attr.data-testid]="'preview_attr_' + prop + '_false'"
      />
      <label class="form-check-label" [for]="'preview_attr_' + prop + '_false'">No</label>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button type="submit" class="btn btn-2022--secondary">Confirm</button>
  </div>
</form>
