import { Component } from '@angular/core';
import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  templateUrl: 'buttons.component.html',
  styleUrls: ['buttons.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
  ],
})
export class ButtonsFieldTypeComponent extends MssFieldBaseFieldTypeComponent {

  public get numColumns(): number {
    return this.props.numColumns ?? 3;
  }
}
