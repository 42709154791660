<div class="d-flex">
  <div class="flex-grow-0 logical-operator-block logical-operator-block-{{condition.operator}}" [class.disabled]="disabled" (click)="toggleOperator()">
    <span>{{operatorLabel}}<br/>({{operatorSubLabel}})</span>
  </div>

  <div class="flex-grow-1 ms-2">
    <lib-rules-engine-condition-editor
      *ngFor="let subCondition of condition.operands; trackBy: trackByIndex; let index = index"
      class="mb-2"
      [schema]="schema"
      [condition]="subCondition"
      (conditionChange)="changeSubCondition($event, index)"
      [disabled]="disabled"
      [isRoot]="false"
      [showErrors]="showErrors"
      [allowAlwaysCondition]="false"
      [canDeleteCondition]="true"
      [parentLogicalOperator]="condition.operator"
      [testIdSuffix]="testIdSuffix + '_' + index" />

    <button type="button" class="btn btn-2022--secondary btn-sm" (click)="addSubCondition()" [disabled]="disabled">+ Condition</button>
  </div>
</div>
