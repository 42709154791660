<p>
  In the absence of a value in your product spreadsheet Ignite will use these default values instead.
  Default values will be applied in the order shown.
  If an earlier rule sets a value on a product it will NOT be overwritten by a later rule.
  All text comparisons are done case-insensitively.
</p>

<div class="text-center" *ngIf="!schema">
  <div class="spinner-border m-5" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<ng-container *ngIf="schema">
  <!-- Rule -->
  <div
    *ngFor="let rule of rules; let index = index; let first = first; let last = last"
    class="product-default-rule-row bg-light border p-2"
    [class.border-top-0]="!first">

    <span class="index">{{index + 1}}</span>

    <lib-rules-engine-condition-editor
      class="condition"
      [schema]="schema"
      [showErrors]="showErrors"
      [allowAlwaysCondition]="true"
      alwaysConditionLabel="-Always (all products)-"
      [(condition)]="rule.condition"
      (conditionChange)="setUnsavedChanges()"
      [testIdSuffix]="'' + index" />

    <span class="then"><strong>Then</strong></span>
    <lib-rules-engine-action-editor
      class="action"
      [schema]="schema"
      [showErrors]="showErrors"
      [(action)]="rule.action"
      (actionChange)="setUnsavedChanges()"
      [testIdSuffix]="'' + index" />

    <div class="controls">
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-sm btn-2022--light-1"
          [ngbTooltip]="(rule.comment === null ? 'Add' : 'Delete') + ' comment'"
          (click)="toggleComment(index)"
          [attr.data-testid]="'default_rules_comment_btn_' + index"
        >
          <i class="fa" [class]="rule.comment === null ? 'fa-comment' : 'fa-comment-slash'" style="width:19px"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-2022--light-1"
          [disabled]="first"
          (click)="moveRule(index, -1)"
          [attr.data-testid]="'default_rules_move_up_btn_' + index"
        >
          <i class="fa fa-arrow-up"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-2022--light-1"
          [disabled]="last"
          (click)="moveRule(index, 1)"
          [attr.data-testid]="'default_rules_move_down_btn_' + index"
        >
          <i class="fa fa-arrow-down"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-2022--primary-lightest text-2022--danger"
          (click)="deleteRule(index)"
          [attr.data-testid]="'default_rules_delete_btn_' + index"
        >
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>

    <div class="comment mt-2" *ngIf="rule.comment !== null">
      <input
        type="text"
        class="form-control form-control-sm"
        [(ngModel)]="rule.comment"
        (ngModelChange)="setUnsavedChanges()"
        placeholder="Comment"
        maxlength="255"
        [data-testid]="'default_rules_comment_input_' + index"
      >
    </div>
  </div>

  <!-- No rules message -->
  <div class="alert alert-info my-3" role="alert" *ngIf="!rules.length">
    <i class="fa fa-info-circle"></i>
    There are no default rules configured for this lender.
  </div>

  <!-- Create/save buttons -->
  <div class="text-end mt-3">
    <button type="button" class="btn btn-2022--secondary me-3" (click)="createNewRule()" data-testid="default_rules_create_btn">
      <i class="fa fa-plus me-1"></i>
      Create rule
    </button>

    <button type="button" class="btn btn-2022--secondary" (click)="saveChanges()" [disabled]="!unsavedChanges" data-testid="default_rules_save_btn">
      <i class="fa fa-save me-1"></i>
      Save Changes
    </button>
  </div>
</ng-container>
