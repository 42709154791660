<lib-page-nav
  [title]="bannerTitle"
></lib-page-nav>

<div class="container my-5">
  @if (isReadOnly) {
    <div class="alert alert-danger">
      Changes are currently being made to your affordability calculations and it is therefore not possible to make any
      further changes at this time. Please contact us to discuss.
    </div>
  }

  @if (hasScheduledChanges) {
    <div class="alert alert-primary mb-3">
      <h4 class="alert-heading mb-2">Scheduled Changes</h4>
      <p>
        A payrate update is scheduled to go live on {{ activeScheduleDate | date : 'dd/MM/yyyy' }} at {{ activeScheduleDate | date : 'HH:mm' }}.
      </p>
      <div class="text-end">
        <button class="btn btn-outline-secondary me-2" (click)="showUpdateScheduleModal()">Reschedule</button>
        <button class="btn btn-outline-danger" (click)="discardSchedule()">Delete</button>
      </div>
    </div>
  }
  @if (tables || taxTables) {
    <div class="p-3 bg-light">
      <div ngbAccordion #acc="ngbAccordion" [closeOthers]="true" [(activeIds)]="activeAccordions">
        @for (text of combinedTitlesAndDescriptions; track text) {
          <div ngbAccordionItem>
            <div ngbAccordionHeader>
              <div class="accordion-button custom-header" [class.collapsed]="!opened">
                <!-- Styling for title and description on 2 seperate lines -->
                <div class="title">
                  <h3 class="m-0">{{text.title}}</h3>
                  <button ngbAccordionToggle class="btn btn-2022--secondary" style="right: auto;">{{ opened ? 'Collapse' : 'Expand' }}</button>
                </div>
                <div class="description">
                  <p class="m-0">{{text.description}}</p>
                </div>
              </div>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <!-- Main accordion content -->
                <ng-template>
                  <!-- Match only regular table titles -->
                  @for (table of tables; track table; let tableIndex = $index) {
                    @if (table.title === text.title) {
                      <form [formGroup]="tableFormArrays[tableIndex]">
                        @if (showTables(table.isMatchingWithIgnite)) {
                          <table class="table table-bordered">
                            <thead>
                              <!-- If there are scheduled changes, show an additional column for each editable column; one for currently live and one for scheduled. -->
                              <tr>
                                @for (column of table.columns; track column) {
                                  <th
                                    [attr.colspan]="hasScheduledChanges && column.editable ? 2 : 1"
                                    [attr.rowspan]="hasScheduledChanges && !column.editable ? 2 : 1"
                                    >
                                    {{ column.header }}
                                  </th>
                                }
                              </tr>
                              @if (hasScheduledChanges) {
                                <tr>
                                  @for (column of table.columns; track column) {
                                    @if (column.editable) {
                                      <th>Live</th>
                                      <th>Scheduled</th>
                                    }
                                  }
                                </tr>
                              }
                            </thead>
                            <tbody class="bg-white">
                              @for (row of table.data; track row; let rowIndex = $index) {
                                <tr [formGroupName]="rowIndex">
                                  @for (column of table.columns; track column; let colIndex = $index) {
                                    <!-- Column with current value. Show in red if there are scheduled changes that alter this value.
                                    Explicitly using != instead of !== because Asanto sometimes returns strings instead of numbers for example. -->
                                    <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                                    <td class="align-middle" [class.table-danger]="hasScheduledChanges && row[colIndex] != table.scheduledData[rowIndex][colIndex]">
                                      @switch (true) {
                                        @case (column.editable && column.type === columnType.Boolean && !hasScheduledChanges) {
                                          <input
                                            type="checkbox"
                                            [formControlName]="colIndex"
                                            />
                                          }
                                          @case (column.editable && column.type === columnType.Number && !hasScheduledChanges) {
                                            <input
                                              type="number"
                                              class="form-control"
                                              [class.is-valid]="isValid(tableIndex, rowIndex, colIndex)"
                                              [class.is-invalid]="isInvalid(tableIndex, rowIndex, colIndex)"
                                              [formControlName]="colIndex"
                                              (keydown)="preventUnwanted($event)"
                                              />
                                            }
                                            @case (hasScheduledChanges || !column.editable) {
                                              {{ formatAsantoValue(row[colIndex], column.type) }}
                                            }
                                          }
                                          @if (controlErrors(tableIndex, rowIndex, colIndex)?.required) {
                                            <div class="invalid-feedback">
                                              Please enter a valid {{ column.header }}
                                            </div>
                                          }
                                        </td>
                                        <!-- If column is editable and changes are scheduled, show a diff column with scheduled data.
                                        Show in green if there are scheduled changes that alter this value.
                                        Explicitly using != instead of !== because Asanto sometimes returns strings instead of numbers for example. -->
                                        @if (column.editable && hasScheduledChanges) {
                                          <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                                          <td [class.table-success]="row[colIndex] != table.scheduledData[rowIndex][colIndex]">
                                            {{ formatAsantoValue(table.scheduledData[rowIndex][colIndex]) }}
                                          </td>
                                        }
                                      }
                                    </tr>
                                  }
                                </tbody>
                              </table>
                              @if (table.isLookup) {
                                <p class="p-1">Lookup columns containing a * character will match any value</p>
                              }
                            } @else {
                              You are unable to edit at this time, please contact us to discuss
                            }
                          </form>
                        }
                      }
                      <form [formGroup]="taxTablesForm">
                        @for (taxTable of taxTables; track taxTable; let taxTableIndex = $index) {
                          <!-- Match only tax table type -->
                          @if (taxTable.title === text.title) {
                            @if (showTaxTables(taxTable)) {
                              <ng-container formArrayName="tables">
                                <div class="form-group">
                                  @for (table of taxTable.tables; track table; let tableIndex = $index) {
                                    <div>
                                      <label class="d-flex">
                                        <input type="radio" value={{table.fiscalYear}} name="{{taxTableIndex}}" formControlName="{{taxTableIndex}}">
                                        <span class="p-1">{{getFiscalYearText(table.fiscalYear)}}</span>
                                      </label>
                                      <table class="table table-bordered">
                                        <thead>
                                          <!-- If there are scheduled changes, show an additional column for each editable column; one for currently live and one for scheduled. -->
                                          <tr>
                                            @for (column of table.columns; track column) {
                                              <th
                                                [attr.colspan]="hasScheduledChanges && column.editable ? 2 : 1"
                                                [attr.rowspan]="hasScheduledChanges && !column.editable ? 2 : 1"
                                                >
                                                {{ column.header }}
                                              </th>
                                            }
                                          </tr>
                                          @if (hasScheduledChanges) {
                                            <tr>
                                              @for (column of table.columns; track column) {
                                                @if (column.editable) {
                                                  <th>Live</th>
                                                  <th>Scheduled</th>
                                                }
                                              }
                                            </tr>
                                          }
                                        </thead>
                                        <tbody class="bg-white">
                                          @for (row of table.data; track row; let rowIndex = $index) {
                                            <tr>
                                              @for (column of table.columns; track column; let colIndex = $index) {
                                                <!-- Column with current value. Show in red if there are scheduled changes that alter this value.
                                                Explicitly using != instead of !== because Asanto sometimes returns strings instead of numbers for example. -->
                                                @if (hasScheduledChanges) {
                                                  <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                                                  <td [class.table-success]="row[colIndex] != table.scheduledData[rowIndex][colIndex]">
                                                    {{ formatAsantoValue(table.scheduledData[rowIndex][colIndex]) }}
                                                  </td>
                                                }
                                                <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                                                <td class="align-middle" [class.table-danger]="hasScheduledChanges && row[colIndex] != table.scheduledData[rowIndex][colIndex]">
                                                  {{ formatAsantoValue(row[colIndex], column.type) }}
                                                </td>
                                              }
                                            </tr>
                                          }
                                        </tbody>
                                      </table>
                                      @if (table.isLookup) {
                                        <p class="p-1">Lookup columns containing a * character will match any value</p>
                                      }
                                    </div>
                                  }
                                </div>
                              </ng-container>
                            } @else {
                              You are unable to edit at this time, please contact us to discuss
                            }
                          }
                        }
                      </form>
                    </ng-template>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
      @if (!hasScheduledChanges) {
        <div class="text-end">
          <!-- Close all active accordions when cancel clicked -->
          <button type="button" class="btn btn-outline-secondary me-2" (click)="cancelChanges()" [disabled]="!anyTablesTouched || isReadOnly" >Cancel</button>
          <button class="btn btn-2022--secondary" (click)="saveChanges()" [disabled]="!anyTablesTouched || isReadOnly">Continue</button>
        </div>
      }
    </div>

    <ng-template #updateConfirmationDialog>
      <!-- Preview -->
      <!-- Warning if lender has hosted version -->
      @if (lenderIsHosted) {
        <div class = "alert alert-warning">
          <p class="p-1">* Changes made for Legal & General Ignite will also reflect in your Hosted Calculator version</p>
        </div>
      }
      @for (table of tables; track table; let tableIndex = $index) {
        <h1>{{ table.title }}</h1>
        <table class="table table-bordered">
          <thead>
            <tr>
              @for (column of table.columns; track column) {
                <th>{{ column.header }}</th>
              }
            </tr>
          </thead>
          @if (tableFormArrays[tableIndex].value; as updatedValues) {
            <tbody>
              @for (row of table.data; track row; let rowIndex = $index) {
                <tr>
                  @for (column of table.columns; track column; let colIndex = $index) {
                    <td>
                      {{ formatAsantoValue(updatedValues[rowIndex][colIndex], column.type) }}
                    </td>
                  }
                </tr>
              }
            </tbody>
          }
        </table>
      }

      @for (taxTable of taxTables; track taxTable; let taxTableIndex = $index) {
        <h1>{{ taxTable.description }}</h1>
        <div class="form-group">
          @for (table of taxTable.tables; track table; let tableIndex = $index) {
            <div>
              @if (taxTableFormArrays.controls[taxTableIndex].value === table.fiscalYear) {
                <span>{{getFiscalYearText(table.fiscalYear)}}</span>
                <table class="table table-bordered">
                  <thead>
                    <!-- If there are scheduled changes, show an additional column for each editable column; one for currently live and one for scheduled. -->
                    <tr>
                      @for (column of table.columns; track column) {
                        <th
                          [attr.colspan]="hasScheduledChanges && column.editable ? 2 : 1"
                          [attr.rowspan]="hasScheduledChanges && !column.editable ? 2 : 1"
                          >
                          {{ column.header }}
                        </th>
                      }
                    </tr>
                    @if (hasScheduledChanges) {
                      <tr>
                        @for (column of table.columns; track column) {
                          @if (column.editable) {
                            <th>Live</th>
                            <th>Scheduled</th>
                          }
                        }
                      </tr>
                    }
                  </thead>
                  <tbody class="bg-white">
                    @for (row of table.data; track row; let rowIndex = $index) {
                      <tr>
                        @for (column of table.columns; track column; let colIndex = $index) {
                          <!-- Column with current value. Show in red if there are scheduled changes that alter this value.
                          Explicitly using != instead of !== because Asanto sometimes returns strings instead of numbers for example. -->
                          <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                          <td class="align-middle" [class.table-danger]="hasScheduledChanges && row[colIndex] != table.scheduledData[rowIndex][colIndex]">
                            {{ formatAsantoValue(row[colIndex], column.type) }}
                          </td>
                        }
                      </tr>
                    }
                  </tbody>
                </table>
              }
            </div>
          }
        </div>
      }

      <!-- Schedule selection and action buttons -->
      <form [formGroup]="scheduleForm">
        <lib-event-schedule
          [scheduleForm]="scheduleForm"
          [alignItems]="'end'"
        ></lib-event-schedule>
        <div class="row mt-3">
          <div class="col">
            <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Go Back</button>
          </div>
          <div class="col text-end">
            <button type="submit" class="btn btn-2022--secondary" (click)="confirmSaveChanges()">Save Changes</button>
          </div>
        </div>
      </form>
    </ng-template>

    <ng-template #updateSuccessDialog>
      <div class="alert alert-success">
        @if (updatedScheduleDate) {
          <p>
            Your changes have been successfully submitted for update and will be live on
            {{ updatedScheduleDate | date : 'dd/MM/yyyy HH:mm' }}.
          </p>
        }
        @if (!updatedScheduleDate) {
          <p>
            Your changes have been successfully updated and will be live in the next few minutes for audit or to view on the
            "Manage Affordability" page
          </p>
        }
        <div class="text-end">
          <a class="btn btn-2022--secondary" [routerLink]="['/']" (click)="closeModal()">Continue</a>
        </div>
      </div>
    </ng-template>

    <ng-template #rescheduleDialog>
      <form>
        <lib-event-schedule
          [scheduleForm]="scheduleForm"
          [showSetLiveNowOption]="false"
        ></lib-event-schedule>
      </form>
      <div class="row mt-3">
        <div class="col">
          <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
        </div>
        <div class="col text-end">
          <button type="submit" class="btn btn-2022--secondary" (click)="confirmUpdateSchedule()">Update Schedule</button>
        </div>
      </div>
    </ng-template>

    <!-- Error message when no tables are displayed -->
