<form [formGroup]="form">
  <div class="container">
    <div class="row mb-2 mt-3">
      <div class="col-4"></div>
      <div class="col d-flex justify-content-between flex-column text-center border-bottom">
        <span class="fw-bold">Don't include in true cost</span>
        <button class="btn btn-2022--secondary p-1 fw-bold mb-1" (click)="selectAllFees(eFeeCalculationScenarios.Exclude)">
          Select All
        </button>
      </div>
      <div class="col d-flex justify-content-between flex-column text-center border-bottom">
        <span class="fw-bold">Include in true cost (don't add to loan)</span>
        <button class="btn btn-2022--secondary p-1 fw-bold mb-1" (click)="selectAllFees(eFeeCalculationScenarios.OneOffCost)">
          Select All
        </button>
      </div>
      <div class="col d-flex justify-content-between flex-column text-center border-bottom">
        <span class="fw-bold">Include in true cost (add to loan)*</span>
        <button class="btn btn-2022--secondary p-1 fw-bold mb-1" (click)="selectAllFees(eFeeCalculationScenarios.AddToLoan)">
          Select All
        </button>
      </div>
    </div>
    <ul class="border lenders-list list-group">
    <div formArrayName="customFeeCalculationScenarios">
      <li class="list-group-item py-1 border-top-0 border-left-0 border-right-0" *ngFor="let fee of allFees; let i = index" [formGroupName]="i">
        <div class="row">
          <div class="col-4 ps-1">
            <span>{{fee.displayName}}</span>
          </div>
          <div class="col d-flex justify-content-center" [class.row-disabled]="isFeeDisabled(fee.type)">
            <input
              type="radio"
              id="Exclude"
              formControlName="feeCalculationScenario"
              [value]="eFeeCalculationScenarios.Exclude"
            >
          </div>
          <div class="col d-flex justify-content-center">
            <input
              type="radio"
              id="OneOffCost"
              formControlName="feeCalculationScenario"
              [value]="eFeeCalculationScenarios.OneOffCost"
            >
          </div>
          <div class="col d-flex justify-content-center">
            <input
              type="radio"
              id="AddToLoan"
              formControlName="feeCalculationScenario"
              [value]="eFeeCalculationScenarios.AddToLoan"
            >
          </div>
        </div>
      </li>
    </div>
  </ul>
  </div>
  <label class="d-flex pointer my-3">
    <input
      type="checkbox"
      class="my-auto me-1"
      formControlName="deductCashback"
    >
    <span>Deduct Cashback</span>
  </label>
  <ng-container *ngIf="!!assumedLegalFee" >
  <label class="d-flex pointer my-3">
    <input
      type="checkbox"
      class="my-auto me-1"
      checked
      disabled
    >
    <span>Assumed legal fee cost</span>
  </label>
  <div class="d-flex mb-2">
    <label for="assumedLegalFeeCost" class="align-self-center">Amount</label>
      <div class="input-group">
        <span class="input-group-text ms-3">£</span>
        <input
          type="number"
          id="assumedLegalFeeCost"
          class="form-control w100 me-1 input-with-border-right"
          disabled
          [value]="assumedLegalFee"
        >
      </div>
    </div>
    <span>Assumed legal fee cost. Used when the process doesn`t have free legal for Remortgage products.
      The estimate legal cost is added to the true cost calculations
    </span>
  </ng-container>
  <p class="mt-2"><strong>Tip: You can add or edit your default broker fee in My Account</strong></p>
  <p>*Please note, where fees are added to the loan they will be calculated over the full term, not the initial period</p>
  <div class="d-flex col-3 ms-auto">
    <button
      (click)="update()"
      type="button"
      class="btn btn-2022--secondary btn-action w-100 h-100"
      [disabled]="form.invalid"
    >
      Update
    </button>
  </div>
</form>
