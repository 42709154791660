<ng-container *ngIf="loadingOptions; let l">
  <div class="normal-overlay" *ngIf="!l.loadingImage">
    <div id="overlay" *ngIf="!l.loadingImage">
      <div class="spinner"></div>
      <br />
      Loading...
    </div>
  </div>

  <div class="loading-overlay" *ngIf="l.loadingImage" role="alert" aria-busy="true">
    <div id="overlay">
      <div class="container h-100 w-50">
        <div class="row h-100">
          <div class="col-sm-12 my-auto loading-container">
            <img src="{{ l.loadingImage }}" class="w-100 mx-auto mt-4 ps-4 pe-4" />
            <br />
            <div class="progress-line mt-4 ms-4 me-4 w-auto"></div>
            <p class="mt-3 mb-4">Loading...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
