import { Component, Input } from '@angular/core';

import { ModalService } from '../../services';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-tooltip',
  styleUrls: ['tooltip.component.scss'],
  templateUrl: 'tooltip.component.html',
  standalone: true,
  imports: [NgIf, NgbTooltip],
})
export class TooltipComponent {
  @Input() public title: string;
  @Input() public tooltip: string;
  @Input() public smallIcon = false;
  @Input() public showAsTooltip = false;
  @Input() public whiteIcon = false;

  public constructor(public modalService: ModalService) {}

  public get id() {
    return this.title?.toLowerCase().replace(/ /g, '-') ?? 'notification-tooltip';
  }

  public openTooltip() {
    this.modalService
      .open({
        title: this.title,
        message: this.tooltip,
      }).then(() => null, () => null);
  }

  public close() {
    this.modalService.close();
  }
}
