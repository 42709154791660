<div class="valuation-fee-scales-card">
  <div class="valuation-fee-scales-card-header">
    <span class="title">Valuation Scales and Fees</span>
    <span class="btn btn-2022--primary btn-sm status">{{ getStatusText() }}</span>
  </div>
  <div class="valuation-fee-scales-card-body">
    <p>View valuation scales and fees for the below fee conditions</p>
    <p><strong>Fee scale condition</strong></p>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" checked>
      <label class="form-check-label">{{ feeScaleConditionText[valuationFeeScale.condition] }}</label>
    </div>
  </div>
  <div class="valuation-fee-scales-card-footer">
    <button class="btn btn-2022--secondary btn-sm" *ngIf="valuationFeeScale.isScheduled" (click)="onDelete()">Delete
    </button>
    <button class="btn btn-2022--secondary btn-sm" *ngIf="valuationFeeScale.isScheduled" (click)="onReschedule()">
      Reschedule
    </button>
    <button class="btn btn-2022--secondary btn-sm" (click)="onView()">View</button>
  </div>
</div>
