import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PartialProductDefaultRule, ProductDefaultRule } from '@msslib/models';
import {
  ActionType,
  Condition,
  ConditionType,
  PropertyCondition,
  RulesEngineSchema,
  RulesEngineSchemaResponseModel,
  SetValueAction,
  alwaysConditionPropertyName,
  validateCondition,
  validateSetValueAction,
} from '@msslib/models/rules-engine';
import { LenderHubDataService, ToastService } from '@msslib/services';
import { FormsModule } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RulesEngineActionEditorComponent } from '../rules-engine/action-editor/action-editor.component';
import { RulesEngineConditionEditorComponent } from '../rules-engine/condition-editor/condition-editor.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'lib-product-default-rules-editor',
  templateUrl: 'product-default-rules-editor.component.html',
  styleUrls: ['product-default-rules-editor.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    RulesEngineConditionEditorComponent,
    RulesEngineActionEditorComponent,
    NgbTooltip,
    FormsModule,
  ],
})
export class ProductDefaultRulesEditorComponent implements OnInit {

  @Input() public lenderNameId: number;

  public schema: RulesEngineSchema;

  public rules: PartialProductDefaultRule[] = [];

  public showErrors = false;

  public unsavedChanges = false;
  @Output() public unsavedChangesChange = new EventEmitter<boolean>();

  public constructor(
    private lenderHubDataService: LenderHubDataService,
    private toastService: ToastService,
  ) {}

  public ngOnInit(): void {
    this.fetchDefaultRules();
  }

  private fetchDefaultRules() {
    this.lenderHubDataService.get<{ rules: ProductDefaultRule[]; schema:  RulesEngineSchemaResponseModel }>(
      `Product/DefaultRules/${this.lenderNameId}`)
      .subscribe(({ rules, schema }) => {
        this.schema = new RulesEngineSchema(schema);
        this.rules = rules.map(rule => ({
          condition: rule.condition
            ? rule.condition
            : { type: ConditionType.Property, propertyName: alwaysConditionPropertyName },
          action: rule.action,
          comment: rule.comment,
        }));
      });
  }

  public createNewRule() {
    this.rules = [
      ...this.rules,
      {
        condition: { type: ConditionType.Property, propertyName: alwaysConditionPropertyName },
        action: { type: ActionType.SetValue },
        comment: null,
      } satisfies PartialProductDefaultRule,
    ];
    this.setUnsavedChanges();
  }

  public moveRule(index: number, dir: 1 | -1) {
    const [ruleToMove] = this.rules.splice(index, 1);
    this.rules.splice(index + dir, 0, ruleToMove);
    this.setUnsavedChanges();
  }

  public deleteRule(index: number) {
    this.rules = this.rules.filter((_, i) => i !== index);
    this.setUnsavedChanges();
  }

  public toggleComment(index: number) {
    this.rules[index].comment = this.rules[index].comment === null ? '' : null;
    this.setUnsavedChanges();
  }

  public setUnsavedChanges(unsavedChanges: boolean = true) {
    this.unsavedChanges = unsavedChanges;
    this.unsavedChangesChange.emit(unsavedChanges);
  }

  public saveChanges() {
    this.showErrors = true;
    const valid = !this.rules.find(r =>
      validateCondition(r.condition as Condition, this.schema, true) ||
      validateSetValueAction(r.action as SetValueAction, this.schema));

    if (!valid) {
      return;
    }

    // Remove any root '$$Always' rules and replace them with null as this is how the API expects them.
    const updateModel = this.rules.map(r => ({
      condition: r.condition.type === ConditionType.Property && r.condition.propertyName === alwaysConditionPropertyName
        ? null
        : r.condition as PropertyCondition,
      action: r.action as SetValueAction,
      comment: r.comment,
    }));

    // Push updates
    this.lenderHubDataService.put<never>(`Product/DefaultRules/${this.lenderNameId}`, updateModel)
      .subscribe(() => {
        this.toastService.success('Successfully updated default rules');
        this.setUnsavedChanges(false);
      });
  }
}
