import { Pipe, PipeTransform } from '@angular/core';
import startCase from 'lodash-es/startCase';

/**
 * Pipe that transform the given string using `lodash.startCase`.
 * If given string arguments, any instance of these stop words are converted to lower case.
 */
@Pipe({
  name: 'startcase',
  standalone: true,
})
export class StartCasePipe implements PipeTransform {
  public transform(value: string, ...lowerWords: string[]) {
    let str = startCase(value);
    if (!lowerWords?.length) {
      return str;
    }
    for (const word of lowerWords) {
      str = str.replace(new RegExp(word, 'ig'), word.toLowerCase());
    }
    return str;
  }
}
