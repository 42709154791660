import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { NgIf } from '@angular/common';
import { EsisHtmlEditorComponent } from '../esis-html-editor/esis-html-editor.component';

@Component({
  selector: 'app-interest-rates-and-other-costs',
  templateUrl: 'interest-rates-and-other-costs.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    EsisHtmlEditorComponent,
  ],
})

export class InterestRatesAndOtherCostsComponent {
  @Input() public id: string;
  @Input() public interestRatesAndOtherCosts: UntypedFormGroup;
  @Input() public isIllustration: boolean;

  public valid(name:string): boolean {
    return this.interestRatesAndOtherCosts.controls[name].valid &&
      this.interestRatesAndOtherCosts.controls[name].touched;
  }

  public invalid(name:string): boolean {
    return this.interestRatesAndOtherCosts.controls[name].invalid &&
      this.interestRatesAndOtherCosts.controls[name].touched;
  }

  public getControl(name: string) {
    return this.interestRatesAndOtherCosts.get(name);
  }
}
