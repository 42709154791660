import { Component, Input } from '@angular/core';

import { IListItem } from '../../models';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'lib-list',
  styleUrls: ['list.component.scss'],
  templateUrl: 'list.component.html',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgTemplateOutlet,
  ],
})
export class ListComponent {
  @Input() public list: IListItem[];

  public trackByFn(index: number) {
    return index;
  }
}
