import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors }
  from '@angular/forms';
import { NgbInputDatepicker, NgbTimeStruct, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, NgIf } from '@angular/common';

export type DateTimeFormType = FormGroup<{
  date: FormControl<Date | null>;
  time: FormControl<NgbTimeStruct | null>;
}>;

@Component({
  selector: 'lib-date-time',
  templateUrl: 'date-time.component.html',
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    NgbInputDatepicker,
    NgbTimepicker,
    NgIf,
  ],
})
export class DateTimeComponent {
  @Input() public alignItems = 'center';
  @Input() public dateTimeForm: DateTimeFormType;


  public isValid(controlName: string): boolean {
    const { touched, valid } = this.dateTimeForm.controls[controlName];
    return touched && valid;
  }

  public isInvalid(controlName: string): boolean {
    const { touched, invalid } = this.dateTimeForm.controls[controlName];
    return touched && invalid;
  }

  public controlErrors(controlName: string): ValidationErrors | null {
    return this.dateTimeForm.controls[controlName].errors;
  }

  public static buildForm(): DateTimeFormType {
    const formBuilder = new FormBuilder();

    return formBuilder.group({
      date: formBuilder.control<Date | null>(null),
      time: formBuilder.control<NgbTimeStruct | null>(null),
    });
  }

  public static getDate(form: DateTimeFormType): Date | null {
    const { date, time } = form.value;
    return date && time
      ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.hour, time.minute)
      : null;
  }

  public static resetForm(form: DateTimeFormType, date: Date | null): void {
    form.reset({
      date: date ? new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        12, // Give it a non-zero hour, else it sometimes shows previous day (I think due to UTC/British Summer Time)
      ) : null,
      time: date ? {
        hour: date.getHours(),
        minute: date.getMinutes(),
      } as NgbTimeStruct : null,
    });
    form.markAsUntouched({ onlySelf: false });
    form.updateValueAndValidity({ onlySelf: false });
  }
}
