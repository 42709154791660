import { Injectable, Type } from '@angular/core';
import { Field } from '@msslib/models';
import { FormButtonComponent, FormButtonGroupComponent, FormCheckboxComponent, FormCheckboxListComponent,
  FormDateComponent, FormFileComponent, FormFilePathComponent, FormHeadingComponent, FormInputComponent,
  FormInputGroupComponent, FormLinkComponent, FormParagraphComponent, FormRadioListComponent,
  FormSelectComponent, FormTextareaComponent, FormTimeComponent, FormToggleComponent } from '../components';

@Injectable({ providedIn: 'root' })
export class FormListService {
  public getComponents() {
    const components: Record<string, Type<Field>> = {
      button: FormButtonComponent,
      buttongroup: FormButtonGroupComponent,
      input: FormInputComponent,
      inputgroup: FormInputGroupComponent,
      number: FormInputComponent,
      file: FormFileComponent,
      filepath: FormFilePathComponent,
      password: FormInputComponent,
      email: FormInputComponent,
      date: FormDateComponent,
      time: FormTimeComponent,
      textarea: FormTextareaComponent,
      checkbox: FormCheckboxComponent,
      checkboxlist: FormCheckboxListComponent,
      radiolist: FormRadioListComponent,
      select: FormSelectComponent,
      link: FormLinkComponent,
      toggle: FormToggleComponent,
      heading: FormHeadingComponent,
      paragraph: FormParagraphComponent,
    };

    return components;
  }
}
