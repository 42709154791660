import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import {
  ESISInformation,
  EsisPreviewFormModel,
  LendingDocumentInformationFormType,
  LendingDocumentInformationType,
  Product,
} from 'apps/shared/src/models';
import { ClubHubDataService } from '@msslib/services';
import { RulesEngineSchema, RulesEngineSchemaResponseModel } from '@msslib/models/rules-engine';

@Injectable({
  providedIn: 'root',
})
export class ESISService {
  public constructor(
    private lenderHubDataService: LenderHubDataService,
    private clubHubDataService: ClubHubDataService,
  ) {}

  public getLendingFormTypes(): Observable<LendingDocumentInformationFormType[]> {
    return this.lenderHubDataService.get<LendingDocumentInformationFormType[]>('lenderEsisInformation/types');
  }

  public getESISInformation(
    lendingTypeId: number,
    documentType: LendingDocumentInformationType,
    getDraft = false,
  ): Observable<ESISInformation> {
    return this.lenderHubDataService.get<ESISInformation>(
      `lenderEsisInformation/${lendingTypeId}/${documentType}${getDraft ? '/Draft' : ''}`);
  }

  public saveESISInformation(
    lendingTypeId: number,
    documentType: LendingDocumentInformationType,
    esisInformation: unknown,
    setLive = false,
  ) {
    return this.lenderHubDataService.put<ESISInformation>(
      `lenderEsisInformation/${lendingTypeId}/${documentType}?setLive=${setLive}`,
      esisInformation,
    );
  }

  public getPreviewProducts(lendingTypeId: number, documentType: LendingDocumentInformationType) {
    return this.clubHubDataService.get<Product[]>(
      `EsisPreview/Products?lendingTypeId=${lendingTypeId}&documentType=${documentType}`);
  }

  public previewESIS(
    documentType: LendingDocumentInformationType,
    lendingTypeId: number,
    esisInformation: ESISInformation,
    formData: EsisPreviewFormModel,
    productAttributes: Record<string, boolean> = {},
  ) {
    return this.clubHubDataService.postFileArrayBuffer('EsisPreview', {
      documentType,
      lendingTypeId,
      ...formData,
      templateData: esisInformation,
      productAttributes,
    });
  }

  public deleteDraftESIS(documentType: LendingDocumentInformationType, lendingTypeId: number) {
    return this.lenderHubDataService.delete<never>(`LenderEsisInformation/${lendingTypeId}/${documentType}/Draft`);
  }

  public getDisplayRulesSchema(lendingTypeId: number) {
    return this.lenderHubDataService
      .get<RulesEngineSchemaResponseModel>(`LenderEsisInformation/DisplayRulesSchema/${lendingTypeId}`)
      .pipe(map(r => new RulesEngineSchema(r)));
  }
}
