import { Component, Input } from '@angular/core';
import { ClubHubDataService } from '@msslib/services';
import { Observable } from 'rxjs';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgIf } from '@angular/common';

interface IntuitiveCriteriaContext {
  affordabilityIntuitiveContextConditionType: number | null;
  productsIntuitiveContextConditionType: number | null;
  propertyIntuitiveContextConditionType: number | null;
  productSourcingConditionType: number | null;
}

interface IntuitiveConditionTypes {
  affordability: Record<number, string>;
  products: Record<number, string>;
  property: Record<number, string>;
  sourcing: Record<number, string>;
}

@Component({
  selector: 'lib-intuitive-criteria-badge',
  templateUrl: 'intuitive-criteria-badge.component.html',
  styleUrls: ['intuitive-criteria-badge.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgbTooltip,
  ],
})
export class IntuitiveBadgeComponent {
  @Input() public context: IntuitiveCriteriaContext;

  private static intuitiveConditionTypes$: Observable<IntuitiveConditionTypes>;
  public static intuitiveConditionTypes: IntuitiveConditionTypes;

  public constructor(clubHubDataService: ClubHubDataService) {
    if (!IntuitiveBadgeComponent.intuitiveConditionTypes$) {
      IntuitiveBadgeComponent.loadConditionTypes(clubHubDataService);
    }
  }

  public get isIntuitive() {
    return this.context.affordabilityIntuitiveContextConditionType !== null
      || this.context.productsIntuitiveContextConditionType !== null
      || this.context.propertyIntuitiveContextConditionType !== null
      || this.context.productSourcingConditionType !== null;
  }

  public get intuitiveConditions() {
    const conditions: { type: string; condition: string }[] = [];
    const conditionTypes = IntuitiveBadgeComponent.intuitiveConditionTypes;

    if (!conditionTypes) {
      return conditions;
    }

    if (this.context.affordabilityIntuitiveContextConditionType !== null) {
      conditions.push({
        type: 'Affordability',
        condition: conditionTypes.affordability[this.context.affordabilityIntuitiveContextConditionType],
      });
    }

    if (this.context.productsIntuitiveContextConditionType !== null) {
      conditions.push({
        type: 'Products',
        condition: conditionTypes.products[this.context.productsIntuitiveContextConditionType],
      });
    }

    if (this.context.propertyIntuitiveContextConditionType !== null) {
      conditions.push({
        type: 'Property',
        condition: conditionTypes.property[this.context.propertyIntuitiveContextConditionType],
      });
    }

    if (this.context.productSourcingConditionType !== null) {
      conditions.push({
        type: 'Sourcing',
        condition: conditionTypes.sourcing[this.context.productSourcingConditionType],
      });
    }

    return conditions;
  }

  private static loadConditionTypes(clubHubDataService: ClubHubDataService) {
    this.intuitiveConditionTypes$ = clubHubDataService
      .get<IntuitiveConditionTypes>('CriteriaIssues/IntuitiveConditionTypes');
    this.intuitiveConditionTypes$.subscribe(res => this.intuitiveConditionTypes = res);
  }
}
