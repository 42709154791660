import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { NgIf } from '@angular/common';

@Component({
  templateUrl: 'form-card-wrapper.component.html',
  styleUrls: ['form-card-wrapper.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class FormCardWrapperComponent extends FieldWrapper {
}
