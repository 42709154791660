<div *ngIf="!isExtendedNonCompliance" [formGroup]="nonCompliance">
  <div class="mb-4 row">
    <label class="col-12" for="nonComplianceText">Please provide 'Consequences for the borrower' wording:</label>
    <div class="col-12">
      <app-esis-html-editor
        id="nonComplianceText"
        [control]="nonCompliance.get('text')"
        placeholder="Consequences for the borrower wording"
        [isRequired]="true" />
      <div *ngIf="invalid('nonCompliance', 'text') && text('nonCompliance')?.errors?.['required']" class="invalid-feedback">
        Consequences for the borrower wording is required.
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="isExtendedNonCompliance">
  <div [formGroup]="nonComplianceCI">
    <div class="mb-4 row">
      <label class="col-12" for="nonComplianceCIText">Please provide 'Consequences for the borrower' wording (Capital and Interest / Interest Only Part And Part):</label>
      <div class="col-12">
        <app-esis-html-editor
          id="nonComplianceCIText"
          [control]="nonComplianceCI.get('text')"
          placeholder="Consequences for the borrower wording"
          [isRequired]="true" />
        <div *ngIf="invalid('nonComplianceCI', 'text') && text('nonComplianceCI')?.errors?.['required']" class="invalid-feedback">
          Consequences for the borrower wording is required.
        </div>
      </div>
    </div>
  </div>
  <div [formGroup]="nonComplianceIO">
    <div class="mb-4 row">
      <label class="col-12" for="nonComplianceIOText">Please provide 'Consequences for the borrower' wording (Interest only):</label>
      <div class="col-12">
        <app-esis-html-editor
          id="nonComplianceIOText"
          [control]="nonComplianceIO.get('text')"
          placeholder="Consequences for the borrower wording"
          [isRequired]="true" />
        <div *ngIf="invalid('nonComplianceIO', 'text') && text('nonComplianceIO')?.errors?.['required']" class="invalid-feedback">
          Consequences for the borrower wording is required.
        </div>
      </div>
    </div>
  </div>
</ng-container>
