import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgIf } from '@angular/common';
@Component({
  selector: 'lib-toggle-switch',
  styleUrls: ['toggle-switch.component.scss'],
  templateUrl: 'toggle-switch.component.html',
  standalone: true,
  imports: [NgIf],
})
export class ToggleSwitchComponent implements OnInit {
  @Input() public checked: boolean;
  @Input() public label: string;
  @Input() public disabled: boolean;

  /**
   * Function that runs before after the user has interacted with the toggle, but before it changes state.
   * If the function returns a boolean, that value overrides the new state of the toggle. If the function returns
   * undefined or a promise that resolves to undefined, the normal toggle behaviour is followed. Can be used to show
   * confirmation modals, for example.
   */
  @Input() public beforeChange: ((newState: boolean) => undefined | boolean | Promise<boolean | undefined>) | undefined;

  @Output() public changed = new EventEmitter<boolean>();

  public id: number;

  public ngOnInit() {
    this.id = Math.floor(Math.random() * 10000) + 1;
  }

  public async changeHandler(e: Event) {
    const target = e.target as HTMLInputElement;
    let newState = target.checked;

    if (typeof this.beforeChange === 'function') {
      // If there is a beforeChange function, we set the state of the checkbox back to what it was before so that it
      // appears like it didn't change. e.preventDefault and e.stopPropagation did not seem to work here.
      target.checked = this.checked;

      const interceptResult = await this.beforeChange(newState);
      if (typeof interceptResult === 'boolean') {
        // If the intercept returned a boolean, force the new value to be that boolean
        target.checked = newState = interceptResult;
      } else {
        // If the intercept returned undefined, then carry on as if the checkbox was clicked
        target.checked = newState;
      }
    }

    if (this.checked !== newState) {
      this.checked = newState;
      this.changed.emit(newState);
    }
  }
}
