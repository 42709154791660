import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { createPopper } from '@popperjs/core';
import { KeyCodeNames } from '@msslib/constants/key-codes';
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'lib-multiselect-filter',
  templateUrl: 'multiselect-filter.component.html',
  styleUrls: ['multiselect-filter.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
  ],
})
export class MultiselectFilterComponent implements OnInit, AfterViewInit {
  @ViewChild('dropdown') public dropdownElementRef: ElementRef<HTMLInputElement>;
  @ViewChild('inputWrapper') public inputWrapperElementRef: ElementRef<HTMLInputElement>;
  @ViewChild('selectedItems') public selectedItemsElementRef: ElementRef<HTMLInputElement>;
  @ViewChild('popper') public popperElementRef: ElementRef<HTMLInputElement>;

  @Input() public itemsLabel: string;
  @Input() public largeHeight: boolean;
  @Input() public wider: boolean;
  @Input() public disabled: boolean;
  @Input() public data: string[];
  @Input() public set checkedValues(value: string[]) {
    this.selectedValues = !!value ? [...value] : [];
    this.updateItemsVisibility();
  }
  @Output() public valuesSelected = new EventEmitter<string[]>();
  @Output() public valueChanged = new EventEmitter<{ value: string; selected: boolean }>();

  public selectedValues: string[] = [];
  public searchTerm: string;
  public itemsHidden: boolean;
  public dropdownId: string;
  public isShown: boolean;
  public dropdown: any;

  public constructor(private cd: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.dropdownId = `multiselect-filter-${Math.random()}`;
    this.resetSearch();
  }

  public ngAfterViewInit(): void {
    const reference = this.dropdownElementRef.nativeElement;
    const popper = this.popperElementRef.nativeElement;
    const instance = createPopper(reference, popper) as any;
    instance.strategy = 'fixed';
    instance.placement = 'bottom-start';
    instance.modifiers = [
      { name: 'flip', enabled: false },
    ];

    this.dropdown = new (window as any).bootstrap.Dropdown(
      this.dropdownElementRef.nativeElement,
      {
        popperConfig: instance,
      },
    );

    // Rendering of that component requires more time in case when the component is used inside of modal window
    setTimeout(() => {
      this.updateItemsVisibility();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    if ((event.code ?? '') as KeyCodeNames === KeyCodeNames.Enter) {
      this.dropdown.toggle();
      event.stopPropagation();
    }
  }

  public onDropdownShow() {
    this.isShown = true;
  }

  public onDropdownHide() {
    this.isShown = false;
    this.resetSearch();
    this.selectedItemsUpdated();
  }

  public isChecked(value: string): boolean {
    return this.selectedValues.some(v => v === value);
  }

  public get filteredOptions() {
    return !!this.searchTerm
      ? this.data.filter(v => v.toLowerCase().includes(this.searchTerm.toLowerCase()))
      : this.data;
  }

  public changeValue(item: string, isChecked: boolean): void {
    if (isChecked) {
      this.addSelected(item);
    } else {
      this.removeSelected(item);
    }
  }

  public removeItem(item: string): void {
    this.removeSelected(item);
    this.selectedItemsUpdated();
  }

  public trackByFn(index: number) {
    return index;
  }

  private removeSelected(item: string): void {
    this.selectedValues = this.selectedValues.filter(v => v !== item);
    this.updateItemsVisibility();
    this.valueUpdated(item, false);
  }

  private addSelected(item: string): void {
    this.selectedValues.push(item);
    this.updateItemsVisibility();
    this.valueUpdated(item, true);
  }

  private updateItemsVisibility(): void {
    if (!this.selectedValues?.length) {
      this.itemsHidden = false;
      return;
    }

    this.cd.detectChanges();
    const element = this.selectedItemsElementRef?.nativeElement;
    const input = this.inputWrapperElementRef?.nativeElement;
    if (element && input) {
      this.itemsHidden = element.clientWidth > input.clientWidth;
    }
  }

  private resetSearch(): void {
    this.searchTerm = '';
  }

  private selectedItemsUpdated(): void {
    this.valuesSelected.emit(this.selectedValues);
  }

  private valueUpdated(value: string, selected: boolean): void {
    this.valueChanged.emit({ value, selected });
  }
}
