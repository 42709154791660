<div [formGroup]="incentivesAdditionalFeatures">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col" class="col-3">Feature name</th>
        <th scope="col">Feature wording</th>
        <th scope="col" class="col-1 action-column">Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="incentivesAdditionalFeatures['controls'].length; else noItems">
        <ng-container *ngFor="let cost of incentivesAdditionalFeatures['controls']; index as i">
          <tr [formGroupName]="i">
            <td>
              <input
              type="text"
              class="form-control"
              placeholder="Feature name"
              formControlName="type"
              [ngClass]="{ 'is-valid': valid('type',i), 'is-invalid': invalid('type',i) }"
              required
              >
              <div *ngIf="invalid('type',i) && getControl('type', i).errors['required']" class="invalid-feedback">
                Feature name is required.
              </div>
              <div *ngIf="invalid('type',i) && getControl('type', i).errors['pattern']" class="invalid-feedback">
                Feature name is invalid.
              </div>

              <button
                type="button"
                *ngIf="hasDisplayCondition(i) || !disabled"
                class="btn btn-2022--secondary w-100 mt-2"
                (click)="editDisplayCondition(i)"
              >
                Display Rules
              </button>

              <p
                *ngIf="!hasDisplayCondition(i)"
                class="bg-2022--secondary-light mt-2 py-1 px-2"
              >
                No display rules set. This wording will always be shown in the ESIS.
              </p>
            </td>
            <td>
              <app-esis-html-editor
                [control]="incentivesAdditionalFeatures.get([i, 'text'])"
                placeholder="Feature wording"
                [isRequired]="true" />
              <div *ngIf="invalid('text',i) && getControl('text', i).errors['required']" class="invalid-feedback">
                Feature wording is required.
              </div>
            </td>
            <td>
              <button *ngIf="removingItem !== i" type="button" class="btn btn-danger" (click)="removingItem = i" [disabled]="disabled">Remove</button>
              <ng-container *ngIf="removingItem === i">
                <button type="button" class="btn btn-danger" (click)="removeFee(i)">Yes</button>
                <button type="button" class="ms-2 btn btn-warning" (click)="removingItem = null">No</button>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #noItems>
        <tr>
          <td colspan="100%" class="text-center">
            No Incentives / Additional Features has been added, please click the "Add more" button if you need to add some.
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <button type="button" class="btn btn-2022--secondary float-end mb-3" (click)="addItem()" [disabled]="disabled">
    Add more
  </button>
</div>

<ng-template #displayRulesModalTemplateRef>
  <app-flexible-features-incentives-display-rules-modal #displayRulesModalComponent
    [schema]="displayRulesSchema"
    [condition]="editingDisplayRuleCondition"
    (conditionSaved)="saveDisplayRuleCondition($event)"
    [disabled]="disabled"
  />
</ng-template>
