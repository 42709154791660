import { Component, Input, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbInputDatepicker, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';

import { ModalService, ToastService } from '@msslib/services';
import { format } from 'date-fns';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-reschedule-upload-modal',
  styleUrls: ['reschedule-upload-modal.component.scss'],
  templateUrl: 'reschedule-upload-modal.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgbInputDatepicker,
    FormsModule,
    NgbTimepicker,
  ],
})
export class RescheduleUploadModalComponent implements OnInit {
  @Input() public startDate: Date | undefined  = undefined;
  @Input() public willRescheduleVariableRateSchedule = false;

  public minDate: NgbDate | undefined = undefined;
  public time: Record<string, number | undefined> | undefined = undefined;

  public constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private calendar: NgbCalendar,
  ) {
    this.minDate = this.calendar.getToday();
  }

  public ngOnInit() {
    this.time = {
      hour: this.startDate?.getHours(),
      minute: this.startDate?.getMinutes(),
      second: this.startDate?.getSeconds(),
    };
  }

  public get startDateFormatted() {
    return this.startDate
      ? format(this.startDate, 'dd/MM/yyyy HH:mm')
      : '';
  }

  public submit() {
    const currentDate = new Date();
    const outputDate = new Date(currentDate);

    if (this.time !== undefined) {
      outputDate.setHours(this.time.hour ?? 0);
      outputDate.setMinutes(this.time.minute ?? 0);
      outputDate.setSeconds(this.time.second ?? 0);
    }

    if (this.startDate === undefined || this.time === undefined) {
      this.toastService.danger('Please select a valid date and time.');
    } else if (
      this.startDate.toLocaleDateString() === currentDate.toLocaleDateString() &&
      outputDate.getTime() < currentDate.getTime()
    ) {
      this.toastService.danger('Schedule date can\'t be in the past.');
    } else {
      this.startDate.setHours(this.time.hour ?? 0);
      this.startDate.setMinutes(this.time.minute ?? 0);
      const releaseDate = this.startDate.toUTCString();

      const result = {
        success: true,
        schedule: releaseDate,
      };

      this.modalService.close(result);
    }
  }

  public cancel() {
    const result = {
      success: false,
    };

    this.modalService.close(result);
  }

  public close() {
    this.modalService.dismiss();
  }
}
