import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { ContextOption } from '@msslib/models/contexts';
import { LendingTypeService } from '@msslib/services/lending-type.service';
import { AuthorizeService } from '@msslib/services/authorize.service';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { LenderName, LenderNameLender, LenderProductHandlerType } from '../models';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { ProductService } from '@msslib/services/product.service';
import { EMAIL_VERIFICATION_SERVICE, IEmailVerificationService, ProductCollectionType, ProductUploadType }
  from '@msslib/models';
import { Observable, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { roles } from '@msslib/constants';
import { keys } from 'apps/clubhub/src/app/constants';
import { NewFeatureService } from '@msslib/services/new-feature.service';
import { NewFeatures } from '@msslib/models/new-features';
import { ModalService } from '@msslib/services/modal.service';


@Injectable({
  providedIn: 'root',
})
export class LenderHubService {
  public currentIssue: ContextOption;
  public currentContext: ContextOption;
  public lenderName: LenderName | null;
  public isAdmin = false;
  public lenderLoaded$ = new Subject();

  public constructor(
    private router: Router,
    private lendingTypeService: LendingTypeService,
    private lenderHubDataService: LenderHubDataService,
    authorizeService: AuthorizeService,
    private productService: ProductService,
    private newFeatureService: NewFeatureService,
    private modalService: ModalService,
    @Inject(EMAIL_VERIFICATION_SERVICE) public emailVerificationService: IEmailVerificationService,

  ) {
    authorizeService.isAuthenticated()
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(isAuthenticated => {
        this.isAdmin = authorizeService.hasRole(roles.flowManagement) || authorizeService.hasRole(roles.formManagement);
        if (isAuthenticated && !this.isAdmin) {
          lenderHubDataService.get<LenderName>('LenderName')
            .subscribe(lenderName => {
              this.lenderName = lenderName;
              this.updateProductsStatuses();
              this.lenderLoaded$.next(true);
            });
        }
      });
  }

  public get lender(): LenderNameLender | undefined {
    return this.lenderName?.lenders.find(x => x.lendingTypeId === this.lendingTypeService.lendingType?.id);
  }

  public get productsHandlerType(): LenderProductHandlerType | undefined {
    return this.lenderName?.resiBtlProductHandlerType;
  }

  public getLenderNameById(lenderNameId: number) : Observable<LenderName> {
    sessionStorage.setItem(keys.lenderNameId, lenderNameId.toString());
    return this.lenderHubDataService.get<LenderName>(`LenderName/Admin?lenderNameId=${lenderNameId}`)
      .pipe(tap(lenderName => {
        this.lenderName = lenderName;
        // this.updateProductsStatuses();
      }));
  }

  public navigateCriteriaHome() {
    this.router.navigate(['criteria']);
  }

  public navigateCriteriaV2Home() {
    this.router.navigate([this.isAdmin ?
      `ignite/criteria/landing/${this.lendingTypeService.lendingType?.id}/lenders` :
      'criteria-v2/dashboard']);
  }

  public navigateCriteriaIssues() {
    this.router.navigate([`criteria/${this.lendingTypeService.lendingType?.id}/issues`]);
  }

  public navigateCriteriaContexts(issueId: number, searchTerm: string) {
    this.navigate(`issues/${issueId}/contexts`, {
      queryParams: { searchTerm },
    });
  }

  public navigateCriteriaUpdateContext(issueId: number, contextId: number, searchTerm: string) {
    this.navigate(`issues/${issueId}/contexts/${contextId}`, {
      queryParams: { searchTerm },
    });
  }

  public navigateCriteriaUnverifiedContexts(lendingTypeId: number | undefined) {
    this.router.navigate([`/criteria/${lendingTypeId}/unverified-contexts`]);
  }

  public navigate(url: string, navigationOptions: NavigationExtras | null = null) {
    let defaultNavigationExtras: NavigationExtras = {};

    if (navigationOptions) {
      defaultNavigationExtras = {
        ...defaultNavigationExtras,
        ...navigationOptions,
      };

      if (navigationOptions.queryParams && Object.values(navigationOptions.queryParams).filter(v => v).length === 0) {
        defaultNavigationExtras.queryParams = {};
      }
    }

    // https://github.com/angular/angular/issues/18798#issuecomment-385526236
    const urlTree = this.router.createUrlTree(
      [`/criteria/${this.lendingTypeService.lendingType?.id}/${url}`],
      defaultNavigationExtras,
    );
    this.router.navigateByUrl(urlTree, defaultNavigationExtras);
  }

  public updateProductsStatuses() {
    if (this.lenderName?.hasProducts) {
      combineLatest ([
        this.productService.getScheduled(ProductCollectionType.Bridging, ProductUploadType.Core),
        this.productService.getScheduled(ProductCollectionType.ResiBtl, ProductUploadType.Core),
      ]).subscribe(([bridgingSchedules, resBtlSchedules]) => {
        if (!!this.lenderName) {
          this.lenderName.hasSchedule = !!bridgingSchedules?.future || !!resBtlSchedules?.future;
        }
      });

      combineLatest ([
        this.productService.getHasLiveProducts(ProductCollectionType.Bridging, ProductUploadType.Core),
        this.productService.getHasLiveProducts(ProductCollectionType.ResiBtl, ProductUploadType.Core),
      ]).subscribe((hasLiveProducts) => {
        if (!!this.lenderName) {
          this.lenderName.hasLiveProducts = hasLiveProducts[0] || hasLiveProducts[1];
        }
      });
    }
  }

  public async displayProductsUploadWarning() {
    // Check if lender loaded
    if (!this.lenderName) {
      // Wait for lender loading
      await firstValueFrom(this.lenderLoaded$);
    }

    if (this.productsHandlerType === LenderProductHandlerType.Bespoke) {
      return true;
    }

    const checkFeatureResult = await firstValueFrom(
      this.newFeatureService.checkIfNewFeatureHasBeenSeen(NewFeatures.UserProductsUploadWarning),
    );

    if (checkFeatureResult.seen) {
      return true;
    }

    return this.modalService
      .open({
        okLabel: 'OK',
        title: 'New Product Sheet format',
        message:
          'Our new product sheet launched on 7st August, please make sure you are using '
          + 'our new format which was shared in an email on 31st July. '
          + 'If you have any questions or require any support, please contact Tech Support at '
          // eslint-disable-next-line spellcheck/spell-checker
          + 'lgmstechsupport@landg.com providing your contact details and we will reply you as soon as possible.',
        size: 'sm',
        sticky: true,
        hideCancel: true,
        showButtons: true,
      })
      .then(() => null, () => null)
      .finally(async () => {
        await firstValueFrom(
          this.newFeatureService.setUserHasSeenNewFeature(NewFeatures.UserProductsUploadWarning, true),
        );
        return true;
      });
  }
}
