<div class="container lenderhub-home mt-3">
  <section class="row">
    <div class="header-image w-100">
      <img src="assets/images/lenderhub/home-page/LH-New-Banner.svg" alt="" />
    </div>
  </section>

  <div class="promo-bar col">
    <h2 class="my-auto">
      {{pageTitle}}
    </h2>
  </div>

  <div class="pb-5 row">
    <app-product-upload-widget
      [isBridging]="isBridging"
      [variableRateCodes]="variableRateCodes"
      [productsVisibilityStates]="productsVisibilityStates"
      [existingProducts]="existingProducts"
      [productCollectionType]="productCollectionType"
      (refreshData)="initialisePage()"
      (fileUploaded)="uploadFile($event)"
    ></app-product-upload-widget>

    @if (hasValuationFee) {
      <div ngbAccordion class="my-3" #acc="ngbAccordion" [closeOthers]="true" activeIds="accordion">
        <div ngbAccordionItem>
          <ng-container>
            <div ngbAccordionHeader id="accordion" title="Valuation Scale">
              <div class="accordion-button custom-header">
                <div class="title">
                  <h3 class="m-0">Update Valuation Fee Scale</h3>
                  <button ngbAccordionToggle class="btn btn-2022--secondary" style="right: auto;">{{ opened ? 'Collapse' : 'Expand' }}</button>
                </div>
                <div class="description">
                  <p class="m-0">Valuation Fees</p>
                </div>
              </div>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <!-- Main accordion content -->
                <ng-template>
                  <lib-valuation-scale
                    (formSubmit)="updateValuationFeeScaleStatus()"
                    [lendingType]="bridgingLendingTypeCode"
                  ></lib-valuation-scale>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    }

    <!-- Packagers do not get the product admin tile -->
    @if (!isPackager) {
      <div class="col-4 mt-3">
        <div class="bg-2022--white h-100 p-3">
          <h2>Product Admin</h2>
          <p>Set and maintain your Floor Rate and Tariff of Charges</p>
          <div class="mt-3">
            <button type="button" class="btn btn-2022--secondary" (click)="productAdmin()" title="Lender admin">
              Products
            </button>
          </div>
        </div>
      </div>
    }

    <!-- Packager only -->
    @if (isPackager) {
      <div class="col-8 mt-3">
        <div class="bg-2022--white p-3">
          <h2>Available Variable Rates</h2>
          <p>A list of variable rates maintained by your lenders that can be used in your products.</p>
          <app-packager-variable-rates-widget />
        </div>
      </div>
    }

    <!-- Exclusive only -->
    @if (isSeparateExclusiveUpload) {
      <app-product-upload-widget
        [isBridging]="isBridging"
        [exclusiveOnly]="isSeparateExclusiveUpload"
        [variableRateCodes]="variableRateCodes"
        [productsVisibilityStates]="productsVisibilityStates"
        [existingProducts]="existingProducts"
        [productCollectionType]="productCollectionType"
        (refreshData)="initialisePage()"
        (fileUploaded)="uploadFile($event)"
      ></app-product-upload-widget>
    }
  </div>
</div>

<ng-template #previewUploadModalTemplateRef>
  <lib-preview-upload-modal
    [uploadResult]="uploadResult"
    [statusMessage]="statusMessage"
    [validationErrors]="validationErrors"
    [errorOccurred]="errorOccurred"
    [feesMissingInEsis]="additionalResult?.feesMissingInEsis"
    [productCollectionType]="productCollectionType"
    (amendEsisFeesSelected)="setAmendFeesSelected($event)"
  ></lib-preview-upload-modal>
</ng-template>

<ng-template #valuationFeeModalTemplateRef>
  <lib-valuation-scale
    [isValuationModalOpen]="true"
    [lendingType]="bridgingLendingTypeCode"
    [deferredSave]="true"
    (formSubmit)="closeValuationFeeModal($event)"
    (formCancel)="dismissModal(false)"
  ></lib-valuation-scale>
</ng-template>

<ng-template #valuationFeeIntroductionModalTemplateRef>
  <div class="alert alert-success">
    <p>
      You haven't provided a valuation fee for some or all of your products.
      Click continue to provide us with your fee scale or close this window and update
      your product spreadsheet with the Valuation fee, then reupload
    </p>
    <div class="text-end">
      <a
        class="btn btn-2022--secondary"
        (click)="dismissModal(true)"
        >
        Continue
      </a>
    </div>
  </div>
</ng-template>

<ng-template #valuationFeeErrorModalTemplateRef>
  <div class="alert alert-danger">
    <p>
      You have set freeVal to Yes on some or all of your products,
      please review the fees entered for these products
    </p>
  </div>
</ng-template>

<ng-template #payrateUpdateModalTemplateRef>
  <div class="alert alert-success">
    <p>
      You can now update aspects of your Affordability Calculation in LenderHub (e.g. Payrate, ONS).
      Please go to the Homepage, and scroll to Manage Affordability tile.
    </p>
    <div class="text-end">
      <a
        class="btn btn-2022--secondary me-2"
        [routerLink]="['/manage-affordability/BTL']"
        (click)="dismissModal(true)"
        >
        Amend now
      </a>
      <a
        class="btn btn-2022--secondary"
        (click)="dismissModal(false)"
        >
        OK
      </a>
    </div>
  </div>
</ng-template>

<ng-template #variableRatesValidationModalTemplateRef>
  <div class="alert alert-warning">
    <p>The margin entered for some of your variable products seems a little high.</p>
    <p>If you do not use a margin you should leave the rate value empty, otherwise please check you have entered just the margin and not the full variable rate in the following:</p>

    @for (message of uploadResult?.additionalFileProcessingResult?.variableRateWarnings; track message) {
      <p>{{message.cellReference | titlecase}} contains {{message.rate}}</p>
    }
  </div>
</ng-template>

<ng-template #lenderProductsAdminModalTemplate>
  <app-lender-products-admin #lenderProductsAdminComponent [lenderNameId]="lenderNameId" [isBridging]="isBridging"/>
</ng-template>
