import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { NgxEditorModule } from 'ngx-editor';
import { EsisHtmlEditorComponent } from '../esis-html-editor/esis-html-editor.component';

@Component({
  selector: 'app-other-rights-of-borrower',
  templateUrl: 'other-rights-of-borrower.component.html',
  styleUrls: ['other-rights-of-borrower.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgIf,
    NgxEditorModule,
    EsisHtmlEditorComponent,
  ],
})

export class OtherRightsOfBorrowerComponent {
  @Input() public otherRightsOfBorrower: UntypedFormGroup;

  public valid(name:string): boolean {
    return this.otherRightsOfBorrower.controls[name].valid && this.otherRightsOfBorrower.controls[name].touched;
  }

  public invalid(name:string): boolean {
    return this.otherRightsOfBorrower.controls[name].invalid && this.otherRightsOfBorrower.controls[name].touched;
  }

  public getControl(name: string) {
    return this.otherRightsOfBorrower.get(name);
  }
}
