import { Component, HostListener, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule,
  UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AffordabilityManagementService } from 'apps/lenderhub/src/app/services';
import { AsantoColumnType } from '@msslib/models';
import type { AffordabilityManagementTable, AffordabilityManagementTaxTables,
  AffordabilityManagementUpdateRequest } from 'apps/lenderhub/src/app/models';
import { ModalService, ToastService } from '@msslib/services';
import { parseISO } from 'date-fns';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DatePipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { EventScheduleComponent, PageNavComponent } from '@msslib/components';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '@msslib/services/guards/pendingChanges.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-affordability-management',
  templateUrl: 'affordability-management.component.html',
  styleUrls: ['affordability-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    PageNavComponent,
    NgIf,
    NgFor,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSwitch,
    NgSwitchCase,
    EventScheduleComponent,
    RouterLink,
    DatePipe,
  ],
})
export class AffordabilityManagementComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('updateConfirmationDialog') public updateConfirmationDialog: TemplateRef<unknown>;
  @ViewChild('updateSuccessDialog') public updateSuccessDialog: TemplateRef<unknown>;
  @ViewChild('rescheduleDialog') public rescheduleDialog: TemplateRef<unknown>;

  public activeScheduleDate: Date | null;
  public tables: AffordabilityManagementTable[];
  public taxTables: AffordabilityManagementTaxTables[];
  public isReadOnly: boolean;
  public tableFormArrays: UntypedFormArray[];
  public scheduleForm: UntypedFormGroup;
  public updatedScheduleDate: Date | null;
  public columnType = AsantoColumnType;
  public taxTablesForm = new FormGroup({
    tables:  new FormArray<FormControl>([]),
  });
  private nonDigitNumericChars = ['e','E', '+', '-'];
  public combinedTitlesAndDescriptions: any[] = [];
  public activeAccordions: string[] = [];
  public hasHostedVersion = false;
  public discardConfirmed = false;

  public constructor(
    private affordabilityManagementService: AffordabilityManagementService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private toastService: ToastService,
    private datePipe: DatePipe,
  ) {
    this.scheduleForm = EventScheduleComponent.buildScheduleForm();
  }

  private get lendingTypeCode(): string {
    return this.route.snapshot.params.lendingTypeCode;
  }

  public ngOnInit(): void {
    this.fetchManagementData();
  }

  @HostListener('window:beforeunload', ['$event'])
  public canDeactivate(): Observable<boolean> | boolean {
    return !this.anyTablesTouched || this.isReadOnly || this.discardConfirmed;
  }

  public canDeactivateMessage() {
    return this.goBack();
  }

  public goBack(): boolean {
    if (!this.anyTablesTouched || this.isReadOnly || this.discardConfirmed) {
      return true;
    }

    this.showCancelConfirmation(true);

    return false;
  }

  private fetchManagementData(): void {
    this.affordabilityManagementService.getTables(this.lendingTypeCode)
      .subscribe(({ tables, taxTables, scheduledDate, isReadOnly, hasHostedVersion }) => {
        this.combinedTitlesAndDescriptions = [];
        this.tables = tables;
        this.isReadOnly = isReadOnly;
        this.tableFormArrays = tables.map(t => this.createTableControls(t));
        this.taxTables = taxTables;
        this.hasHostedVersion = hasHostedVersion;
        this.createTaxTableControls(taxTables);
        this.activeScheduleDate = scheduledDate === null ? null : parseISO(scheduledDate);

        for (const table of tables) {
          this.combinedTitlesAndDescriptions.push(
            {'title':table.title,'description':table.description},
          );
        }
        for (const taxTable of taxTables) {
          this.combinedTitlesAndDescriptions.push(
            {'title':taxTable.title,'description':taxTable.description},
          );
        }

        this.combinedTitlesAndDescriptions.sort((a, b) => a.title.localeCompare(b.title));
      });
  }

  public get taxTableFormArrays() {
    return this.taxTablesForm.controls.tables;
  }

  private createTableControls(table: AffordabilityManagementTable): UntypedFormArray {
    const controls = this.fb.array(
      table.data.map(row => this.fb.array(
        table.columns.map((_, colIndex) => this.fb.control(
          row[colIndex],
          Validators.required,
        )),
      )),
    );
    if (this.isReadOnly) {
      controls.disable({ onlySelf: false });
    }
    return controls;
  }

  private createTaxTableControls(taxTables: AffordabilityManagementTaxTables[]) {
    taxTables.map(table => this.taxTableFormArrays.push(new FormControl(table.fiscalYear)));
  }

  public get hasScheduledChanges(): boolean {
    return !!this.activeScheduleDate;
  }

  public get anyTablesTouched(): boolean {
    return this.tableFormArrays?.some(x => x.touched) || this.taxTablesForm.dirty;
  }

  public isValid(tableIndex: number, rowIndex: number, colIndex: number): boolean {
    const { touched, valid } = this.getControl(tableIndex, rowIndex, colIndex);
    return touched && valid;
  }

  public isInvalid(tableIndex: number, rowIndex: number, colIndex: number): boolean {
    const { touched, invalid } = this.getControl(tableIndex, rowIndex, colIndex);
    return touched && invalid;
  }

  public controlErrors(tableIndex: number, rowIndex: number, colIndex: number): ValidationErrors | null {
    return this.getControl(tableIndex, rowIndex, colIndex).errors;
  }

  private getControl(tableIndex: number, rowIndex: number, colIndex: number): AbstractControl {
    return (this.tableFormArrays[tableIndex].controls[rowIndex] as UntypedFormArray).controls[colIndex];
  }

  public showSaveConfirmation(): void {
    // Mark all tables as edited so they show their validation states
    // If there are any validation errors, scroll that error into view
    this.tableFormArrays.forEach(x => x.markAllAsTouched());
    if (this.tableFormArrays.some(x => x.invalid)) {
      setTimeout(() => document.querySelector('input.is-invalid')?.scrollIntoView({ behavior: 'smooth' }));
      return;
    }

    // If no errors, reset the scheduling form and show the confirmation dialog
    this.scheduleForm.reset();
    this.scheduleForm.markAsUntouched({ onlySelf: false });
    this.modalService.open({
      title: 'Confirm Changes',
      template: this.updateConfirmationDialog,
      size: 'xl',
    }).then(() => null, () => null);
  }

  public showCancelConfirmation(goBack: boolean): void {
    this.modalService.open({
      title: 'You have unsaved changes',
      message: 'Are you sure you wish to discard these changes?',
      size: 'md',
      okLabel: 'Discard Changes',
      cancelLabel: 'Go back',
      showButtons: true,
    }).then(() => goBack ? this.backToHome() : this.refresh(), () => this.closeModal());
  }

  public refresh(): void {
    this.discardConfirmed = true;
    window.location.reload();
  }

  public backToHome(): void {
    this.discardConfirmed = true;
    this.router.navigate(['/']);
  }

  public saveChanges(): void {
    this.activeAccordions = [];
    this.showSaveConfirmation();
  }

  public cancelChanges(): void {
    this.activeAccordions = [];
    this.showCancelConfirmation(false);
  }

  public confirmSaveChanges(): void {
    this.scheduleForm.markAllAsTouched();
    if (!this.scheduleForm.valid) {
      return;
    }

    const scheduleDate = EventScheduleComponent.getScheduleFormDate(this.scheduleForm);
    const updateRequestModel = {
      tables: [
        ...this.tables.map((table, tableIndex) => ({
          id: table.id,
          data: this.tableFormArrays[tableIndex].value,
        })),
        ...this.taxTables.map((taxTable: AffordabilityManagementTaxTables, i) => {
          const table: AffordabilityManagementTable | undefined
            = taxTable.tables.find(table => table.fiscalYear === this.taxTableFormArrays.controls[i].value);
          return {
            id: table?.id,
            data: table?.data,
          };
        })],
      scheduleDate: EventScheduleComponent.getScheduleFormDate(this.scheduleForm),
    } as AffordabilityManagementUpdateRequest;
    this.affordabilityManagementService.updateTables(this.lendingTypeCode, updateRequestModel)
      .subscribe(() => {
        this.updatedScheduleDate = scheduleDate;
        this.markChangesAsSaved();
        this.modalService.close();
        this.modalService.open({
          title: 'Affordability Update',
          template: this.updateSuccessDialog,
        }).then(() => null, () => this.router.navigate(['/'])); // Return to home when user closes
      });
  }

  public showUpdateScheduleModal(): void {
    if (this.activeScheduleDate) {
      EventScheduleComponent.resetScheduleForm(this.scheduleForm, this.activeScheduleDate);
      this.modalService.open({
        title: 'Update Schedule',
        template: this.rescheduleDialog,
        size: 'md',
      }).then(() => null, () => null);
    }
  }

  public confirmUpdateSchedule(): void {
    if (!this.scheduleForm.valid) {
      return;
    }
    const newDate = EventScheduleComponent.getScheduleFormDate(this.scheduleForm) ?? new Date(0);
    this.affordabilityManagementService.updateScheduledDateTime(this.lendingTypeCode, newDate)
      .subscribe(() => {
        this.toastService.success('Updated scheduled change date');
        this.activeScheduleDate = newDate;
        this.modalService.close();
      });
  }

  public discardSchedule(): void {
    this.modalService.warn({
      title: 'Delete Schedule',
      message: 'Are you sure you want to delete the update scheduled to go live on '
      + `${this.datePipe.transform(this.activeScheduleDate, 'dd/MM/yyyy HH:mm')}? If you click "No, go back", `
      + 'your current affordability values will remain available on Legal & General Ignite until replaced.',
      size: 'md',
      showButtons: true,
      okLabel: 'Yes, delete schedule',
      cancelLabel: 'No, go back',
    }).then(
      () => this.affordabilityManagementService.discardScheduledChanges(this.lendingTypeCode)
        .subscribe(() => {
          this.toastService.success('Deleted scheduled changes');
          this.fetchManagementData(); // Refresh page data
        }),
      () => { /* Catch promise rejection */ },
    );
  }

  public closeModal(): void {
    this.modalService.close();
  }

  public formatAsantoValue(value: string | number, type: AsantoColumnType): unknown {
    switch (type) {
      case AsantoColumnType.Boolean:
        if (typeof value === 'string') {
          return ['yes', 'true'].includes(value?.toLowerCase()) ? 'Yes' : 'No';
        }
      case AsantoColumnType.Number:
        if (typeof(value) === 'string') {
          if (value.startsWith('<=')) {
            return `${value.substring(2)} or lower`;
          } else if (value.startsWith('>=')) {
            return `${value.substring(2)} or higher`;
          } else if (value.startsWith('>')) {
            return `greater than ${value.substring(1)}`;
          } else if (value.startsWith('<')) {
            return `less than ${value.substring(1)}`;
          }
        }
      default: return value;
    }
  }

  // Remove scientific notation from number inputs
  public preventUnwanted(event) {
    if (this.nonDigitNumericChars.includes(event.key)) {
      event.preventDefault();
    }
  }
  public get bannerTitle() {
    if (this.route.snapshot.url[1].toString() === 'RES') {
      return 'Manage Affordability - Residential';
    } else if (this.route.snapshot.url[1].toString() === 'BTL') {
      return 'Manage Affordability - Buy To Let';
    }
    return '';
  }

  public showTables(isMatching:boolean):boolean {
    if (!this.hasHostedVersion) {
      return true;
    } else if (this.hasHostedVersion && isMatching) {
      return true;
    }
    return false;
  }

  public showTaxTables(taxTable:AffordabilityManagementTaxTables):boolean {
    if (!this.hasHostedVersion) {
      return true;
    } else if (this.hasHostedVersion && taxTable?.tables?.some(table => table.isMatchingWithIgnite)) {
      return true;
    }
    return false;
  }

  public lenderIsHosted():boolean {
    return this.hasHostedVersion;
  }

  public getFiscalYearText(fiscalYear:string):string {
    return fiscalYear === 'custom' ? 'Does not match any known tax year or period' : fiscalYear;
  }

  private markChangesAsSaved(): void {
    this.tableFormArrays.forEach(r => r.markAsUntouched());
    this.taxTablesForm.markAsPristine();
  }
}
