import { Component, Input } from '@angular/core';
import {
  Product,
  RateType,
  ResiBtlProductRate,
} from 'apps/shared/src/models';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { NgFor, NgIf } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

interface RateViewModel {
  title: string;
  rateType: string;
  rateValue: string;
  initialPeriod: string;
  until: string;
}

@Component({
  selector: 'lib-product-rates-tooltip',
  templateUrl: 'product-rates-tooltip.component.html',
  styleUrls: ['product-rates-tooltip.component.scss'],
  standalone: true,
  imports: [
    NgbTooltip,
    NgFor,
    NgIf,
  ],
})
export class ProductRatesTooltipComponent {
  @Input() public product: Product;

  public get initialRateValue(): number | null {
    return this.product.initialRateValue;
  }

  public get ratesViewModels() {
    if (!this.product.rates?.length) {
      return;
    }

    const viewModels: RateViewModel[] = [];
    const rates = this.product.rates.sort((a, b) => a.ordinal - b.ordinal);

    viewModels.push(this.buildRateViewModel(rates[0], 'Initial Rate'));

    if (rates.length > 1) {
      for (let index = 1; index < rates.length; index++) {
        viewModels.push(this.buildRateViewModel(rates[index]));
      }
    }

    return viewModels;
  }

  public get isMultipleRates(): boolean {
    return this.product.rates?.length > 1;
  }

  public trackByFn(title: string) {
    return title;
  }

  private buildRateViewModel(rate: ResiBtlProductRate, title?: string): RateViewModel {
    const viewModel = {
      title: title ?? `Rate ${rate.ordinal}`,
      rateType: rate.rateType ? RateType[rate.rateType] : '',
      rateValue: `${rate.rate}%`,
      initialPeriod: rate.initialPeriod ? `${rate.initialPeriod} Months` : 'For the term',
      until: rate.until ? `until ${format(parseISO(rate.until), 'dd/MM/yyyy')}` : '',
    };

    return viewModel;
  }
}
