import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm, UntypedFormArray } from '@angular/forms';

import { FieldTypes, IFieldConfig } from '@msslib/models/forms';
import { ModalService } from '@msslib/services/modal.service';
import { MiSaveOutcomeModel } from '../../models';
import { AppFormComponent } from '../forms/components/form/form.component';

@Component({
  selector: 'lib-print-results-modal',
  styleUrls: ['print-results-modal.component.scss'],
  templateUrl: 'print-results-modal.component.html',
  standalone: true,
  imports: [AppFormComponent],
})
export class PrintResultsModalComponent implements OnInit {
  @Input() public editItem: MiSaveOutcomeModel;
  @Input() public notesOnly: boolean;
  @Output() public emitPrintOption = new EventEmitter<unknown>();
  public config: IFieldConfig[];
  public constructor(private modalService: ModalService) {}

  public ngOnInit() {
    this.config = [
      // Radio buttons to sort print options using the onRadioChange function when clicked/changed
      {
        name: 'printSorting',
        label: 'Print Sorting Options: ',
        type: FieldTypes.RadioList,
        value: 'true-cost',
        options: [
          {
            value: 'maximum-loan',
            text: 'Maximum Affordable Loan Amount (High to Low)',
          },
          {
            value: 'true-cost',
            text: 'True Cost',
          },
          {
            value: 'alphabetically',
            text:'Alphabetically (A to Z)',
          },
        ],
        onRadioChange: this.updatePrintSortingOptions.bind(this),
      },
      {
        name: 'printOptions',
        label: 'Please select which pages you would like to print',
        type: FieldTypes.CheckboxList,
        validation: [this.multipleCheckboxRequireOne],
        hidden: this.notesOnly,
        clicked: false,
        options: [
          {
            value: 'input',
            text: 'Calculator Input',
          },
          {
            value: 'summary',
            text: 'Results Summary',
          },
          {
            value: 'detail',
            text: 'Detailed Results',
          },
          {
            value: 'criteria',
            text: 'Detailed criteria',
          },
        ],
      },
      {
        name: 'submit',
        label: 'Print',
        type: FieldTypes.ButtonGroup,
        onSubmit: this.submit.bind(this),
        onCancel: this.cancel.bind(this),
      },
    ];
    // Reset to default value
    this.emitPrintOption.emit('');
  }

  public submit(form: NgForm) {
    if (form.valid) {
      this.modalService.close(form.value);
    } else {
      this.config[0].clicked = true;
    }
  }

  public cancel() {
    this.modalService.dismiss();
  }

  public multipleCheckboxRequireOne(fa: UntypedFormArray) {
    let valid = false;

    for (let x = 0; x < fa.length; ++x) {
      if (fa.at(x).value) {
        valid = true;
        break;
      }
    }
    return valid ? null : {
      multipleCheckboxRequireOne: true,
    };
  }

  public updatePrintSortingOptions(event: unknown) {
    this.emitPrintOption.emit(event);
  }
}
