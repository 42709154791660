import { Component } from '@angular/core';
import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

@Component({
  templateUrl: 'input.component.html',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
})
export class InputFieldTypeComponent extends MssFieldBaseFieldTypeComponent {}
