import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { tap } from 'rxjs/operators';

import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { ContextOption } from '@msslib/models/contexts';
import { LenderHubService } from 'apps/shared/src/services';

export const issueResolver: ResolveFn<ContextOption> =
  (route: ActivatedRouteSnapshot) => {
    const clubHubDataService = inject(ClubHubDataService);
    const lenderHubService = inject(LenderHubService);

    return clubHubDataService
      .get<ContextOption>(`Answer/GetIssue/${route.params.issueId}`)
      .pipe(tap(issue => (lenderHubService.currentIssue = issue as ContextOption)));
  };
