import { AfterViewInit, Component, OnInit } from '@angular/core';

import { MiHostedService } from './mi-hosted.service';
import { IEmbedConfig, IMiHostedReport } from './mi-hosted.model';
import { AnalyticsService } from '@msslib/services/analytics.service';
import { PowerBiComponent } from '../../../../../../../libs/msslib/src/lib/components/powerbi/powerbi.component';
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { MiPageNavComponent } from '../mi-page-nav/mi-page-nav.component';

@Component({
  selector: 'app-hosted-mi',
  styleUrls: ['mi-hosted.component.scss'],
  templateUrl: 'mi-hosted.component.html',
  providers: [MiHostedService],
  standalone: true,
  imports: [
    MiPageNavComponent,
    NgIf,
    NgFor,
    FormsModule,
    PowerBiComponent,
  ],
})
export class MiHostedComponent implements OnInit, AfterViewInit {
  public miHostedReports: IMiHostedReport[];
  public embedConfig: IEmbedConfig;

  public constructor(private analyticsService: AnalyticsService, private miHostedPaidService: MiHostedService) {}

  public ngOnInit() {
    this.miHostedPaidService.getReports().subscribe(reports => {
      this.miHostedReports = reports;
      this.reportChange(reports[0].id);
    });
  }

  public ngAfterViewInit() {
    this.analyticsService.log('Hosted MI');
  }

  public reportChange(reportId: string) {
    this.miHostedPaidService.getReportConfiguration(reportId).subscribe(config => {
      this.embedConfig = config;
    });
  }
}
