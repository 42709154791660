<!-- Tried using an ngComponentOutlet instead of a switch, but it doesn't work with Angular Outputs :( -->
<ng-container [ngSwitch]="condition?.type">
  <div *ngSwitchCase="conditionType.Property" class="property-condition-container d-flex" >
    <lib-rules-engine-property-condition-editor
      class="flex-grow-1"
      [schema]="schema"
      [condition]="condition"
      (conditionChange)="conditionChange.emit($event)"
      [disabled]="disabled"
      [showErrors]="showErrors"
      [allowAlwaysCondition]="allowAlwaysCondition"
      [alwaysConditionLabel]="alwaysConditionLabel"
      [testIdSuffix]="testIdSuffix" />

    <!-- We put these here rather than inside the property-condition-editor-component because when the user adds an
    adjacent condition, we need to convert that condition into a group condition, which Typescript doesn't like because
    the type of `condition` on that compoennt is a PropertyCondition. Just easier to deal with it here instead :) -->
    <div class="btn-group ms-2 align-self-center" *ngIf="!isRoot || canDeleteCondition">
      <button
        *ngIf="!isRoot"
        type="button"
        class="btn btn-sm btn-2022--light-1"
        [disabled]="disabled"
        (click)="addAdjacentCondition()"
        [attr.data-testid]="'add_adjacent_condition_' + testIdSuffix">
        <i class="fas fa-plus"></i>
      </button>
      <button
        *ngIf="canDeleteCondition"
        type="button"
        class="btn btn-sm btn-2022--primary-lightest text-2022--danger delete-button"
        [disabled]="disabled"
        (click)="onDeleteCondition()"
        [attr.data-testid]="'delete_condition_' + testIdSuffix">
        <i class="fas fa-trash"></i>
      </button>
    </div>
  </div>

  <lib-rules-engine-logical-group-condition-editor *ngSwitchCase="conditionType.LogicalGroup"
    [schema]="schema"
    [condition]="condition"
    (conditionChange)="groupConditionChange($event)"
    [disabled]="disabled"
    [showErrors]="showErrors"
    [testIdSuffix]="testIdSuffix" />
</ng-container>

<!-- Only show this button when we are showing the root of the tree AND when the condition is not a logical group.
Otherwise there will already be a button from within the logical-group-condition-editor. -->
<button
  *ngIf="isRoot && condition?.type !== conditionType.LogicalGroup"
  type="button"
  class="btn btn-2022--secondary btn-sm mt-2"
  [disabled]="disabled"
  (click)="addAdjacentCondition()"
  [attr.data-testid]="'add_adjacent_condition_' + testIdSuffix">
  + Condition
</button>
