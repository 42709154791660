import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  templateUrl: 'postcode-input.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    NgIf,
  ],
})
export class PostcodeInputComponent extends FieldType {
  public test() {
    this.props.submit;
  }
}
