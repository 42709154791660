import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalService } from '@msslib/services';
import {
  esisApplicableAvailabilityProperties,
  getEsisApplicableAvailabilityProperties,
} from 'app/models/esis-product-attributes';
import { Product } from 'apps/shared/src/models/matched-product';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-esis-preview-attributes-modal',
  templateUrl: 'esis-preview-attributes-modal.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgFor,
  ],
})
export class EsisPreviewAttributesModalComponent implements OnInit {
  @Input({ required: true }) public product: Product;
  public form: FormGroup<Record<string, FormControl<boolean>>>;

  public applicableProductProperties: string[];

  public constructor(
    private readonly modalService: ModalService,
  ) { }

  public ngOnInit(): void {
    this.applicableProductProperties = getEsisApplicableAvailabilityProperties(this.product);

    this.form = new FormGroup(Object.fromEntries(
      this.applicableProductProperties
        .map(key => [key, new FormControl(false, { nonNullable: true })])));
  }

  public getLabel(key: string): string {
    return esisApplicableAvailabilityProperties[key] ?? '';
  }

  public submit() {
    this.modalService.close(this.form.value);
  }
}
