import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import * as pbi from 'powerbi-client';
import * as models from 'powerbi-models';

import { PowerBiService } from './powerbi.service';

export const enum TokenType {
  Aad = 'Aad',
  Embed = 'Embed',
}

export const enum ReportType {
  Dashboard = 'Dashboard',
  Report = 'Report',
  Tile = 'Tile',
}

@Component({
  selector: 'lib-powerbi',
  styleUrls: ['powerbi.component.scss'],
  templateUrl: 'powerbi.component.html',
  standalone: true,
})
export class PowerBiComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public accessToken: string;
  @Input() public tokenType: TokenType;
  @Input() public embedUrl: string;
  @Input() public id: string;
  @Input() public type: ReportType;
  @Input() public name: string;
  @Input() public options: models.ISettings;
  @Output() public embedded = new EventEmitter<number>();
  @ViewChild('powerBiIFrame', { static: true }) public powerBiIFrameRef: ElementRef;

  private component: pbi.Embed | null;

  public constructor(private powerBiService: PowerBiService) { }

  public ngOnInit() {
    if (this.powerBiIFrameRef.nativeElement && this.validateRequiredAttributes()) {
      this.embed(this.powerBiIFrameRef.nativeElement, this.getConfig());
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    const { accessToken, tokenType, embedUrl, id, type, name, options } = changes;

    if (this.validateRequiredAttributes()) {
      const config = this.getConfig(
        accessToken?.currentValue,
        tokenType?.currentValue,
        embedUrl?.currentValue,
        id?.currentValue,
        type?.currentValue,
        name?.currentValue,
        options?.currentValue,
      );
      this.embed(this.powerBiIFrameRef.nativeElement, config);
    } else if (this.component) {
      this.reset(this.powerBiIFrameRef.nativeElement);
    }
  }

  public ngOnDestroy() {
    if (this.component) {
      this.reset(this.powerBiIFrameRef.nativeElement);
    }
  }

  private validateRequiredAttributes(): boolean {
    return typeof this.embedUrl === 'string' && this.embedUrl.length > 0 &&
      typeof this.accessToken === 'string' && this.accessToken.length > 0;
  }

  private getTokenType(tokenType: TokenType): models.TokenType {
    switch (tokenType) {
      case TokenType.Aad:
        return models.TokenType.Aad;
      case TokenType.Embed:
      default:
        return models.TokenType.Embed;
    }
  }

  private getConfig(
    accessToken?: string,
    tokenType?: TokenType,
    embedUrl?: string,
    id?: string,
    type?: ReportType,
    name?: string,
    options?: models.ISettings,
  ): pbi.IEmbedConfiguration {
    return {
      type: type ?? this.type ?? ReportType.Report,
      embedUrl: embedUrl ?? this.embedUrl,
      accessToken: accessToken ?? this.accessToken,
      tokenType: this.getTokenType(tokenType ?? this.tokenType),
      id: id ?? this.id,
      uniqueId: name ?? this.name,
      settings: options ?? this.options,
    };
  }

  public reset(element: HTMLElement) {
    this.powerBiService.reset(element);
    this.component = null;
  }

  private embed(element: HTMLElement, config: pbi.IEmbedConfiguration) {
    this.component = this.powerBiService.embed(element, config);
    this.embedded.emit(this.component as any);
  }
}
