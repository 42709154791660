import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequiredHtmlValidatorDirective } from '@msslib/components/forms/directives/required-html-validator.directive';
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor';

@Component({
  selector: 'app-esis-html-editor',
  templateUrl: './esis-html-editor.component.html',
  styleUrl: './esis-html-editor.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    NgxEditorModule,
    ReactiveFormsModule,
    RequiredHtmlValidatorDirective,
  ],
})
export class EsisHtmlEditorComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public control: FormControl;
  @Input() public placeholder = '';
  @Input() public isRequired = false;

  public editor: Editor;

  public readonly toolbar: Toolbar = [
    ['bold', 'italic', 'underline'],
    ['bullet_list', 'ordered_list'],
  ];

  public ngOnInit(): void {
    this.editor = new Editor({
      keyboardShortcuts: true,
    });
  }

  public ngOnDestroy(): void {
    this.editor.destroy();
  }

  public get isDisabled() {
    return this.control?.disabled ?? true;
  }

  @HostBinding('class.is-valid')
  public get valid(): boolean {
    return !!this.control && this.control.valid && this.control.touched;
  }

  @HostBinding('class.is-invalid')
  public get invalid(): boolean {
    return !!this.control && this.control.invalid && this.control.touched;
  }
}
