import { Component, Input } from '@angular/core';
import { ProductsFilterService, ProductsSearchService, SourcingProductsFilterService }
  from 'apps/clubhub/src/app/ignite/services';
import { Product } from 'apps/shared/src/models';
import { NgFor, NgIf } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

/**
 * Component that calculates and displays the overrides applied to a specific column.
 */
@Component({
  selector: 'lib-product-override-tooltip',
  templateUrl: './product-override-tooltip.component.html',
  standalone: true,
  imports: [
    NgbTooltip,
    NgIf,
    NgFor,
  ],
})
export class ProductOverrideTooltipComponent {
  @Input({ required: true }) public product: Product;
  @Input({ required: true }) public property: keyof Product;
  @Input() public label: string | undefined = undefined;
  @Input() public displayPrefix = '';
  @Input() public displaySuffix = '';

  public constructor(
    private sourcingProductsFilterService: SourcingProductsFilterService,
    private productsSearchService: ProductsSearchService,
    private productsFilterService: ProductsFilterService,
  ) {}

  /** The current value of this property on the product (with all overrides applied). */
  public get currentValue() {
    return this.product[this.property];
  }

  /** The original value of this property on the product. */
  public get baseValue() {
    // Note that we explicitly check for the existence of the property in these dictionaries rather than using `??`
    // because the original value may have been a `null` (indicating no upper or lower limit).

    // May have been first overridden on server, which would place the original value in the baseValues
    if (!!this.product.baseValues && this.property in this.product.baseValues) {
      return this.product.baseValues[this.property];
    }

    // If not, may have been overridden by the FE sourcing filter service
    const feOriginal = this.sourcingProductsFilterService.getOriginalProduct(this.product.id ?? -1);
    if (!!feOriginal && this.property in feOriginal) {
      return feOriginal[this.property];
    }

    // If not, use the current value
    return this.currentValue;
  }

  /** A list of all overrides that have been applied to the product. */
  public get overrides() {
    return this.sourcingProductsFilterService.getActiveOverrides(
      this.product,
      this.property,
      this.productsSearchService.matchedProducts,
      this.productsFilterService.activeFilterTitles,
    );
  }

  public format(v: any): string {
    return v === null || v === undefined
      ? 'No limit'
      : `${this.displayPrefix}${v}${this.displaySuffix}`;
  }

  public trackByLabel({ label }: { label: string }) {
    return label;
  }
}
