import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo', pure: false,
  standalone: true,
})
export class YesNoPipe implements PipeTransform {
  public transform(value: unknown): string {
    if (value) {
      return 'Yes';
    }
    return 'No';
  }
}
