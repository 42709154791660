<div [formGroup]="interestRatesAndOtherCosts">
  <div *ngIf="isIllustration" class="mb-4 row">
    <label class="col-12" [for]="id + 'Text'">Please provide Interest Rates and Other Costs wording:</label>
    <div class="col-12">
      <app-esis-html-editor
        [id]="id + 'Text'"
        [control]="interestRatesAndOtherCosts.get('text')"
        placeholder="Interest rates and Other Costs wording" />
    </div>
  </div>
  <div class="mb-4 row">
    <label class="col-12" [for]="id + 'Text'">
      Please provide wording for the costs are not known to the lender and are therefore not included in the APRC:
    </label>
    <div class="col-12">
      <app-esis-html-editor
        [id]="id + 'Text'"
        [control]="interestRatesAndOtherCosts.get('costsNotKnownToLender')"
        placeholder="Costs not known to the Lender wording" />
    </div>
  </div>
</div>
