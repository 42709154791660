<ngx-editor-menu
  [editor]="editor"
  [toolbar]="toolbar"
  [disabled]="isDisabled"
/>
<ngx-editor
  [placeholder]="isDisabled ? '' : placeholder"
  [editor]="editor"
  [formControl]="control"
  [requiredHtml]="isRequired"
/>
