import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  CreateValuationFeeScaleResponseModel,
  ValuationFeeScale,
  ValuationFeeScaleEntry,
  ValuationFeeScaleUpdateRequest,
} from '@msslib/models';
import { ProductService } from '@msslib/services/product.service';
import { ModalService, ToastService } from '@msslib/services';
import { FeeScaleConditionType } from '@msslib/models/enums/fee-scale-condition-type';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule,
  ValidatorFn } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { hasChanges } from '@msslib/helpers/model-helper';
import { EventScheduleComponent, ScheduleFormType } from '@msslib/components';
import { DatePipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'lib-valuation-fee-scales-editor',
  templateUrl: './valuation-fee-scales-editor.component.html',
  styleUrls: ['./valuation-fee-scales-editor.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgIf,
    EventScheduleComponent,
    DatePipe,
  ],
})
export class ValuationFeeScalesEditorComponent implements OnInit, OnDestroy {

  @Input() public lenderNameId: number;
  @Input() public valuationFeeScale: ValuationFeeScale;
  @Input() public lendingTypeCode: string;
  @Output() public formSubmit = new EventEmitter<void>();
  @Output() public unsavedChangesChange = new EventEmitter<boolean>();
  @Output() public closeValuationFeeScalesEditor = new EventEmitter<boolean>();
  @ViewChild('valuationFeeScalesPreSaveDialogTemplate')
  public valuationFeeScalesPreSaveDialogTemplate: TemplateRef<void>;
  @ViewChild('valuationFeeScalesSaveResultDialogTemplate')
  public valuationFeeScalesSaveResultDialogTemplate: TemplateRef<void>;
  public form: FormGroup;
  public valuationFeeScaleEntries: ValuationFeeScaleEntry[] = [];
  public isNew: boolean;
  public isForView: boolean;
  public valuationFeeScaleCondition: FeeScaleConditionType | null = null;
  public valuationFeeScaleConditionTypes: { value: FeeScaleConditionType; label: string }[];
  public hasUnsavedChanges: boolean;
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  public scheduleForm: ScheduleFormType;
  public showConfirmStep: boolean;
  public saveResultMessage: string;
  private pristineFormModel: any;
  private nonDigitNumericChars = ['e','E', '+', '-', '.'];

  public constructor(private productService: ProductService,
                     private toastService: ToastService,
                     private modalService: ModalService,
                     private datePipe: DatePipe,
                     private fb: FormBuilder) {
    this.form = this.fb.group({
      condition: [FeeScaleConditionType.Standard],
      entries: this.fb.array([]),
    });
    this.scheduleForm = EventScheduleComponent.buildScheduleForm();
  }

  public ngOnInit(): void {
    this.getFeeScaleConditionTypes();
    if (this.valuationFeeScale) {
      this.isForView = true;
      this.valuationFeeScaleEntries = this.valuationFeeScale.entries;
      this.valuationFeeScaleCondition = this.valuationFeeScale.condition;
      this.initForm();
    } else {
      this.isNew = true;
      this.addEntry();
    }
    this.checkValuationFeeScaleFormUnsavedChanges();
  }

  public get entries(): FormArray {
    return this.form.get('entries') as FormArray;
  }

  public initForm() {
    this.valuationFeeScaleEntries.forEach((entry, index) => {
      this.entries.push(this.createEntryGroup(entry, index, this.isForView));
    });
    this.form.patchValue({ condition: this.valuationFeeScaleCondition });
    this.form.get('condition')?.disable({ emitEvent: false });
  }

  public createEntryGroup(entry: ValuationFeeScaleEntry, index: number, disabled: boolean): FormGroup {
    return this.fb.group({
      propertyPriceFrom: [
        { value: entry.propertyPriceFrom, disabled },
        [this.propertyPriceFromValidator(index)],
      ],
      propertyPriceTo: [
        { value: entry.propertyPriceTo, disabled },
        [this.propertyPriceToValidator(index)],
      ],
      fee: [
        { value: entry.fee, disabled },
        [this.feeValidator(index)],
      ],
    });
  }

  public addEntry(index?: number): void {
    if (index !== undefined) {
      if (Object.keys(this.getRowErrors(index)).length) {
        return;
      }

      const previousEntry = this.entries.at(index || 0)?.value;
      const newEntry: ValuationFeeScaleEntry = {
        propertyPriceFrom: (previousEntry?.propertyPriceTo ?? 0) + 1,
        propertyPriceTo: undefined,
        fee: null,
        id: 0,
      };

      this.entries.insert(index + 1, this.createEntryGroup(newEntry, index + 1, false));
      this.updateValidators();
    } else {
      const newEntry: ValuationFeeScaleEntry = {
        propertyPriceFrom: 1,
        propertyPriceTo: undefined,
        fee: null,
        id: 0,
      };

      this.entries.insert(0, this.createEntryGroup(newEntry, 0, false));
    }
  }

  public deleteEntry(index: number) {
    if (this.entries.length > 1) {
      this.entries.removeAt(index);
      this.updateValidators();
      this.onFocusRow(index);
    }
  }

  public updateValidators(): void {
    this.entries.controls.forEach((group: FormGroup, index) => {
      group.get('propertyPriceFrom')?.setValidators(this.propertyPriceFromValidator(index));
      group.get('propertyPriceTo')?.setValidators(this.propertyPriceToValidator(index));
      group.get('fee')?.setValidators(this.feeValidator(index));

      Object.keys(group.controls).forEach(controlName => {
        group.get(controlName)?.updateValueAndValidity();
      });
    });
  }

  public editClick() {
    this.isForView = false;
    this.entries.controls.forEach((group) => group.enable());
  }

  public propertyPriceFromValidator(entryIndex: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;

      if (entryIndex === 0) {
        if (value === undefined || value === null) {
          return { required: 'Enter a value.' };
        }
      } else {
        const previousEntry = this.entries.at(entryIndex - 1);
        const expectedValue = previousEntry.valid ? previousEntry.value.propertyPriceTo + 1 : null;

        if (!value && !expectedValue) {
          return { required: 'Enter a value.' };
        }
        if (!value && expectedValue) {
          return { required: `Enter a value. Must be £${expectedValue}` };
        }
        if (value && expectedValue && value !== expectedValue) {
          return { mismatch: `Must be £${expectedValue}` };
        }
      }
      return null;
    };
  }

  public propertyPriceToValidator(entryIndex: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;
      const propertyPriceFrom = control.parent?.get('propertyPriceFrom');
      const nextEntry = this.entries.at(entryIndex + 1)?.value;
      const previousEntry = entryIndex === 0 ? null : this.entries.at(entryIndex - 1)?.value;
      const expectedValue = propertyPriceFrom?.value && propertyPriceFrom.valid ? propertyPriceFrom.value :
        previousEntry ? previousEntry.propertyPriceTo + 1 : null;

      if (expectedValue) {
        if (!value) {
          return { required: `Enter a value. Must be greater than £${(expectedValue)}` };
        }
        if (value <= expectedValue) {
          return { min: `Must be greater than £${expectedValue}` };
        }
      }
      if (nextEntry && nextEntry.propertyPriceTo && value >= nextEntry.propertyPriceTo) {
        return { overlap: 'Overlapping Range' };
      }

      return null;
    };
  }

  public feeValidator(entryIndex: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;
      const previousEntry = entryIndex === 0 ? null : this.entries.at(entryIndex - 1)?.value;
      const expectedValue = previousEntry?.fee ?? 0;

      if (!value) {
        return { required: `Enter a value. Must be greater than £${expectedValue}` };
      }

      if (previousEntry && value <= previousEntry.fee) {
        return { min: `Must be greater than £${expectedValue}` };
      }

      return null;
    };
  }

  public onFocusRow(index: number): void {
    const activeRowGroup = this.entries.at(index) as FormGroup;
    Object.keys(activeRowGroup.controls).forEach(controlName => {
      const control = activeRowGroup.get(controlName);
      if (control) {
        control?.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    });
  }

  public getRowErrors(index: number): any {
    const group = this.entries.at(index) as FormGroup;
    const errors: any = {};

    Object.keys(group.controls).forEach((key) => {
      const controlErrors = group.get(key)?.errors;
      if (controlErrors) {
        errors[key] = Object.values(controlErrors).join(' ');
      }
    });

    return errors;
  }

  public preventUnwanted(event: any) {
    if (this.nonDigitNumericChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  public getFeeScaleConditionTypes() {
    const valuationFeeScaleConditionTypesRes = [
      { value: FeeScaleConditionType.Standard, label: 'Standard' },
      { value: FeeScaleConditionType.Purchase, label: 'Purchase' },
      { value: FeeScaleConditionType.Remortgage, label: 'Remortgage' },
      { value: FeeScaleConditionType.ExpatNotInUk, label: 'Expat Not in Uk' },
      { value: FeeScaleConditionType.HelpToBuy, label: 'Help to Buy' },
      { value: FeeScaleConditionType.SelfBuild, label: 'Self Build' },
    ];
    const valuationFeeScaleConditionTypesBtl = [
      { value: FeeScaleConditionType.Standard, label: 'Standard' },
      { value: FeeScaleConditionType.Purchase, label: 'Purchase' },
      { value: FeeScaleConditionType.Remortgage, label: 'Remortgage' },
      { value: FeeScaleConditionType.ExpatNotInUk, label: 'Expat Not in Uk' },
      { value: FeeScaleConditionType.Hmo, label: 'Hmo' },
    ];
    this.valuationFeeScaleConditionTypes =
      this.lendingTypeCode === 'RES' ? valuationFeeScaleConditionTypesRes : valuationFeeScaleConditionTypesBtl;
  }

  public saveValuationScales() {
    this.updateValidators();
    if (this.form.invalid) {
      return;
    }
    this.openValuationFeeScalesPreSaveDialog();
  }

  public checkValuationFeeScaleFormUnsavedChanges() {
    this.pristineFormModel = this.form.value;
    this.unsavedChangesChange.emit(this.hasUnsavedChanges);
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((currentModel) => {
      this.hasUnsavedChanges = hasChanges(this.pristineFormModel, currentModel);
      this.unsavedChangesChange.emit(this.hasUnsavedChanges);
    });
  }

  public openValuationFeeScalesPreSaveDialog() {
    this.modalService.open({
      title: 'Valuation Fee Scales',
      size: 'md',
      sticky: true,
      template: this.valuationFeeScalesPreSaveDialogTemplate,
      beforeDismiss: async () => await this.canCloseValuationFeeScalesPreSaveDialog(),
    }).then(() => this.showConfirmStep = false, () => null);
  }

  public canCloseValuationFeeScalesPreSaveDialog() {
    return this.modalService.open({
      title: 'Discard Valuation Fee Scales?',
      message: 'Valuation fee scales is not set live or scheduled to go live. Would you like to exit?',
      showButtons: true,
      hideTopClose: true,
      sticky: true,
      size: 'md',
      okLabel: 'No, continue editing',
      cancelLabel: 'Yes, exit',
    }).then(() => false, () => this.closeEditor());
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public goBackPreSaveDialogClick() {
    this.modalService.close();
  }

  public setLivePreSaveDialogClick() {
    if (this.isNew || this.valuationFeeScale.isScheduled) {
      this.showConfirmStep = true;
    } else {
      this.confirmPreSaveDialogClick();
    }
  }

  private closeEditor() {
    this.modalService.close();
    this.closeValuationFeeScalesEditor.emit();
    return true;
  }

  public confirmPreSaveDialogClick() {
    if (!this.scheduleForm.valid) {
      return;
    }
    const scheduleDate = EventScheduleComponent.getScheduleFormDate(this.scheduleForm);
    const dateStr = this.datePipe.transform(scheduleDate, 'dd/MM/yyyy HH:mm');
    const feeScaleUpdateRequest: ValuationFeeScaleUpdateRequest = {
      id: this.isNew ? null : this.valuationFeeScale.id,
      scheduleDate: scheduleDate,
      condition: this.isNew ? this.form.value.condition : this.valuationFeeScaleCondition,
      entries: this.form.value.entries,
    };
    if (this.isNew) {
      const createValuationFeeScaleObservable = this.lenderNameId ?
        this.productService.createValuationFeeScaleByLenderNameId(
          this.lendingTypeCode, this.lenderNameId, feeScaleUpdateRequest) :
        this.productService.createValuationFeeScale(this.lendingTypeCode, feeScaleUpdateRequest);
      createValuationFeeScaleObservable
        .subscribe({
          next: (result: CreateValuationFeeScaleResponseModel) => {
            if (result.isSuccess) {
              this.saveResultMessage = scheduleDate ?
                `Valuation scales and fees have been successfully submitted and wil be live on ${dateStr}.` :
                'Valuation scales and fees are set live now.';
              this.closeEditor();
              this.openValuationFeeScalesSaveResultDialog();
              this.formSubmit.emit();
            } else {
              const dateForMessage =
                this.datePipe.transform(new Date(result.replaceData), 'dd/MM/yyyy HH:mm') as string;
              this.saveResultMessage = result.needReplace ?
                result.message?.replace('{dataForReplace}', dateForMessage) as string : result.message as string;
              this.openValuationFeeScalesSaveResultDialog();
            }
          },
        });
      return;
    }
    const updateValuationFeeScaleObservable = this.lenderNameId ?
      this.productService
        .updateValuationFeeScaleByLenderNameId(this.lendingTypeCode, this.lenderNameId, feeScaleUpdateRequest) :
      this.productService.updateValuationFeeScale(this.lendingTypeCode, feeScaleUpdateRequest);
    updateValuationFeeScaleObservable
      .subscribe({
        next: () => {
          this.saveResultMessage = scheduleDate ?
            `Valuation scales and fees have been successfully submitted and wil be live on ${dateStr}.` :
            'Valuation scales and fees are set live now.';
          this.closeEditor();
          this.openValuationFeeScalesSaveResultDialog();
          this.formSubmit.emit();
        },
        error: () => {
          this.toastService.danger('Valuation Fee Scale update failed');
        },
      });
  }

  public openValuationFeeScalesSaveResultDialog() {
    this.modalService.open({
      title: 'Valuation Fee Scales',
      size: 'md',
      sticky: true,
      template: this.valuationFeeScalesSaveResultDialogTemplate,
    }).then(() => null, () => null);
  }
}
