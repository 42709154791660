import { Component } from '@angular/core';
import { contactUsPhone } from '../../constants';

@Component({
  selector: 'lib-error-page',
  templateUrl: 'error-page.component.html',
  styleUrls: ['error-page.component.scss'],
  standalone: true,
})
export class ErrorPageComponent {
  public contactUsPhone = contactUsPhone;
}
