import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ProductService } from '@msslib/services/product.service';
import { roles } from '@msslib/constants/roles';
import { format, isEqual, parseISO } from 'date-fns';
import { ProductCollectionType, ProductUploadType, ProductsVisibilityState } from '@msslib/models';
import {
  AuthorizeService,
  LendingTypeService,
  ModalService,
  ToastService,
} from '@msslib/services';
import { VariableRateCodeLookupViewModel } from '@msslib/models/variable-rate-codes';
import { LenderHubService } from 'apps/shared/src/services/lenderhub.service';
import { LenderName } from 'apps/shared/src/models';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { RescheduleUploadModalComponent, SwitchComponent } from '@msslib/components';
import { ProductsVisibilityCheckboxComponent } from
  '../products-visibility-checkbox/products-visibility-checkbox.component';

@Component({
  selector: 'app-product-upload-widget',
  templateUrl: './product-upload-widget.component.html',
  styleUrls: ['./product-upload-widget.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgFor,
    ProductsVisibilityCheckboxComponent,
    SwitchComponent,
    RescheduleUploadModalComponent,
  ],
})
export class ProductUploadWidgetComponent implements OnInit {
  @Input() public isBridging = false;
  @Input() public exclusiveOnly = false;
  @Input() public variableRateCodes: VariableRateCodeLookupViewModel;
  @Input() public productsVisibilityStates: ProductsVisibilityState[];
  @Input() public existingProducts: Record<string, number> = {};
  @Input() public productCollectionType: ProductCollectionType;

  @Output() public refreshData = new EventEmitter();
  @Output() public fileUploaded = new EventEmitter<{files: FileList; uploadType: ProductUploadType}>();

  @ViewChild('rescheduleUploadModalTemplateRef', { static: true })
  public rescheduleUploadModalTemplateRef: TemplateRef<void>;

  public roles = roles;
  public hasSchedule = false;
  public scheduleId: string | null = null;
  public uploadDateTime: string | null = null;
  public startDateTime: string | null = null;
  public scheduleDateTime: Date;

  public constructor(
    private productService: ProductService,
    private modalService: ModalService,
    private toastService: ToastService,
    private authService: AuthorizeService,
    private lenderHubService: LenderHubService,
    private lendingTypeService: LendingTypeService,
  ) {}

  public ngOnInit(): void {
    this.getScheduled();
  }

  public get canEditShowProducts() {
    return this.authService.hasRole(roles.product);
  }

  public get productUploadType(): ProductUploadType {
    return this.exclusiveOnly
      ? ProductUploadType.Exclusive
      : ProductUploadType.Core;
  }

  public get manageScheduledProductsTitle(): string {
    return `Manage scheduled ${this.productsTypeDisplayString} products`;
  }

  public get manageScheduledBtnText(): string {
    return `View scheduled ${this.productsTypeDisplayString} products`;
  }

  public get getAllProductsLink(): string {
    return this.isBridging ? '/bridging-all-products' : '/all-products';
  }

  public get areProductVariableRateSchedulesLinked() {
    // If the revert rate schedule occurs at the same time as the products schedule, they will be 'linked', and so
    // rescheduling or deleting the products schedule will also reschedule/delete revert rate schedule respectively.
    return this.variableRateCodes.enabled
      && this.variableRateCodes.scheduledDate
      && isEqual(parseISO(this.variableRateCodes.scheduledDate), this.scheduleDateTime);
  }

  private get productsTypeDisplayString(): string {
    return this.exclusiveOnly
      ? 'Exclusive'
      : this.isBridging ? 'Bridging' : 'Residential and Buy To Let';
  }

  public get uploadCardTitle(): string {
    return `Upload ${this.productsTypeDisplayString} products`;
  }

  public get viewProductsLink(): string {
    return `/all-products${this.exclusiveOnly ? '?exclusiveOnly=true' : ''}`;
  }

  public uploadFile(files: FileList) {
    this.fileUploaded.emit({
      files,
      uploadType: this.productUploadType,
    });
  }

  public hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  public get lenderName(): LenderName | null {
    return this.lenderHubService.lenderName;
  }

  public get lendingTypes() {
    return this.lendingTypeService.lendingTypes;
  }

  public showProductsStatusChange(status: {value: boolean; lendingTypeCode: string}) {
    this.showProductsChange(status.value, status.lendingTypeCode);
  }

  public showProductsChange(newValue: boolean, lendingType: string) {
    const productType = this.productsVisibilityStates?.find((type) => type.lendingTypeCode === lendingType);
    if (productType) {
      productType.showProducts = newValue;
      this.productService
        .setProductLendingTypeVisible(lendingType, newValue)
        .subscribe(() =>
          this.toastService.success(`${newValue ? 'Shown' : 'Hidden'} ${productType.lendingTypeName} products.`),
        );
    }
  }

  public formatLendingTypeString(lendingTypeName: string) {
    return lendingTypeName.split(' ').join('').toLowerCase();
  }

  private getScheduled() {
    this.productService.getScheduled(this.productCollectionType, this.productUploadType).subscribe((schedules) => {
      if (schedules.future) {
        this.hasSchedule = true;
        this.uploadDateTime = schedules.future.uploadTime
          ? format(parseISO(schedules.future.uploadTime), 'dd/MM/yyyy HH:mm')
          : null;
        this.startDateTime = schedules.future.startTime
          ? format(parseISO(schedules.future.startTime), 'dd/MM/yyyy HH:mm')
          : null;
        this.scheduleDateTime = schedules.future.startTime
          ? new Date(schedules.future.startTime)
          : new Date;
        this.scheduleId = schedules.future.id.toString();
      } else {
        this.hasSchedule = false;
      }
    });
  }

  public deleteScheduleModal() {
    const variableRateStr = this.areProductVariableRateSchedulesLinked ? ' and revert rate' : '';
    this.modalService
      .open({
        title: 'Delete schedule',
        message: `Are you sure you want to delete the products${variableRateStr} scheduled to go live on ` +
          `${this.startDateTime}? If you click  no, your current range of live products will remain available on ` +
          'Legal & General Ignite until replaced.',
        size: 'md',
        showButtons: true,
        okLabel: 'Yes, delete schedule',
        cancelLabel: 'No, go back',
      })
      .then(() => this.deletedScheduledProducts(this.scheduleId), () => null);
  }

  public rescheduleScheduleModal() {
    this.modalService.open({
      title: 'Reschedule',
      template: this.rescheduleUploadModalTemplateRef,
      size: 'md',
    })
      .then((rescheduleResult: any) => {
        if (rescheduleResult.success) {
          this.rescheduledScheduledProducts(this.scheduleId, rescheduleResult.schedule);
        }
      }, () => null);
  }

  private rescheduledScheduledProducts(scheduleId: string | null, scheduleDateTime: string) {
    const errorMessage = 'Unable to reschedule products.';
    if (!!scheduleId && this.scheduleDateTime.getTime() > new Date().getTime()) {
      this.productService.updateSchedule(this.productCollectionType, scheduleId, scheduleDateTime).subscribe({
        next: () => {
          this.toastService.success('Products schedule successfully updated.');
          this.refreshData.emit();
          this.getScheduled();
        },
        error: () => {
          this.toastService.danger(errorMessage);
        },
      });
    } else {
      this.toastService.danger(errorMessage);
    }
  }

  private deletedScheduledProducts(scheduleId: string | null) {
    if (!scheduleId) {
      return;
    }
    this.productService.deleteSchedule(this.productCollectionType, scheduleId).subscribe(() => {
      this.refreshData.emit();
      this.getScheduled();
    });
  }
}
