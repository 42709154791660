import { Component } from '@angular/core';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgClass, NgIf } from '@angular/common';

@Component({
  templateUrl: 'currency-multi-add.component.html',
  styleUrls: ['currency-multi-add.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgxMaskModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
})
export class CurrencyMultiAddFieldTypeComponent extends MssFieldBaseFieldTypeComponent {
  public static defaultOptions: FormlyFieldConfig = {
    defaultValue: 0,
    props: {
      addonStart: '£',
      min: 0,
      ...MssFieldBaseFieldTypeComponent.focusBlurZeroDefault,
    },
  };

  public get numColumns(): number {
    return this.props.numColumns ?? 3;
  }
}
