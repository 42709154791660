import { Component, EventEmitter, Input, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'lib-search-input',
  styleUrls: ['search-input.component.scss'],
  templateUrl: 'search-input.component.html',
  standalone: true,
  imports: [FormsModule, NgbTypeahead],
})
export class SearchInputComponent {
  @Input() public id: string;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public model = '';
  @Output() public searchChanged = new EventEmitter();

  public search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.searchChanged.emit(term)),
    );
}
