<div [formGroup]="additionalInformation">
  <div class="mb-4 row">
    <label class="col-12" for="additionalInformationText">Please provide any additional information wording:</label>
    <div class="col-12">
      <app-esis-html-editor
        id="additionalInformationText"
        [control]="additionalInformation.get('text')"
        placeholder="Additional information wording"
        [isRequired]="true" />
      <div *ngIf="invalid('text') && text?.errors?.['required']" class="invalid-feedback">
        Additional information wording is required.
      </div>
    </div>
  </div>
</div>
