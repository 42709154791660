/* eslint-disable no-empty-function */
import { NgClass, NgFor } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface RadioOption {
  label?: string;
  icon?: string;
  value: unknown;
}

@Component({
  selector: 'lib-radio-group',
  templateUrl: 'radio-group.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    // eslint-disable-next-line no-use-before-define
    useExisting: forwardRef(() => RadioGroupComponent),
  }],
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    FormsModule,
  ],
})
export class RadioGroupComponent implements ControlValueAccessor {
  @Input() public id: string;
  @Input() public options: RadioOption[] = [];
  @Input() public required = false;
  @Input() public containerClass: NgClass['ngClass'] = '';
  @Input() public selectedValue: unknown;
  private onChange: (value: unknown) => void;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public writeValue(value: unknown): void {
    this.selectedValue = value;
  }

  public selectValue(value: unknown) {
    this.selectedValue = value;
    this.onChange?.(value);
  }

  public trackByIndex(index: number) {
    return index;
  }

  // These are required for `ControlValueAccessor`
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched(): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public setDisabledState(): void {}
}
