import { Component } from '@angular/core';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgClass, NgIf } from '@angular/common';

@Component({
  templateUrl: 'currency.component.html',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgxMaskModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
})
export class CurrencyFieldTypeComponent extends MssFieldBaseFieldTypeComponent {
  public static defaultOptions: FormlyFieldConfig = {
    defaultValue: 0,
    props: {
      addonStart: '£',
      min: 0,
      ...MssFieldBaseFieldTypeComponent.focusBlurZeroDefault,
    },
  };
}
