import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Animations } from '@msslib/animations';
import { RulesEngineConditionEditorComponent } from '@msslib/components';
import { Condition, RulesEngineSchema, validateCondition } from '@msslib/models/rules-engine';
import { ModalService } from '@msslib/services';

@Component({
  selector: 'app-flexible-features-incentives-display-rules-modal',
  templateUrl: 'flexible-features-incentives-display-rules-modal.component.html',
  animations: [Animations.fadeInOut({ inDuration: '0.2s', outDuration: '2s' })],
  standalone: true,
  imports: [RulesEngineConditionEditorComponent],
})
export class FlexibleFeaturesIncentivesDisplayRulesModalComponent {
  @Input({ required: true }) public schema: RulesEngineSchema;
  @Input({ required: true }) public condition: Condition | null;
  @Output() public conditionSaved = new EventEmitter<Condition | null>();
  @Input() public disabled = false;

  public showErrors = false;
  public showSaveSuccessMessage = false;
  private hasUnsavedChanges = false;

  public constructor(
    private readonly modalService: ModalService,
  ) {}

  public onConditionChange(condition: Condition | null) {
    this.condition = condition;
    this.hasUnsavedChanges = true;
  }

  public apply() {
    const isConditionValid = this.condition === null
      || (validateCondition(this.condition, this.schema, false)?.length ?? 0) === 0;

    this.showErrors = !isConditionValid;
    if (isConditionValid) {
      this.conditionSaved.emit(this.condition);
      this.hasUnsavedChanges = false;
      this.showSaveSuccessMessage = true;
      setTimeout(() => this.showSaveSuccessMessage = false,3000);
    }
  }

  /**
   * Determines whether the modal or tab can be closed.
   * If there are unsaved changes, shows the 'Discard changes?' modal window and waits for a response.
   * @returns `true` if the operation should continue (no changes or user has chosen to discard changes), or `false` if
   * the operation should cancel (user has chosen to NOT discard changes).
   */
  private canClose() {
    if (!this.hasUnsavedChanges) {
      return Promise.resolve(true);
    }

    return this.modalService.open({
      title: 'Discard changes?',
      message: 'You have made changes to this tab which have not yet been saved. ' +
        'Would you like to discard these changes?',
      showButtons: true,
      hideTopClose: true,
      sticky: true,
      size: 'md',
      okLabel: 'No, continue editing', // Use 'No' as the Okay because this should be the 'primary' action
      cancelLabel: 'Yes, discard changes', // Use 'Yes' as the cancel because this should be the 'secondary' action
    }).then(() => false, () => true);
  }

  public dismiss() {
    this.canClose().then(canClose => {
      if (canClose) {
        this.modalService.close();
      }
    });
  }
}
