import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';

import { FormsValidators } from '@msslib/components/forms/validators/forms.validators';
import { ModalService, ModalStateService } from '@msslib/services';
import { Frequency } from 'apps/shared/src/models';
import { FormFieldType } from '../formly/formly.config';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-add-option-modal',
  templateUrl: 'add-option-modal.component.html',
  styleUrls: ['add-option-modal.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
  ],
})
export class AddOptionModalComponent implements OnInit {
  public form = new UntypedFormGroup({});
  public fields: FormlyFieldConfig[];

  public formOptions: FormlyFormOptions = {};

  public constructor(
    @Inject(ModalStateService) private modalStateService,
    @Inject(ModalService) private modalService,
  ) {}

  public ngOnInit() {
    const { optionLabel, options } = this.options.data;
    this.fields = [
      {
        key: 'category',
        type: 'select',
        defaultValue: null,
        props: {
          label: optionLabel,
          options,
          required: true,
        },
      },
      {
        key: 'amount',
        type: FormFieldType.Currency,
        props: {
          label: 'Monthly amount',
          required: true,
        },
        validators: {
          validation: [FormsValidators.greaterThan(0)],
        },
      },
    ];
  }

  public get options() {
    return this.modalStateService.options;
  }

  public submit() {
    if (this.form.valid) {
      const { category, amount } = this.form.value;
      if (!this.options.data.model) {
        this.options.data.model = [];
      }
      this.options.data.model.push({ option: { category, amount, frequency: Frequency.Monthly } });
      this.modalService.close();
    }
  }

  public cancel() {
    this.modalService.dismiss();
  }
}
