import { Component, Input, OnInit } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';

import { FieldTypes, IFieldConfig } from '@msslib/models/forms';
import { ModalService } from '@msslib/services/modal.service';
import { MiSaveOutcomeModel } from '../../models';
import { AppFormComponent } from '../forms/components/form/form.component';

@Component({
  selector: 'lib-save-outcome-modal',
  styleUrls: ['save-outcome-modal.component.scss'],
  templateUrl: 'save-outcome-modal.component.html',
  standalone: true,
  imports: [AppFormComponent],
})
export class SaveOutcomeModalComponent implements OnInit {
  @Input() public editItem: MiSaveOutcomeModel;
  @Input() public notesOnly: boolean;
  public config: IFieldConfig[];

  public constructor(private modalService: ModalService) {}

  public ngOnInit() {
    this.config = [
      {
        name: 'surname',
        label: 'Surname',
        type: FieldTypes.Textbox,
        validation: [Validators.required],
        hidden: this.notesOnly,
        value: this.editItem?.surname ?? '',
      },
      {
        name: 'postcode',
        label: 'Postcode (2-4 characters)',
        type: FieldTypes.Textbox,
        validation: [Validators.required, Validators.minLength(2), Validators.maxLength(4)],
        errorMessages: {
          minLength: 'Postcode must be 2-4 characters',
          maxLength: 'Postcode must be 2-4 characters',
        },
        hidden: this.notesOnly,
        value: this.editItem?.postcode ?? '',
      },
      {
        name: 'notes',
        label: 'Notes',
        type: FieldTypes.TextArea,
        value: this.editItem?.notes ?? '',
      },
      {
        name: 'submit',
        label: 'Save',
        type: FieldTypes.ButtonGroup,
        onSubmit: this.submit.bind(this),
        onCancel: this.cancel.bind(this),
      },
    ];
  }

  public submit(form: NgForm) {
    if (form.valid) {
      this.modalService.close(form.value);
    }
  }

  public cancel() {
    this.modalService.dismiss();
  }
}
