import { Injectable } from '@angular/core';
import { Availability, MatchedLenderProducts, Product, ProductSourcingRule }
  from 'apps/shared/src/models/matched-product';
import {
  productAvailabilityFilterFor,
} from 'apps/clubhub/src/app/ignite/services/products-filter/products-filter-rules';
import {
  ProductSourcingConditionType,
} from 'apps/clubhub/src/app/ignite/models/products/product-sourcing-condition-type';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as ProductFiltersConstants from 'apps/clubhub/src/app/ignite/constants/product-filters';
import { IgniteHelperService } from '@msslib/services/ignite.helper';

@Injectable({
  providedIn: 'root',
})
export class SourcingProductsFilterService {

  private sourcingTypesMap = new Map<string, ProductSourcingConditionType>([
    [ ProductFiltersConstants.expatNotInUkFilterValue, ProductSourcingConditionType.ExpatNotInUk ],
    [ ProductFiltersConstants.greenEcoFilterValue, ProductSourcingConditionType.GreenEco ],
    [ ProductFiltersConstants.offsetFilterValue, ProductSourcingConditionType.Offset ],
    [ ProductFiltersConstants.secondResidentialFilterValue, ProductSourcingConditionType.SecondResidential ],
    [ ProductFiltersConstants.regulatedBTLFilterValue, ProductSourcingConditionType.RegulatedBuyToLet ],

    // Backend sourcing rule mapping (although we don't apply BE overrides again, we do need this for the tooltip):
    [ ProductFiltersConstants.hmoFilterValue, ProductSourcingConditionType.Hmo ],
    [ ProductFiltersConstants.limitedCompanyPurchaseFilterValue, ProductSourcingConditionType.LimitedCompanyPurchase ],
    // eslint-disable-next-line spellcheck/spell-checker
    [ ProductFiltersConstants.multiUnitFreeholdBlockFilterValue, ProductSourcingConditionType.Mufb ],
    [ ProductFiltersConstants.newBuildFilterValue, ProductSourcingConditionType.NewBuild ],
    [ ProductFiltersConstants.portfolioLandlordFilterValue, ProductSourcingConditionType.PortfolioLandlord ],
    [
      ProductFiltersConstants.jointBorrowerSoleProprietorFilterValue,
      ProductSourcingConditionType.JointBorrowerSoleProprietor,
    ],
    [ ProductFiltersConstants.retirementInterestOnlyFilterValue, ProductSourcingConditionType.RetirementInterestOnly ],
    [ ProductFiltersConstants.rightToBuyFilterValue, ProductSourcingConditionType.RightToBuy ],
    [ ProductFiltersConstants.selfBuildFilterValue, ProductSourcingConditionType.SelfBuild ],
    [ ProductFiltersConstants.sharedEquityFilterValue, ProductSourcingConditionType.SharedEquity ],
    [ ProductFiltersConstants.sharedOwnershipFilterValue, ProductSourcingConditionType.SharedOwnership ],
  ]);

  private filtersTitlesMap = new Map<string, string>([
    [ ProductFiltersConstants.expatNotInUkFilterTitle, ProductFiltersConstants.expatNotInUkFilterValue ],
    [ ProductFiltersConstants.greenEcoFilterTitle, ProductFiltersConstants.greenEcoFilterValue ],
    [ ProductFiltersConstants.offsetFilterTitle, ProductFiltersConstants.offsetFilterValue ],
    [ ProductFiltersConstants.secondResidentialFilterTitle, ProductFiltersConstants.secondResidentialFilterValue ],
    [ ProductFiltersConstants.regulatedBTLFilterTitle, ProductFiltersConstants.regulatedBTLFilterValue ],
  ]);

  // Inverse map of sourcingTypesMap
  private sourcingConditionTypeToPropertyMap = new Map([...this.sourcingTypesMap.entries()].map(([k, v]) => [v, k]));

  private productFieldsSearchRequestMap = new Map<string, string>([
    [ 'maxLtv', 'ltv' ],
    [ 'minLtv', 'ltv' ],
    [ 'minLoanPolicy', 'loanAmount' ],
    [ 'maxLoanPolicy', 'loanAmount' ],
  ]);

  private overrodeProducts = new Map<number, Partial<Product>>();

  public constructor(
    private helperService: IgniteHelperService,
  ) { }

  public sourcingCriteriaFilterFor(property: keyof Product)
    : Record<'isMatchFunc' | 'isUnknownFunc' | 'isExcludeFunc', ((p: Product, matchedLenders?: any) => boolean)> {
    return {
      isMatchFunc: this.getIsMatchFunc(property),
      isUnknownFunc: (p: Product, includedFiltersNames: string[] | null = null) => {
        this.restoreProductIfNeeded(p, includedFiltersNames);
        // Ignore Unknown filter for Sourcing rules based on requirements from MCONE-5358
        return false;
      },
      isExcludeFunc: (p: Product, includedFiltersNames: string[] | null = null) => {
        this.restoreProductIfNeeded(p, includedFiltersNames);
        if (p[property] === null) {
          return true;
        }
        return productAvailabilityFilterFor(property).isExcludeFunc(p);
      },
    };
  }

  public clear() {
    this.overrodeProducts.clear();
  }

  public reset(products?: Product[] | null) {
    if (!products?.length) {
      return;
    }

    const overrodeProducts = products
      .filter(p => !!p.id && this.overrodeProducts.has(p.id));
    overrodeProducts.forEach(product => this.restoreProductIfNeeded(product, null));
  }

  private restoreProductIfNeeded(
    product: Product,
    includedFiltersNames: string[] | null = null,
  ) {
    if (!includedFiltersNames?.length && product.id) {
      const originalProduct = this.overrodeProducts.get(product.id);
      if (originalProduct) {
        Object.keys(originalProduct).forEach(propertyName => {
          product[propertyName] = originalProduct[propertyName];
        });
      }
    }
  }

  private getIsMatchFunc(property: keyof Product): ((p: Product) => boolean) {
    const isMatchFunc = (
      product: Product,
      matchedProducts: MatchedLenderProducts[] | null = null,
      includedFiltersNames: string[] | null = null,
      productsModelRequest: any = null,
    ) => {
      const propertyAvailability = product[property] as Availability;

      // Apply only for "AlsoAvailable" and "NULL"
      if (propertyAvailability === Availability.OnlyAvailable || propertyAvailability === Availability.No) {
        return productAvailabilityFilterFor(property).isMatchFunc(product);
      }

      if (matchedProducts?.length && product.lender && includedFiltersNames) {
        const sourcingRules = this.getSourcingRules(includedFiltersNames, product.lender, matchedProducts);
        if (!sourcingRules) {
          return true;
        }

        const isSomeLending = sourcingRules?.every(r => r.someLending);
        if (!isSomeLending) {
          return false;
        }

        const productFieldsOverrides = this.prepareOverrides(sourcingRules);
        const searchRequest = this.prepareSearchRequest(productFieldsOverrides, productsModelRequest);
        if (!this.validateOverrides(searchRequest, productFieldsOverrides)) {
          return false;
        }

        // it makes sense when product is valid for override
        this.applyOverrides(product, productFieldsOverrides);
        return true;
      }

      return true;
    };

    return isMatchFunc;
  }

  private getSourcingRules(selectedFilters: string[], lenderName: string, matchedProducts: MatchedLenderProducts[]) {
    const sourcingRules = matchedProducts.find(mp => mp.lenderName === lenderName)?.sourcingRules;

    // Use default filter when lender doesn't have sourcing rules
    if (!sourcingRules || !Object.keys(sourcingRules).length) {
      return null;
    }

    const filterRules = selectedFilters
      .map(filterTitle => {
        const filterName = this.filtersTitlesMap.get(filterTitle);
        if (!filterName) {
          return null;
        }

        const sourceType = this.sourcingTypesMap.get(filterName);

        if (!sourceType) {
          return;
        }
        const typeName = ProductSourcingConditionType[sourceType];
        return sourcingRules[typeName];

      }).filter(r => !!r);

    return filterRules as ProductSourcingRule[];
  }

  private prepareOverrides(sourcingRules: ProductSourcingRule[]): Partial<Product> {
    const sourceOverrides = sourcingRules.map(p => p.overrides);
    const fieldsMap = new Map<string, number>();

    sourceOverrides.forEach(overrides => {
      const overridesKeys = Object.keys(overrides);

      overridesKeys.forEach(overrideKey => {
        if (fieldsMap.has(overrideKey)) {
          const value = overrides[overrideKey] as number;
          const existedValue = fieldsMap.get(overrideKey) as number;

          if (overrideKey.startsWith('min')) {
            // set min value
            if (value > existedValue) {
              fieldsMap.set(overrideKey, value);
            }
          } else if (overrideKey.startsWith('max')) {
            // set max value
            if (value < existedValue) {
              fieldsMap.set(overrideKey, value);
            }
          }
        } else {
          fieldsMap.set(overrideKey, overrides[overrideKey]);
        }
      });
    });

    const productOverrides = {
      ...Object.fromEntries(fieldsMap),
    } as Partial<Product>;
    return productOverrides;
  }

  private applyOverrides(product: Product, overrides: Partial<Product>) {
    // Reset the product before applying overrides, because otherwise previous rules may remain applied to a product
    // when disabling that rule.
    this.restoreProductIfNeeded(product);

    const overridesKeys = Object.keys(overrides);
    for (let i = 0; i < overridesKeys.length; i++) {
      const propertyName = overridesKeys[i];
      this.ensureOriginalProductFieldSaved(product, propertyName);

      const sourcingRuleValue = overrides[propertyName];
      const productPropertyValue = product[propertyName];

      // When overriding `min` fields, we treat `null` as no limit (i.e. a low value)
      if (propertyName.startsWith('min') && (productPropertyValue ?? -Infinity) < (sourcingRuleValue ?? -Infinity)) {
        product[propertyName] = sourcingRuleValue;
        continue;
      }

      // When overriding `max` fields, we treat `null` as no limit (i.e. a high value)
      if (propertyName.startsWith('max') && ((productPropertyValue ?? Infinity) > (sourcingRuleValue ?? Infinity))) {
        product[propertyName] = sourcingRuleValue;
      }
    }

    return true;
  }

  private prepareSearchRequest(overrides: Partial<Product>, productsModelRequest: any = null) {
    const searchRequest = {} as Partial<Product>;

    Object.keys(overrides).forEach(propertyName => {
      searchRequest[propertyName] = this.getSearchRequestValueByPropName(propertyName, productsModelRequest);
    });

    return searchRequest;
  }

  private validateOverrides(searchRequest: Partial<Product>, overrides: Partial<Product>) {
    const overridesKeys = Object.keys(overrides);
    for (let i = 0; i < overridesKeys.length; i++) {
      const propertyName = overridesKeys[i];
      const sourcingRuleValue = overrides[propertyName];
      const searchRequestValue = searchRequest[propertyName];

      // Don't run the filter if either:
      // - The search request value is missing (e.g. the broker didn't fill a loan amount in the form)
      // - OR the sourcing rule value is null, meaning that there is no limit
      if (!searchRequestValue || !sourcingRuleValue) {
        continue;
      }

      if (propertyName.startsWith('min')) {
        if (searchRequestValue < sourcingRuleValue) {
          return false;
        }

        continue;
      }

      if (propertyName.startsWith('max')) {
        if (searchRequestValue > sourcingRuleValue) {
          return false;
        }
      }
    }

    return true;
  }

  private ensureOriginalProductFieldSaved(product: Product, propertyName: string) {
    if (!!product.id) {
      const savedEntry = this.overrodeProducts.get(product.id);
      if (savedEntry && !(propertyName in savedEntry)) {
        savedEntry[propertyName] = product[propertyName];
        this.overrodeProducts.set(product.id, savedEntry);
      } else if (!savedEntry) {
        const newEntry = {
          [propertyName]: product[propertyName],
        };

        this.overrodeProducts.set(product.id, newEntry);
      }
    }
  }

  private getSearchRequestValueByPropName(propertyName: string, productsModelRequest: any = null) {
    if (!productsModelRequest) {
      return null;
    }

    const requestPropertyName = this.productFieldsSearchRequestMap.get(propertyName);
    if (!requestPropertyName) {
      return null;
    }

    switch (requestPropertyName) {
      case 'loanAmount':
        return productsModelRequest['loanAmount'];
      case 'ltv':
        return this.helperService.calculateLtv(productsModelRequest);
      default: return null;
    }
  }

  public getOriginalProduct(productId: number) {
    return this.overrodeProducts.get(productId);
  }

  /** Determines the product criteria rules that have been applied to the given product. */
  public getActiveOverrides(
    product: Product,
    propertyName: keyof Product,
    matchedProducts: MatchedLenderProducts[] | null,
    activeFilterTitles: string[],
  ) {
    // Get rules for this lender
    const lenderRules = matchedProducts
      ?.find(lp => lp.lenderName === product.lender)?.sourcingRules;
    if (!lenderRules) {
      return [];
    }

    // List of selected front-end filters
    const activeFilterTypeNames = activeFilterTitles
      .map(filterTitle => {
        const filterName = this.filtersTitlesMap.get(filterTitle);
        const sourceType = filterName && this.sourcingTypesMap.get(filterName);
        return sourceType ? ProductSourcingConditionType[sourceType] : null;
      })
      .filter(String) as string[];

    // Get the rules that may adjust the target property (e.g. if we're looking for overrides of maxLtv, find all the
    // rules that override the maxLtv value) and that have the potential of being applied (e.g. for the NewBuild filter
    // it would not get applied to NewBuild=Only product, so ignore that rule also)
    let overrides = Object.entries(lenderRules)
      // Filter out rules that do not affect the property we are looking for
      .filter(([, rule]) => propertyName in rule.overrides)

      // Filter out rules that don't get applied due to 'Only' available (e.g. remove NewBuild if product.NewBuild=Only)
      .filter(([typeName]) => {
        const key = ProductSourcingConditionType[typeName];
        return !this.sourcingConditionTypeToPropertyMap.has(key)
          || product[this.sourcingConditionTypeToPropertyMap.get(key) as string] !== Availability.OnlyAvailable;
      })

      // Return backend rules, plus those that are active based on the filters the broker has selected
      .filter(([typeName, rule]) => activeFilterTypeNames.includes(typeName) || rule.isBackendRule)

      .map(([, rule]) => ({ label: rule.name, value: rule.overrides[propertyName] }))

      .sort((a, b) => a.label.localeCompare(b.label));

    const propertyBaseValue = product.baseValues && product.baseValues[propertyName];
    // Don't display overrides which don't affect product property
    if (propertyBaseValue) {
      if (propertyName.startsWith('max')) {
        overrides = overrides.filter(o => !!o.value && o.value < propertyBaseValue);
      } else if (propertyName.startsWith('min')) {
        overrides = overrides.filter(o => !!o.value && o.value > propertyBaseValue);
      }
    }

    return overrides;
  }
}
