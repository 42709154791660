<form [formGroup]="form">
  <table>
    <thead>
    <tr>
      <th>Property Value From</th>
      <th>Property Value To</th>
      <th>Valuation Fee</th>
      <th class="actions-column">Actions</th>
    </tr>
    </thead>
    <tbody formArrayName="entries">
    <tr *ngFor="let entry of entries.controls; let i = index" [formGroupName]="i">
      <td class="input-cell">
        <div
          class="input-group my-1"
          [class.is-invalid]="getRowErrors(i)?.propertyPriceFrom">
          <span class="input-group-text">£</span>
          <input
            formControlName="propertyPriceFrom"
            (focus)="onFocusRow(i)"
            min="0"
            class="form-control"
            type="number"
            (keydown)="preventUnwanted($event)"
            [attr.data-testid]="'property_price_from_' + i" />
        </div>
        <div class="error-message">
          <span class="me-1" *ngIf="getRowErrors(i)?.propertyPriceFrom">
                {{ getRowErrors(i)?.propertyPriceFrom }}
              </span>
        </div>
      </td>
      <td class="input-cell">
        <div
          class="input-group my-1"
          [class.is-invalid]="getRowErrors(i)?.propertyPriceTo">
          <span class="input-group-text">£</span>
          <input
            formControlName="propertyPriceTo"
            (focus)="onFocusRow(i)"
            min="0"
            class="form-control"
            type="number"
            (keydown)="preventUnwanted($event)"
            [attr.data-testid]="'property_price_to_' + i" />
        </div>
        <div class="error-message">
          <span class="me-1" *ngIf="getRowErrors(i)?.propertyPriceTo">
                {{ getRowErrors(i)?.propertyPriceTo }}
              </span>
        </div>
      </td>
      <td class="input-cell">
        <div
          class="input-group my-1"
          [class.is-invalid]="getRowErrors(i)?.fee">
          <span class="input-group-text">£</span>
          <input
            formControlName="fee"
            (focus)="onFocusRow(i)"
            min="0"
            class="form-control"
            type="number"
            (keydown)="preventUnwanted($event)"
            [attr.data-testid]="'fee_' + i" />
        </div>
        <div class="error-message">
              <span class="me-1" *ngIf="getRowErrors(i)?.fee">
                {{ getRowErrors(i)?.fee }}
              </span>
        </div>
      </td>
      <td>
        <button
          class="btn btn-sm btn-outline-2022--secondary me-2 action-btn"
          (click)="addEntry(i)"
          [disabled]="isForView">
          Add row below
        </button>
        <button
          class="btn btn-sm btn-outline-danger action-btn"
          (click)="deleteEntry(i)"
          [disabled]="isForView"
          title="Delete row">
          Delete
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="isForView && valuationFeeScale.isScheduled" class="schedule-info schedule-info-text">
    Scheduled to go live on {{ valuationFeeScale.startDate | date: 'dd/MM/yyyy HH:mm' }}
  </div>
  <div class="col pb-5">
    <div class="d-flex flex-column col-10 mt-4">
      <p class="mb-1">Select fee scale condition</p>
      <ng-container *ngFor="let type of valuationFeeScaleConditionTypes;">
        <div class="form-check form-check-inline">
          <input
            formControlName="condition"
            class="form-check-input me-1"
            type="radio"
            [value]="type.value"
            [attr.data-testid]="'fee_scale_condition_type_radio_' + type.value">
          <label class="form-check-label">
            {{ type.label }}
          </label>
        </div>
      </ng-container>
    </div>
    <div class="edit-button">
      <button *ngIf="isForView" class="preview-submit btn btn-2022--secondary" (click)="editClick()"
              type="button">Edit
      </button>
      <button *ngIf="!isForView" class="preview-submit btn btn-2022--secondary" (click)="saveValuationScales()"
              type="button">Save
      </button>
    </div>
  </div>
</form>

<ng-template #valuationFeeScalesPreSaveDialogTemplate>
  <div *ngIf="!showConfirmStep; else confirmStep" class="pre-save-dialog">
    <div class="pre-save-dialog-content">
      Valuation scales and fees saved successfully.
    </div>
    <div class="pre-save-dialog-footer">
      <button class="btn btn-2022--secondary btn-sm" (click)="goBackPreSaveDialogClick()">Go Back</button>
      <button class="btn btn-2022--secondary btn-sm" (click)="setLivePreSaveDialogClick()">Set Live</button>
    </div>
  </div>
  <ng-template #confirmStep>
    <div class="pre-save-dialog">
      <lib-event-schedule [scheduleForm]="scheduleForm"
                          [alignItems]="'end'">
      </lib-event-schedule>
      <div class="pre-save-dialog-footer">
        <button class="btn btn-2022--secondary btn-sm" (click)="goBackPreSaveDialogClick()">Go Back</button>
        <button class="btn btn-2022--secondary btn-sm" (click)="confirmPreSaveDialogClick()">Confirm</button>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #valuationFeeScalesSaveResultDialogTemplate>
  <div class="save-result-dialog">
    <div class="save-result-dialog-content">
      {{ saveResultMessage }}
    </div>
  </div>
</ng-template>
