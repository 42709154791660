/* eslint-disable no-use-before-define */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import { AuthenticationResultStatus, AuthorizeService, ConfigService } from '../../services';
import { applicationPaths, logoutActions, returnUrlType } from '../../constants';
import { AsyncPipe } from '@angular/common';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
@Component({
  selector: 'lib-logout',
  templateUrl: 'logout.component.html',
  styleUrls: ['logout.component.css'],
  standalone: true,
  imports: [AsyncPipe],
})
export class LogoutComponent implements OnInit {
  public message = new BehaviorSubject<string | null>(null);

  public constructor(
    private authorizeService: AuthorizeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
  ) {}

  public async ngOnInit() {
    const action = this.activatedRoute.snapshot.url[1];
    switch (action.path) {
      case logoutActions.logout:
        if (!!window.history.state.local) {
          await this.logout(this.getReturnUrl());
        } else {
          // This prevents regular links to <app>/authentication/logout from triggering a logout
          this.message.next('The logout was not initiated from within the page.');
        }

        break;
      case logoutActions.logoutCallback:
        await this.processLogoutCallback();
        break;
      case logoutActions.loggedOut:
        this.message.next('You have successfully logged out!');
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }

  private async logout(returnUrl: string): Promise<void> {
    const state: INavigationState = { returnUrl };
    const isAuthenticated = await firstValueFrom(this.authorizeService
      .isAuthenticated()
      .pipe(take(1)));
    if (isAuthenticated) {
      const result = await this.authorizeService.signOut(state);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          await this.navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          this.message.next(result.message);
          break;
        default:
          throw new Error('Invalid authentication result status.');
      }
    } else {
      this.message.next('You have successfully logged out!');
    }
  }

  private async processLogoutCallback(): Promise<void> {
    const result = await this.authorizeService.completeSignOut(this.configService.config.identityServerUrl);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeAuthentication finishes
        // is when we are doing a redirect sign in flow.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        this.message.next(result.message);
        break;
      default:
        throw new Error('Invalid authentication result status.');
    }
  }

  private async navigateToReturnUrl(returnUrl: string) {
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (this.activatedRoute.snapshot.queryParams as INavigationState).returnUrl;
    // If the url is coming from the query string, check that is either
    // a relative url or an absolute url
    if (fromQuery && !fromQuery.startsWith('/') && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return ((state?.returnUrl) ?? fromQuery) || applicationPaths.loggedOut;
  }
}

interface INavigationState {
  [returnUrlType]: string;
}
