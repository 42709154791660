import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lib-form-input-group',
  styleUrls: ['form-input-group.component.scss'],
  templateUrl: 'form-input-group.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgClass,
    FormFieldErrorComponent,
  ],
})
export class FormInputGroupComponent extends FieldBaseComponent {}
