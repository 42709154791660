import { NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';

@Component({
  templateUrl: 'disabled.component.html',
  standalone: true,
  imports: [NgIf, NgClass],
})
export class DisabledFieldTypeComponent extends MssFieldBaseFieldTypeComponent {
  public get fieldAddonStart(): string {
    return this.props.addonStart;
  }

  public get fieldAddonStartClass(): NgClass['ngClass'] {
    return this.props.addonStartClass ?? '';
  }

  public get fieldAddonEnd(): string {
    return this.props.addonEnd;
  }

  public get fieldAddonEndClass(): NgClass['ngClass'] {
    return this.props.addonEndClass ?? '';
  }
}
