import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { FormsValidators } from '../validators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[requiredHtml]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line no-use-before-define
      useExisting: RequiredHtmlValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class RequiredHtmlValidatorDirective implements Validator {
  @Input() public requiredHtml: boolean;

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.requiredHtml ? FormsValidators.requiredHtml(control) : null;
  }
}
