import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-lenderhub-home-card',
  styleUrls: ['home-card.component.scss'],
  templateUrl: 'home-card.component.html',
  standalone: true,
  imports: [NgIf],
})
export class LenderHubHomeCardComponent {
  @Input() public image: string;
  @Input() public title: string;
}
