import { RepaymentMethod } from './repayment-method';

export enum LendingTypeCode {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _Unset = '',
  Res = 'RES',
  Btl = 'BTL',
  Bdg = 'BDG',
}

export enum UkLocation {
  EastMidlands = 1,
  EastOfEngland = 2,
  GreaterLondon = 3,
  NorthEast = 4,
  NorthWest = 5,
  SouthEast = 6,
  SouthWest = 7,
  WestMidlands = 8,
  YorkshireAndHumberside = 9,
  NorthernIreland = 10,
  Scotland = 11,
  Wales = 12,
}
export enum PropertyType {
  House = 1,
  Flat = 2,
}

export enum TaxStatus {
  BasicRateTaxPayer = 1,
  HighRateTaxPayer = 2,
}

export enum TaxStatusBtl {
  NonTaxPayer = 1,
  Starter = 2,
  Basic = 3,
  Intermediate = 4,
  Higher = 5,
  Top = 6,
  Additional = 7,
}

export enum PurchaserType {
  FirstTimeBuyer = 1,
  HomeMover = 2,
}
export enum OwnerOccupier {
  Yes = 1,
  No = 2,
}
export enum FirstTimeBuyer {
  Yes = 1,
  No = 2,
}
export enum RemortgageReason {
  Purchase = 1,
  PurchaseAdditionalShares = 2,
  PoundForPound = 3,
  Other = 4,
}
export enum RemortgageType {
  None = 0,
  LetToBuy = 1,
  LikeForLike = 2,
  CapitalRaising = 3,
}


export function repaymentMethodToString(repaymentMethod: RepaymentMethod | undefined) {
  switch (repaymentMethod) {
    case RepaymentMethod.CapitalAndInterest:
      return 'Capital and Interest';
    case RepaymentMethod.InterestOnly:
      return 'Interest Only';
    case RepaymentMethod.InterestOnlyPartAndPart:
      return 'Interest Only Part and Part';
    default:
      return '';
  }
}

export enum ProductType {
  Standard = 1,
  SharedOwnership = 2,
  RightToBuy = 3,
  SharedEquity = 4,
  SelfBuild = 5,
  RetirementInterestOnly = 6,
  JointBorrowerSoleProprietor = 7,
}

export enum MethodOfRepayment {
  Serviced = 1,
  RolledUp = 2,
  Retained = 3,
}

export enum MortgageLenders {
  Other = '-1',
}

export enum LoanSecurity {
  FirstCharge = 1,
  SecondCharge = 2,
}

export enum RepaymentVehicle {
  SaleOfProperty = 1,
  SaleOfSecondProperty = 2,
  EndowmentsInvestmentsPension = 3,
  SavingsISA = 4,
}
export enum EmploymentStatus {
  Employed = 1,
  SelfEmployed = 2,
  DirectorOrShareholder = 3,
  Homemaker = 4,
  Retired = 5,
  Student = 6,
  Unemployed = 7,
}
export enum ContractType {
  FixedTerm = 1,
  Permanent = 2,
  SubContractorFtc = 3,
  SubContractorOpen = 4,
  Temporary = 5,
}
export enum WorkRelatedIncomeType {
  Bonus = 1,
  Commission = 2,
  Overtime = 3,
  BankWork = 4,
  Bursary = 5,
  FosterIncome = 6,
  Stipend = 7,
  CarAllowance = 8,
  LargeTownAllowance = 9,
  LondonWeighting = 10,
  ShiftAllowance = 11,
}
export enum WorkRelatedIncomeTypeBtl {
  Bonus = 1,
  Commission = 2,
  Overtime = 3,
  CarAllowance = 4,
  ChildBenefit = 5,
  ChildTaxCredits = 6,
  ShiftAllowance = 7,
  UniversalCredit = 8,
  Maintenance = 9,
  DlaOrPip = 10,
  CarersAllowance = 11,
  WorkingTaxCredits = 12,

}

export enum ExpenditureForSubjectPropertyType {
  MonthlyPropertyMaintenance = 1,
  GroundRentServiceCharge = 2,
  Certificates = 3,
  BoilerCover = 4,
}

export enum BenefitIncomeType {
  ChildBenefit = 1,
  ChildTaxCredits = 2,
  WorkingTaxCredits = 3,
  CarersAllowance = 4,
  DlaOrPip = 5,
  UniversalCredit = 6,
  Maintenance = 7,
}
export enum OtherIncomeType {
  InvestmentIncome = 1,
  LodgerIncome = 2,
  RentalIncome = 3,
  Maintenance = 4,
}

export enum Frequency {
  Annually = 1,
  HalfYearly = 2,
  Quarterly = 3,
  Monthly = 4,
  FourWeekly = 5,
  Fortnightly = 6,
  Weekly = 7,
}

export enum HolidayLetFrequency {
  Daily = 1,
  Weekly = 2,
  Fortnightly = 3,
  FourWeekly = 4,
  Monthly = 5,
  Quarterly = 6,
  HalfYearly = 7,
  Annually = 8,
}

export enum ProductLength {
  OneYear = 1,
  TwoYears = 2,
  ThreeYears = 3,
  FourYears = 4,
  FiveYears = 5,
}

export enum ProductLengthExtended {
  OneYear = 1,
  TwoYears = 2,
  ThreeYears = 3,
  FourYears = 4,
  FiveYears = 5,
  FiveYearsPlus = 6,
  ForTerm = 7,
}

export enum BtlType {
  Standard = 1,
  HMO = 2,
  MUFB = 3,
  HolidayLet = 4,
  CommercialProperty = 5,
}

export enum PropertyUse {
  Rental = 1,
  OwnUse = 2,
}

export enum ApplicantCountry {
  England = 1,
  NorthernIreland = 2,
  Scotland = 3,
  Wales = 4,
  OutsideUk = 5,
}

export enum AffordabilitySource {
  ScreenScrape = 'ScreenScrape',
  Asanto = 'Asanto',
  Api = 'Api',
}

export enum SkipListKeys {
  Age = 'Age',
  AgeAtEndOfMortgage = 'AgeAtEndOfMortgage',
  NumberOfIncomesNeeded = 'NumberOfIncomesNeeded',
  EquityValue = 'EquityValue',
  HelpToBuyScheme = 'HelpToBuyScheme',
  HouseOrFlat = 'HouseOrFlat',
  LoanAmount = 'LoanAmount',
  LoanTermYears = 'LoanTermYears',
  Ltv = 'LTV',
  MortgageType = 'MortgageType',
  NewBuild = 'NewBuild',
  NumberOfApplicants = 'NumberOfApplicants',
  PropertyLocation = 'PropertyLocation',
  PropertyValue = 'PropertyValue',
  RemortgageReason = 'RemortgageReason',
  RepaymentVehicle = 'RepaymentVehicle',
  SoleOrJoint = 'SoleOrJoint',
}

export enum AffordabilityResponseErrorStatusCode {
  LtvTooHigh = 'LtvTooHigh',
  TooOld = 'TooOld',
  TimeoutReceivingResult = 'TimeoutReceivingResult',
  ContactLender = 'ContactLender',
  TooManyMortgages = 'TooManyMortgages',
}

export enum NumberOfBedrooms {
  Studio = 0,
  // eslint-disable-next-line @typescript-eslint/no-mixed-enums
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
}

export enum SearchType {
  Product = 1,
  Affordability = 2,
  Property = 3,
  Criteria = 4,
}
