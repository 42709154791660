import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { IFieldConfig } from '@msslib/models';
import { AppFormComponent } from '../form';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-form-field-error, [libFormFieldError]',
  styleUrls: ['form-field-error.component.scss'],
  templateUrl: 'form-field-error.component.html',
  standalone: true,
  imports: [NgIf],
})
export class FormFieldErrorComponent {
  @Input() public fieldConfig: IFieldConfig;
  public constructor(public formComponent: AppFormComponent) {}

  public errorMessage(): string {
    const control: AbstractControl = this.formComponent.controls[this.fieldConfig.name];
    if (control) {
      for (const errorCode in control.errors) {
        if (control.errors.hasOwnProperty(errorCode) && (control.touched || this.formComponent.submitted)) {
          return this.getErrorText(errorCode,
            (this.fieldConfig.errorLabel ?? this.fieldConfig.label ?? ''),
            control.errors[errorCode],
            control);
        }
      }
    }
    return '';
  }

  public get errorKey() {
    return `${this.fieldConfig.name}err`;
  }

  private getErrorText(code: string, errorLabel: string, value: any, control: AbstractControl) {
    const config: any = {
      required: `${errorLabel ?? 'Field'} is required`,
      minLength: `${errorLabel ?? 'Field'} must have a minimum of ${value.requiredLength} characters`,
      maxLength: `${errorLabel ?? 'Field'} must have a maximum of ${value.requiredLength} characters`,
      min: `${errorLabel ?? 'Field'} must be a minimum of ${value.min}`,
      max: `${errorLabel ?? 'Field'} must be a maximum of ${value.max}`,
      email: errorLabel ? `${errorLabel} is invalid` : 'Invalid email address entered',
      telephone: errorLabel ? `${errorLabel} is invalid` : 'Invalid telephone entered',
      date: `${errorLabel ?? 'Field'} is invalid`,
      invalidDomain: `${errorLabel || 'Field'} has an invalid domain`,
      money: 'Invalid amount entered',
      invalidPostcode: 'Postcode is invalid',
      number: `Invalid number entered for the ${errorLabel || 'field'}`,
      numberNotZero: `${errorLabel ?? 'Field'} must be greater than 0`,
      multipleCheckboxRequireAtLeastOne: 'At least one option required',
      multipleCheckboxRequireMoreThanOne: 'More than one options required',
      containsNumbers: `${errorLabel ?? 'Field'} cannot contain numbers`,
      integer: `${errorLabel ?? 'Field'} must be a whole number`,
      positive: `${errorLabel ?? 'Field'} cannot be negative`,
      leadingWhitespace: `${errorLabel ?? 'Field'} must not have leading whitespace`,
      trailingWhitespace: `${errorLabel ?? 'Field'} must not have trailing whitespace`,
      printableCharactersOnly: `${errorLabel ?? 'Field'} contains invalid characters`,
    };
    const customFieldErrorMessage = this.fieldConfig.errorMessages ? this.fieldConfig.errorMessages[code] : null;
    if (typeof customFieldErrorMessage === 'string') {
      return customFieldErrorMessage;
    } else if (typeof customFieldErrorMessage === 'function') {
      return customFieldErrorMessage(control, value);
    }
    return config[code];
  }
}
