import { Component, Input } from '@angular/core';
import { HeadingSize } from '../../models';
import { NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'lib-page-heading',
  styleUrls: ['page-heading.component.scss'],
  templateUrl: 'page-heading.component.html',
  standalone: true,
  imports: [NgSwitch, NgSwitchCase],
})
export class PageHeadingComponent {
  @Input() public text: string;
  @Input() public size: HeadingSize = HeadingSize.H1;

  public headingSize = HeadingSize;
}
