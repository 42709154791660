import { Component } from '@angular/core';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

@Component({
  templateUrl: 'number.component.html',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
})
export class NumberFieldTypeComponent extends MssFieldBaseFieldTypeComponent {
  public static defaultOptions: FormlyFieldConfig = {
    props: {
      ...MssFieldBaseFieldTypeComponent.focusBlurZeroDefault,
    },
  };

  public preventUnwanted(event) {
    if (event.key === 'e' || (!this.props.allowDecimal && event.key === '.')) {
      event.preventDefault();
    }
  }
}
