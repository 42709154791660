<ng-container [ngSwitch]="elementType">
  <!-- For input based types (e.g. string, number) -->
  <input
    *ngSwitchCase="'input'"
    class="form-control form-control-sm border-radius-0"
    [placeholder]="label ?? ''"
    [appAttrFromObj]="inputAttributes"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
    (keydown)="onInputKeyDown($event)"
    [disabled]="disabled"
    [attr.data-testid]="'default_rules_value_input_' + testIdSuffix"
    >

  <!-- For dropdown based types (e.g. enums) -->
  <select
    *ngSwitchCase="'select'"
    class="form-select form-select-sm border-radius-0"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
    [disabled]="disabled"
    [attr.data-testid]="'default_rules_value_select_' + testIdSuffix"

  >
    <option *ngFor="let option of selectOptions" [ngValue]="option.value">
      {{option.displayName}}
    </option>
  </select>
</ng-container>
