<div *ngIf="availableFormTypes" class="container-fluid py-3">
  <div class="d-flex justify-content-center py-3">
    <lib-radio-buttons
      [underLine]="true"
      [value]="selectedFormIndex"
      [list]="availableFormTypes"
      (valueChange)="selectFormType($event)"
    ></lib-radio-buttons>
  </div>

  <div *ngIf="currentLendingTypeId && showForm" class="mx-auto esis">
    <div class="border">
      <div class="top-title py-2">{{ title }}</div>

      <div class="my-2">
        <button
          *ngIf="!isEditMode"
          type="button"
          class="btn btn-2022--secondary me-2"
          (click)="edit()">
          Edit
        </button>

        <button
          *ngIf="isEditMode"
          type="button"
          class="btn btn-2022--secondary me-2"
          (click)="discardChanges()">
          Discard Changes
        </button>

        <!-- Use a separate element for the tooltip because otherwise disabling the button also disables the TT -->
        <div class="d-inline-block" *ngIf="isEditMode" [ngbTooltip]="!esisForm.valid && getButtonTooltip('set live')">
          <button
            *ngIf="isEditMode"
            type="button"
            class="btn btn-2022--secondary me-2"
            [disabled]="!esisForm.valid"
            (click)="onSubmit(true)">
            Set Live
          </button>
        </div>

        <!-- Use a separate element for the tooltip because otherwise disabling the button also disables the TT -->
        <div class="d-inline-block" [ngbTooltip]="!previewButtonEnabled && getButtonTooltip('preview')">
          <button
            type="button"
            class="btn btn-2022--secondary"
            [disabled]="!previewButtonEnabled"
            (click)="showPreviewModal()">
            Preview
          </button>
        </div>
      </div>

      <form [formGroup]="esisForm" (ngSubmit)="onSubmit(false)">
        <div class="accordion m-2" id="accordionContexts">
          <div class="card" *ngFor="let accordion of accordions; index as i">
            <div class="card-header quick-edit" [class.pointer]="accordion.canOpen !== false" [id]="'heading' + i" (click)="toggleSectionOpen(accordion.name)">
              <div class="d-flex align-items-center">
                <div class="my-auto me-auto">
                  <div class="card-title-text fw-bold">
                    {{ accordion.title }}
                  </div>
                </div>
                <div *ngIf="showSectionToggle(accordion.name)" class="me-3">
                  <lib-toggle-switch
                    [checked]="isSectionEnabled(accordion.name)"
                    (changed)="setSectionEnabled(accordion.name, $event)"
                    [disabled]="!isEditMode"
                  ></lib-toggle-switch>
                </div>
                <div class="d-flex" [class.opacity-0]="accordion.canOpen === false" [class.pointer-events-none]="accordion.canOpen === false">
                  <div class="accordion-invalid p-3" *ngIf="isSectionInvalid(accordion.name)" >
                    <i class='fa fa-exclamation-triangle'></i>
                  </div>
                  <button
                    type="button"
                    class="btn my-3 toggle-btn btn-2022--secondary"
                    [class.btn-2022--light-1]="!isSectionEnabled(accordion.name)"
                    [disabled]="!isSectionEnabled(accordion.name)"
                  >
                    <span class="button-text me-2">{{ !isSectionOpen(accordion.name) ? 'Expand' : 'Collapse' }}</span>
                    <i [class]="!isSectionOpen(accordion.name) ? 'fas fa-chevron-down text-end' : 'fas fa-chevron-up text-end'"></i>
                  </button>
                </div>
              </div>
            </div>

            <div [id]="'collapse' + i" [ngbCollapse]="!isSectionOpen(accordion.name)">
              <div [class.m-3]="!accordion.subAccordions?.length" [ngSwitch]="accordion.name">
                <ng-container *ngSwitchCase="accordionNames.Introduction">
                  <app-single-text-editor
                    [id]="'introduction'"
                    [formGroup]="esisForm.controls.introduction"
                    [placeholder]="'Introduction wording'"
                  ></app-single-text-editor>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.LenderDetails">
                  <app-lender-details [lenderDetails]="esisForm.controls.lenderDetails"></app-lender-details>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.MainFeaturesOfLoan">
                  <app-single-text-editor
                    [id]="'mainFeaturesOfLoan'"
                    [formGroup]="esisForm.controls.mainFeaturesOfLoan"
                    [placeholder]="'Main Features of the Loan wording'"
                  ></app-single-text-editor>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.InterestRatesOtherCosts">
                  <div class="accordion" id="accordionSubContexts">
                    <div class="card" *ngFor="let subAccordion of accordion?.subAccordions; index as j">
                      <div class="card-header sub-card-header quick-edit" [class.pointer]="subAccordion.canOpen !== false" [id]="'subHeading' + j" (click)="toggleSubsectionOpen(subAccordion.name)">
                        <div class="d-flex align-items-center">
                          <div class="my-auto me-auto">
                            <div class="card-title-text fw-bold">
                              {{ subAccordion.title }}
                            </div>
                          </div>
                          <div *ngIf="showSectionToggle(subAccordion.name)" class="me-3">
                            <lib-toggle-switch
                              [checked]="isSectionEnabled(subAccordion.name)"
                              (changed)="setSectionEnabled(subAccordion.name, $event)"
                            ></lib-toggle-switch>
                          </div>
                          <div class="d-flex" [class.opacity-0]="subAccordion.canOpen === false" [class.pointer-events-none]="subAccordion.canOpen === false">
                            <div class="accordion-invalid p-3" *ngIf="isSectionInvalid(subAccordion.name)" >
                              <i class='fa fa-exclamation-triangle'></i>
                            </div>
                            <button
                              type="button"
                              class="btn my-3 toggle-btn btn-2022--secondary"
                              [class.btn-2022--light-1]="!isSectionEnabled(subAccordion.name)"
                              [disabled]="!isSectionEnabled(subAccordion.name)"
                            >
                              <span class="button-text me-2">{{ !isSubsectionOpen(subAccordion.name) ? 'Expand' : 'Collapse' }}</span>
                              <i [class]="!isSubsectionOpen(subAccordion.name) ? 'fas fa-chevron-down text-end' : 'fas fa-chevron-up text-end'"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div [id]="'subCollapse' + j" [ngbCollapse]="!isSubsectionOpen(subAccordion.name)">
                        <div class="m-3" [ngSwitch]="subAccordion.name">
                          <ng-container *ngSwitchCase="accordionNames.CostsToBePaid">
                            <app-costs-to-be-paid
                              [costsToBePaid]="esisForm.controls.costsToBePaid"
                              [costs]="esisResult.costsToBePaid"
                              [feesMissingInEsis]="feesMissingInEsis"
                              [disabled]="!isEditMode"
                            ></app-costs-to-be-paid>
                          </ng-container>
                          <ng-container *ngSwitchCase="accordionNames.ExternalReferenceRate">
                            <app-external-reference-rate
                              [externalReferenceRate]="esisForm.controls.externalReferenceRate"
                            ></app-external-reference-rate>
                          </ng-container>
                          <ng-container *ngSwitchCase="accordionNames.InterestRatesOtherCosts">
                            <app-interest-rates-and-other-costs
                              [id]="'interestRatesOtherCosts'"
                              [interestRatesAndOtherCosts]="esisForm.controls.interestRatesOtherCosts"
                              [isIllustration]="isIllustration"
                            ></app-interest-rates-and-other-costs>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.FrequencyNumberPayments">
                  <app-single-text-editor
                    [id]="'frequencyNumberPayments'"
                    [formGroup]="esisForm.controls.frequencyNumberPayments"
                    [placeholder]="'Frequency and Number of Payments wording'"
                  ></app-single-text-editor>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.AmountEachInstalment">
                  <app-single-text-editor
                    [id]="'amountEachInstalment'"
                    [formGroup]="esisForm.controls.amountEachInstalment"
                    [placeholder]="'Amount of Each Instalment wording'"
                  ></app-single-text-editor>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.AdditionalObligations">
                  <app-additional-obligations
                    [lenderUser]="lenderUser"
                    [additionalObligations]="esisForm.controls.additionalObligations"
                    [linkedSavings]="esisForm.controls.linkedSavings"
                    [springBoard]="esisForm.controls.springBoard"
                  >
                  </app-additional-obligations>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.FlexibleFeatures">
                  <div class="accordion" id="accordionSubContexts">
                    <div class="card" *ngFor="let subAccordion of accordion?.subAccordions; index as j">
                      <div class="card-header sub-card-header quick-edit" [class.pointer]="subAccordion.canOpen !== false" [id]="'subHeading' + j" (click)="toggleSubsectionOpen(subAccordion.name)">
                        <div class="d-flex align-items-center">
                          <div class="my-auto me-auto">
                            <div class="card-title-text fw-bold">
                              {{ subAccordion.title }}
                            </div>
                          </div>
                          <div *ngIf="showSectionToggle(subAccordion.name)" class="me-3">
                            <lib-toggle-switch
                              [checked]="isSectionEnabled(subAccordion.name)"
                              (changed)="setSectionEnabled(subAccordion.name, $event)"
                            ></lib-toggle-switch>
                          </div>
                          <div class="d-flex" [class.opacity-0]="subAccordion.canOpen === false" [class.pointer-events-none]="subAccordion.canOpen === false">
                            <div class="accordion-invalid p-3" *ngIf="isSectionInvalid(subAccordion.name)" >
                              <i class='fa fa-exclamation-triangle'></i>
                            </div>
                            <button
                              type="button"
                              class="btn my-3 toggle-btn btn-2022--secondary"
                              [class.btn-2022--light-1]="!isSectionEnabled(subAccordion.name)"
                              [disabled]="!isSectionEnabled(subAccordion.name)"
                            >
                              <span class="button-text me-2">{{ !isSubsectionOpen(subAccordion.name) ? 'Expand' : 'Collapse' }}</span>
                              <i [class]="!isSubsectionOpen(subAccordion.name) ? 'fas fa-chevron-down text-end' : 'fas fa-chevron-up text-end'"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div [id]="'subCollapse' + j" [ngbCollapse]="!isSubsectionOpen(subAccordion.name)">
                        <div class="m-3" [ngSwitch]="subAccordion.name">
                          <ng-container *ngSwitchCase="accordionNames.FlexibleFeatures">
                            <app-flexible-features
                            [flexibleFeatures]="esisForm.controls.flexibleFeatures"
                            [features]="esisResult.flexibleFeatures"
                            [displayRulesSchema]="displayRulesSchema"
                            [disabled]="!isEditMode"
                          >
                          </app-flexible-features>
                          </ng-container>
                          <ng-container *ngSwitchCase="accordionNames.IncentivesAdditionalFeatures">
                            <app-incentives-additional-features
                              [incentivesAdditionalFeatures]="esisForm.controls.incentivesAdditionalFeatures"
                              [incentives]="esisResult.incentivesAdditionalFeatures"
                              [displayRulesSchema]="displayRulesSchema"
                              [disabled]="!isEditMode"
                            >
                            </app-incentives-additional-features>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.OtherRightsOfBorrower">
                  <app-other-rights-of-borrower
                    [otherRightsOfBorrower]="esisForm.controls.otherRightsOfBorrower"
                  >
                  </app-other-rights-of-borrower>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.EarlyRepaymentCharges">
                  <app-early-repayment-charges
                    [earlyRepaymentCharges]="esisForm.controls.earlyRepaymentCharges"
                    [charges]="esisResult.earlyRepaymentCharges"
                    [earlyRepaymentChargeTerms]="esisForm.controls.earlyRepaymentChargeTerms"
                    [disabled]="!isEditMode"
                  >
                  </app-early-repayment-charges>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.NonCompliance">
                  <app-non-compliance
                    [lenderUser]="lenderUser"
                    [nonCompliance]="esisForm.controls.nonCompliance"
                    [nonComplianceCI]="esisForm.controls.nonComplianceCI"
                    [nonComplianceIO]="esisForm.controls.nonComplianceIO"
                  >
                  </app-non-compliance>
                </ng-container>
                <ng-container *ngSwitchCase="accordionNames.AdditionalInformation">
                  <app-additional-information
                    [additionalInformation]="esisForm.controls.additionalInformation"
                  >
                  </app-additional-information>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <button *ngIf="esisForm.touched || esisForm.dirty" type="submit" [disabled]="!esisForm.valid" class="btn btn-2022--secondary floating-save-btn">Save</button>
      </form>
    </div>
  </div>

  <ng-template #esisPreviewModal>
    <app-esis-preview-modal
      [esisInformation]="isEditMode ? esisForm.value : esisResult"
      [documentType]="currentDocumentType"
      [lendingTypeId]="currentLendingTypeId"
    />
  </ng-template>
</div>
