import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LenderAuditService } from 'apps/shared/src/services';
import { AnalyticsService } from '@msslib/services/analytics.service';
import { format } from 'date-fns';
import { LenderAuditLog, LenderAuditResult, LenderAuditType } from 'apps/shared/src/models';
import { Router } from '@angular/router';
import { FilterKey } from '../../models/audit-filters';
import { ISortOption } from '@msslib/models';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgIf } from '@angular/common';
import { DropdownFilterDatePickerComponent, DropdownFilterSorterComponent } from '@msslib/components';

@Component({
  selector: 'app-audit-history',
  templateUrl: 'audit-history.component.html',
  styleUrls: ['audit-history.component.scss'],
  standalone: true,
  imports: [
    DropdownFilterDatePickerComponent,
    DropdownFilterSorterComponent,
    NgIf,
    NgFor,
    NgbTooltip,
  ],
})
export class AuditHistoryComponent implements OnInit, AfterViewInit {
  public readonly auditTypeFilterOptions: ISortOption[] = [
    { label: 'Ignite', icon: '', value: LenderAuditType.Ignite },
    { label: 'Products', icon: '', value: LenderAuditType.Products },
    { label: 'Product Upload', icon: '', value: LenderAuditType.ProductUpload },
    { label: 'Affordability Update', icon: '', value: LenderAuditType.Affordability },
    { label: 'ESIS Review', icon: '', value: LenderAuditType.EsisReview },
    { label: 'Illustration Review', icon: '', value: LenderAuditType.IllustrationReview },
    { label: 'ESIS Set Live', icon: '', value: LenderAuditType.EsisSetLive },
    { label: 'Illustration Set Live', icon: '', value: LenderAuditType.IllustrationSetLive },
  ];

  public readonly auditResultFilterOptions: ISortOption[] = [
    { label: 'Approved', icon: 'fa fa-check', value: LenderAuditResult.Approved },
    { label: 'Error Reported', icon: 'fa fa-exclamation-triangle', value: LenderAuditResult.ErrorReported },
    { label: 'Not Completed', icon: 'fa fa-ban', value: LenderAuditResult.NotCompleted },
    { label: 'Affordability Update', icon: '', value: LenderAuditResult.AffordabilityUpdate },
    { label: 'Not Applicable', icon: '', value: LenderAuditResult.NotApplicable },
  ];

  public history: LenderAuditLog[];
  public unfilteredHistory: LenderAuditLog[];
  public filterKey = FilterKey;
  public filters = new Map<string, unknown>();

  public constructor(
    private analyticsService: AnalyticsService,
    private lenderAuditService: LenderAuditService,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.lenderAuditService.getAuditHistory()
      .subscribe(logs => this.unfilteredHistory = this.history = logs);
  }

  public ngAfterViewInit() {
    this.analyticsService.log('LenderHub Audit History');
  }

  public canReplayResult({ type }: LenderAuditLog) {
    return [LenderAuditType.Ignite, LenderAuditType.Products].includes(type);
  }

  public replayResult({ type, referenceId }: LenderAuditLog) {
    this.router.navigate(['ignite/outcomeAuditHistory'], { queryParams: { referenceId, auditType: type } });
  }

  public formatDate(date: string) {
    return date ? format(new Date(date), 'dd-MM-yyyy HH:mm:ss') : 'n/a';
  }

  public isAffordabilityUpdate(resultType: LenderAuditResult): boolean {
    return resultType === LenderAuditResult.AffordabilityUpdate;
  }

  public filterResults(value: unknown, filterType: string) {
    if (value !== null) {
      this.filters.set(filterType, value);
    } else {
      this.filters.delete(filterType);
    }

    this.history = [...this.unfilteredHistory];

    if (this.filters.has(FilterKey.CreatedDate)) {
      const compareTo = this.filters.get(this.filterKey.CreatedDate);
      this.history = this.history.filter(item => new Date(item.createdDate).getDate() === compareTo);
    }

    if (this.filters.has(FilterKey.AuditType)) {
      this.history = this.history.filter(item => item.type === this.filters.get(FilterKey.AuditType));
    }

    if (this.filters.has(FilterKey.AuditResult)) {
      this.history = this.history.filter(item => item.result === this.filters.get(FilterKey.AuditResult));
    }
  }

  public formatLenderAuditType(type: LenderAuditType) {
    switch (type) {
      case LenderAuditType.Ignite: return 'Ignite';
      case LenderAuditType.Products: return 'Products';
      case LenderAuditType.EsisReview: return 'ESIS Review';
      case LenderAuditType.EsisSetLive: return 'ESIS Set Live';
      default: return LenderAuditType[type].replace(/([a-z])([A-Z])/g, '$1 $2');
    }
  }

  public formatLenderAuditResult(result: LenderAuditResult) {
    return LenderAuditResult[result].replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  public toggleWithChangedExpressions(tooltip, changedExpressions: string): void {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open({ changedExpressions });
    }
  }
}
