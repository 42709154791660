import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-lender-details',
  templateUrl: 'lender-details.component.html',
  styleUrls: ['lender-details.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgIf,
  ],
})

export class LenderDetailsComponent {
  @Input() public lenderDetails: UntypedFormGroup;

  public getControl(name: string) {
    return this.lenderDetails.get(name);
  }

  public valid(name:string): boolean {
    return this.lenderDetails.controls[name].valid && this.lenderDetails.controls[name].touched;
  }

  public invalid(name:string): boolean {
    return this.lenderDetails.controls[name].invalid && this.lenderDetails.controls[name].touched;
  }

}
