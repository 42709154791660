import { Component, OnInit } from '@angular/core';

import { appName } from '@msslib/constants/app-name';
import { FooterComponent } from '../../../../libs/msslib/src/lib/components/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { ToastComponent } from '../../../../libs/msslib/src/lib/components/toast/toast.component';
import { ModalComponent } from '../../../../libs/msslib/src/lib/components/modal/modal.component';
import { ModalTemplateDirective } from '../../../../libs/msslib/src/lib/components/modal/modal-template.directive';
import { LoadingComponent } from '../../../../libs/msslib/src/lib/components/loading/loading.component';

@Component({
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [
    LoadingComponent,
    ModalTemplateDirective,
    ModalComponent,
    ToastComponent,
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
  ],
})
export class AppComponent implements OnInit {

  public ngOnInit() {
    (window as any).appName = appName.lenderHub;
  }

}
