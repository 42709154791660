import { Directive, ElementRef, HostListener, Input, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { KeyCodeNames } from '../../../constants';
import { FormsValidators } from '../validators';
import { FormatService, FractionalMode } from '../../../services';

const noop = () => {
  // Do nothing
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line no-use-before-define
  useExisting: forwardRef(() => CurrencyMaskDirective),
  multi: true,
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[currencyMask]',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
})
export class CurrencyMaskDirective implements ControlValueAccessor {
  private el: HTMLInputElement;
  private modelValue: any;

  @Input('currencyMaskAllowFractional') public allowFractional = false;

  public constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private formatService: FormatService,
  ) {
    this.el = elementRef.nativeElement;
  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (a: any) => void = noop;

  public setDisabledState(disabled: boolean): void {
    this.el.disabled = disabled;
  }

  @HostListener('focus', ['$event.target.value'])

  @HostListener('blur', ['$event.target.value'])
  public onBlur(value: string) {
    if (value !== '') {
      this.onTouchedCallback();

      const valid = FormsValidators.greaterThanZero(value);

      if (!valid) {
        this.el.value = '0';
        this.modelValue = 0;
      } else {
        // When the user defocuses the input, strip or add the pence as per the 'allowFractional' flag
        this.el.value = this.format(value, this.allowFractional ? FractionalMode.Enforce : FractionalMode.Remove) ?? '';
        this.modelValue = this.parse(this.el.value);
        this.onChangeCallback(this.modelValue);
        if (this.modelValue) {
          this.renderer.setAttribute(this.elementRef.nativeElement, 'value', this.modelValue);
        }
      }
    }
  }

  @HostListener('change', ['$event.target.value'])
  public onChange(value: string) {
    // When the user is typing, do not 'Enforce' decimals as this will disrupt the typing flow
    this.el.value = this.format(value, this.allowFractional ? FractionalMode.Keep : FractionalMode.Remove) ?? '';
    this.modelValue = this.parse(this.el.value);
    this.onChangeCallback(this.modelValue);
    if (this.modelValue) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'value', this.modelValue);
    }
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    if ((event.code as KeyCodeNames ?? '') === KeyCodeNames.Tab) {
      return;
    }

    this.preventInvalidEntry(event);
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(event: KeyboardEvent) {
    const key = (event.code ?? '') as KeyCodeNames;
    if (key === KeyCodeNames.Tab) {
      return;
    }

    this.preventInvalidEntry(event);
    if (key === KeyCodeNames.Shift) {
      return;
    }
    let value: string | number = (event.target as HTMLInputElement).value.replace(/,/g, '');

    if (key === KeyCodeNames.ArrowUp) {
      value = +value + 1;
    } else if (key === KeyCodeNames.ArrowDown) {
      if (+value > 0) {
        value = +value - 1;
      }
    }

    // When the user is typing, do not 'Enforce' decimals as this will disrupt the typing flow
    this.el.value =
      this.format(value.toString(), this.allowFractional ? FractionalMode.Keep : FractionalMode.Remove) ?? '';
    this.modelValue = this.parse(this.el.value);
    this.onChangeCallback(this.modelValue);
    if (this.modelValue) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'value', this.modelValue);
    }
  }

  public get value(): unknown {
    return this.modelValue;
  }
  public set value(v: unknown) {
    if (v !== this.modelValue) {
      this.modelValue = v;
      this.onChangeCallback(v);
    }
  }

  public writeValue(value: any) {
    if (value !== this.modelValue) {
      // When writing value from programmatic changes, use the 'allowFractional'
      // to determine whether to strip or remove pence
      this.el.value = this.format(value, this.allowFractional ? FractionalMode.Enforce : FractionalMode.Remove) ?? '';
      if (value) {
        this.renderer.setAttribute(this.elementRef.nativeElement, 'value', value);
      }
      this.modelValue = value;
    }
  }

  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // to display value
  private format(value: string, mode: FractionalMode) {
    return this.formatService.formatCurrency(value, mode);
  }

  // to model
  private parse(value: string) {
    value = value.replace(/[^\d.]/g, '');
    return value;
  }

  private preventInvalidEntry(event: KeyboardEvent) {
    const key = (event.code ?? '') as KeyCodeNames;

    if (
      key === KeyCodeNames.Insert ||
      (key !== KeyCodeNames.Delete &&
        key >= KeyCodeNames.Space &&
        !(
          (key >= KeyCodeNames.Digit0 && key <= KeyCodeNames.Digit9) ||
          (key >= KeyCodeNames.Numpad0 && key <= KeyCodeNames.Numpad9) ||
          key === KeyCodeNames.ArrowLeft ||
          key === KeyCodeNames.ArrowRight ||
          (
            this.allowFractional && !(event.target as HTMLInputElement).value.includes('.') &&
            (key === KeyCodeNames.Period || key === KeyCodeNames.NumpadPeriod)
          )
        ))
    ) {
      event.preventDefault();
    }
  }
}
