import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { NgClass } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lib-form-checkbox',
  styleUrls: ['form-checkbox.component.scss'],
  templateUrl: 'form-checkbox.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormFieldErrorComponent,
  ],
})
export class FormCheckboxComponent extends FieldBaseComponent {}
