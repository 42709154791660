import { Availability, Product } from 'apps/shared/src/models/matched-product';

// List of all properties that are relevant for the ESIS preview attributes modal.
// Any of these properties that are 'AlsoAvailable' on the given product will be shown to the user.
export const esisApplicableAvailabilityProperties = {
  'existingCustomer': 'Existing Customer',
  'expatNotInUk': 'Expat not in UK',
  'firstTimeBuyer': 'First Time Buyer',
  'greenEco': 'Green/Eco',
  'helpToBuy': 'Help to Buy',
  'hmo': 'HMO',
  'holidayLetAirBb': 'Holiday Let / Air B&B',
  'jointBorrowerSoleProprietor': 'Joint Borrower-Sole Proprietor',
  'letToBuy': 'Let to Buy',
  'limitedCompanyBuyToLet': 'Limited Company Buy to Let',
  'multiUnitFreeholdBlock': 'Multi Unit Freehold Block',
  'newBuild': 'New Build',
  'offset': 'Offset',
  'portfolioLandlord': 'Portfolio Landlord',
  'productTransfer': 'Product Transfer',
  'regulatedBuyToLet': 'Regulated Buy to Let',
  'rightToBuy': 'Right to Buy',
  'secondResidential': 'Second Residential',
  'selfBuild': 'Self Build',
  'sharedEquity': 'Shared Equity',
  'sharedOwnership': 'Shared Ownership',
} as const satisfies Partial<Record<keyof Product, string>>;

/**
 * Returns a list of the properties on the given product that can be used by a lender in the ESIS flexible features/
 * incentives display rules filter, and also have the 'Also Available' value.
 */
export function getEsisApplicableAvailabilityProperties(product: Product) {
  return Object.keys(esisApplicableAvailabilityProperties)
    .filter(key => product[key] === Availability.AlsoAvailable);
}
