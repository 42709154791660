import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IDateInput } from '@msslib/components/formly-legacy';
import { dateIsValid, getCurrentDate, mapDateToUtc, min18Years } from '../../../helpers';

@Injectable()
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class FormsValidators {
  public static postcodeRegex
    = /^(([A-PR-UWYZ0-9][A-HK-Y0-9][ADEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? ?){1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/i;
  public static moneyRegex = /^\d{1,18}(\.\d{1,2})?$/;
  public static emailRegex
    = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+]*/;
  public static telephoneRegex = /^([()\-.\\\\/+# 0-9x]|ext|extension){8,15}$/;
  public static leadingWhitespaceRegex = /^\s/;
  public static trailingWhitespaceRegex = /\s$/;
  public static domainRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
  public static printableCharactersRegex = /[^ -~]+/;
  public static urlRegex = /^(?:https:\/\/)(?:[a-z0-9-]+\.)+[a-z]{2,}(?:\/[^\s]*)?$/i;

  public static dateOrEmpty(control: AbstractControl) {
    const { value } = control;
    if (!value) {
      return;
    }

    if (!value.day && !value.month && !value.year) {
      return;
    }

    return FormsValidators.date(control);
  }

  public static productTransferDateValidation(): ValidatorFn {
    return control => {
      const productTransferInitialDate = control?.value?.productTransferInitialDate as IDateInput;
      const dateInputIsEmpty = FormsValidators.isDateInputEmpty(productTransferInitialDate);
      // Allow empty date
      if (dateInputIsEmpty) {
        return null;
      }

      const isDateValid = dateIsValid(productTransferInitialDate);
      if (!isDateValid) {
        return null;
      }

      const inputDate = mapDateToUtc(productTransferInitialDate);
      const currentDate = getCurrentDate();
      if (currentDate > inputDate) {
        return { dateInPast: true };
      }

      return null;
    };
  }

  public static date(control: AbstractControl) {
    const { value } = control;
    if (value) {
      const valid = dateIsValid(value);
      return valid ? null : { date: true };
    }
    return { date: true };
  }

  public static atLeast18Years(control: AbstractControl) {
    const { value } = control;
    if (value) {
      const valid = min18Years(value);
      return valid ? undefined : { atLeast18Years: true };
    }
    return { atLeast18Years: true };
  }

  public static trueOrFalse(fc: AbstractControl) {
    const valid = fc.value === true || fc.value === false;

    return valid ? null : { required: true };
  }

  public static integer(fc: AbstractControl) {
    if (fc.value === undefined || fc.value === null) {
      return null;
    }
    const value = +fc.value;
    const valid = typeof value === 'number' && !isNaN(value) && Number.isInteger(value);

    return valid
      ? null
      : {
        integer: true,
      };
  }

  public static number(fc: AbstractControl) {
    const value = +fc.value;
    const valid = typeof value === 'number' && !isNaN(value);

    return valid
      ? null
      : {
        number: true,
      };
  }

  /** Checks that the value is either not provided (null/undefined) or a number if provided. */
  public static optionalNumber({ value }: AbstractControl) {
    const valid = value === null || value === undefined || !isNaN(Number(value));
    return valid ? null : { number: true };
  }

  public static numberNotZero(fc: AbstractControl) {
    const value = +fc.value;
    const valid = typeof value === 'number' && !isNaN(value) && value > 0;

    return valid
      ? null
      : {
        numberNotZero: true,
      };
  }

  public static positive(fc: AbstractControl) {
    const value = +fc.value;
    const valid = typeof value === 'number' && !isNaN(value) && value >= 0;

    return valid
      ? null
      : {
        positive: true,
      };
  }

  /** Checks that the value is either not provided (null/undefined) or a positive number if provided. */
  public static optionalPositive({ value }: AbstractControl) {
    const valid = value === undefined || value === null || (!isNaN(Number(value)) && Number(value) > 0);
    return valid ? null : { positive: true };
  }

  public static noNumbersValidator(fc: AbstractControl) {
    const containsNumbersRegex = /[0-9]+/;
    if (!containsNumbersRegex.test(fc.value)) {
      return null;
    }

    return { containsNumbers: true };
  }

  public static email(fc: AbstractControl) {
    const valid = fc.value && FormsValidators.emailRegex.test(fc.value);

    return valid
      ? null
      : {
        email: true,
      };
  }

  // https://www.regexpal.com/?fam=104738
  public static telephone(fc: AbstractControl) {
    const valid = !fc.value || FormsValidators.telephoneRegex.test(fc.value);

    return valid
      ? null
      : {
        telephone: true,
      };
  }

  public static domain(fc: AbstractControl) {
    if (FormsValidators.domainRegex.test(fc.value)) {
      return null;
    }
    return {
      invalidDomain: true,
    };
  }

  public static postcode(control: AbstractControl) {
    const valid = !control.value || FormsValidators.postcodeRegex.test(control.value);
    return valid
      ? null
      : {
        postcode: true,
      };
  }

  public static money(control: AbstractControl) {
    const valid = FormsValidators.moneyRegex.test(control.value);
    return valid
      ? null
      : {
        money: true,
      };
  }

  public static percentage(control: AbstractControl) {
    const value = parseFloat(control.value);
    const valid = !isNaN(value) && value >= 0 && value <= 100;
    return valid
      ? null
      : {
        percentage: true,
      };
  }

  public static multipleCheckboxRequireAtLeastOne(fa: UntypedFormArray) {
    const valid = fa.controls.filter((x) => x.value).length >= 1;
    return valid
      ? null
      : {
        multipleCheckboxRequireAtLeastOne: true,
      };
  }

  public static multipleCheckboxRequireMoreThanOne(fa: UntypedFormArray) {
    const valid = fa.controls.filter((x) => x.value).length > 1;
    return valid
      ? null
      : {
        multipleCheckboxRequireMoreThanOne: true,
      };
  }

  public static greaterThan(min: number) {
    return (control: UntypedFormControl) => {
      if (control.value === '' || isNaN(control.value) || control.value <= min) {
        return { greaterThan: min };
      }
      return null;
    };
  }

  public static greaterThanOnTouched(min: number): any {
    return (control: UntypedFormControl) => {
      //field is valid if it was not touched
      if (control.value === '' ||
        control.value === null ||
        isNaN(control.value)) {
        return null;
      }

      if (control.value <= min) {
        return { greaterThan: min };
      }
      return null;
    };
  }

  public static lessThan(max: number): unknown {
    return (control: UntypedFormControl) => {
      if (control.value === '' || isNaN(control.value) || control.value >= max) {
        return { lessThan: max };
      }
      return null;
    };
  }

  public static noLeadingWhitespace(fc: AbstractControl) {
    const invalid = fc.value && FormsValidators.leadingWhitespaceRegex.test(fc.value);
    return invalid
      ? { leadingWhitespace: true }
      : null;
  }

  public static noTrailingWhitespace(fc: AbstractControl) {
    const invalid = fc.value && FormsValidators.trailingWhitespaceRegex.test(fc.value);
    return invalid
      ? { trailingWhitespace: true }
      : null;
  }

  public static validationExist(func: { toString: () => string }): boolean {
    const funcStr = func.toString().replace(/\s/g, '');
    return (
      funcStr.includes('{required:') ||
      funcStr.includes('{\'required\':') ||
      funcStr.includes('multipleCheckboxesRequireOnlyOneChecked:') ||
      funcStr.includes('multipleCheckboxRequireAtLeastOne:') ||
      funcStr.includes('multipleCheckboxRequireMoreThanOne:')
    );
  }

  public static greaterThanZero(value: any) {
    value = (value || '').replace(/,/g, '');

    if (typeof value === 'string') {
      value = +value;
    }

    return value !== '' && typeof value === 'number' && !isNaN(value) && +value > 0;
  }

  public static printableCharactersOnly(fc: AbstractControl) {
    const valid = fc.value && !FormsValidators.printableCharactersRegex.test(fc.value);
    return valid
      ? null
      : {
        printableCharactersOnly: true,
      };
  }

  public static conditional(
    condition: (control: AbstractControl) => boolean,
    trueValidator: ValidatorFn | null,
    falseValidator: ValidatorFn | null = null,
  ): ValidatorFn {
    return control => condition(control)
      ? trueValidator?.(control) ?? null
      : falseValidator?.(control) ?? null;
  }

  public static ltv(maxLtv = 500): ValidatorFn {
    return control => {
      const { loanAmount, propertyValue } = control.value as { loanAmount: number; propertyValue: number };
      return !loanAmount || !propertyValue || (100 * loanAmount / propertyValue <= maxLtv)
        ? null
        : { maxLtv };
    };
  }

  public static lts(maxLts = 500): ValidatorFn {
    return control => {
      const { loanAmount, purchasePriceShare } = control.value as { loanAmount: number; purchasePriceShare: number };
      return !loanAmount || !purchasePriceShare || (100 * loanAmount / purchasePriceShare <= maxLts)
        ? null
        : { maxLts };
    };
  }

  /** Compares the subfield of this control with another, and throws validation error if fieldB's value is equal to or
   * higher than fieldA's value */
  public static greaterThanField<T = unknown>(
    fieldA: keyof T,
    fieldB: keyof T,
    onlyWhenChanged = false,
  ): ValidatorFn {
    return control => {
      const value = control.value as T;
      const a = value[fieldA];
      const b = value[fieldB];
      if (onlyWhenChanged &&
        (a === '' || a === null || a === undefined) &&
        (b === '' || b === null || b === undefined)
      ) {
        return null;
      }
      return a < b ? null : { fieldA, fieldB };
    };
  }

  /** Converts the given `ValidatorFn` into a function for use in a `FormlyValidatorExpressionFn.expression` object. */
  public static expression(validator: ValidatorFn) {
    return (control: AbstractControl) => !validator(control);
  }

  private static isDateInputEmpty(dateInput: IDateInput) {
    if (!dateInput) {
      return true;
    }

    if (!dateInput.day && !dateInput.month && !dateInput.year) {
      return true;
    }

    return false;
  }

  public static isEnumMember<T>(
    enumArg: Record<string | number | symbol, T>): ValidatorFn {
    return control => {
      return (Object.values(enumArg) as unknown[]).includes(control.value) ? null : { valueNotInEnum: true};
    };
  }

  public static nonNegativeRatesValidator(discountProductRates: {rate: number; code: string}[]): ValidatorFn | null {
    return (control: AbstractControl) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const code = control.parent?.get('code')?.value;
      const variableRate = control.parent?.get('variableRate')?.value;
      const discountRate = discountProductRates.find(x=>x.code === code)?.rate ?? 0;

      return variableRate - discountRate < 0 ? { negativeRate: true } : null;
    };
  }

  public static variableRateGreaterThenZero(): any {
    return (control: AbstractControl) => {
      const isTrackerControl = control.parent?.controls['isTracker'];
      if (!isTrackerControl) {
        return FormsValidators.greaterThanOnTouched(0)(control);
      }

      const isTracker = isTrackerControl.value;
      return isTracker ? null : FormsValidators.greaterThanOnTouched(0)(control);
    };
  }

  /**
   * Similar to Validators.required, but checks that HTML strings are non-empty.
   * E.G. "<p></p>" would fail validation, but "<span>Hello world</span>" would be allowed.
   * Recommend to use `updateOn: 'blur'` to prevent excessive calls.
   */
  public static requiredHtml(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;

    // If the value is already null/undefined/empty/whitespace then skip creating an element.
    if (!value?.length || !value.trim().length) {
      return { required: true };
    }

    // If the string is non-empty, then create an element using that HTML and check to see if the innerText is empty.
    const temporaryElement = document.createElement('div');
    temporaryElement.innerHTML = value;
    if (!temporaryElement.innerText.trim().length) {
      return { required: true };
    }

    return null;
  }
}
