import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-input-error-description',
  templateUrl: 'input-error-description.component.html',
  standalone: true,
  imports: [NgIf],
})
export class InputErrorDescriptionComponent {
  @Input() public errorMessage: string;
  @Input() public visible: boolean;
}
