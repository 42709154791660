import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValuationFeeScale } from '@msslib/models';
import { FeeScaleConditionType } from '@msslib/models/enums/fee-scale-condition-type';
import { DatePipe, NgIf } from '@angular/common';

@Component({
  selector: 'lib-valuation-fee-scales-card',
  templateUrl: './valuation-fee-scales-card.component.html',
  styleUrls: ['./valuation-fee-scales-card.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ValuationFeeScalesCardComponent {

  @Input() public valuationFeeScale: ValuationFeeScale;

  @Output() public delete = new EventEmitter<void>();
  @Output() public reschedule = new EventEmitter<void>();
  @Output() public view = new EventEmitter<void>();

  public feeScaleConditionText = {
    [FeeScaleConditionType.Standard]: 'Standard',
    [FeeScaleConditionType.ExpatNotInUk]: 'Expat Not in UK',
    [FeeScaleConditionType.HelpToBuy]: 'Help to Buy',
    [FeeScaleConditionType.Hmo]: 'HMO',
    [FeeScaleConditionType.Purchase]: 'Purchase',
    [FeeScaleConditionType.Remortgage]: 'Remortgage',
    [FeeScaleConditionType.SelfBuild]: 'Self Build',
  };

  public constructor(private datePipe: DatePipe) {
  }

  public getStatusText(): string {
    if (this.valuationFeeScale.isScheduled) {
      const dateStr = this.datePipe.transform(this.valuationFeeScale.startDate, 'dd/MM/yyyy HH:mm');
      return `Scheduled to go live on ${dateStr}`;
    }
    return 'Live';
  }

  public onDelete() {
    this.delete.emit();
  }

  public onReschedule() {
    this.reschedule.emit();
  }

  public onView() {
    this.view.emit();
  }
}
