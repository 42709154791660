<lib-lending-type-buttons
  [value]="lendingTypeCode"
  [filter]="['RES','BTL']"
  (lendingTypeSelect)="switchLendingType($event)"
></lib-lending-type-buttons>
<div>
  <div class="valuation-fee-scales-content">
    <div class="valuation-fee-scales-container mt-3">
      <lib-valuation-fee-scales-card
        *ngFor="let valuationFeeScale of valuationFeeScales"
        [valuationFeeScale]="valuationFeeScale"
        (delete)="handleDelete(valuationFeeScale)"
        (reschedule)="handleReschedule(valuationFeeScale)"
        (view)="handleView(valuationFeeScale)"
      ></lib-valuation-fee-scales-card>
    </div>
  </div>
</div>

<div class="valuation-fee-scales-footer mt-5">
  <button class="btn btn-2022--secondary" (click)="createValuationFeeScales()">Create Valuation Scales</button>
</div>

<ng-template #valuationFeeScalesEditorModalTemplate>
  <lib-valuation-fee-scales-editor [lenderNameId]="lenderNameId"
                                   [valuationFeeScale]="valuationFeeScaleCurrent"
                                   [lendingTypeCode]="lendingTypeCode"
                                   (formSubmit)="fetchValuationFeeScales()"
                                   (unsavedChangesChange)="hasUnsavedChanges = $event"
                                   (closeValuationFeeScalesEditor)="closeValuationFeeScalesEditor()">
  </lib-valuation-fee-scales-editor>
</ng-template>

<ng-template #rescheduleModalTemplate>
  <div class="reschedule-dialog">
    <div class="reschedule-dialog-header">
      <div>Are you sure you want to reschedule?
        This will reschedule the current valuation fee scale schedule for
        {{ valuationFeeScaleCurrent.startDate | date: 'dd/MM/yyyy HH:mm' }}
      </div>
    </div>
    <div class="reschedule-dialog-body">
      <lib-event-schedule
        [scheduleForm]="scheduleForm"
        [showSetLiveNowOption]="false"
      ></lib-event-schedule>
    </div>
    <div class="reschedule-dialog-footer">
      <button class="btn btn-2022--secondary btn-sm" (click)="rescheduleModalClick()">Yes, reschedule</button>
    </div>
  </div>
</ng-template>

<ng-template #scheduleDeleteModalTemplate>
  <div class="reschedule-dialog">
    <div class="reschedule-dialog-header">
      <div>Are you sure you want to delete the valuation fee scale scheduled to go live on
        {{ valuationFeeScaleCurrent.startDate | date: 'dd/MM/yyyy HH:mm' }}.?
        If you click no, your current live
        valuation fee scale will remain available on Legal & General Ignite until replaced.
      </div>
    </div>
    <div class="schedule-delete-dialog-footer">
      <button class="btn btn-2022--secondary btn-sm" (click)="scheduleDeleteNoClick()">No, go back</button>
      <button class="btn btn-2022--secondary btn-sm" (click)="scheduleDeleteYesClick()">Yes, delete schedule</button>
    </div>
  </div>
</ng-template>

<ng-template #saveResultDialogTemplate>
  <div class="save-result-dialog">
    <div class="save-result-dialog-content">
      {{ saveResultMessage }}
    </div>
  </div>
</ng-template>
