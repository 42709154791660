import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';
import { RouterLink } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lib-form-link',
  styleUrls: ['form-link.component.scss'],
  templateUrl: 'form-link.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
  ],
})
export class FormLinkComponent extends FieldBaseComponent {}
