import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ISortMainImage, ISortOption } from '../../models';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { NgbDropdown, NgbDropdownAnchor, NgbDropdownMenu, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-dropdown-filter-datepicker',
  styleUrls: ['dropdown-filter-datepicker.component.scss'],
  templateUrl: 'dropdown-filter-datepicker.component.html',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownAnchor,
    NgClass,
    NgIf,
    NgbDropdownMenu,
    NgbInputDatepicker,
    FormsModule,
  ],
})
export class DropdownFilterDatePickerComponent {
  @Input() public name = 'filterdropdown';
  @Input() public label: string;
  @Input() public textClass: string;
  @Input() public image: ISortMainImage;
  @Input() public list: ISortOption[];
  @Input() public activeValue: unknown;
  @Input() public hideClearOption: boolean;
  @Input() public ariaText: string;
  @Output() public dateChange = new EventEmitter();
  public date: Date | null;

  public get active(): ISortOption | undefined {
    return this.list?.find((l) => l.value === this.activeValue);
  }

  public select(date: Date | null) {
    this.dateChange.next(date);
  }

  public clearDate() {
    this.date = null;
    this.dateChange.next(this.date);
  }
}
