import { Component } from '@angular/core';

import { MssFieldBaseFieldTypeComponent } from '../mss-field-base.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';

@Component({
  styleUrls: ['radio-list.component.scss'],
  templateUrl: 'radio-list.component.html',
  standalone: true,
  imports: [
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
})
export class RadioListComponent extends MssFieldBaseFieldTypeComponent {
  public defaultOptions = {
    props: {
      options: [] as unknown[],
      formCheck: 'custom', // 'custom' | 'stacked' | 'inline'
    },
  };
}
