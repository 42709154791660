<div class="multiselect dropdown">
  <div
    class="form-select"
    aria-expanded="false"
    tabindex="-1"
    [id]="dropdownId"
    [attr.data-bs-toggle]="props.disabled ? '' : 'dropdown'"
    (hide.bs.dropdown)="onDropdownHide()"
    (show.bs.dropdown)="onDropdownShow()"
    #dropdown
  >
    <div class="multiselect-labels">{{ selectedLabels }}</div>
  </div>

  <div #popper class="popper-container"></div>

  <div class="dropdown-menu multiselect-options" [attr.aria-labelledby]="dropdownId">
    <div
      *ngFor="let option of props.options; index as index; trackBy: trackByFn;"
      (click)="$event.stopPropagation()"
    >
      <div class="multiselect-option p-1 ms-3">
        <input
          class="form-check-input"
          type="checkbox"
          [id]="dropdownId + '-multiselect-option-' + option.value"
          [attr.data-testid]="'multiselect_option_' + index"
          [value]="option.value"
          [disabled]="option.disabled"
          [checked]="isChecked(option)"
          (change)="changeValue(option, $event.target.checked)"
        />
        <label
          [for]="dropdownId + '-multiselect-option-' + option.value"
          class="option-label form-check-label ps-1"
        >
          {{ option.label }}
        </label>
      </div>
    </div>
  </div>
</div>
