import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'lib-form-date',
  styleUrls: ['form-date.component.scss'],
  templateUrl: 'form-date.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgbInputDatepicker,
    NgClass,
    FormFieldErrorComponent,
  ],
})
export class FormDateComponent extends FieldBaseComponent {}
