import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import {
  AccordionComponent,
  AddOptionModalComponent,
  AgencyNumberModalComponent,
  AppFileInputDirective,
  AppFormComponent,
  AuditLogChangeListComponent,
  BannerComponent,
  BridgingProductFeesModalComponent,
  BridgingProductFeesTooltipComponent,
  CardComponent,
  CardDeckComponent,
  CheckboxListComponent,
  ConfirmSwitchModalComponent,
  CurrencyMaskDirective,
  DateTimeComponent,
  DisclaimerContentClubHubComponent,
  DisclaimerContentLenderHubComponent,
  DisclaimerModalComponent,
  DocumentPreferencesComponent,
  DropdownFilterDatePickerComponent,
  DropdownFilterSorterComponent,
  EmailVerificationModalComponent,
  EsisAdditionalInformationComponent,
  EsisAdditionalModalComponent,
  EsisProductSummaryComponent,
  EventScheduleComponent,
  FirmDetailsComponent,
  FloorRatesComponent,
  FooterComponent,
  FormButtonComponent,
  FormButtonGroupComponent,
  FormCheckboxComponent,
  FormCheckboxListComponent,
  FormDateComponent,
  FormFieldDirective,
  FormFieldErrorComponent,
  FormFileComponent,
  FormFilePathComponent,
  FormHeadingComponent,
  FormInputComponent,
  FormInputGroupComponent,
  FormLinkComponent,
  FormParagraphComponent,
  FormRadioListComponent,
  FormSelectComponent,
  FormTextareaComponent,
  FormTimeComponent,
  FormToggleComponent,
  FormlyLtvFieldComponent,
  FormsService,
  GreaterThanOnTouchedValidatorDirective,
  GreaterThanValidatorDirective,
  HeaderComponent,
  IgniteBannerComponent,
  ImageResizerComponent,
  InputErrorDescriptionComponent,
  IntuitiveBadgeComponent,
  IssuesSearchBoxComponent,
  LenderTariffOfChargesComponent,
  LendingTypeButtonsComponent,
  ListComponent,
  LoadingComponent,
  LoginComponent,
  LoginMenuComponent,
  LogoutComponent,
  ModalComponent,
  ModalTemplateDirective,
  MssFormlyModule,
  MultiselectFilterComponent,
  PageHeadingComponent,
  PageNavComponent,
  PowerBiComponent,
  PreviewUploadModalComponent,
  PrintResultsModalComponent,
  ProductDefaultRulesEditorComponent,
  ProductErcTooltipComponent,
  ProductFeesModalComponent,
  ProductFeesTooltipComponent,
  ProductOverrideTooltipComponent,
  ProductRatesTooltipComponent,
  ProductResultsModalComponent,
  ProgressCircleComponent,
  RadioButtonsComponent,
  RadioGroupComponent,
  RadioListComponent,
  RescheduleUploadModalComponent,
  RevertRateColumnComponent,
  RulesEngineActionEditorComponent,
  RulesEngineConditionEditorComponent,
  RulesEngineLogicalGroupConditionEditorComponent,
  RulesEnginePropertyConditionEditorComponent,
  RulesEnginePropertySelectorComponent,
  RulesEngineValueEditorComponent,
  SaveOutcomeModalComponent,
  SearchInputComponent,
  SearchInputTypeaheadComponent,
  SimpleGridCellTemplate,
  SimpleGridComponent,
  SwitchComponent,
  ToastComponent,
  ToggleSwitchComponent,
  TooltipComponent,
  TypeProductColumnComponent,
  TypeProductColumnTooltipComponent,
  UserCustomProductSettingsComponent,
  ValuationFeeScaleComponent,
  VariableRateCodesEditorComponent,
  YesNoColumnComponent,
} from './components';
import { ContactPageComponent, ErrorPageComponent } from './pages';
import { EMAIL_VERIFICATION_SERVICE, MSSLIB_CONFIG, MssLibConfig } from './models';
import { AsantoQuoteNumberPipe, EnumValuesPipe, GroupByPipe, SafePipe, SlugifyPipe, StartCasePipe,
  YesNoPipe } from './pipes';
import {
  AuthInterceptor,
  CustomDateFormatter,
  CustomNgbDateNativeUTCAdapter,
  JwtInterceptor,
  LoadingInterceptor,
} from './services';
import { routes } from './msslib.routes';
import { EmailVerificationService } from './services/email-verification.service';
import { AttributesFromObjectDirective, DigitOnlyDirective, TwoDigitDecimalNumberDirective } from './directives';
import { VariableRateCodesComponent } from '@msslib/components/variable-rate-codes/variable-rate-codes.component';
import {
  ProductTransferDetailsComponent,
} from '@msslib/components/product-transfer-details/product-transfer-details.component';
import {
  PackagerRelationshipsComponent,
} from '@msslib/components/packager-relationships/packager-relationships.component';
import {
  RefreshNowAppBannerComponent,
} from '@msslib/components/refresh-now-app-banner/refresh-now-app-banner.component';
import { ValuationFeeScalesComponent } from '@msslib/components/valuation-fee-scales/valuation-fee-scales.component';
import {
  ValuationFeeScalesCardComponent,
} from '@msslib/components/valuation-fee-scales-card/valuation-fee-scales-card.component';
import {
  ValuationFeeScalesEditorComponent,
} from '@msslib/components/valuation-fee-scales-editor/valuation-fee-scales-editor.component';

const components = [
  // Custom components
  AddOptionModalComponent,
  AccordionComponent,
  PageHeadingComponent,
  CardDeckComponent,
  CheckboxListComponent,
  DropdownFilterSorterComponent,
  DropdownFilterDatePickerComponent,
  CardComponent,
  ToggleSwitchComponent,
  SearchInputComponent,
  SearchInputTypeaheadComponent,
  SimpleGridComponent,
  SimpleGridCellTemplate,
  ListComponent,
  LoginComponent,
  LogoutComponent,
  LoginMenuComponent,
  ProgressCircleComponent,
  InputErrorDescriptionComponent,
  // Forms
  // directives
  CurrencyMaskDirective,
  FormFieldDirective,
  GreaterThanValidatorDirective,
  GreaterThanOnTouchedValidatorDirective,
  // components
  ContactPageComponent,
  ConfirmSwitchModalComponent,
  ErrorPageComponent,
  FooterComponent,
  HeaderComponent,
  LendingTypeButtonsComponent,
  PageNavComponent,
  ValuationFeeScaleComponent,
  PreviewUploadModalComponent,
  ProductResultsModalComponent,
  RescheduleUploadModalComponent,
  VariableRateCodesEditorComponent,
  SaveOutcomeModalComponent,
  PrintResultsModalComponent,
  ProductErcTooltipComponent,
  ProductFeesTooltipComponent,
  ProductRatesTooltipComponent,
  BridgingProductFeesTooltipComponent,
  SwitchComponent,
  RadioGroupComponent,
  IssuesSearchBoxComponent,
  FormlyLtvFieldComponent,
  EsisAdditionalModalComponent,
  EsisAdditionalInformationComponent,
  EsisProductSummaryComponent,
  DocumentPreferencesComponent,
  FirmDetailsComponent,
  ProductFeesModalComponent,
  BridgingProductFeesModalComponent,
  BannerComponent,
  IgniteBannerComponent,
  EventScheduleComponent,
  DateTimeComponent,
  UserCustomProductSettingsComponent,
  AppFormComponent,
  FormButtonComponent,
  FormButtonGroupComponent,
  FormInputComponent,
  FormHeadingComponent,
  FormParagraphComponent,
  FormInputGroupComponent,
  FormFileComponent,
  AppFileInputDirective,
  FloorRatesComponent,
  FormFilePathComponent,
  FormDateComponent,
  FormTimeComponent,
  FormToggleComponent,
  FormTextareaComponent,
  FormCheckboxComponent,
  FormCheckboxListComponent,
  FormRadioListComponent,
  FormSelectComponent,
  FormFieldErrorComponent,
  FormLinkComponent,
  ModalComponent,
  ModalTemplateDirective,
  ToastComponent,
  ImageResizerComponent,
  LoadingComponent,
  PowerBiComponent,
  RadioButtonsComponent,
  RadioListComponent,
  TooltipComponent,
  EmailVerificationModalComponent,
  DisclaimerModalComponent,
  DisclaimerContentLenderHubComponent,
  DisclaimerContentClubHubComponent,
  AgencyNumberModalComponent,
  AuditLogChangeListComponent,
  ProductOverrideTooltipComponent,
  ProductDefaultRulesEditorComponent,
  VariableRateCodesComponent,
  ProductTransferDetailsComponent,
  PackagerRelationshipsComponent,
  IntuitiveBadgeComponent,
  RefreshNowAppBannerComponent,
  ValuationFeeScalesComponent,
  ValuationFeeScalesCardComponent,
  ValuationFeeScalesEditorComponent,
  // Pages
  ContactPageComponent,
  ErrorPageComponent,
  // Grid
  MultiselectFilterComponent,
  YesNoColumnComponent,
  TypeProductColumnComponent,
  TypeProductColumnTooltipComponent,
  LenderTariffOfChargesComponent,
  RevertRateColumnComponent,
  // Rules engine
  RulesEngineActionEditorComponent,
  RulesEngineConditionEditorComponent,
  RulesEngineLogicalGroupConditionEditorComponent,
  RulesEnginePropertyConditionEditorComponent,
  RulesEnginePropertySelectorComponent,
  RulesEngineValueEditorComponent,
];

const pipes = [
  AsantoQuoteNumberPipe,
  EnumValuesPipe,
  GroupByPipe,
  SafePipe,
  StartCasePipe,
  YesNoPipe,
  SlugifyPipe,
];

const directives = [
  AttributesFromObjectDirective,
  TwoDigitDecimalNumberDirective,
  DigitOnlyDirective,
];

@NgModule({ exports: [
  ...components,
  ...pipes,
  ...directives,
  // Modules
  CommonModule,
  MssFormlyModule,
  NgbModule,
], imports: [CommonModule,
  NgbModule,
  FormsModule,
  MssFormlyModule,
  NgxMaskModule.forRoot({
    thousandSeparator: ',',
  }),
  RouterModule.forChild(routes),
  ...components,
  ...pipes,
  ...directives], providers: [
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  FormsService,
  StartCasePipe,
  YesNoPipe,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  { provide: NgbDateParserFormatter, useClass: CustomDateFormatter },
  { provide: NgbDateAdapter, useClass: CustomNgbDateNativeUTCAdapter },
  { provide: EMAIL_VERIFICATION_SERVICE, useClass: EmailVerificationService },
  provideHttpClient(withInterceptorsFromDi()),
] })
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class MssLibModule {
  public static forRoot(mssLibConfig: MssLibConfig): ModuleWithProviders<MssLibModule> {
    return {
      ngModule: MssLibModule,
      providers: [
        {
          provide: MSSLIB_CONFIG,
          useValue: mssLibConfig,
        },
      ],
    };
  }
}
