import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { esisModalTooltipMessages } from 'apps/clubhub/src/app/ignite/models/affordability';
import { Availability, Product } from 'apps/shared/src/models';
import {
  EsisEorGenFormDetails,
  EsisSearchDetailsModel,
  WhenPayable,
  WhenRefundable,
} from 'apps/shared/src/models/esis';
import { ProductFeeType, getFormattedFeeTypes } from '../../../constants/product-fees';
import { EsisFormBaseComponent } from '../esis-form-base.component';
import { StartCasePipe } from '../../../pipes/start-case.pipe';
import { EnumValuesPipe } from '../../../pipes/enum-values.pipe';
import { InputErrorDescriptionComponent } from '../../input-error-description/input-error-description.component';
import { CurrencyMaskDirective } from '../../forms/directives/currency-mask.directive';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-esis-additional-information',
  templateUrl: 'esis-additional-information.component.html',
  styleUrls: ['../esis-tab-shared-style.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgbTooltip,
    NgIf,
    NgFor,
    NgClass,
    CurrencyMaskDirective,
    InputErrorDescriptionComponent,
    EnumValuesPipe,
    StartCasePipe,
  ],
})
export class EsisAdditionalInformationComponent extends EsisFormBaseComponent implements OnInit {
  @Input() public searchDetails: EsisSearchDetailsModel;
  @Input() public formDetails: EsisEorGenFormDetails;
  @Input() public product: Product;
  @Output() public addFee = new EventEmitter<void>();
  @Output() public removeFee = new EventEmitter<number>();
  public removingItem = -1;
  public tooltipMessages = esisModalTooltipMessages;
  public allFeeTypes = getFormattedFeeTypes();
  public whenPayable = WhenPayable;
  public whenRefundable = WhenRefundable;
  public customFeeFocusIndex = -1;

  private customFeeBlurTimeout: any;
  private notAvailableFeesForProductTransfer = [ProductFeeType.Chaps, ProductFeeType.Valuation];

  public constructor() {
    super();
  }

  public ngOnInit() {
    if (!this.product.packager) {
      this.allFeeTypes = getFormattedFeeTypes();
    }
  }

  public get availableFeeTypes() {
    if (this.product.productTransfer === Availability.OnlyAvailable || this.product.productTransferEligible) {
      return this.allFeeTypes.filter(r => !this.notAvailableFeesForProductTransfer.includes(r.key));
    }

    return this.allFeeTypes;
  }

  public get feesGroup(): UntypedFormGroup {
    return this.form.get('fees.fees') as UntypedFormGroup;
  }

  public isLenderFee(index: number): boolean {
    return this.form.get(['fees', 'fees', index, 'isLenderFee'])?.value;
  }

  public isCustomFeeType(index: number): boolean {
    return +(this.form.get(['fees', 'fees', index, 'type'])?.value) === ProductFeeType.Custom;
  }

  public whenRefundableChange(newValue: WhenRefundable, index: number) {
    // When the refundable dropdown changes, update the refundable amount.
    // If set to 'Not Refundable', clear the value otherwise set it to the current value, which will force the
    // validation to re-run. If we do not re-run validation, when moving from not refundable to another option, the user
    // can immediately press generate and generate a £0 value against a required refundable amount.
    const amountControl = this.form.get(['fees', 'fees', index, 'amountRefundable']);
    amountControl?.setValue(+newValue as WhenRefundable === WhenRefundable.NotRefundable ? 0 : +amountControl.value);
  }

  public confirmDeleteFee(index: number) {
    this.removeFee.emit(index);
    this.removingItem = -1;
  }

  public trackFee(_index: number, feeControlGroup: UntypedFormGroup) {
    // uiId = a unique ID for the UI only to allow this fee to be tracked properly in the ngFor block
    // - Cannot track by index as this causes inconsistent input field values when deleting the non-last fee from the UI
    // - Cannot use fee.type as a unique ID as there may be multiple custom fees
    // - Cannot use fee.customFeeName as unique ID as it can be changed by the user and therefore causes the input
    //   controls to be recreated as the user is typing and lose focus on the input - very not good
    return feeControlGroup.value.uiId;
  }

  public customFeeOnBlur() {
    this.customFeeBlurTimeout = setTimeout(() => {
      this.customFeeFocusIndex = -1;
    }, 100);
  }

  public customFeeOnFocus(index: number) {
    this.customFeeFocusIndex = index;

    if (this.customFeeBlurTimeout) {
      clearTimeout(this.customFeeBlurTimeout);
      this.customFeeBlurTimeout = null;
    }
  }

  public isCustomFeeDropdownHidden(index: number): boolean {
    return this.isCustomFeeType(index) && this.customFeeFocusIndex !== index;
  }

  public getPayableToLabel(index: number): string {
    const feeType = +this.form.get(['fees', 'fees', index, 'type'])?.value ?? 0;

    switch (feeType) {
      case ProductFeeType.Broker:
        return this.brokerName;
      case ProductFeeType.Custom:
        return '';
      default:
        return this.lenderName;
    }
  }

  private get brokerName(): string {
    return this.formDetails?.userEsisInformation?.broker?.brokerName ?? '';
  }

  private get lenderName(): string {
    return this.product.lender;
  }

  public isAddToLoanReadonly(i: number): boolean {
    return this.form.get(['fees', 'fees', i, 'customFeeName'])?.value?.toLowerCase() === 'broker fee' ||
    +(this.form.get(['fees', 'fees', i, 'type'])?.value) === ProductFeeType.Broker;
  }

  public addToLoanClasses(index: number) {
    const validityClasses = this.validityClasses(['fees', 'fees', index, 'addToLoan']);
    return {
      'disabled': this.isAddToLoanReadonly(index),
      ...validityClasses,
    };
  }
}
