import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { NgIf } from '@angular/common';
import { EsisHtmlEditorComponent } from '../esis-html-editor/esis-html-editor.component';
import { Editor, NgxEditorModule } from 'ngx-editor';

@Component({
  selector: 'app-additional-obligations',
  templateUrl: 'additional-obligations.component.html',
  styleUrls: ['additional-obligations.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxEditorModule,
    NgIf,
    EsisHtmlEditorComponent,
  ],
})

export class AdditionalObligationsComponent implements OnInit, OnDestroy {
  @Input() public additionalObligations: UntypedFormGroup;
  @Input() public linkedSavings: UntypedFormGroup;
  @Input() public springBoard: UntypedFormGroup;
  @Input() public lenderUser: string | null;
  public editor: Editor;

  public ngOnInit(): void {
    this.editor = new Editor();
  }

  public ngOnDestroy(): void {
    this.editor.destroy();
  }

  public get isBarclays() {
    return this.lenderUser === 'Barclays';
  }

  public controlText(formControlName: string) {
    return this[formControlName].get('text');
  }

  public valid(formControlName: string, name:string): boolean {
    return this[formControlName].controls[name].valid && this[formControlName].controls[name].touched;
  }

  public invalid(formControlName: string, name:string): boolean {
    return this[formControlName].controls[name].invalid && this[formControlName].controls[name].touched;
  }
}
