import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '../../../sentry';

import { environment } from './environments/environment';
// import { hmrBootstrap } from './hmr';
import { AppComponent } from './app/app.component';
import { MssFormlyModule } from '@msslib/components/formly/mss-formly-form.module';
import { ComponentsModule } from './app/components/components.module';
import { MssLibModule } from '@msslib/msslib.module';
import { routes } from './app/app.routes';
import { provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MiGenericService } from './app/pages/mi/mi-generic-report-viewer/mi-generic.service';
import { MiSurveyingServicesService } from './app/pages/mi/mi-surveying-services/mi-surveying-services.service';
import { ConfigService } from '@msslib/services/config.service';

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: 'https://6985d9641ccc4ec284cf0a1acbc95265@sentry.theidol.com/63',
    attachStacktrace: true,
    debug: false,
    environment: 'production',
    maxValueLength: 5000,
    // // Set tracesSampleRate to 1.0 to capture 100%
    // // of transactions for performance monitoring.
    // // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}


bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, CommonModule, FormsModule, ReactiveFormsModule,
      MssLibModule.forRoot(environment.mssLibConfig), ComponentsModule, MssFormlyModule),
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.load(),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    MiSurveyingServicesService,
    MiGenericService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withRouterConfig({ canceledNavigationResolution: 'computed' })),
  ],
});

// if (environment.hmr) {
//   if ((module as any).hot) {
//     hmrBootstrap(module, bootstrap);
//   } else {
//     // eslint-disable-next-line no-console
//     console.error('HMR is not enabled for webpack-dev-server!');
//     // eslint-disable-next-line no-console
//     console.log('Are you using the --hmr flag for ng serve?');
//   }
// } else {
//   // eslint-disable-next-line no-console
//   bootstrap().catch(err => console.log(err));
// }
