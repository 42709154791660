<!--Implement full modal here (instead of just content) so that we can dynamically change title based on current page-->
<div class="modal-header">
  <h3 class="modal-title">{{ modalTitle }}</h3>
  <div class="d-flex align-items-center">
    <button
      *ngIf="isFormPage"
      type="submit"
      (click)="onSubmit()"
      [class.btn-2022--disabled]="!form.valid"
      [class.btn-2022--secondary]="form.valid"
      class="btn me-2 modal-header-btn"
    >
      Generate Documents
    </button>
    <button
      *ngIf="isDownloadPage"
      type="button"
      (click)="activePage = 2"
      class="btn me-2 btn-2022--secondary modal-header-btn"
    >
      Amend
    </button>
    <button type="button" aria-label="Close" class="btn-close" (click)="dismiss()">
    </button>
  </div>
</div>

<div class="modal-body bg-light">
  <ng-container [ngSwitch]="activePage">
    <!-- Loading page -->
    <div *ngSwitchCase="page.Loading" class="d-flex flex-column align-items-center justify-content-center">
      <div class="spinner-border text-2022--primary" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <!-- Extra details request form -->
    <div class="esis-form-container" *ngSwitchCase="page.DocumentPreferences">
      <p>
        Please provide us with some additional information that will make it easier for you to generate documents.
        You only need to provide this information once and it can be edited at any time in 'My Account'.
      </p>
      <lib-firm-details
        #firmDetailsForm
        *ngIf="isSsoUser$ | async"
        class="d-block mb-4"
        [showSave]="false"
        (requestForbidden)="modalService.dismiss()"
      ></lib-firm-details>
      <lib-document-preferences
        #documentPreferencesForm
        [showSave]="false"
        (requestForbidden)="modalService.dismiss()"
      ></lib-document-preferences>
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-md-offset-0 pt-3">
            <div class="d-grid">
              <button type="button" class="btn btn-2022--secondary" (click)="saveOneTimeForms()">Save Document Preferences</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Main ESIS form -->
    <ng-container *ngSwitchCase="page.Form">
      <div class="d-flex flex-column align-items-center justify-content-center loading-overlay" *ngIf="isGeneratingDocs">
        <div class="spinner-border text-2022--primary" style="width: 3rem; height: 3rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <span class="mt-3">Please hold on a second while we generate your documents...</span>
      </div>

      <div class="form-container">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div>
            <lib-esis-product-summary
              [form]="form"
              [product]="product"
              [searchDetails]="searchDetails"
              [formDetails]="esisFormDetails"
              [isBridging]="isBridging"
              [isEorOnly]="isEorOnly"
            ></lib-esis-product-summary>
          </div>
          <div>
            <lib-esis-additional-information
              [form]="form"
              [searchDetails]="searchDetails"
              [formDetails]="esisFormDetails"
              [product]="product"
              (addFee)="createFee()"
              (removeFee)="removeFee($event)"
            ></lib-esis-additional-information>
          </div>
        </form>
      </div>
    </ng-container>

    <!-- ESIS/EOR download buttons -->
    <ng-container *ngSwitchCase="page.DocDownload">
      <p>{{esisMessage}}</p>
      <div class="row justify-content-center">
        <div *ngFor="let button of downloadButtons; trackBy: trackByIndex" class="col-4">
          <button
            type="button"
            class="d-flex flex-column align-items-center py-3 px-4 h-100 w-100 btn btn-2022--secondary"
            [class.btn-2022--light-1]="!button.enabled"
            (click)="downloadDocument(button.docType)"
            [disabled]="!button.enabled"
          >
            <p><i class="fas fa-download fa-2x"></i></p>
            <p class="my-2"><strong>Download {{ button.name }}</strong></p>
            <p *ngIf="showDownloadButtonDescription" [ngSwitch]="button.enabled">
              <ng-container *ngSwitchCase="true">{{ button.description }}</ng-container>
              <ng-container *ngSwitchCase="false">{{ button.unavailableDescription }}</ng-container>
            </p>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
