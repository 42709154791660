import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { LendingType } from '../../models';
import { LendingTypeService } from '../../services';
import { IInputList } from '@msslib/models/input';
import { LendingTypeCode } from 'apps/shared/src/models';
import { RadioButtonsComponent } from '../radio-buttons/radio-buttons.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-lending-type-buttons',
  styleUrls: ['lending-type-buttons.component.scss'],
  templateUrl: 'lending-type-buttons.component.html',
  standalone: true,
  imports: [NgIf, RadioButtonsComponent],
})
export class LendingTypeButtonsComponent implements OnInit {
  @Input() public value: string;
  @Input() public list: IInputList[];
  @Input() public filter: string[];
  @Input() public all: boolean;
  @Output() public lendingTypeSelect = new EventEmitter<LendingType>();
  public text: string;

  public constructor(public lendingTypeService: LendingTypeService) {}

  public ngOnInit() {
    if (!this.lendingTypeService.lendingTypes) {
      this.lendingTypeService.getLendingTypes().subscribe();
    }
  }

  public get isReady() {
    return !!this.list || !!this.lendingTypes;
  }

  public get lendingTypes(): LendingType[] {
    return this.lendingTypeService.lendingTypes;
  }

  public get inputList() {
    if (this.list) {
      return this.list;
    }

    const lendingTypes = this.lendingTypeService.lendingTypes.filter(l => !l.archived).map((l) => {
      return {
        value: l.code,
        text: l.name,
      } as IInputList;
    });

    if (this.all) {
      lendingTypes.unshift({
        value: LendingTypeCode._Unset,
        text: 'All',
      } as IInputList);
    }
    if (this.filter) {
      return lendingTypes.filter((type) => this.filter.includes(type.value));
    }
    return lendingTypes;
  }

  public getText(code: string) {
    return this.lendingTypes.find((l) => l.code === code)?.name;
  }

  public setLendingType(code: LendingTypeCode) {
    const finalCode = this.lenderCode(code);
    const lendingType = finalCode as LendingTypeCode === LendingTypeCode._Unset
      ? this.lenderAllType
      : this.lendingTypeService.lendingTypes.find((l) => l.code as LendingTypeCode === finalCode);
    this.lendingTypeService.lendingType = lendingType;
    if (lendingType) {
      this.lendingTypeSelect.next(lendingType);
    }
  }

  public lenderCode(code: LendingTypeCode) {
    if (code === LendingTypeCode._Unset && this.all) {
      return LendingTypeCode._Unset;
    } else if (code === LendingTypeCode._Unset && !this.all) {
      return LendingTypeCode.Res;
    }
    return code;
  }

  public get lenderAllType() {
    return {
      id: -1,
      name: 'All',
      code: LendingTypeCode._Unset,
      logoUrl: '',
      sortOrder: 0,
      brokerVisible: false,
      archived: false,
    };
  }
}
