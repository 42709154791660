import { Component, Input } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-external-reference-rate',
  templateUrl: 'external-reference-rate.component.html',
  styleUrls: ['external-reference-rate.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
  ],
})
export class ExternalReferenceRateComponent {
  @Input() public externalReferenceRate: AbstractControl;

  public get valid() {
    return this.externalReferenceRate.valid && this.externalReferenceRate.touched;
  }

  public get invalid() {
    return this.externalReferenceRate.invalid && this.externalReferenceRate.touched;
  }
}
