import {Component, HostBinding, TemplateRef} from '@angular/core';
import { ToastService } from '../../services';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'lib-toast',
  styleUrls: ['toast.component.scss'],
  templateUrl: 'toast.component.html',
  standalone: true,
  imports: [
    NgFor,
    NgbToast,
    NgIf,
    NgTemplateOutlet,
  ],
})
export class ToastComponent {
  public constructor(public toastService: ToastService) {}

  @HostBinding('class.ngb-toasts') public ngbToasts = true;

  public isTemplate(toast: Record<string, unknown>) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
