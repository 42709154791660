import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { NgClass, NgFor } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  templateUrl: 'select.component.html',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    ReactiveFormsModule,
    FormlyModule,
    NgFor,
  ],
})
export class SelectFieldTypeComponent extends FieldType {
  public static defaultOptions: FormlyFieldConfig = {
    props: {
      placeholder: 'Please select...',
    },
  };
}
