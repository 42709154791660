import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumvalues',
  standalone: true,
})
export class EnumValuesPipe implements PipeTransform {
  public transform(enumType: any) {
    return Object.entries(enumType)
      .filter(([key]) => !isNaN(+key))
      .map(([key, value]) => ({ key, value }));
  }
}
