import { Component } from '@angular/core';
import { ModalService } from '@msslib/services/modal.service';
import { FieldType } from '@ngx-formly/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-ff-separator',
  styleUrls: ['./separator.component.scss'],
  templateUrl: './separator.component.html',
  standalone: true,
  imports: [NgIf],
})
export class SeparatorComponent extends FieldType {
  public constructor(private modalService: ModalService) {
    super();
  }

  public openTooltip() {
    this.modalService
      .open({
        title: this.props.header !== undefined ? this.props.header : this.props.addText,
        message: this.props.headerTooltip,
      }).then(() => null, () => null);
  }

  public close() {
    this.modalService.close();
  }
}
